import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  InputGroup,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Edit from "../../../Assets/Edit.png";
import Delete from "../../../Assets/Delete.png";
import EyeOpen from "../../../Assets/eyeopen.png";
import ImageNotFound from "../../../Assets/Noimage.png";
import EyeClose from "../../../Assets/eyeclose.png";
import { FiActivity } from "react-icons/fi";
import { FaToggleOn, FaToggleOff, FaLink, FaEyeSlash } from "react-icons/fa";
import { MdAssignmentAdd } from "react-icons/md";
import { RotatingLines } from "react-loader-spinner";
import XLSX from "xlsx-color";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import "./index.css";
import Select from 'react-select';
import { APIURL, IMAGEMODELURL } from "../../../Process";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ShowPasswardModel from "../../ShowPasswardModel";
import { handleKeyDownNumber, handlePaste } from "../../../HelperMethod";

const ViewUserAdminDetails = () => {
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState();
  const [openModel, setOpenModel] = useState(false);
  const [showPasswordID, setShowPasswordID] = useState("");
  const [pageCount, setPageCount] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [DestinationData, setDestinationData] = useState([])
  const [filterData, setFilterData] = useState({
    emailID: "",
    mobileNumber: "",
  });

  useEffect(() => {
    fetchInitialUserAdminDetailsData(pageCount);
  }, []);

  const fetchInitialUserAdminDetailsData = async (count) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${APIURL}User/getUserAdmin`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "UserID": "",
          "Name": "",
          "MobileNo": "",
          "UserType": "Admin",
          "IsActive": "",
          "IsDelete": "false",
          IsPagination: "true",
          UniqueID: "",
          PerPage: count,
        }),
      });
      const result = await res.json();
      console.log(result, "opopopopopopopopo")
      // setTotalCount(result.total[0].TotalCount);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      console.log(tempt, "tempttempt")
      setIsLoading(false);
      setTableData(tempt);
      setPageId(1);
      setAllTableData(tempt);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const confirmDelete = async (id) => {
    try {
      const res = await fetch(`${APIURL}User/RemoveUser`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UserID: id,
        }),
      });

      const result = await res.json();
      if (result.status == 1) {
        Swal.fire({
          title: "Successfully Deleted!",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        fetchInitialUserAdminDetailsData(pageCount);
      } else {
        Swal.fire({
          title: result?.message || "",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
      console.log(result);
    } catch (err) {
      console.log(err);
    }
  };
  const handleDeleteUserAdminDetails = async (id) => {
    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmDelete(id);
      }
    });
  };
  const editAppointmentDetails = (element) => {
    navigate("/westKameng/addUserAdmin", {
      state: element.obj,
    });
  };
  const handleEyeOn = (index) => {
    console.log("eyeon");
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: true,
    });
    setTableData(tempt);
  };
  const handleEyeOff = (index) => {
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: false,
    });
    setTableData(tempt);
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleFilterInputChange = (e) => {
    setFilterData({
      [e.target.name]: e.target.value,
    });
  };


  const setNextApi = async () => {
    console.log(pageId, "pageID", pageCount);
    if (pageId * pageCount < allTableData.length) {
      const tempt = [...allTableData];
      console.log(tempt, pageId * pageCount, (pageId + 1) * pageCount);
      const sliced = tempt.slice(pageId * pageCount, (pageId + 1) * pageCount);
      setTableData(sliced);
      setPageId(pageId + 1);
      return;
    }
    const paginationIndex = allTableData[pageId * pageCount - 1]?.obj?.UserID;
    if (!paginationIndex) {
      return;
    }
    console.log(pageId, allTableData);
    try {
      const res = await fetch(`${APIURL}User/getUserAdmin`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          MobileNo: filterData.mobileNumber || "",
          EmailID: filterData.emailID || "",
          "UserType": "Admin",
          IsPagination: "true",
          IsActive: "",
          IsDelete: "false",
          UniqueID: `${paginationIndex}`,
          PerPage: `${pageCount}`,
        }),
      });
      const result = await res.json();
      console.log(result);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      console.log(tempt, "tempt");
      setTableData(tempt);
      setPageId(pageId + 1);
      setAllTableData([...allTableData, ...tempt]);
    } catch (err) {
      console.log(err);
    }
  };
  const setPreviousApi = () => {
    if (pageId == 1) {
      return;
    }
    const tempt = [...allTableData];
    console.log(pageId, "pageId");
    console.log(
      pageId,
      tempt,
      (pageId - 1) * pageCount,
      pageId * pageCount + 1
    );
    const sliced = tempt.slice(
      (pageId - 2) * pageCount,
      (pageId - 1) * pageCount
    );
    setTableData(sliced);
    setPageId(pageId - 1);
  };
  const searchHandle = async (count, paginationIndex, filterData) => {
    // setIsLoading(true);
    try {

      const res = await fetch(`${APIURL}User/getUserAdmin`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          MobileNo: filterData.mobileNumber || "",
          EmailID: filterData.emailID || "",
          "UserType": "Admin",
          "IsActive": "",
          "IsDelete": "false",
          IsPagination: "true",
          UniqueID: `${paginationIndex}`,
          PerPage: count ? count.toString() : "",
        }),
      });
      const result = await res.json();
      // console.log(result);
      // setTotalCount(result.total[0].TotalCount);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      console.log(tempt, "?tempt");
      setTableData(tempt);
      setIsLoading(false);
      setAllTableData(tempt);
      setPageId(1);
    } catch (err) {
      // setIsLoading(false);
      console.log(err);
    }
  };
  const handlePageCount = (e) => {
    setPageCount(e.target.value);
    searchHandle(e.target.value, "", filterData);
  };
  const handleCancle = () => {
    setFilterData({
      emailID: "",
      mobileNumber: "",
    });
    fetchInitialUserAdminDetailsData(pageCount);
  };
  async function fetchExcelData() {
    // setIsLoading(true)
    setIsLoading(true);
    try {
      const res = await fetch(`${APIURL}User/getUserAdmin`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          MobileNo: filterData.mobileNumber || "",
          EmailID: filterData.emailID || "",
          "UserType": "Admin",
          "IsActive": "",
          "IsDelete": "false",
          IsPagination: "false",
          UniqueID: "",
          PerPage: "",
        }),
      });
      const result = await res.json();
      handleDownloadExcel(result.data);
    } catch (err) {
      console.log(err);
    }
  }
  const handleDownloadExcel = (array) => {
    const header = [
      "Name",
      "Mobile No",
      "Email ID",
      "Address"
    ];
    const body = [];
    array?.forEach((element, index) => {
      body.push([
        element.Name || "",
        element.MobileNo || "",
        element.EmailID || "",
        element.Address || ""
      ]);
    });
    if (!body.length) {
      setIsLoading(false);
      return Swal.fire({
        title: "No Data Found!",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    }
    //   setIsLoading(false)

    const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);

    header.forEach((col, index) => {
      ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
        font: { bold: true, color: { rgb: "f7f4ef" } }, // White font color
        fill: { patternType: "solid", fgColor: { rgb: "54153255" } }, // Blue background color
        alignment: { wrapText: true, horizontal: "center" },
      };
    });

    ws1["!cols"] = [
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "UserAdmin Details");

    // Save the workbook
    setIsLoading(false);
    XLSX.writeFile(
      wb,
      `UserAdmin_Details${moment().format("DD-MM-YYYY_HH:mm")}.xlsx`
    );
  };

  const handleUserAdminActive = async (UserID, status) => {

    try {
      const res = await fetch(`${APIURL}User/UpdateActiveStatus`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UserID: UserID,
          Status: `${status}`,
        }),
      });
      const result = await res.json();
      if (result.status == 1) {
        searchHandle(pageCount, "", filterData);
      } else {
        Swal.fire({
          title: result.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false
        });
      }

    } catch (err) {
      console.log(err);
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: "6",
          left: "600px",
          top: "300px",
        }}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="200"
          height="200"
          visible={true}
        />
      </div>
    );
  }
  return (
    <div className="body-wrapper">
      <div className="container-fluid">
        {isLoading && (
          <div
            style={{
              position: "absolute",
              zIndex: "6",
              left: "600px",
              top: "300px",
            }}
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="200"
              height="200"
              visible={true}
            />
          </div>
        )}
        {openModel && (
          <ShowPasswardModel
            openModel={openModel}
            setOpenModel={setOpenModel}
            UserID={showPasswordID}
            UserType={"Admin"}
          />
        )}
        <div
          className={`pt-2 pb-2 UserAdmin-filter  ${isOpen ? "open" : "closed"}`}
        >
          <Row>
            <Col md={2} sm={2} className="col-4">
              <div className="d-flex gap-2 pt-1">
                <span>Total Count</span>
                <span>{totalCount}</span>
              </div>
            </Col>
            <Col md={2} sm={2} className="col-4">
              <Form.Select
                onChange={handlePageCount}
                size="sm"
                className="w-70"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </Form.Select>
            </Col>

            <Col md={{ span: 2, offset: 6 }} className="col-4">
              <Button
                className="float-end"
                md={2}
                variant="outline-primary"
                size="sm"
                onClick={handleToggle}
              >
                {isOpen ? "Close Filter" : "Open Filter"}
              </Button>
            </Col>
          </Row>
          {isOpen && (
            <div
              className="filter-content row"
              style={{
                marginTop: "20px",
                padding: "20px",
                border: "1px solid gray",
              }}
            >
              <Form className="d-flex row pb-4">



                <div className="mb-2 col-md-3">
                  <label
                    htmlFor="mobileNumber"
                    className="form-label fw-semibold"
                  >
                    {" "}
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    name="mobileNumber"
                    maxLength={10}
                    onKeyDown={handleKeyDownNumber}
                    onPaste={handlePaste}
                    placeholder="Mobile Number"
                    value={filterData.mobileNumber}
                    className="form-control"
                    onChange={handleFilterInputChange}
                  />
                </div>
                <div className="mb-2 col-md-3">
                  <label htmlFor="name" className="form-label fw-semibold">
                    {" "}
                    EmailID
                  </label>

                  <input
                    type="text"
                    name="emailID"
                    placeholder="Email  ID"
                    value={filterData.emailID}
                    className="form-control"
                    onChange={handleFilterInputChange}
                  />
                </div>

                <div className="col-md-3" style={{ position: "relative", top: "27px" }}>
                  <Button
                    size="sm"
                    variant="primary"
                    className="me-2"
                    onClick={() => searchHandle(pageCount, "", filterData)}
                  >
                    Apply
                  </Button>

                  <Button
                    className="me-2"
                    onClick={fetchExcelData}
                    size="sm"
                    variant="success"
                  >
                    Export
                  </Button>
                  <Button onClick={handleCancle} size="sm" variant="danger">
                    Cancel
                  </Button>
                </div>
                {/* Add more InputGroup components for additional filters */}
              </Form>
            </div>
          )}
        </div>
        <div className="overflow-auto">
          <Table
            style={{
              boxShadow: "2px 3px 15px",
              textAlign: "left",
              border: "1px solid gray",
            }}
            striped
            bordered
            hover
          >
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>Status</th>
                <th>Name</th>
                <th>Password</th>
                <th>Mobile No</th>
                <th>EmailID</th>
                <th>Address</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((element, index) => (
                <>
                  <tr>
                    <td>{(pageId - 1) * pageCount + (index + 1)}</td>
                    <td>
                      {" "}
                      {element.obj.IsActive ? (
                        <FaToggleOn
                          onClick={() =>
                            handleUserAdminActive(element.obj.UserID, 0)
                          }
                          style={{ color: "green", fontSize: "1.9em" }}
                        />
                      ) : (
                        <FaToggleOff
                          onClick={() =>
                            handleUserAdminActive(element.obj.UserID, 1)
                          }
                          style={{ color: "red", fontSize: "1.8em" }}
                        />
                      )}
                    </td>
                    <td>{element.obj.Name}</td>
                    {/* <td>{element.obj.Password}</td> */}
                    <td>
                      <FaEyeSlash
                        onClick={() => {
                          setOpenModel(true);
                          setShowPasswordID(element.obj.UserID);
                        }}
                        style={{ fontSize: "1.4em", cursor: "pointer" }}
                      />
                    </td>
                    <td>{element.obj.MobileNo}</td>
                    <td>{element.obj.EmailID}</td>
                    <td>{element.obj.Address}</td>
                    <td>
                      <img
                        onClick={() => editAppointmentDetails(element)}
                        src={Edit}
                        height={"25px"}
                        alt=""
                      />
                    </td>
                    <td>
                      <img
                        src={Delete}
                        onClick={() =>
                          handleDeleteUserAdminDetails(element.obj.UserID)
                        }
                        height={"25px"}
                        alt=""
                      />
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
            <tbody>
              <tr>
                {!tableData.length && (
                  <td colSpan={12}>
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#ff0000",
                        backgroundColor: "#f0f0f0",
                        border: "1px solid #e0e0e0",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      NO DATA FOUND
                    </div>
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="pt-2 pb-2">
          <Button onClick={setPreviousApi} variant="primary" size="sm">
            PREVIOUS
          </Button>{" "}
          <Button onClick={setNextApi} variant="secondary" size="sm">
            NEXT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewUserAdminDetails;

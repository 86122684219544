import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import ImageNotFound from "../../../Assets/Noimage.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { APIURL, IMAGEMODELURL } from "../../../Process";
import axios from "axios";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";

import { compressImage } from "../../CommonComponents";
import Cookies from "js-cookie";
const AssignActivity = () => {
  const fileInputRef = useRef(null);
  ;
  const { state } = useLocation();

  const [validImage, setValidImage] = useState(true);
  const [editeIndex, setEditeIndex] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [isAppend, setIsAppend] = useState(false);
  const [updateImage, setUpdateImage] = useState("");
  const [editeElement, setEditeElement] = useState();
  const [status, setStatus] = useState(true);
  const ActivityImageUrl = `${IMAGEMODELURL}/Activity/ActivityImage`;
  console.log(state, "state");
  const navigate = useNavigate();

  const handleClearButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  useEffect(() => {
    handleGetActivityData();
  }, []);

  const [activityData, setActivityData] = useState(
    state?.obj.ActivityDetail || []
  );
  const [input, setInput] = useState({
    ActivityName: "",
    ActivityImage: "",
    // Description: "",
  });

  const handleGetActivityData = async () => {
    const Id = state?.obj?.PlaceID;
    setIsLoading(true);
    try {
      console.log(state, "state");
      const res = await fetch(`${APIURL}/Activity/GetActivity`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PlaceID: Id,
          PaginationID: "-1",
          PageLength: "",
        }),
      });
      const result = await res.json();
      console.log(result.data, "result");
      setIsLoading(false);
      setActivityData(result.data);
    } catch (err) {
      console.log(err);
    }
  };
  const handleActivityAppend = async (submitStatus) => {
    if (!input.ActivityName || !input.ActivityImage ) {
      setIsAppend(true);
      return;
    }

    if (!validImage) {
      return;
    }

    console.log(input, "input");

    const form = new FormData();
    // form.append("ActivityID",)
    form.append("PlaceID", state?.obj.PlaceID);
    form.append("ActivityName", input.ActivityName);
    form.append("ActivityImage",  input.ActivityImage ? !(input.ActivityImage instanceof Blob) ? input.ActivityImage :  await compressImage(input.ActivityImage,0.9) : "");
    // form.append("Description", input.Description);
    form.append("CreatedByUserID", Cookies.get("ckUserID"));
    form.append("CreatedByUserName", Cookies.get("ckUserName"));
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    axios
      .post(`${APIURL}/Activity/SetActivity`, form, config)
      .then((res) => {
        setInput({
          ActivityName: "",
          ActivityImage: "",
          // Description: "",
        });
        console.log(res, "res");
        handleClearButtonClick();
        handleGetActivityData();
        Swal.fire({
          title: "Activity Assigned Successfully",
          icon: "success",
          timer: 1500,
showConfirmButton: false,
        });
        setIsAppend(false);
        if (submitStatus) {
          navigate("/westKameng/viewplacedetails");
        }
      })
      .catch((err)=>{
        console.log(err)
      });
  };
  const handleClearInput = () => {
    setInput({
      ActivityName: "",
      ActivityImage: "",
      // Description: "",
    });
    handleClearButtonClick();
    setValidImage(true);
    setIsAppend(false);
    setStatus(true);
  };
  const handleInputChange = (e) => {
    if (e.target.name == "ActivityImage") {
      if (
        ["jpg", "png", "jpeg"].includes(
          e.target.value.split(".")[e.target.value.split(".").length - 1]
        ) ||
        e.target.value === ""
      ) {
        setInput({
          ...input,
          [e.target.name]: e.target.files[0],
        });
        setValidImage(true);
      } else {
        setValidImage(false);
      }

      setUpdateImage("");
    } else {
      setInput({
        ...input,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleActivityChange = (index, element) => {
    // const tempt=[...activityData]
    // tempt.splice(index,1,{
    //     ...activityData[index],
    //     [e.target.name]:e.target.value
    // })
    // setActivityData(tempt)

    console.log(element, index, "...");
    setInput({
      ActivityName: element.ActivityName,
      ActivityImage: element.ActivityImage,
      // Description: element.Description || "",
    });
    setUpdateImage(element.ActivityImage);
    setStatus(false);
    handleClearButtonClick();
    setEditeElement(element);
    setEditeIndex(index);
  };
  const confirmActivityDelete = async (ActivityID, index) => {
    try {
      const res = await fetch(`${APIURL}/Activity/RemoveActivity`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ActivityID: ActivityID,
        }),
      });
      const result = await res.json();
      if (result.status === 1) {
        Swal.fire({
          title: "Activity Deleted Successfully",
          icon: "success",
          timer: 1500,
showConfirmButton: false,
        });
        const tempt = [...activityData];
        tempt.splice(index, 1);
        setActivityData(tempt);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleActivityDelete = async (ActivityID, index) => {
    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmActivityDelete(ActivityID, index);
      }
    });
  };
  const EditActiveData = async (element, submitStatus) => {
    if (!input.ActivityName || !input.ActivityImage ) {
      setIsAppend(true);
      return;
    }

    if (!validImage) {
      return;
    }
    const form = new FormData();
    form.append("ActivityID", element.ActivityID);
    form.append("PlaceID", element.PlaceID);
    form.append("ActivityName", input.ActivityName);

    form.append("ActivityImage", input.ActivityImage);
    if (element?.ActivityImage) {
      form.append("OldActivityImage", element?.ActivityImage);
    }
    form.append("UpdatedByUserID", Cookies.get("ckUserID"));
    form.append("UpdatedByUserName", Cookies.get("ckUserName"));
    // form.append("Description", input.Description);
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    axios
      .post(`${APIURL}/Activity/SetActivity`, form, config)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: "Activity Updated Successfully",
          icon: "success",
          timer: 1500,
showConfirmButton: false,
        });
        const tempt = [...activityData];
        tempt.splice(editeIndex, 1, input);
        setActivityData(tempt);
        setInput({
          ActivityName: "",
          ActivityImage: "",
          // Description: "",
        });
        setUpdateImage("");
        setStatus(true);
        handleGetActivityData();
        handleClearButtonClick();
        setIsAppend(false);
        if (submitStatus) {
          navigate("/westKameng/viewplacedetails");
        }
      })
      .catch((err)=>{
        console.log(err)
      });
  };
  const handleActivitySubmit = () => {
    if (!status) {
      EditActiveData(editeElement, true);
    }
    handleActivityAppend(true);
  };
  return (
    <div
      className="body-wrapper"
      style={{ backgroundColor: "#d3d3d3", minHeight: "100vh" }}
    >
      <div className="container-fluid">
        {isLoading && (
          <div
            style={{
              position: "absolute",
              zIndex: "6",
              left: "600px",
              top: "300px",
            }}
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="200"
              height="200"
              visible={true}
            />
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <div className="card mt-3 " style={{ boxShadow: "2px 3px 15px" }}>
              <div className="px-3 pt-4 border-bottom">
                <h5>Assign Activity</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <Table
                    style={{ textAlign: "left", border: "1px solid gray" }}
                    striped
                    bordered
                    hover
                  >
                    <thead>
                      <tr>
                        <th>Activity Name</th>
                        <th>ActivityImage</th>
                        {/* <th>Description</th> */}
                        <th>Add</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            placeholder="Enter Activity Name"
                            value={input.ActivityName}
                            type="text"
                            className="form-control"
                            name="ActivityName"
                            onChange={handleInputChange}
                          />
                          {isAppend && !input.ActivityName && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        </td>
                        <td>
                          <input
                            name="ActivityImage"
                            ref={fileInputRef}
                            onChange={handleInputChange}
                            type="file"
                            accept="image/jpeg, image/png, image/jpg"
                            className="form-control"
                          />
                          {isAppend && !input.ActivityImage && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                          {!status && <span>{updateImage}</span>}
                          {!validImage && (
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              PLEASE SELECT VALID IMAGE{" "}
                            </span>
                          )}
                        </td>
                        {/* <td>
                          <textarea
                            value={input.Description}
                            onChange={handleInputChange}
                            name="Description"
                            style={{ height: "150px" }}
                            rows="2"
                            cols="20"
                            placeholder="Enter Descreption"
                            className="form-control"
                          />
                          {isAppend && !input.Description && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        </td> */}
                        <td>
                          {status ? (
                            <Button
                              onClick={() => handleActivityAppend(false)}
                              variant="primary"
                              size="sm"
                            >
                              ADD
                            </Button>
                          ) : (
                            <Button
                              onClick={() =>
                                EditActiveData(editeElement, false)
                              }
                              variant="primary"
                              size="sm"
                            >
                              UPDATE
                            </Button>
                          )}
                        </td>
                        <td>
                          <Button
                            onClick={handleClearInput}
                            variant="secondary"
                            size="sm"
                          >
                            CANCEL
                          </Button>
                        </td>
                      </tr>
                      {activityData.map((element, index) => (
                        <tr>
                          <td>
                            {element.ActivityName}
                            {/* <input placeholder='Enter Activity Name' type="text" value={element.ActivityName} className='form-control' name='ActivityName' onChange={(e)=>handleActivityChange(e,index)} /> */}
                            {/* {submit && !element.ActivityName && <span style={{color:"red"}}>*</span>} */}
                          </td>
                          <td>
                            <img
                              src={
                                element?.ActivityImage
                                  ? `${ActivityImageUrl}/${element.ActivityImage}`
                                  : ImageNotFound
                              }
                              style={{
                                height: 90,
                                width: 90,
                                borderWidth: 0,
                                border: "1px solid #007AB1",
                              }}
                            />
                          </td>

                          {/* <td> */}
                            {/* {element.Description} */}
                            {/* <textarea  value={element.Description=="null" ? "" : element.Description} name='Description' style={{ height: "150px" }} rows="2" cols="20" placeholder="Enter Descreption" className='form-control' /> */}
                          {/* </td> */}
                          <td>
                            <Button
                              onClick={(e) =>
                                handleActivityChange(index, element)
                              }
                              variant="primary"
                              size="sm"
                            >
                              EDIT
                            </Button>
                          </td>
                          <td>
                            <Button
                              onClick={() => {
                                handleActivityDelete(element.ActivityID, index);
                              }}
                              variant="secondary"
                              size="sm"
                            >
                              DELETE
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex align-items-center gap-2 mt-4">
                  <button
                    type="submit"
                    onClick={() => handleActivitySubmit()}
                    className="btn btn-outline-primary font-weight-bolder ml-2 "
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate("/westKameng/viewplacedetails")}
                    className="btn btn-outline-primary font-weight-bolder ml-2"
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignActivity;

import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  InputGroup,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Edit from "../../src/Assets/Edit.png";
import Delete from "../../src/Assets/Delete.png";
import EyeOpen from "../Assets/eyeopen.png";
import EyeClose from "../Assets/eyeclose.png";
import { FiActivity } from "react-icons/fi";
import { FaToggleOn, FaToggleOff, FaLink } from "react-icons/fa";
import { MdAssignmentAdd } from "react-icons/md";
import { RotatingLines } from "react-loader-spinner";
import XLSX from "xlsx-color";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import { APIURL, IMAGEMODELURL } from "../Process";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from 'react-select';
import DOMPurify from "dompurify";
import Cookies from "js-cookie";

const ViewDestination = () => {
  const navigate = useNavigate();

  const DestinationImageUrl = `${IMAGEMODELURL}/Destination/DestinationImage`;
  const ImageUrl = `${IMAGEMODELURL}/Destination/Images`;
  ;
  console.log(Cookies.get("ckUserID"))
  // const [totalCount, setTotalCount] = useState();
  const [pageCount, setPageCount] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [DestinationData, setDestinationData] = useState([])
  const [filterData, setFilterData] = useState({
    DestinationName: "",
    DestinationTempt: ""
  });

  useEffect(() => {
    GetDestination()
    fetchDestinationData(pageCount);
  }, []);

  const GetDestination = async () => {
    try {
      const res = await fetch(`${APIURL}/Destination/getDestination`, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          DestinationID: '',
          DestinationName: '',
          IsActive: 'true',
          IsDelete: 'false',
          IsPagination: '',
          PerPage: '',
          UniqueID: '',
        })
      })
      const result = await res.json()
      console.log(result, "result")

      setDestinationData(result.data.map(item => ({ value: item.DestinationID, label: item.DestinationName })))
    } catch (err) {
      console.log(err)
    }

  }

  const selectDropDownData1 = (e, action) => {
    console.log(e.value, "epppp")
    setFilterData({
      ...filterData,
      [action.name]: e.value,
      DestinationTempt: e,
    })
  }
  const fetchDestinationData = async (count) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${APIURL}/Destination/getDestination`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DestinationID: "",
          DestinationName: "",
          IsActive: "",
          IsDelete: "false",
          IsPagination: "true",
          PerPage: count,
          UniqueID: ""
        }),
      });

      const result = await res.json();
      console.log(result, "result")
      // setTotalCount(result.total[0].TotalCount);
      const tempt = result.data.map((element) => ({ obj: element }));
      setIsLoading(false);
      setTableData(tempt);
      setPageId(1);
      setAllTableData(tempt);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleEyeOn = (index) => {
    console.log("eyeon");
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: true,
    });
    setTableData(tempt);
  };
  const handleEyeOff = (index) => {
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: false,
    });
    setTableData(tempt);
  };


  async function fetchExcelData() {
    // setIsLoading(true)
    // setIsLoading(true);
    debugger;
    try {
      const res = await fetch(`${APIURL}/Destination/getDestination`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({

          DestinationID: filterData.DestinationName,
          DestinationName: "",
          IsActive: "",
          IsDelete: "false",
          IsPagination: "",
          PerPage: "",
          UniqueID: ""
        }),
      });
      const result = await res.json();
      handleDownloadExcel(result.data);
    }
    catch (err) {
      console.log(err);
    }
  }
  const handleDownloadExcel = (array) => {
    console.log(array, "array");
    const header = [
      "DestinationName",
      "Description",
      "Date",
    ];
    const body = [];
    array?.forEach((element, index) => {
      const descriptionWithoutTags = element.Description.replace(/<[^>]*>?/gm, '');
      body.push([
        element.DestinationName,
        descriptionWithoutTags,
        element.CreatedDate
      ]);
    });
    if (!body.length) {
      setIsLoading(false);
      return Swal.fire({
        title: "No Data Found!",
        icon: "error",
        timer: 1500,
showConfirmButton: false,
      });
    }
    //   setIsLoading(false)
    const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);

    header.forEach((col, index) => {
      ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
        font: { bold: true, color: { rgb: "f7f4ef" } }, // White font color
        fill: { patternType: "solid", fgColor: { rgb: "54153255" } }, // Blue background color
        alignment: { wrapText: true, horizontal: "center" },
      };
    });

    ws1["!cols"] = [
      { width: 20 },
      { width: 70 },
      { width: 20 },
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "Destination Details");

    // Save the workbook
    setIsLoading(false);
    XLSX.writeFile(
      wb,
      `Destination_Details${moment().format("DD-MM-YYYY_HH:mm")}.xlsx`
    );
  };

  const confirmUsereDelete = async (id) => {
    debugger;
    try {
      console.log()
      const res = await fetch(`${APIURL}/Destination/removeDestination`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DestinationID: id,
          RemovedByUserType: Cookies.get("ckUserType"),
          RemovedByUserID: Cookies.get("ckUserID"),
          RemovedByName: Cookies.get("ckUserName")
        }),
      });

      const result = await res.json();
      if (result.status == 1) {
        Swal.fire({
          title: "Successfully Deleted!",
          icon: "success",
          timer: 1500,
showConfirmButton: false,
        });
        fetchDestinationData(pageCount);
      }
      console.log(result);
    } catch (err) {
      console.log(err)
    }
  }
  const handleDeleteUserDetails = async (id) => {

    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red"

    }).then((result) => {

      if (result.isConfirmed) {
        confirmUsereDelete(id)
      }
    });
  };

  const editDestinationDetails = (element) => {
    debugger;
    navigate("/westKameng/addDestination", {
      state: element.obj.DestinationID,
    });

  };

  const setNextApi = async () => {
    console.log(pageId, "pageID", pageCount);
    if (pageId * pageCount < allTableData.length) {
      const tempt = [...allTableData];
      console.log(tempt, pageId * pageCount, (pageId + 1) * pageCount);
      const sliced = tempt.slice(pageId * pageCount, (pageId + 1) * pageCount);
      setTableData(sliced);
      setPageId(pageId + 1);
      return;
    }
    const paginationIndex = allTableData[pageId * pageCount - 1]?.obj?.DestinationID;
    if (!paginationIndex) {
      return;
    }

    console.log(pageId, allTableData);
    try {
      const res = await fetch(`${APIURL}/Destination/getDestination`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({

          DestinationID: "",
          DestinationName: filterData.DestinationName,
          IsActive: "",
          IsDelete: "false",
          IsPagination: "true",
          PerPage: `${pageCount}`,
          UniqueID: `${paginationIndex}`
        }),
      });
      const result = await res.json();
      console.log(result);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
        });
      });
      console.log(tempt, "tempt");
      setTableData(tempt);
      setPageId(pageId + 1);
      setAllTableData([...allTableData, ...tempt]);
    } catch (err) {
      console.log(err);
    }
  };

  const setPreviousApi = () => {
    if (pageId == 1) {
      return;
    }
    const tempt = [...allTableData];
    console.log(pageId, "pageId");
    console.log(
      pageId,
      tempt,
      (pageId - 1) * pageCount,
      pageId * pageCount + 1
    );
    const sliced = tempt.slice(
      (pageId - 2) * pageCount,
      (pageId - 1) * pageCount
    );
    setTableData(sliced);
    setPageId(pageId - 1);
  };

  const searchHandle = async (count, paginationIndex, filterData) => {
    // setIsLoading(true);
    try {
      console.log(filterData, "filterDataaaaaaaaa", count);
      const res = await fetch(`${APIURL}/Destination/getDestination`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DestinationID: filterData.DestinationName,
          DestinationName: "",
          IsActive: "",
          IsDelete: "false",
          IsPagination: "true",
          PerPage: count,
          UniqueID: ""
        }),
      });
      const result = await res.json();
      console.log(result);
      // setTotalCount(result.total[0].TotalCount);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
        });
      });
      console.log(tempt, "?tempt");
      setTableData(tempt);
      setIsLoading(false);
      setAllTableData(tempt);
      setPageId(1);
    } catch (err) {
      // setIsLoading(false);
      console.log(err);
    }
  };
  const handlePageCount = (e) => {
    setPageCount(e.target.value);
    searchHandle(e.target.value, 0, filterData);
  };

  const handleCancle = () => {
    setFilterData({
      DestinationName: "",
    });
    fetchDestinationData(pageCount);
  };

  const handleTourActive = async (DestinationID, status) => {
    console.log(DestinationID, "DestinationID");
    try {
      const res = await fetch(`${APIURL}/Destination/updateDestinationStatus`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DestinationID: DestinationID,
          IsActive: `${status}`,
          UpdatedByUserType: Cookies.get("ckUserType"),
          UpdatedByUserID: Cookies.get("ckUserID"),
          UpdatedByName: Cookies.get("ckUserName")
        }),
      });
      const result = await res.json(); // Move this line here
      console.log(result, "placeActiveStatus");
      searchHandle(pageCount, 0, filterData);
    } catch (err) {
      console.log(err)
    }
  };


  const deleteImageDetails = async (element) => {
    console.log(element, "element");
    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmImageDelete(element);
      }
    });
  };
  const confirmImageDelete = async (element) => {
    console.log(element, "element");
    try {
      const res = await fetch(`${APIURL}/ImageDetail/DeleteImages`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ImageDetailID: `${element.ImageDetailID}`,
          ImageType: element.ImageType,
        }),
      });
      const result = await res.json();
      if (result.status === 1) {
        Swal.fire({
          title: "Successfully Deleted!",
          icon: "success",
          timer: 1500,
showConfirmButton: false,
        });
        fetchDestinationData(pageCount);
      }
      console.log(result, "result");
    } catch (err) {
      console.log(err);
    }
  };


  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: "6",
          left: "600px",
          top: "300px",
        }}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="200"
          height="200"
          visible={true}
        />
      </div>
    );
  }
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="body-wrapper">
      <div className="container-fluid">
        {isLoading && (
          <div
            style={{
              position: "absolute",
              zIndex: "6",
              left: "600px",
              top: "300px",
            }}
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="200"
              height="200"
              visible={true}
            />
          </div>
        )}
        <div className={`pt-2 pb-2 place-filter  ${isOpen ? "open" : "closed"}`}>
          <Row>
            {/* <Col md={2} sm={2} className="col-4">
              <div className="d-flex gap-2 pt-1">
                <span>Total Count</span>
                <span>{totalCount}</span>
              </div>
            </Col> */}
            <Col md={2}>
              <Form.Select
                onChange={handlePageCount}
                size="sm"
                className="w-50"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </Form.Select>
            </Col>

            <Col>
              <Button
                className="float-end"
                md={2}
                variant="outline-primary"
                size="sm"
                style={{ marginBottom: '5px' }}
                onClick={handleToggle}
              >
                {isOpen ? "Close Filter" : "Open Filter"}
              </Button>
            </Col>
          </Row>
          {isOpen && (
            <div
              className="filter-content"
              style={{
                marginTop: "20px",
                padding: "20px",
                border: "1px solid gray",
              }}
            >
              <Form className="d-flex gap-4">

                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="DestinationName" className="form-label fw-semibold">
                      {" "}
                      Destination Name
                    </label>
                    <Select name='DestinationName' placeholder="--Select Destination--" onChange={selectDropDownData1} value={filterData.DestinationTempt} options={[{ label: '--Select Destination --', value: '' }, ...DestinationData]} />
                  </div>
                </div>

                <div style={{ position: "relative", top: "27px", marginRight: '5px' }}>
                  <Button size="sm" variant="primary" className="me-2" onClick={() => searchHandle(pageCount, 0, filterData)} > Apply </Button>
                  <Button className="me-2" onClick={fetchExcelData} size="sm" variant="success"> Export </Button>
                  <Button onClick={handleCancle} size="sm" variant="danger">Cancel</Button>
                </div>
              </Form>

            </div>
          )}
        </div>
        <div className="overflow-auto">
          <Table style={{ boxShadow: "2px 3px 15px", textAlign: "left", border: "1px solid gray", }}
            striped
            bordered
            hover
          >
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>Images</th>
                <th>Status</th>
                <th>Destination Image</th>
                <th>Destination Name</th>
                <th>Description</th>
                <th>Edit</th>
                <th>Delete</th>

              </tr>
            </thead>
            <tbody>
              {tableData.map((element, index) => (
                <>
                  <tr>
                    <td>{(pageId - 1) * pageCount + (index + 1)}</td>

                    <td>
                      {!element.isDetails &&
                        <img
                          onClick={() => handleEyeOn(index)}
                          src={EyeOpen}
                          height={"22px"}
                          alt=""
                        />
                      }
                      {element.isDetails &&
                        <img
                          onClick={() => handleEyeOff(index)}
                          src={EyeClose}
                          height={"22px"}
                          alt=""
                        />
                      }
                    </td>

                    <td>
                      {" "}
                      {element.obj.IsActive ? (
                        <FaToggleOn
                          onClick={() =>
                            handleTourActive(element.obj.DestinationID, 0)
                          }
                          style={{ color: "green", fontSize: "1.9em" }}
                        />
                      ) : (
                        <FaToggleOff
                          onClick={() =>
                            handleTourActive(element.obj.DestinationID, 1)
                          }
                          style={{ color: "red", fontSize: "1.8em" }}
                        />
                      )}
                    </td>

                    {/* <td>
                      <img
                        src={
                          `${DestinationImageUrl}/${element.obj.DestinationImage}`
                        }
                        style={{
                          height: 80,
                          width: 80,
                          borderWidth: 0,
                          border: "1px solid #007AB1",
                        }}
                      />
                    </td> */}
                    <td>
                      <img
                        src={`${DestinationImageUrl}/${element.obj.DestinationImage}`}
                        // onError={(e) => { e.target.onerror = null; e.target.src = "Images/Noimage.png" }}
                        style={{ height: 80, width: 80, borderWidth: 0, border: "1px solid #007AB1", }} alt="Destination Image"
                      />
                    </td>

                    <td>{element.obj.DestinationName}</td>

                    <td>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            element.obj.Description
                          ),
                        }}
                      ></div>
                    </td>

                    <td>
                      <img
                        src={Edit}
                        onClick={() => editDestinationDetails(element)}
                        height={"25px"}
                        alt=""
                      />
                    </td>

                    <td>
                      <img
                        src={Delete}
                        onClick={() => {
                          handleDeleteUserDetails(element.obj.DestinationID);
                        }}
                        height={"25px"}
                        alt=""
                      />
                    </td>

                  </tr>

                  {element.isDetails && (
                    <tr>
                      <td colSpan={11}>
                        <Table
                          style={{
                            textAlign: "left",
                            border: "1px solid gray",
                          }}
                          striped
                          bordered
                          hover
                        >
                          <thead>
                            <th className="p-2">Images Details</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "none" }} className="d-flex">
                                {" "}
                                {element.obj.ImageDetail ? (
                                  element.obj.ImageDetail.map(
                                    (element, index) => (
                                      <div
                                        className="d-flex"
                                        style={{
                                          flexDirection: "row",
                                          width: "100px",
                                          marginBottom: "10px",
                                          marginRight: "10px",
                                        }}
                                      >
                                        <img
                                          key={index}
                                          src={
                                            element?.Image
                                              ? `${ImageUrl}/${element.Image}`
                                              : require("../Assets/Noimage.png")
                                          }
                                          style={{
                                            marginLeft: "10px",
                                            height: 90,
                                            width: 90,
                                            borderWidth: 0,
                                            border: "1px solid #007AB1",
                                          }}
                                        />
                                        <span
                                          onClick={() =>
                                            deleteImageDetails(element)
                                          }
                                          style={{
                                            color: "black",
                                            textAlign: "right",
                                            fontSize: "1.4em",
                                            position: "relative",
                                            right: "20px",
                                            bottom: "4px",
                                          }}
                                        >
                                          <MdDelete />
                                        </span>
                                      </div>
                                    )
                                  )
                                ) : (
                                  <td
                                    style={{
                                      padding: "5px",
                                      fontSize: "1.3em",
                                      color: "gray",
                                    }}
                                  >
                                    No Images Found 😟
                                  </td>
                                )}
                              </td>      
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
            <tbody>
              <tr>
                {!tableData.length && (
                  <td colSpan={11}>
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#ff0000",
                        backgroundColor: "#f0f0f0",
                        border: "1px solid #e0e0e0",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      NO DATA FOUND
                    </div>
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="pt-2 pb-2">
          <Button onClick={setPreviousApi} variant="primary" size="sm">
            PREVIOUS
          </Button>{" "}
          <Button onClick={setNextApi} variant="secondary" size="sm">
            NEXT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewDestination;


import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { APIURL } from "../Process";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddContactUs = () => {

  const [isSubmit, setIsSubmit] = useState(false);
  const [isCancle, setIsCancle] = useState(false);
  const [validMail, setValidMail] = useState(true);

  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state, "state");
  const [formData, setFormData] = useState({
    EmailID: {
      textValue: state?.EmailID || "",
      required: false,
    },
    ContactNo1: {
      textValue: state?.ContactNo1 || "",
      required: true,
    },
    ContactNo2: {
      textValue: state?.ContactNo2 || "",
      required: false,
    },
    Description: {
      textValue: state?.Description || "",
      required: true,
    },
    Address: {
      textValue: state?.Address || "",
      required: true,
    },
  });
  const handleInputChange = (e) => {
    let lastInputString = e.target.value[e.target.value.length - 1];
    console.log(lastInputString);
    if (
      e.target.name == "ContactNo1" ||
      e.target.name == "ContactNo2"
    ) {
      if (
        e.target.value == "" ||
        (lastInputString.charCodeAt() >= 48 &&
          lastInputString.charCodeAt() <= 57)
      ) {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.value,
          },
        });
      } else {
        return;
      }
      return;
    }
    if (e.target.name === "EmailID") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(e.target.value) || e.target.value=="" ) {
        setValidMail(true);
      } else {
        setValidMail(false);
      }
    }

    setFormData({
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        textValue: e.target.value,
      },
    });
  };

  const handleSubmit = async (e) => {
    setIsSubmit(true);
    e.preventDefault();
    console.log(formData, "formData");
    let validForm = true;

    for (let key in formData) {
      if (formData[key].required) {
        if (!formData[key].textValue) {
          validForm = false;
        }
        if (!validForm) {
          return;
        }
      }

      if (!validMail) {
        return;
      }
    }
    
    if((formData?.ContactNo1.textValue?.length && formData.ContactNo1.textValue.length<10) || (formData?.ContactNo2?.textValue.length && formData?.ContactNo2?.textValue.length<10) ){
return
    }
 

    try {
      const res = await fetch(`${APIURL}/ContactUs/SetContactUs`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ContactUsID: state?.ContactUsID || "",
          Description: formData.Description.textValue,
          Address: formData.Address.textValue,
          ContactNo1: formData.ContactNo1.textValue,
          ContactNo2: formData.ContactNo2.textValue,
          EmailID: formData.EmailID.textValue,
        }),
      });
      const result = await res.json();
      
      if(result.status){
        Swal.fire({
            title: "Data Inserted successfully!",
            icon: "success",
            timer: 1500,
showConfirmButton: false
          });
          navigate("/westKameng/viewContactUs")
      }else{
        Swal.fire({
            title: result.message,
            icon: "error",
            timer: 1500,
showConfirmButton: false
          });
      }

    } catch (err) {
      console.log(err);
    }
  };
  const handleCancle = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      EmailID: {
        textValue: "",
        required: false,
      },
      ContactNo1: {
        textValue: "",
        required: true,
      },
      ContactNo2: {
        textValue: "",
        required: false,
      },
      Description: {
        textValue: "",
        required: true,
      },
      Address: {
        textValue: "",
        required: true,
      },
    }));

    setIsCancle(true);
  };
  return (
    <div
      className="body-wrapper"
      style={{ backgroundColor: "#d3d3d3", minHeight: "100vh" }}
    >
      <div className="container-fluid">
        <div className="card mt-4" style={{ boxShadow: "2px 3px 15px" }}>
          <div className="px-3 pt-4 border-bottom">
            <h5>Add Contact Us</h5>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-3 mb-2">
                  <div className="form-group mb-3">
                    <label htmlFor="contact" className="form-label fw-semibold">
                      {" "}
                      Contact Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      onChange={handleInputChange}
                      name="ContactNo1"
                      value={formData.ContactNo1.textValue}
                      maxLength={11}
                      type="text"
                      className="form-control"
                      placeholder="Enter Contact Number"
                      style={{
                        border: `1px solid ${
                          isSubmit &&
                          (!formData.ContactNo1.textValue ||
                            formData.ContactNo1.textValue.length < 10)
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    ></input>
                    {isSubmit && !formData.ContactNo1.textValue && (
                      <span className="text-danger">*</span>
                    )}
                    {isSubmit &&
                      formData.ContactNo1.textValue &&
                      formData.ContactNo1.textValue.length <10 && (
                        <span className="text-danger">
                          Please Enter Valid Contact No
                        </span>
                      )}
                  </div>
                  <div className="form-group mb-3">
                    <label
                      htmlFor="ContactNo2"
                      className="form-label fw-semibold"
                    >
                      {" "}
                      Contact Number 2{" "}
                    </label>
                    <input
                      onChange={handleInputChange}
                      name="ContactNo2"
                      value={formData.ContactNo2.textValue}
                      maxLength={11}
                      type="text"
                      className="form-control"
                      placeholder="Enter Contact Number"
                      style={{
                        border: `1px solid ${
                          isSubmit &&
                          formData.ContactNo2.textValue &&
                          formData.ContactNo2.textValue.length <10
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    ></input>
                    {isSubmit &&
                      formData.ContactNo2.textValue &&
                      formData.ContactNo2.textValue.length < 10 && (
                        <span className="text-danger">
                          Please Enter Valid Contact No
                        </span>
                      )}

                    {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="EmailID" className="form-label fw-semibold">
                      {" "}
                      EmailID
                      <span style={{ color: "gray" }}>
                        {" "}
                        (Ex. xyz@gmail.com)
                      </span>
                    </label>
                    <input
                      onChange={handleInputChange}
                      name="EmailID"
                      value={formData.EmailID.textValue}
                      type="text"
                      className="form-control"
                      placeholder="Enter Email-ID"
                      style={{
                        border: `1px solid ${
                          isSubmit &&
                          ( !validMail)
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    ></input>
                    {isSubmit && !validMail && formData.EmailID.textValue && (
                      <span className="text-danger">
                        Please Enter valid Email ID
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6 mb-4 ">
                  <div className="form-group">
                    <label className="form-label fw-semibold">
                      Address <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      onChange={handleInputChange}
                      name="Address"
                      value={formData.Address.textValue}
                      style={{
                        height: "210px",
                        border: `1px solid ${
                          isSubmit && !formData.Address.textValue
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                      rows="2"
                      cols="20"
                      placeholder="Enter Address"
                      className="form-control"
                    />
                    {isSubmit && !formData.Address.textValue && (
                      <span className="text-danger">*</span>
                    )}
                    {/* {hasSubmit && !formData.Address.textValue && <span className='text-danger'>*</span>} */}
                  </div>
                </div>
                {
                  <div className="col-md-12">
                    <label
                      htmlFor="hotelName"
                      className="form-label fw-semibold"
                    >
                      {" "}
                      Description<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="form-group" style={{ width: "100%" }}>
                      <div
                        className="img-thumbnail"
                        style={{
                          border: `1px solid ${
                            isSubmit && !formData.Description.textValue
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                      >
                        <CKEditor
                          editor={ClassicEditor}
                          data={formData.Description.textValue}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            // setLetterData(data)
                            setFormData((prev) => {
                              return {
                                ...prev,
                                Description: {
                                  ...prev.Description,
                                  textValue: data,
                                },
                              };
                            });
                          }}
                        />
                        {isSubmit && !formData.Description.textValue && (
                          <span className="text-danger">*</span>
                        )}
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="d-flex align-items-center gap-2 mt-4">
                <button
                  type="submit"
                  className="btn btn-outline-primary font-weight-bolder ml-2 "
                >
                  Submit
                </button>
                <button
                  onClick={handleCancle}
                  type="button"
                  className="btn btn-outline-danger font-weight-bolder ml-2"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    navigate("/westKameng/viewContactUs")
                  }}
                  type="button"
                  className="btn btn-outline-primary font-weight-bolder ml-2"
                >
                  View
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddContactUs;

import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { APIURL, IMAGEMODELURL } from "../../Process";
import EyeClose from "../../Assets/eyeclose.png";
import EyeOpen from "../../Assets/eyeopen.png";
import Edit from "../../Assets/Edit.png";
import Delete from "../../Assets/Delete.png";
import PDFImg from "../../Assets/pdf2.png";
import ImageNotFound from "../../Assets/Noimage.png";
import { FiActivity } from "react-icons/fi";
import { MdAssignmentAdd } from "react-icons/md";
import XLSX from "xlsx-color";
import moment from "moment";
import Select from 'react-select';
import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import { MdDelete } from "react-icons/md";
import { Form } from "react-bootstrap";
import { MdAddCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { FaEyeSlash, FaToggleOff, FaToggleOn } from "react-icons/fa";
import { placeContext } from "../../Context/PlaceContext";
import ShowPasswardModel from "../ShowPasswardModel";
import Cookies from "js-cookie";

const ViewRestaurentDetails = () => {
  const { setIsRestaurantEmpty } = useContext(placeContext);
  const RestaurantImageDIR = `${IMAGEMODELURL}/Restaurant/RestaurantImage`;
  const RestaurantOwnerImageDIR = `${IMAGEMODELURL}/Restaurant/OwnerImage`;
  const RestaurantOwnerIDImageDIR = `${IMAGEMODELURL}/Restaurant/OwnerIDImage`;
  const ManagerPhotoDIR = `${IMAGEMODELURL}/Restaurant/ManagerPhoto`;
  const RestaurantFireNOCDIR = `${IMAGEMODELURL}/Restaurant/FireNOC/`;
  const RestaurantPCCertificateDIR = `${IMAGEMODELURL}/Restaurant/PoliceClearanceCertificate/`;
  const RestaurantImagesDIR = `${IMAGEMODELURL}/Restaurant/Images`;
  const RestaurantMenuDIR = `${IMAGEMODELURL}/Restaurant/RestaurantMenu`;
  const [pageCount, setPageCount] = useState("10");
  const [tableData, setTableData] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [showPasswordID, setShowPasswordID] = useState("");
  const [DestinationData, setDestinationData] = useState([])
  const [totalCount, setTotalCount] = useState();
  const [allTableData, setAllTableData] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState({
    name: "",
    DestinationName: "",
    DestinationTempt: ""
  });
  useEffect(() => {
    if (Cookies.get("ckUserType") === "Admin") {
      GetDestination()
      fetchInitialPlaceDetailsData(pageCount);
    } else if (
      Cookies.get("ckUserType") == "Restaurant" ||
      Cookies.get("ckUserType") == "Hotel"
    ) {
      fetchRestaurantUserData();
    }
  }, []);

  const GetDestination = async () => {
    try {
      const res = await fetch(`${APIURL}/Destination/getDestination`, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          DestinationID: '',
          DestinationName: '',
          IsActive: 'true',
          IsDelete: 'false',
          IsPagination: '',
          PerPage: '',
          UniqueID: '',
        })
      })
      const result = await res.json()
      console.log(result, "result")

      setDestinationData(result.data.map(item => ({ value: item.DestinationID, label: item.DestinationName })))
    } catch (err) {
      console.log(err)
    }

  }

  const selectDropDownData1 = (e, action) => {
    console.log(e.value, "epppp")
    setFilterData({
      ...filterData,
      [action.name]: e.value,
      DestinationTempt: e,
    })
  }

  const navigate = useNavigate();
  const handleEyeOn = (index) => {
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: true,
    });
    setTableData(tempt);
  };
  const handleEyeOff = (index) => {
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: false,
    });
    setTableData(tempt);
  };

  const fetchRestaurantUserData = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`${APIURL}/Restaurant/GetRestaurant`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DestinationID: "",
          RestaurantID:
            Cookies.get("ckUserType") == "Restaurant" ? Cookies.get("ckUserID") : "",
          HotelID: Cookies.get("ckUserType") == "Hotel" ? Cookies.get("ckUserID") : "",
          PaginationID: "-1",
          PageLength: "",
        }),
      });
      const result = await res.json();
      console.log(result, "initialReasult");
      const tempt = [];
      result.data.forEach((element) => {
        let restaurantMenu = [];
        let restaurantImages = [];
        if (element.ImageDetail) {
          element.ImageDetail.map((item) => {
            if (item.RestaurantImageType === "RestaurantMenu") {
              restaurantMenu.push(item);
            } else if (item.RestaurantImageType === "RestaurantImage") {
              restaurantImages.push(item);
            }
          });
        }
        tempt.push({
          obj: element,
          isDetails: false,
          menuDetails: restaurantMenu,
          imagesDetails: restaurantImages,
        });
      });
      console.log(tempt, "tempt", "want");
      if (!tempt.length) {
        setIsRestaurantEmpty(true);
      } else {
        setIsRestaurantEmpty(false);
      }
      setTableData(tempt);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const fetchInitialPlaceDetailsData = async (count) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${APIURL}/Restaurant/GetRestaurant`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          RestaurantID: "",
          HotelID: "",
          DestinationID: "",
          RestaurantName: "",
          CreatedByUserID: "",
          StartDate: "",
          EndDate: "",
          PaginationID: "0",
          PageLength: count,
          UsedFor: "Panel",
        }),
      });
      const result = await res.json();
      console.log(result, "initialReasult");
      setTotalCount(result.total[0].TotalCount);
      const tempt = [];

      result.data.forEach((element) => {
        let restaurantMenu = [];
        let restaurantImages = [];
        if (element.ImageDetail) {
          element.ImageDetail.map((item) => {
            if (item.RestaurantImageType === "RestaurantMenu") {
              restaurantMenu.push(item);
            } else if (item.RestaurantImageType === "RestaurantImage") {
              restaurantImages.push(item);
            }
          });
        }
        tempt.push({
          obj: element,
          isDetails: false,
          menuDetails: restaurantMenu,
          imagesDetails: restaurantImages,
        });
      });
      setIsLoading(false);
      setTableData(tempt);
      setPageId(1);
      setAllTableData(tempt);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const confirmRestaurantDelete = async (id) => {
    try {
      const res = await fetch(`${APIURL}/Restaurant/RemoveRestaurant`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          RestaurantID: id,
        }),
      });

      const result = await res.json();
      if (result.status == 1) {
        Swal.fire({
          title: "Successfully Deleted!",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        fetchInitialPlaceDetailsData(pageCount);
      }
      console.log(result);
    } catch (err) {
      console.log(err);
    }
  };
  const handleDeletePlaceDetails = async (id) => {
    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmRestaurantDelete(id);
      }
    });
  };
  const setNextApi = async () => {
    console.log(pageId, "pageID", pageCount);
    if (pageId * pageCount < allTableData.length) {
      const tempt = [...allTableData];
      console.log(tempt, pageId * pageCount, (pageId + 1) * pageCount);
      const sliced = tempt.slice(pageId * pageCount, (pageId + 1) * pageCount);
      setTableData(sliced);
      setPageId(pageId + 1);
      return;
    }
    const paginationIndex =
      allTableData[pageId * pageCount - 1]?.obj?.RestaurantID;
    if (!paginationIndex) {
      return;
    }
    console.log(paginationIndex, "paginationIndex");
    try {
      const res = await fetch(`${APIURL}/Restaurant/GetRestaurant`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DestinationID: filterData.DestinationName,
          RestaurantName: filterData.name,
          PaginationID: `${paginationIndex}`,
          PageLength: `${pageCount}`,
          UsedFor: "Panel",
        }),
      });
      const result = await res.json();
      console.log(result);
      const tempt = [];
      result.data.forEach((element) => {
        let restaurantMenu = [];
        let restaurantImages = [];
        if (element.ImageDetail) {
          element.ImageDetail.map((item) => {
            if (item.RestaurantImageType === "RestaurantMenu") {
              restaurantMenu.push(item);
            } else if (item.RestaurantImageType === "RestaurantImage") {
              restaurantImages.push(item);
            }
          });
        }
        tempt.push({
          obj: element,
          isDetails: false,
          menuDetails: restaurantMenu,
          imagesDetails: restaurantImages,
        });
      });
      console.log(tempt, "tempt");
      setTableData(tempt);
      setPageId(pageId + 1);
      setAllTableData([...allTableData, ...tempt]);
    } catch (err) {
      console.log(err);
    }
  };
  const setPreviousApi = () => {
    if (pageId == 1) {
      return;
    }
    const tempt = [...allTableData];
    console.log(pageId, "pageId");
    console.log(
      pageId,
      tempt,
      (pageId - 1) * pageCount,
      pageId * pageCount + 1
    );
    const sliced = tempt.slice(
      (pageId - 2) * pageCount,
      (pageId - 1) * pageCount
    );
    setTableData(sliced);
    setPageId(pageId - 1);
  };
  const handleRestaurentEdit = (element) => {
    navigate("/westKameng/addRestaurant", {
      state: element.obj,
    });
  };
  const handlePageCount = (e) => {
    setPageCount(e.target.value);
    searchHandle(e.target.value, 0, filterData);
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleFilterInputChange = (e) => {
    let tempt = { ...filterData };
    tempt = {
      ...filterData,
      [e.target.name]: e.target.value,
    };
    setFilterData(tempt);
  };
  const searchHandle = async (count, paginationIndex, filterData) => {
    console.log(count, "count", filterData, "filterData");
    console.log("searchHandle", count);

    console.log(filterData, "filterDataaaaaaaaa", count);

    try {
      const res = await fetch(`${APIURL}/Restaurant/GetRestaurant`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({

          DestinationID: filterData.DestinationName,
          RestaurantName: filterData.name,
          PaginationID: `${paginationIndex}`,
          PageLength: count,
          UsedFor: "Panel",
        }),
      });
      const result = await res.json();
      console.log(result);
      setTotalCount(result.total[0].TotalCount);
      // setIsLoading(false)
      const tempt = [];
      // setTotalEntries(result.total[0].TotalArji)
      result.data.forEach((element) => {
        let restaurantMenu = [];
        let restaurantImages = [];
        if (element.ImageDetail) {
          element.ImageDetail.map((item) => {
            if (item.RestaurantImageType === "RestaurantMenu") {
              restaurantMenu.push(item);
            } else if (item.RestaurantImageType === "RestaurantImage") {
              restaurantImages.push(item);
            }
          });
        }
        tempt.push({
          obj: element,
          isDetails: false,
          menuDetails: restaurantMenu,
          imagesDetails: restaurantImages,
        });
      });
      console.log(tempt, "?tempt");
      setTableData(tempt);
      setAllTableData(tempt);
      setPageId(1);
    } catch (err) {
      console.log(err);
    }
  };
  async function fetchExcelData() {
    setIsLoading(true);
    try {
      const res = await fetch(`${APIURL}/Restaurant/GetRestaurant`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DestinationID: filterData.DestinationName,
          RestaurantName: filterData.name,
          PaginationID: "-1",
          PageLength: ``,
          UsedFor: "Panel",
        }),
      });
      const result = await res.json();
      handleDownloadExcel(result.data);
    } catch (err) {
      setIsLoading(false);

      console.log(err);
    }
  }
  const handleDownloadExcel = (array) => {
    console.log(array, "array");
    const header = [
      "DestinationName",
      "Restaurant Type",
      "Restaurant Name",
      "Open Time",
      "Close Time",
      "Is Within Hotel",
      "Owner Name",
      "Owner Aadharcard",
      "Owner Pancard",
      "Owner Address",
      "Restaurant Address",
      "Restaurant Contact details",
      "Restaurant Location on Map(Latitude & Longitude)",
      "Manager Name",
      "	Manager Address",
    ];
    const body = [];
    array?.forEach((element, index) => {
      body.push([
        element.DestinationName,
        element.RestaurantType,
        element.RestaurantName,
        element.RestaurantTime.split(" - ")[0],
        element.RestaurantTime.split(" - ")[1],
        element.IsWithinHotel,
        element.OwnerName,
        element.OwnerAadharCardNo,
        element.OwnerPanCard,
        element.OwnerAddress,
        element.RestaurantAddress,
        `${element.RestaurantContactNo1
          ? `Contact1 - ${element.RestaurantContactNo1}`
          : ""
        } ${element.RestaurantContactNo2
          ? `${","} Contact2 - ${element.RestaurantContactNo2} `
          : ""
        } ${element.RestaurantContactNo3
          ? `${","} Contact3 - ${element.RestaurantContactNo3} `
          : ""
        }`,
        `${element.RestaurantLatitude
          ? `Longitude - ${element.RestaurantLatitude}`
          : ""
        } ${element.RestaurantLongitude
          ? `${","} Longitude - ${element.RestaurantLongitude}`
          : ""
        }`,
        element.ManagerName,
        element.ManagerAddress,
      ]);
    });
    if (!body.length) {
      setIsLoading(false);
      return Swal.fire({
        title: "No Data Found!",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    }

    const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);
    header.forEach((col, index) => {
      ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
        font: { bold: true, size: 14, color: { rgb: "f7f4ef" } },
        fill: { patternType: "solid", fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: "center" },
      };
    });
    ws1["!cols"] = [
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 20 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 40 },
      { width: 40 },
      { width: 60 },
      { width: 40 },
      { width: 40 },
      { width: 50 },
      { width: 10 },
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "Restaurant Details");

    setIsLoading(false);
    XLSX.writeFile(wb, `Restaurant${moment().format("DD-MM-YYYY_HH:mm")}.xlsx`);
  };
  const handleCancle = () => {
    setFilterData({
      name: "",
      DestinationName: "",
      DestinationTempt: ""
    });
    fetchInitialPlaceDetailsData(pageCount);
  };
  const confirmImageDelete = async (element) => {
    console.log(element, "element");
    try {
      const res = await fetch(`${APIURL}/ImageDetail/DeleteImages`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ImageDetailID: `${element.ImageDetailID}`,
          ImageType: element.ImageType,
          Image: element.Image,
        }),
      });
      const result = await res.json();
      if (result.status === 1) {
        Swal.fire({
          title: "Successfully Deleted!",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        if (Cookies.get("ckUserType") === "Admin") {
          searchHandle(pageCount, 0, filterData);
        } else if (Cookies.get("ckUserType") == "Restaurant" || "Hotel") {
          fetchRestaurantUserData();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const deleteImageDetails = async (element) => {
    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmImageDelete(element);
      }
    });
  };
  const handlePlaceActive = async (RestaurantID, status) => {
    console.log(RestaurantID, "placeID");
    try {
      const res = await fetch(`${APIURL}/Restaurant/UpdateActiveStatus`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          RestaurantID: RestaurantID,
          Status: `${status}`,
        }),
      });
      const result = await res.json();
      searchHandle(pageCount, 0, filterData);
      console.log(result, "placeActiveStutus");
    } catch (err) {
      console.log(err)
    }
  };
  const handleEmptyRestaurant = () => {
    navigate("/westKameng/addRestaurant");
  };
  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: "6",
          left: "600px",
          top: "300px",
        }}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="200"
          height="200"
          visible={true}
        />
      </div>
    );
  }
  return (
    <>
      {Cookies.get("ckUserType") === "Admin" && (
        <div className="body-wrapper">
          <div className="container-fluid">
            {isLoading && (
              <div
                style={{
                  position: "absolute",
                  zIndex: "6",
                  left: "600px",
                  top: "300px",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="200"
                  height="200"
                  visible={true}
                />
              </div>
            )}
            {openModel && (
              <ShowPasswardModel
                openModel={openModel}
                setOpenModel={setOpenModel}
                RestaurantID={showPasswordID}
                UserType={"Restaurant"}
              />
            )}
            <div
              className={`pt-2 pb-2 place-filter  ${isOpen ? "open" : "closed"
                }`}
            >
              <Row>
                <Col md={2} sm={2} className="col-4">
                  <div className="d-flex gap-2 pt-1">
                    <span>Total Count</span>
                    <span>{totalCount}</span>
                  </div>
                </Col>
                <Col md={2} sm={2} className="col-4">
                  <Form.Select
                    onChange={handlePageCount}
                    size="sm"
                    className="w-70"
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                  </Form.Select>
                </Col>
                <Col md={{ span: 2, offset: 6 }} className="col-4">
                  <Button
                    className="float-end"
                    md={2}
                    variant="outline-primary"
                    size="sm"
                    onClick={handleToggle}
                  >
                    {isOpen ? "Close Filter" : "Open Filter"}
                  </Button>
                </Col>
              </Row>
              {isOpen && (
                <div
                  className="filter-content row"
                  style={{
                    marginTop: "20px",
                    padding: "20px",
                    border: "1px solid gray",
                  }}
                >
                  <Form className="d-flex row pb-4">


                    <div className="form-group col-md-3 mb-2">
                      <label
                        htmlFor="DestinationName"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        Destination Name
                      </label>
                      <Select name='DestinationName' placeholder="--Select Destination--" onChange={selectDropDownData1} value={filterData.DestinationTempt} options={[{ label: '--Select Destination --', value: '' }, ...DestinationData]} />
                    </div>

                    <div className="form-group mb-2 col-md-3">
                      <label
                        htmlFor="hotelType"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        Restaurant Name
                      </label>
                      <input
                        onChange={handleFilterInputChange}
                        name="name"
                        value={filterData.name}
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                      ></input>

                      {/* {isSubmit && !formData.hotelType.textValue && (
                          <span className="text-danger">*</span>
                        )} */}
                      {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                    </div>

                    <div className="col-md-3" style={{ position: "relative", top: "27px" }}>
                      <Button
                        size="sm"
                        variant="primary"
                        className="me-2 "
                        onClick={() => {
                          searchHandle(pageCount, 0, filterData);
                        }}
                      >
                        Apply
                      </Button>
                      <Button
                        onClick={
                          () => {
                            fetchExcelData();
                          }
                        }
                        size="sm"
                        className="me-2"
                        variant="success"
                      >
                        Export
                      </Button>
                      <Button
                        onClick={
                          () => {
                            handleCancle();
                          }
                          // handleCancle()
                        }
                        size="sm"
                        variant="danger"
                      >
                        Cancel
                      </Button>
                    </div>
                    {/* Add more InputGroup components for additional filters */}
                  </Form>
                </div>
              )}
            </div>
            <div className="overflow-auto">
              <Table
                style={{
                  boxShadow: "2px 3px 15px",
                  textAlign: "left",
                  border: "1px solid gray",
                }}
                striped
                bordered
                hover
              >
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Details</th>
                    <th>Status</th>
                    <th>Destination Name</th>
                    <th>Restaurant Name / Restaurant Type</th>
                    <th>Contact Details</th>
                    <th>Open Time /Close Time</th>
                    <th>Email ID</th>
                    <th>Address</th>
                    <th>Show Password</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((element, index) => (
                    <>
                      <tr>
                        <td>{(pageId - 1) * pageCount + (index + 1)}</td>
                        <td>
                          {!element.isDetails && (
                            <img
                              onClick={() => handleEyeOn(index)}
                              src={EyeOpen}
                              height={"22px"}
                              alt=""
                            />
                          )}{" "}
                          {element.isDetails && (
                            <img
                              onClick={() => handleEyeOff(index)}
                              src={EyeClose}
                              height={"22px"}
                              alt=""
                            />
                          )}
                        </td>
                        <td>
                          {" "}
                          {element.obj.IsActive ? (
                            <FaToggleOn
                              onClick={() =>
                                handlePlaceActive(element.obj.RestaurantID, 0)
                              }
                              style={{ color: "green", fontSize: "1.9em" }}
                            />
                          ) : (
                            <FaToggleOff
                              onClick={() =>
                                handlePlaceActive(element.obj.RestaurantID, 1)
                              }
                              style={{ color: "red", fontSize: "1.8em" }}
                            />
                          )}
                        </td>
                        <td> {element.obj.DestinationName}</td>
                        <td>
                          {element.obj.RestaurantName} (
                          {element.obj.RestaurantType})
                        </td>
                        <td>
                          <Table
                            size="sm"
                            style={{
                              textAlign: "left",
                              border: "1px solid gray",
                            }}
                            striped
                            bordered
                            hover
                          >
                            <tbody>
                              <tr>
                                <td>Contact No 1</td>
                                <td>
                                  <span>{element.obj.RestaurantContactNo1}</span>
                                  <br />
                                </td>
                              </tr>
                              <tr>
                                <td>Contact No 2</td>
                                <td>
                                  <span>{element.obj.RestaurantContactNo2}</span>
                                  <br />
                                </td>
                              </tr>
                              <tr>
                                <td>Landline No</td>
                                <td>
                                  <span>{element.obj.RestaurantContactNo3}</span>
                                  <br />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                        <td>{element.obj.RestaurantTime}</td>
                        <td>{element.obj.RestaurantEmailID}</td>
                        <td>{element.obj.RestaurantAddress}</td>
                        <td>
                          <FaEyeSlash
                            onClick={() => {
                              setOpenModel(true);
                              setShowPasswordID(element.obj.RestaurantID);
                            }}
                            style={{ fontSize: "1.4em", cursor: "pointer" }}
                          />
                        </td>
                        <td>
                          <img
                            onClick={() => {
                              handleRestaurentEdit(element);
                            }}
                            src={Edit}
                            height={"25px"}
                            alt=""
                          />
                        </td>
                        <td>
                          <img
                            src={Delete}
                            onClick={() => {
                              handleDeletePlaceDetails(element.obj.RestaurantID);
                            }}
                            height={"25px"}
                            alt=""
                          />
                        </td>
                      </tr>
                      {element.isDetails && (
                        <tr>
                          <td colSpan={12}>
                            <Table
                              style={{
                                textAlign: "left",
                                border: "1px solid gray",
                              }}
                              striped
                              bordered
                              hover
                            >
                              <thead>
                                <th className="p-2">Restaurant Details</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{ display: "flex" }}>
                                    <Table
                                      style={{
                                        textAlign: "left",
                                        border: "1px solid gray",
                                        width: "30%",
                                      }}
                                      striped
                                      bordered
                                      hover
                                    >
                                      <thead>
                                        <th className="p-2">Restaurant Image</th>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td style={{ border: "none" }}>
                                            <img
                                              src={
                                                element?.obj.RestaurantImage
                                                  ? `${RestaurantImageDIR}/${element.obj.RestaurantImage}`
                                                  : ImageNotFound
                                              }
                                              style={{
                                                height: 80,
                                                width: 80,
                                                borderWidth: 0,
                                                border: "1px solid #007AB1",
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                    <Table
                                      style={{
                                        textAlign: "left",
                                        border: "1px solid gray",
                                      }}
                                      striped
                                      bordered
                                      hover
                                    >
                                      <thead>
                                        <th className="p-2">
                                          Restaurant Menu Images
                                        </th>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{ border: "none" }}
                                            className="d-flex"
                                          >
                                            {" "}
                                            {element.menuDetails?.map(
                                              (element, index) => (
                                                <div
                                                  className="d-flex"
                                                  style={{
                                                    flexDirection: "row",
                                                    width: "100px",
                                                    marginBottom: "10px",
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  <img
                                                    key={index}
                                                    src={
                                                      element?.Image
                                                        ? `${RestaurantMenuDIR}/${element.Image}`
                                                        : ImageNotFound
                                                    }
                                                    style={{
                                                      marginLeft: "10px",
                                                      height: 90,
                                                      width: 90,
                                                      borderWidth: 0,
                                                      border: "1px solid #007AB1",
                                                    }}
                                                  />
                                                  <span
                                                    onClick={() =>
                                                      deleteImageDetails(element)
                                                    }
                                                    style={{
                                                      color: "black",
                                                      textAlign: "right",
                                                      fontSize: "1.4em",
                                                      position: "relative",
                                                      right: "20px",
                                                      bottom: "4px",
                                                    }}
                                                  >
                                                    <MdDelete />
                                                  </span>
                                                </div>
                                              )
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                    <Table
                                      style={{
                                        textAlign: "left",
                                        border: "1px solid gray",
                                      }}
                                      striped
                                      bordered
                                      hover
                                    >
                                      <thead>
                                        <th className="p-2">
                                          Restaurant Detail Images
                                        </th>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{ border: "none" }}
                                            className=" d-flex"
                                          >
                                            {" "}
                                            {element.imagesDetails?.map(
                                              (element, index) => (
                                                <div
                                                  className="d-flex"
                                                  style={{
                                                    flexDirection: "row",
                                                    width: "100px",
                                                    marginBottom: "10px",
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  <img
                                                    key={index}
                                                    src={
                                                      element?.Image
                                                        ? `${RestaurantImagesDIR}/${element.Image}`
                                                        : ImageNotFound
                                                    }
                                                    style={{
                                                      marginLeft: "10px",
                                                      height: 90,
                                                      width: 90,
                                                      borderWidth: 0,
                                                      border: "1px solid #007AB1",
                                                    }}
                                                  />
                                                  <span
                                                    onClick={() =>
                                                      deleteImageDetails(element)
                                                    }
                                                    style={{
                                                      color: "black",
                                                      textAlign: "right",
                                                      fontSize: "1.4em",
                                                      position: "relative",
                                                      right: "20px",
                                                      bottom: "4px",
                                                    }}
                                                  >
                                                    <MdDelete />
                                                  </span>
                                                </div>
                                              )
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                    <Table
                                      style={{
                                        textAlign: "left",
                                        border: "1px solid gray",
                                        width: "20%"
                                      }}
                                      striped
                                      bordered
                                      hover
                                    >
                                      <thead>
                                        <th className="p-2">Lattitude</th>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td style={{ border: "none" }} className="d-flex">
                                            {element.obj.RestaurantLatitude}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                    <Table
                                      style={{
                                        textAlign: "left",
                                        border: "1px solid gray",
                                        width: "20%"
                                      }}
                                      striped
                                      bordered
                                      hover
                                    >
                                      <thead>
                                        <th className="p-2">Longitude</th>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td style={{ border: "none" }} className="d-flex">
                                            {element.obj.RestaurantLongitude}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                            <Table
                              style={{
                                textAlign: "left",
                                border: "1px solid gray",
                              }}
                              striped
                              bordered
                              hover
                            >
                              <thead>
                                <th className="p-2">Owner Details</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {
                                      <Table
                                        style={{
                                          textAlign: "left",
                                          border: "1px solid gray",
                                        }}
                                        striped
                                        bordered
                                        hover
                                      >
                                        <thead>
                                          <tr>
                                            <th> Owner Name</th>
                                            <th> Adhar Card Number</th>
                                            <th> Pan Card Number </th>
                                            <th>Owner Address</th>
                                            <th>Owner ID Image</th>
                                            <th>Owner Image</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>{element.obj.OwnerName}</td>
                                            <td>
                                              {element.obj.OwnerAadharCardNo}
                                            </td>
                                            <td>{element.obj.OwnerPanCard}</td>
                                            <td>{element.obj.OwnerAddress}</td>
                                            <td>
                                              {" "}
                                              <img
                                                key={index}
                                                src={
                                                  element?.obj.OwnerIDImage
                                                    ? `${RestaurantOwnerIDImageDIR}/${element.obj.OwnerIDImage}`
                                                    : ImageNotFound
                                                }
                                                style={{
                                                  marginLeft: "10px",
                                                  height: 90,
                                                  width: 90,
                                                  borderWidth: 0,
                                                  border: "1px solid #007AB1",
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <img
                                                key={index}
                                                src={
                                                  element?.obj.OwnerImage
                                                    ? `${RestaurantOwnerImageDIR}/${element.obj.OwnerImage}`
                                                    : ImageNotFound
                                                }
                                                style={{
                                                  marginLeft: "10px",
                                                  height: 90,
                                                  width: 90,
                                                  borderWidth: 0,
                                                  border: "1px solid #007AB1",
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </Table>

                            <Table
                              style={{
                                textAlign: "left",
                                border: "1px solid gray",
                              }}
                              striped
                              bordered
                              hover
                            >
                              <thead>
                                <th className="p-2">Manager Details</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {
                                      <Table
                                        style={{
                                          textAlign: "left",
                                          border: "1px solid gray",
                                        }}
                                        striped
                                        bordered
                                        hover
                                      >
                                        <thead>
                                          <tr>
                                            <th> Manager Name</th>
                                            <th>Manager Photo</th>
                                            <th>Manager Address </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>{element.obj.ManagerName}</td>
                                            <td>
                                              {" "}
                                              <img
                                                key={index}
                                                src={
                                                  element?.obj.ManagerPhoto
                                                    ? `${ManagerPhotoDIR}/${element.obj.ManagerPhoto}`
                                                    : ImageNotFound
                                                }
                                                style={{
                                                  marginLeft: "10px",
                                                  height: 90,
                                                  width: 90,
                                                  borderWidth: 0,
                                                  border: "1px solid #007AB1",
                                                }}
                                              />
                                            </td>
                                            <td>{element.obj.ManagerAddress}</td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </Table>

                            <Table
                              style={{
                                textAlign: "left",
                                border: "1px solid gray",
                              }}
                              striped
                              bordered
                              hover
                            >
                              <thead>
                                <th className="p-2">
                                  Restaurant Certificates And Registration Details
                                </th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {
                                      <Table
                                        style={{
                                          textAlign: "left",
                                          border: "1px solid gray",
                                        }}
                                        striped
                                        bordered
                                        hover
                                      >
                                        <thead>
                                          <tr>
                                            <th> Is Within Stay</th>
                                            {element?.obj?.HotelName && (
                                              <th>Stay Name</th>
                                            )}
                                            <th>
                                              {" "}
                                              Police Clearance Certificate{" "}
                                            </th>
                                            <th> Fire Clearance Certificate </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>{element.obj.IsWithinHotel}</td>
                                            {element?.obj?.HotelName && (
                                              <td>{element?.obj?.HotelName}</td>
                                            )}
                                            <td>
                                              <a
                                                target="_blank"
                                                href={`${RestaurantPCCertificateDIR}${element.obj.PoliceClearanceCertificateFile}`}
                                              >
                                                {element?.obj
                                                  .PoliceClearanceCertificateFile && (
                                                    <img
                                                      height={60}
                                                      width={60}
                                                      src={PDFImg}
                                                      alt=""
                                                    />
                                                  )}
                                              </a>
                                            </td>
                                            <td>
                                              <a
                                                target="_blank"
                                                href={`${RestaurantFireNOCDIR}${element.obj.FireNOCFile}`}
                                              >
                                                {element?.obj.FireNOCFile && (
                                                  <img
                                                    height={60}
                                                    width={60}
                                                    src={PDFImg}
                                                    alt=""
                                                  />
                                                )}
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
                <tbody>
                  <tr>
                    {!tableData.length && (
                      <td colSpan={11}>
                        <div
                          style={{
                            textAlign: "center",
                            padding: "20px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#ff0000",
                            backgroundColor: "#f0f0f0",
                            border: "1px solid #e0e0e0",
                            borderRadius: "5px",
                            width: "100%",
                          }}
                        >
                          NO DATA FOUND
                        </div>
                      </td>
                    )}
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="pt-2 pb-2">
              <Button variant="primary" onClick={setPreviousApi} size="sm">
                PREVIOUS
              </Button>{" "}
              <Button variant="secondary" onClick={setNextApi} size="sm">
                NEXT
              </Button>
            </div>
          </div>
        </div>
      )}
      {(Cookies.get("ckUserType") === "Restaurant" ||
        Cookies.get("ckUserType") === "Hotel") && (
          <div className="body-wrapper">
            <div className="container-fluid">
              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: "6",
                    left: "600px",
                    top: "300px",
                  }}
                >
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="200"
                    height="200"
                    visible={true}
                  />
                </div>
              )}

              {tableData?.length && (
                <Table
                  style={{
                    marginTop: "3rem",
                    boxShadow: "2px 5px 15px",
                    textAlign: "left",
                    border: "1px solid gray",
                  }}
                  striped
                  bordered
                  hover
                >
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Restaurant Name / Restaurant Type</th>
                      <th>Contact Details</th>
                      <th>Open Time /Close Time</th>
                      <th>Email ID</th>
                      <th>Address</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((element, index) => (
                      <>
                        <tr>
                          <td>{(pageId - 1) * pageCount + (index + 1)}</td>

                          <td>
                            {element.obj.RestaurantName} (
                            {element.obj.RestaurantType})
                          </td>
                          <td>
                            <Table
                              size="sm"
                              style={{
                                marginTop: "2rem",
                                textAlign: "left",
                                border: "1px solid gray",
                              }}
                              striped
                              bordered
                              hover
                            >
                              <tbody>
                                <tr>
                                  <td>Contact No 1</td>
                                  <td>
                                    <span>
                                      {element.obj.RestaurantContactNo1}
                                    </span>
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Contact No 2</td>
                                  <td>
                                    <span>
                                      {element.obj.RestaurantContactNo2}
                                    </span>
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Contact No 3</td>
                                  <td>
                                    <span>
                                      {element.obj.RestaurantContactNo3}
                                    </span>
                                    <br />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                          <td>{element.obj.RestaurantTime}</td>
                          <td>{element.obj.RestaurantEmailID}</td>
                          <td>{element.obj.RestaurantAddress}</td>
                          <td>
                            <img
                              onClick={() => {
                                handleRestaurentEdit(element);
                              }}
                              src={Edit}
                              height={"25px"}
                              alt=""
                            />
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={11}>
                            <Table
                              style={{
                                textAlign: "left",
                                border: "1px solid gray",
                              }}
                              striped
                              bordered
                              hover
                            >
                              <thead>
                                <th className="p-2">Restaurant Details</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{ display: "flex" }}>
                                    <Table
                                      style={{
                                        textAlign: "left",
                                        border: "1px solid gray",
                                        width: "30%",
                                      }}
                                      striped
                                      bordered
                                      hover
                                    >
                                      <thead>
                                        <th className="p-2">Restaurant Image</th>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td style={{ border: "none" }}>
                                            <img
                                              src={
                                                element?.obj.RestaurantImage
                                                  ? `${RestaurantImageDIR}/${element.obj.RestaurantImage}`
                                                  : ImageNotFound
                                              }
                                              style={{
                                                height: 90,
                                                width: 90,
                                                borderWidth: 0,
                                                border: "1px solid #007AB1",
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                    <Table
                                      style={{
                                        textAlign: "left",
                                        border: "1px solid gray",
                                      }}
                                      striped
                                      bordered
                                      hover
                                    >
                                      <thead>
                                        <th className="p-2">
                                          Restaurant Menu Images
                                        </th>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{ border: "none" }}
                                            className="d-flex"
                                          >
                                            {" "}
                                            {element.menuDetails?.map(
                                              (element, index) => (
                                                <div
                                                  className="d-flex"
                                                  style={{
                                                    flexDirection: "row",
                                                    width: "100px",
                                                    marginBottom: "10px",
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  <img
                                                    key={index}
                                                    src={
                                                      element?.Image
                                                        ? `${RestaurantMenuDIR}/${element.Image}`
                                                        : ImageNotFound
                                                    }
                                                    style={{
                                                      marginLeft: "10px",
                                                      height: 90,
                                                      width: 90,
                                                      borderWidth: 0,
                                                      border: "1px solid #007AB1",
                                                    }}
                                                  />
                                                  <span
                                                    onClick={() =>
                                                      deleteImageDetails(element)
                                                    }
                                                    style={{
                                                      color: "black",
                                                      textAlign: "right",
                                                      fontSize: "1.4em",
                                                      position: "relative",
                                                      right: "20px",
                                                      bottom: "4px",
                                                    }}
                                                  >
                                                    <MdDelete />
                                                  </span>
                                                </div>
                                              )
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                    <Table
                                      style={{
                                        textAlign: "left",
                                        border: "1px solid gray",
                                      }}
                                      striped
                                      bordered
                                      hover
                                    >
                                      <thead>
                                        <th className="p-2">
                                          Restaurant Detail Images
                                        </th>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{ border: "none" }}
                                            className=" d-flex"
                                          >
                                            {" "}
                                            {element.imagesDetails?.map(
                                              (element, index) => (
                                                <div
                                                  className="d-flex"
                                                  style={{
                                                    flexDirection: "row",
                                                    width: "100px",
                                                    marginBottom: "10px",
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  <img
                                                    key={index}
                                                    src={
                                                      element?.Image
                                                        ? `${RestaurantImagesDIR}/${element.Image}`
                                                        : ImageNotFound
                                                    }
                                                    style={{
                                                      marginLeft: "10px",
                                                      height: 90,
                                                      width: 90,
                                                      borderWidth: 0,
                                                      border: "1px solid #007AB1",
                                                    }}
                                                  />
                                                  <span
                                                    onClick={() =>
                                                      deleteImageDetails(element)
                                                    }
                                                    style={{
                                                      color: "black",
                                                      textAlign: "right",
                                                      fontSize: "1.4em",
                                                      position: "relative",
                                                      right: "20px",
                                                      bottom: "4px",
                                                    }}
                                                  >
                                                    <MdDelete />
                                                  </span>
                                                </div>
                                              )
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                            <Table
                              style={{
                                textAlign: "left",
                                border: "1px solid gray",
                              }}
                              striped
                              bordered
                              hover
                            >
                              <thead>
                                <th className="p-2">Owner Details</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {
                                      <Table
                                        style={{
                                          textAlign: "left",
                                          border: "1px solid gray",
                                        }}
                                        striped
                                        bordered
                                        hover
                                      >
                                        <thead>
                                          <tr>
                                            <th> Owner Name</th>
                                            <th> Adhar Card Number</th>
                                            <th> Pan Card Number </th>
                                            <th>Address</th>
                                            <th>ID Image</th>
                                            <th>Owner Image</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>{element.obj.OwnerName}</td>
                                            <td>
                                              {element.obj.OwnerAadharCardNo}
                                            </td>
                                            <td>{element.obj.OwnerPanCard}</td>
                                            <td>{element.obj.OwnerAddress}</td>
                                            <td>
                                              {" "}
                                              <img
                                                key={index}
                                                src={
                                                  element?.obj.OwnerIDImage
                                                    ? `${RestaurantOwnerIDImageDIR}/${element.obj.OwnerIDImage}`
                                                    : ImageNotFound
                                                }
                                                style={{
                                                  marginLeft: "10px",
                                                  height: 90,
                                                  width: 90,
                                                  borderWidth: 0,
                                                  border: "1px solid #007AB1",
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <img
                                                key={index}
                                                src={
                                                  element?.obj.OwnerImage
                                                    ? `${RestaurantOwnerImageDIR}/${element.obj.OwnerImage}`
                                                    : ImageNotFound
                                                }
                                                style={{
                                                  marginLeft: "10px",
                                                  height: 90,
                                                  width: 90,
                                                  borderWidth: 0,
                                                  border: "1px solid #007AB1",
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </Table>

                            <Table
                              style={{
                                textAlign: "left",
                                border: "1px solid gray",
                              }}
                              striped
                              bordered
                              hover
                            >
                              <thead>
                                <th className="p-2">Manager Details</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {
                                      <Table
                                        style={{
                                          textAlign: "left",
                                          border: "1px solid gray",
                                        }}
                                        striped
                                        bordered
                                        hover
                                      >
                                        <thead>
                                          <tr>
                                            <th> Manager Name</th>
                                            <th> Photo</th>
                                            <th> Address </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>{element.obj.ManagerName}</td>
                                            <td>
                                              {" "}
                                              <img
                                                key={index}
                                                src={
                                                  element?.obj.ManagerPhoto
                                                    ? `${ManagerPhotoDIR}/${element.obj.ManagerPhoto}`
                                                    : ImageNotFound
                                                }
                                                style={{
                                                  marginLeft: "10px",
                                                  height: 90,
                                                  width: 90,
                                                  borderWidth: 0,
                                                  border: "1px solid #007AB1",
                                                }}
                                              />
                                            </td>
                                            <td>{element.obj.ManagerAddress}</td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </Table>

                            <Table
                              style={{
                                textAlign: "left",
                                border: "1px solid gray",
                              }}
                              striped
                              bordered
                              hover
                            >
                              <thead>
                                <th className="p-2">
                                  Restaurant Certificates And Registration Details
                                </th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {
                                      <Table
                                        style={{
                                          textAlign: "left",
                                          border: "1px solid gray",
                                        }}
                                        striped
                                        bordered
                                        hover
                                      >
                                        <thead>
                                          <tr>
                                            <th> Is Within Stay</th>
                                            {element?.obj?.HotelName && (
                                              <th>Stay Name</th>
                                            )}
                                            <th>
                                              {" "}
                                              Police Clearance Certificate{" "}
                                            </th>
                                            <th> Fire Clearance Certificate </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>{element.obj.IsWithinHotel}</td>
                                            {element?.obj?.HotelName && (
                                              <td>{element?.obj?.HotelName}</td>
                                            )}
                                            <td>
                                              <a
                                                target="_blank"
                                                href={`${RestaurantPCCertificateDIR}${element.obj.PoliceClearanceCertificateFile}`}
                                              >
                                                {element?.obj
                                                  .PoliceClearanceCertificateFile && (
                                                    <img
                                                      height={60}
                                                      width={60}
                                                      src={PDFImg}
                                                      alt=""
                                                    />
                                                  )}
                                              </a>
                                            </td>
                                            <td>
                                              <a
                                                target="_blank"
                                                href={`${RestaurantFireNOCDIR}${element.obj.FireNOCFile}`}
                                              >
                                                {element?.obj.FireNOCFile && (
                                                  <img
                                                    height={60}
                                                    width={60}
                                                    src={PDFImg}
                                                    alt=""
                                                  />
                                                )}
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                  {/* <tbody>
            <tr>
              {!tableData?.length &&  (
                <td colSpan={11}>
                
                </td>
              )}
            </tr>
          </tbody> */}
                </Table>
              )}

              {!tableData.length && (
                <div
                  style={{
                    boxShadow: "2px 5px 15px",
                    textAlign: "center",
                    padding: "20px",
                    fontSize: "20px",
                    fontWeight: "bold",

                    backgroundColor: "#f0f0f0",
                    border: "1px solid #e0e0e0",
                    borderRadius: "5px",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => handleEmptyRestaurant()}
                >
                  <div>
                    <MdAddCircle
                      style={{
                        fontSize: "2.5em",
                        position: "relative",
                        bottom: "5px",
                      }}
                    />
                  </div>
                  <div style={{ fontSize: "1.5em" }}> ADD NEW RESTAURANT </div>
                </div>
              )}
            </div>
          </div>
        )}
    </>
  );
};

export default ViewRestaurentDetails;

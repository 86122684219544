import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { APIURL } from '../Process';
import Swal from 'sweetalert2';
import { handleLogout } from './CommonComponents';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const ChangePasswordModal = ({ openModel, setOpenModel, UserType, id }) => {
  const navigate = useNavigate()
  const [newPassword, setNewPassword] = useState('');
 const [isSubmit,setIsSubmit]=useState(false)
  const [oldPassword, setOldPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const handleChangePassword = async () => {
    setIsSubmit(true)
    if(!oldPassword?.length || !confirmPassword?.length || !newPassword?.length ){
      return
    }
    const res1 = await fetch(`${APIURL}/Common/ShowPassword`, {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "UserType": UserType,
        "HotelID": UserType == "Hotel" ? id : "",
        "RestaurantID":  UserType == "Restaurant" ? id : "",
        "UserID":  UserType == "Admin" ? id : ""
      })
    })
    const result2 = await res1.json()

    console.log(result2, "result2")
    if (result2?.data[0]?.Password !== oldPassword) {
      return Swal.fire({
        title: "Incorrect Old Password!",
        icon: "error",
        timer: 1500,
        showConfirmButton: false
      });
    }
    if (newPassword !== confirmPassword) {
      return Swal.fire({
        title: "Password Does Not Match!",
        icon: "error",
        timer: 1500,
        showConfirmButton: false
      });
    }
    console.log(result2, "result2")
    const passObj = {
      UserType: UserType,
      Password: newPassword
    }
    if (UserType == "Hotel") {
      passObj.HotelID = id
    } else if (UserType == "Restaurant") {
      passObj.RestaurantID = id
    }else if(UserType == "Admin")(
      passObj.UserID = id
    )
    // Implement your logic to change the password
    // For example, send a request to your server to update the password
    try {
      console.log('Changing password to:', newPassword);
      const res = await fetch(`${APIURL}/Common/ChangePassword`, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...passObj
        })
      })

      const result = await res.json()
      console.log(result, "result")
      if (result.status == 1) {
        setIsSubmit(false)
        await Swal.fire({
          title: "Password Updated Successfully",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        handleLogout(navigate)
      } else {
        Swal.fire({
          title: result?.message || "Sorry! Could Not Change Password ",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }

      // Close the modal after changing the password
    } catch (err) {
      console.log(err)
    }
  };
  return (
    <Modal show={openModel} centered>
      <Modal.Header >
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="newPassword" className='mb-2'>
            <Form.Label>Old Password:</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your new password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
           { (isSubmit && !oldPassword?.length) &&<span className='text-danger'>Please Enter Old Password</span>}
          </Form.Group>
          <Form.Group controlId="newPassword" className='mb-2'>
            <Form.Label>New Password:</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
        {  (isSubmit && !newPassword?.length) &&  <span className='text-danger'>Please Enter New Password</span>}
          </Form.Group>
          <Form.Group controlId="newPassword">
            <Form.Label>Confirm Password:</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
           {(isSubmit && !confirmPassword?.length) &&  <span className='text-danger'>Please Confirm Password</span>}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {setIsSubmit(false);setOpenModel(false)}}>
          Close
        </Button>
        <Button variant="primary" onClick={handleChangePassword}>
          Change Password
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangePasswordModal
import React, { useRef, useState } from 'react'
import { APIURL } from '../../Process';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { compressImage } from '../CommonComponents';

const AddBanner = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isCancle, setIsCancle] = useState(false)

  
  const { state } = useLocation();
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    Images:{
        textValue:  state?.Image || "",
        required:  true  ,
        valid: true,
        type: "file",
    }
  });
  const resetFileInput = () => {
    // Reset the value of the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
   console.log(state,"state")
  const navigate = useNavigate();
  ;

      const handleFileChange = (e) => {
        if (
          ["jpg", "png", "jpeg"].includes(
            e.target.value.split(".")[e.target.value.split(".").length - 1]
          ) ||
          e.target.value === ""
        ) {
            setFormData({
                ...formData,
                [e.target.name]: {
                  ...formData[e.target.name],
                  textValue: e.target.files[0],
                  valid: true,
                },
              });
        }
        else {
            setFormData({
              ...formData,
              [e.target.name]: {
                ...formData[e.target.name],
                textValue: "",
                valid: false,
              },
            });
          }
      };
      const handleSubmit=async(e)=>{
        setIsSubmit(true)
        e.preventDefault()
       console.log(formData,"formData")
       let validForm = true;
       
       for (let key in formData) {
        if (formData[key].required) {
          if (!formData[key].textValue) {
            validForm = false;
          }
          if (!validForm) {
            return;
          }
        }
    
        if (formData[key].type === "file" && formData[key].valid == false) {
          return;
        }
    }
        const form = new FormData();
        if(state){
            form.append("BannerID",state?.BannerID)
          }
        form.append("BannerImage",   formData.Images.textValue ? !(formData.Images.textValue instanceof Blob) ? formData.Images.textValue :  await compressImage(formData.Images.textValue,0.9) : "");
        if(  !isCancle){
            if(state?.Image){
              form.append("OldBannerImage",state?.Image)
            }
        }
          if(state){
            form.append("UpdatedByUserID",Cookies.get("ckUserID"))
            form.append("UpdatedByUserName",Cookies.get("ckUserName"))
          }else{
            form.append("CreatedByUserID",Cookies.get("ckUserID"))
            form.append("CreatedByUserName",Cookies.get("ckUserName"))
          }
          const config = { headers: { "Content-Type": "multipart/form-data" } }
          axios.post(`${APIURL}/Banner/SetBanner`, form, config)
            .then((res) => {
              console.log(res, "res")
              if(state && res.data?.status == 1 ){
                Swal.fire({
                  title: "Data Updated successfully!",
                  icon: "success",
                  timer: 1500,
showConfirmButton: false
                });
                navigate("/westKameng/viewBanner")
              }
              else if(res.data?.status == 1 ) {
                Swal.fire({
                  title: "Data Inserted successfully!",
                  icon: "success",
                  timer: 1500,
showConfirmButton: false
                });
                navigate("/westKameng/viewBanner")
              }
              else if(res.data?.status==0){
                Swal.fire({
                  title: res.data.message,
                  icon: "error",
                  timer: 1500,
showConfirmButton: false
                });
              }
            }
            )
            .catch((err)=>{
  console.log(err)
            })
    
      }
      const handleCancle=()=>{
        setFormData(()=>{
        const update={
            Images:{
                textValue:  "",
                required:  true  ,
                valid: true,
                type: "file",
            }
                    }
                    return update
        });
        resetFileInput()
        setIsCancle(true)
      }
  return (
    <div className="body-wrapper" style={{backgroundColor:"#d3d3d3",minHeight:"100vh"}}>
      <div className="container-fluid">
      <div className="card mt-4" style={{boxShadow: "2px 3px 15px"}}>
            <div className="px-3 pt-4 border-bottom">
            <h5>Add Banner Image</h5>
          </div>
        
          <form onSubmit={handleSubmit}>
          <div className="card-body">
            <div className="row">
             {/* {formData.HistoryType.textValue!=="Tourism" &&  <div className="col-md-3 mb-4"> */}
                <div className="form-group mb-3">
                  <label className="form-label fw-semibold">
                    Select Banner Image
                  </label>
                  <input
                    name="Images"
                    type="file"
                    onChange={handleFileChange}
                    accept="image/jpeg, image/png, image/jpg"
                    ref={fileInputRef}
                    className="form-control"
                    style={{
                      border: `1px solid ${
                        isSubmit && (!formData.Images.textValue || !formData.Images.valid)
                          ? "red"
                          : isSubmit
                          ? "green"
                          : "light-gray"
                      }  `,
                    }}
                  />

                  {!formData.Images.valid && (
                    <span className="text-danger">
                      *please upload valid image{" "}
                    </span>
                  )}
                                            {!isCancle && state?.Image  && <span >{state?.Image}</span>}

                  {/* {hasSubmit && (!formData.image.textValue || !formData.image.textValue.length  )  && <span className='text-danger'>*</span>} */}
                </div>
              </div>
              <div className="d-flex align-items-center gap-2 mt-4">
                    <button
                      type="submit"
                      className="btn btn-outline-primary font-weight-bolder ml-2 "
                    >
                      Submit
                    </button>
                    <button
                    onClick={handleCancle}
                      type="button"
                      className="btn btn-outline-danger font-weight-bolder ml-2"
                    >
                      Cancel
                    </button>
                    <button 
                    onClick={()=>{navigate("/westKameng/viewBanner")}}
                      type="button"
                      className="btn btn-outline-primary font-weight-bolder ml-2"
                    >
                      View
                    </button>
                  </div>
            </div>
            
            </form>
          </div>
        </div>
      </div>

  )
}

export default AddBanner

import XLSX from "xlsx-color";
import moment from "moment";
export const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData("text/plain");
  
    const isValid = /^\d+$/.test(pastedText.trim());
    if (!isValid) {
      event.preventDefault();
    }
  };
  
  export const handleKeyDownNumber = (event) => {
    const charCode = event.which || event.keyCode;
    if (event.key === "v" && (event.ctrlKey || event.metaKey)) {
      return; // Allow pasting
    }
    if (event.key === "c" && (event.ctrlKey || event.metaKey)) {
      return; // Allow copying
    }
    if (
      !/\d/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      charCode !== 9
    ) {
      event.preventDefault();
    }
  };

  export const handleExcellDownload = (header, body, sheetName) => {
    const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);
  
    // Style header row
    header.forEach((col, index) => {
      ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
        font: { bold: true, size: 14, color: { rgb: "f7f4ef" } },
        fill: { patternType: "solid", fgColor: { rgb: "54153255" } }, // Add fill color to header cells
        alignment: { wrapText: true, horizontal: "center" },
      };
    });
  
    // Calculate column widths based on content length
    const columnWidths = header.map((_, index) => {
      const colValues = body.map((row) => row[index]?.toString());
      const maxLength = Math.max(
        ...colValues.map((value) => value?.length || 10)
      );
      return Math.min(Math.max(maxLength + 2, 10), 50); // Limiting max width to 50 characters
    });
  
    // Apply column widths to the worksheet
    ws1["!cols"] = columnWidths.map((width) => ({ width }));
  
    // Apply text wrapping and vertical alignment to all cells except header
    for (let rowIndex = 0; rowIndex < body.length; rowIndex++) {
      for (let colIndex = 0; colIndex < header.length; colIndex++) {
        const cellAddress = XLSX.utils.encode_cell({
          r: rowIndex + 1,
          c: colIndex,
        });
        if (!ws1[cellAddress]) continue;
        ws1[cellAddress].s = {
          alignment: { wrapText: true, vertical: "top" }, // Align vertically top for description cells
        };
      }
    }
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, sheetName);
  
    XLSX.writeFile(wb, `${sheetName} ${moment().format("DD-MM-YYYY-HH-mm")}.xlsx`);
  };
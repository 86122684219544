import React, { useEffect, useState } from "react";
import { radialBar } from "react-chartjs-2";
import { APIURL, IMAGEMODELURL } from "../Process";
import ReactApexChart from "react-apexcharts";
import { Table } from "react-bootstrap";

const Dashboard = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchInitialUserDetailsData();
  }, []);
  const UserImageDIR = `${IMAGEMODELURL}/User`;
  const fetchInitialUserDetailsData = async() => {
    try{
    const res=await fetch(`${APIURL}/Dashboard/GetAdminDashboard`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const result=await res.json()
    setData(result.data);
  }
  catch(err){
    console.log(err)
  }
  };
  // useEffect(()=>{
  //  window.location.reload()
  // },[])
  const placeChart = {
    series: [data[0]?.TotalPlace || 0],
    options: {
      chart: {
        height: 350,
        type: 'radialBar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: '70%',
            background: '#fff',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: '#fff',
            strokeWidth: '67%',
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: '#888',
              fontSize: '17px',
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: '#111',
              fontSize: '36px',
              show: true,
            },
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#ABE5A1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: ['Places'],
    },
  };
  const hotelChart = {
    series: [data[0]?.TotalHotel || 0],
    options: {
      chart: {
        height: 350,
        type: 'radialBar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: '70%',
            background: '#fff',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: '#fff',
            strokeWidth: '67%',
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: '#888',
              fontSize: '17px',
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: '#111',
              fontSize: '36px',
              show: true,
            },
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#ABE5A1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: ['Hotel'],
    },
  };
  const restaurantChart = {
    series: [data[0]?.TotalRestaurant || 0],
    options: {
      chart: {
        height: 350,
        type: 'radialBar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: '70%',
            background: '#fff',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: '#fff',
            strokeWidth: '67%',
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: '#888',
              fontSize: '17px',
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: '#111',
              fontSize: '36px',
              show: true,
            },
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#ABE5A1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: ['Restaurant'],
    },
  };
  const userChart = {
    series: [data[0]?.TotalUser || 0] ,
    options: {
      chart: {
        height: 350,
        type: 'radialBar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: '70%',
            background: '#fff',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: '#fff',
            strokeWidth: '67%',
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: '#888',
              fontSize: '17px',
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: '#111',
              fontSize: '36px',
              show: true,
            },
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#ABE5A1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: ['User'],
    },
  };

  return (
    
    <div className="body-wrapper" >
      <div className="container-fluid">
        <div className=" mt-4">
          <div  style={{display:"flex",flexWrap:"wrap",justifyContent:"space-evenly"}} >
            {/* First Box */}
            {/* <div style={{boxShadow: "2px 6px 15px",width:"23%",marginBottom:"3rem" }}> */}
              <div style={{width:"18rem"}}    className="card   bg-primary text-white rounded h-100">
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      fontSize: "2.3em",
                      fontWeight: "650",
                      color: "white",
                    }}
                  >
                    Places
                  </h5>
                  <p className="card-text">Total Places Count</p>
                  <div id="chart">
                    <ReactApexChart
                      options={placeChart?.options}
                      series={placeChart?.series}
                      type="radialBar"
                      height={250}
                    />
                  </div>
                </div>
              </div>
            {/* Second Box */}
            {/* <div style={{boxShadow: "2px 6px 15px",width:"23%",marginBottom:"3rem" }}>/ */}
              <div style={{width:"18rem"}}  className="card  bg-success text-white rounded h-100">
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      fontSize: "2.3em",
                      fontWeight: "650",
                      color: "white",
                    }}
                  >
                    Stay
                  </h5>
                  <p className="card-text">Total Stay Count</p>
                  <div id="chart">
                    <ReactApexChart
                      options={hotelChart?.options}
                      series={hotelChart?.series}
                      type="radialBar"
                      height={250}
                    />
                  </div>
                </div>
              </div>
            {/* </div> */}

            {/* Third Box */}
            {/* <div style={{boxShad ow: "2px 6px 15px",width:"23%",marginBottom:"3rem" }}> */}
              <div style={{width:"18rem"}}   className="card  bg-warning text-white rounded h-100">
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      fontSize: "2.3em",
                      fontWeight: "650",
                      color: "white",
                    }}
                  >
                    Restaurant
                  </h5>
                  <p className="card-text">Total Restaurant Count</p>
                  <div id="chart">
                    <ReactApexChart
                      options={restaurantChart?.options}
                      series={restaurantChart?.series}
                      type="radialBar"
                      height={250}
                    />
                  </div>
                </div>
              </div>
            {/* </div> */}

            {/* Fourth Box */}
            {/* <div style={{boxShadow: "2px 6px 15px",width:"23%",marginBottom:"3rem" }}> */}
              <div  style={{width:"18rem"}}  className="card  bg-danger text-white rounded h-100">
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      fontSize: "2.3em",
                      fontWeight: "650",
                      color: "white",
                    }}
                  >
                    User
                  </h5>
                  <p className="card-text">Recently User Count</p>
                  <div id="chart">
                    <ReactApexChart
                      options={userChart?.options}
                      series={userChart?.series}
                      type="radialBar"
                      height={250}
                    />
                  </div>
                </div>
              </div>
            {/* </div> */}
          {/* </div> */}
          </div>

          {/* Beautiful Table */}
          <div className="row">
            <div className="row">
              <div className="col-md-12">
                <div  style={{ overflowX: "auto", maxWidth: "100%",boxShadow: "2px 6px 15px"}} className="card p-4 rounded">
                  <h5 className="card-title ms-3">Top Registered User</h5>
                  <table  style={{ overflowX: "auto", maxWidth: "100%"}}   className="table table-bordered dataTable no-footer"
    id="tblOrder"
    role="grid"
    >
                    <thead>
                      <tr role="row">
                        <th>Name</th>
                        <th>User Image</th>
                        <th>Mobile No</th>
                        <th>Email ID</th>
                        <th>Address</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data[0]?.TopRegisteredUser.map((element, index) => (
                       element?.UserType!=="Admin" &&
                        <tr>
                          <td>{element.Name}</td>
                          <td>
                            <img
                              src={`${element.UserImage ? UserImageDIR+"/"+element.UserImage : require("../Assets/avatar.jpg") }` }
                              alt={`User ${index + 1}`}
                              className="img-fluid rounded"
                              style={{ width: "50px" ,height:"50px" }}
                            />
                          </td>
                          <td>{element.MobileNo}</td>
                          <td>{element.EmailID}</td>
                          <td>{element.Address}</td>
                          <td>{element.Type}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

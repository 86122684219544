import React, { useEffect, useRef, useState } from "react";

import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { APIURL } from "../../Process";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Table } from "react-bootstrap";
import { Button } from "react-bootstrap/esm";
import { compressImage } from "../CommonComponents";
import Cookies from "js-cookie";

const PackageDetails = () => {
  const fileInputRef = useRef(null);
  const imageRef = useRef(null);
  ;
  const [status, setStatus] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isCancle, setIsCancle] = useState(false);
  const [isEditActiveIndex,setIsEditActiveIndex]=useState(false)
  const [activityData, setActivityData] =
    useState();
    // state?.obj.ActivityDetail || []
  const [input, setInput] = useState({
    PackageDetailID:"",
    Days: "",
    Title: "",
    Description: "",
  });
  
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(location.state);
  console.log(state,"state")
  const [tableData,setTableData]=useState( state?.PackageDetail || [])
  const [isAppend, setIsAppend] = useState(false);
  const [validImage, setValidImage] = useState(true);
  
  const [formData, setFormData] = useState({
    Title: {
      textValue: state?.PackageTitle ||   "",
      required: true,
    },
    PackageDescription: {
      textValue:state?.PackageDescription ||   "",
      required: true,
    },
    Days: {
      textValue:  state?.PackageDays || "",
      required: true,
    },
    latitude: {
      textValue:state?.Latitude ||  "",
      required: true,
    },
    longitude: {
      textValue: state?.Longitude || "",
      required: true,
    },
    location: {
      textValue: state?.Location || "",
      required: true,
    },
    incluedLocation: {
      textValue: state?.Route || "",
      required: true,
    },
    PackageImage: {
      textValue: state?.PackageImage || "",
      required: true,
      valid: true,
      type: "file",
    },
    Images: {
      textValue: state?.ImageDetail || "",
      required: true,
      valid: true,
      type: "file",
      count: state?.ImageDetail?.length || 0,
    },
    Description: {
      textValue: state?.AdditionalDescription || "",
      required: true,
    }

  });
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        textValue: e.target.value,
      },
    });
  };
  const handleClearButtonClick = () => {
    fileInputRef.current.value = "";
    imageRef.current.value=""
  };

  const handleFileChange = (e) => {
    if(e.target.files.length==0){
   return    setFormData({
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          valid: true,
          required:false,
          count: (state?.ImageDetail?.length || 0) + e.target.files.length,
        },
      });
    }
    if (
      ["jpg", "png", "jpeg"].includes(
        e.target.value.split(".")[e.target.value.split(".").length - 1]
      ) ||
      e.target.value === ""
    ) {
      if(e.target.name==="Images"){
      if (state?.ImageDetail?.length + e.target.files.length > 5) {
        Swal.fire({
          title: "Cannot Select More Than 5 Images!",
          icon: "error",
          timer: 1500,
showConfirmButton: false,
        });
        return;
      }
      setFormData({
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          textValue: e.target.files,
          valid: true,
          count: (state?.ImageDetail?.length || 0) + e.target.files.length,
        },
      });
    }else{
      setFormData({
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          textValue: e.target.files[0],
          valid: true,
        },
      });
    }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          // textValue: "",
          valid: false,
        },
      });
    }
  };
  const handleSubmit = async(e) => {
    setIsSubmit(true);
    e.preventDefault();

    let validForm = true;

    for (let key in formData) {
      if (formData[key].required) {
        if (!formData[key].textValue) {
          validForm = false;
        }
        if (!validForm) {
          return;
        }
      }

      if (formData[key].type === "file" && formData[key].valid == false) {
        return;
      }
    }
    if(formData.longitude.textValue > 90 ||
        formData.longitude.textValue < -90 ||
        !formData.longitude.textValue){
            return 
        }
        if(formData.latitude.textValue > 90 ||
            formData.latitude.textValue < -90 ||
            !formData.latitude.textValue){
                return 
            }
            if(!formData.Images.valid || !formData.Images.textValue ||  !formData.Images.textValue.length ){
                return
            }
  if(!tableData.length){
    return
  }
          //   Swal.fire({
          //   title: "Data Inserted successfully!",
          //   icon: "success",
          //   timer: 1500,
// showConfirmButton: false,
          // });
          console.log(formData,"formData",tableData,"tableData")

    const form = new FormData();
    if (state) {
      form.append("PackageID", state?.PackageID || "");
    }
    form.append("PackageTitle", formData.Title.textValue );
    form.append("PackageDescription", formData.PackageDescription.textValue );
    form.append("PackageDays", formData.Days.textValue );
    form.append("Location", formData.location.textValue );
    form.append("Latitude", formData.latitude.textValue) ;
    form.append("Longitude", formData.longitude.textValue );
    for (let i = 0; i < formData.Images.textValue.length; i++) {
      form.append("PackageImages",  formData.Images.textValue[i] ? !(formData.Images.textValue[i] instanceof Blob) ? formData.Images.textValue[i] :  await compressImage(formData.Images.textValue[i],0.9) : "");
    }
    form.append("PackageImage", formData.PackageImage.textValue ? !(formData.PackageImage.textValue instanceof Blob) ? formData.PackageImage.textValue :  await compressImage(formData.PackageImage.textValue,0.9) : "")
    if(!isCancle){
      if(state?.PackageImage){
        form.append("OldPackageImage",state?.PackageImage)
      }
    }
    form.append("Route", formData.incluedLocation.textValue);
    form.append("AdditionalDescription", formData.Description.textValue);
    form.append("PackageDetails", JSON.stringify(tableData));
    // form.append("PackageImages",formData.Images.textValue)
    if (state) {
      form.append("UpdatedByUserID", Cookies.get("ckUserID"));
      form.append("UpdatedByUserName", Cookies.get("ckUserName"));
      form.append("UpdatedByUserType",Cookies.get("ckUserType"))
    } else {
      form.append("EntryByUserID", Cookies.get("ckUserID"));
      form.append("EntryByName", Cookies.get("ckUserName"));
      form.append("EntryByUserType",Cookies.get("ckUserType"))
    }
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    axios
      .post(`${APIURL}/Package/setPackage`, form, config)
      .then((res) => {
        console.log(res, "res");
        if (state && res.data?.status == 1) {
          Swal.fire({
            title: "Data Updated successfully!",
            icon: "success",
            timer: 1500,
showConfirmButton: false,
          });
          // formData.HistoryType.textValue.toLowerCase() == "tourist"
            // ? navigate("/viewWestKamengTourismDetails")
            navigate("/westKameng/viewPackageDetails");
        } else if (res.data?.status == 1) {
          Swal.fire({
            title: "Data Inserted successfully!",
            icon: "success",
            timer: 1500,
showConfirmButton: false,
          });
          // formData.HistoryType.textValue.toLowerCase() == "tourist"
          //   ? navigate("/viewWestKamengTourismDetails")
          navigate("/westKameng/viewPackageDetails");
        } else if (res.data?.status == 0) {
          Swal.fire({
            title: res.data.message,
            icon: "error",
            timer: 1500,
showConfirmButton: false,
          });
        }
      })
      .catch((err)=>{
        console.log(err)
      });
  };
  const handleCancle = () => {
  if(state){
 navigate("/westKameng/viewPackageDetails")
  }
      setFormData({
        Title: {
            textValue: "",
            required: true,
          },
          PackageDescription: {
            textValue: "",
            required: true,
          },
          Days: {
            textValue: "",
            required: true,
          },
          latitude: {
            textValue: "",
            required: true,
          },
          longitude: {
            textValue: "",
            required: true,
          },
          location: {
            textValue: "",
            required: true,
          },
          incluedLocation: {
            textValue: "",
            required: true,
          },
          PackageImage: {
            textValue: "",
            required: true,
            valid: true,
            type: "file",
          },
          Images: {
            textValue: "",
            required: true,
            valid: true,
            type: "file",
            count: 0,
          },
          Description: {
            textValue: "",
            required: true,
          },
      })
   setTableData([])
   setState(null)
    setIsCancle(true);
    handleClearButtonClick();
  };
  const handleKeyDownNumber = (event) => {
    if (!/\d/.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };
  const handleTableInputChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const handleSetTableData=()=>{
    if(!input.Days || !input.Description || !input.Title){
        return setIsAppend(true)
    }
    if(isEditActiveIndex){
        console.log(isEditActiveIndex,"isEditActiveIndex")
        const tempt=[...tableData]
        tempt.splice(isEditActiveIndex.id,1,input)
        setInput({
          PackageDetailID:"",
            Days: "",
            Title: "",
            Description: ""
          })
          setTableData(tempt)
          setIsEditActiveIndex()
     return 
    }
  const tempt=[...tableData]
  tempt.push(input)
  setInput({
    PackageDetailID:"",
    Days: "",
    Title: "",
    Description: ""
  })
  setTableData(tempt)
  }
  const handleDeleteTableData=(index)=>{
    if(state){
    handleDeleteFetchData(index,tableData[index].PackageDetailID)
    }else{
      const tempt=[...tableData]
      tempt.splice(index,1)
      setInput({
        PackageDetailID :"",
        Days: "",
        Title: "",
        Description: ""
      })
      setTableData(tempt)
    }
   

    }
    const handleDeleteFetchData=async(index,id)=>{
      const res=await fetch(`${APIURL}/Package/removePackageDetail`,{
        method:"post",
        headers:{
          'Content-Type':"application/json"
        },
        body:JSON.stringify({
          "PackageDetailID": id
        })
      })
      const result=await res.json()
      if(result.status==1){
        const tempt=[...tableData]
        tempt.splice(index,1)
        setInput({
          PackageDetailID :"",
          Days: "",
          Title: "",
          Description: ""
        })
        setTableData(tempt)
      }else{
        Swal.fire({
          title: result.message,
          icon: "error",
          timer: 1500,
showConfirmButton: false,
        });
        return 
      }

    }
    const handleEditTableData=(index)=>{
  const tempt=[...tableData]
  const data=tempt.splice(index,1)
  console.log(data,"data")
  setInput({
    PackageDetailID:data[0].PackageDetailID,
    Days: data[0].Days,
    Title: data[0].Title,
    Description: data[0].Description
  })
  setIsEditActiveIndex({
    id:index
  })
  
    }
    
  //   const handleActivityAppend = async (submitStatus) => {
  //     if (!input.ActivityName || !input.ActivityImage ) {
  //       setIsAppend(true);
  //       return;
  //     }

  //     if (!validImage) {
  //       return;
  //     }

  //     console.log(input, "input");

  //     const form = new FormData();
  //     // form.append("ActivityID",)
  //     form.append("PlaceID", state?.obj.PlaceID);
  //     form.append("ActivityName", input.ActivityName);
  //     form.append("ActivityImage", input.ActivityImage);
  //     // form.append("Description", input.Description);
  //     form.append("CreatedByUserID", Cookies.get("ckUserID"));
  //     form.append("CreatedByUserName", Cookies.get("ckUserName"));
  //     const config = { headers: { "Content-Type": "multipart/form-data" } };
  //     axios
  //       .post(`${APIURL}/Activity/SetActivity`, form, config)
  //       .then((res) => {
  //         setInput({
  //           ActivityName: "",
  //           ActivityImage: "",
  //           // Description: "",
  //         });
  //         console.log(res, "res");
  //         handleClearButtonClick();
  //         handleGetActivityData();
  //         Swal.fire({
  //           title: "Activity Assigned Successfully",
  //           icon: "success",
  //           timer: 1500,
// showConfirmButton: false,
  //         });
  //         setIsAppend(false);
  //         if (submitStatus) {
  //           navigate("/westKameng/viewplacedetails");
  //         }
  //       });
  //   };

  const handleClearInput = () => {
    setInput({
      PackageDetailID:"",
      Days: "",
      Title: "",
      Description: "",
    });
    setIsAppend(false);
    setStatus(true);
  };
  return (
    <div
      className="body-wrapper"
      style={{ backgroundColor: "#d3d3d3", minHeight: "100vh" }}
    >
      <div className="container-fluid">
        <div className="card mt-4" style={{ boxShadow: "2px 3px 15px" }}>
          <div className="px-3 pt-4 border-bottom">
            <h5>Package Details</h5>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-3 mb-4 ">
                  <div className="form-group mb-3">
                    <label
                      htmlFor="hotelName"
                      className="form-label fw-semibold"
                    >
                      {" "}
                      Title <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      onChange={handleInputChange}
                      name="Title"
                      value={formData.Title.textValue}
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      style={{
                        border: `1px solid ${
                          isSubmit && !formData.Title.textValue
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    ></input>
                    {isSubmit && !formData.Title.textValue && (
                      <span className="text-danger">*</span>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label fw-semibold">
                      {" "}
                      Latitude<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                    
                      onChange={handleInputChange}
                      name="latitude"
                      value={formData.latitude.textValue}
                      type="number"
                      className="form-control"
                      placeholder="Enter Latitude"
                      style={{
                        border: `1px solid ${
                          isSubmit &&
                          (formData.latitude.textValue > 90 ||
                            formData.latitude.textValue < -90 ||
                            !formData.latitude.textValue)
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    ></input>

                    {isSubmit &&
                      (formData.latitude.textValue > 90 ||
                        formData.latitude.textValue < -90 ||
                        !formData.latitude.textValue) && (
                        <span className="text-danger">
                          Lattitude must be in range of -90 to 90
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-3 mb-4 ">
                  <div className="form-group mb-3">
                    <label
                      htmlFor="hotelName"
                      className="form-label fw-semibold"
                    >
                      {" "}
                      Days <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      onChange={handleInputChange}
                      name="Days"
                      value={formData.Days.textValue}
                      type="text"
                      className="form-control"
                      placeholder="Enter Days"
                      style={{
                        border: `1px solid ${
                          isSubmit && !formData.Days.textValue
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    ></input>
                    {isSubmit && !formData.Days.textValue && (
                      <span className="text-danger">*</span>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label fw-semibold">
                      {" "}
                      Longitude<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      onChange={handleInputChange}
                    
                      name="longitude"
                      value={formData.longitude.textValue}
                      type="number"
                      className="form-control"
                      placeholder="Enter Longitude"
                      style={{
                        border: `1px solid ${
                          isSubmit &&
                          (formData.longitude.textValue > 90 ||
                            formData.longitude.textValue < -90 ||
                            !formData.longitude.textValue)
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    ></input>
                    {isSubmit &&
                      (formData.longitude.textValue > 90 ||
                        formData.longitude.textValue < -90 ||
                        !formData.longitude.textValue) && (
                        <span className="text-danger">
                          Lattitude must be in range of -90 to 90
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-3 mb-4">
                  <div className="form-group mb-3">
                    <label className="form-label fw-semibold">
                      Detail Images<span style={{ color: "red" }}>*</span>
                      {state && (
                        <>
                          {" ("}{" "}
                          <span style={{ color: "gray" }}>
                            {formData.Images.count} Images Selected{" "}
                          </span>{" "}
                          {") "}
                        </>
                      )}
                    </label>
                    <input
                      name="Images"
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      accept="image/jpeg, image/png, image/jpg"
                      multiple
                      className="form-control"
                      style={{
                        border: `1px solid ${
                          isSubmit &&
                          (!formData.Images.valid || !formData.Images.textValue ||  !formData.Images.textValue.length )
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    />

                    {!formData?.Images?.valid && (
                      <span className="text-danger">
                        *please upload valid image{" "}
                      </span>
                    )}
                    {isSubmit && (!formData.Images.textValue || !formData.Images.textValue.length  )  && <span className='text-danger'>*</span>}
                  </div>
                  <div className="form-group mb-3">
                    <label
                      htmlFor="hotelName"
                      className="form-label fw-semibold"
                    >
                      {" "}
                      Location <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      onChange={handleInputChange}
                      name="location"
                      value={formData.location.textValue}
                      type="text"
                      className="form-control"
                      placeholder="Enter Location"
                      style={{
                        border: `1px solid ${
                          isSubmit && !formData.location.textValue
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    ></input>
                    {isSubmit && !formData.location.textValue && (
                      <span className="text-danger">*</span>
                    )}
                    {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                  </div>
                </div>
                <div className="col-md-3 mb-4">
                  <div className="form-group ">
                    <label className="form-label fw-semibold">
                      Inclued Location<span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      style={{
                        border: `1px solid ${
                          isSubmit && !formData.incluedLocation.textValue
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                      value={formData.incluedLocation.textValue}
                      onChange={handleInputChange}
                      name="incluedLocation"
                      rows="5"
                      cols="40"
                      placeholder="Enter Inclued Location"
                      className="form-control"
                    />
                        {isSubmit && !formData.incluedLocation.textValue && (
                      <span className="text-danger">*</span>
                    )}
                  </div>
                </div>
                <div className="col-md-3 mb-2  ">
                      <label className="form-label fw-semibold">
                        Package Image{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input

                        name="PackageImage"
                        type="file"
                        ref={imageRef}
                        onChange={handleFileChange}
                        accept="image/jpeg, image/png, image/jpg"
                        className="form-control"
                        style={{border:`1px solid ${(isSubmit && (!formData.PackageImage.textValue || !formData.PackageImage.valid))  ? "red" : isSubmit ? "green" : "light-gray" }  `}}

                      />
                      {isSubmit && !formData.PackageImage.textValue && (
                        <span className="text-danger">*</span>
                      )}
                      {!formData.PackageImage.valid && (
                        <span className="text-danger">
                          *please upload valid image{" "}
                        </span>
                      )}
                      {!isCancle && state?.PackageImage  && <span >{state?.PackageImage}</span>}
                  
                    </div>
                <div className="col-md-12 mb-4 ">
                  <div className="form-group" style={{ width: "100%" }}>
                    <label
                      htmlFor="hotelName"
                      className="form-label fw-semibold"
                    >
                      {" "}
                      Package Description{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div
                      className="img-thumbnail"
                      style={{
                        border: `1px solid ${
                          isSubmit && !formData.PackageDescription.textValue
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    >
                      <CKEditor
                        editor={ClassicEditor}
                        data={formData.PackageDescription.textValue}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log(data);
                          // setLetterData(data)
                          setFormData((prev) => ({
                            ...prev,
                            PackageDescription: {
                              ...prev.PackageDescription,
                              textValue: data,
                            },
                          }));
                        }}
                      />
                      {isSubmit && !formData.PackageDescription.textValue && (
                        <span className="text-danger">*</span>
                      )}
                    </div>
                    {/* <span>uidahfadsads</span> */}
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="form-group" style={{ width: "100%" }}>
                    <label
                      htmlFor="hotelName"
                      className="form-label fw-semibold"
                    >
                      {" "}
                      Description<span style={{ color: "red" }}>*</span>
                    </label>
                    <div
                      className="img-thumbnail"
                      style={{
                        border: `1px solid ${
                          isSubmit && !formData.Description.textValue
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    >
                      <CKEditor
                        editor={ClassicEditor}
                        data={formData.Description.textValue}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log(data);
                          // setLetterData(data)
                          setFormData((prev) => ({
                            ...prev,
                            Description: {
                              ...prev.Description,
                              textValue: data,
                            },
                          }));
                        }}
                      />
                      {isSubmit && !formData.Description.textValue && (
                        <span className="text-danger">*</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <Table
                          style={{
                            textAlign: "left",
                            border: `1px solid ${isSubmit ? !tableData.length ? "red" :"green"  : "gray"}`,
                          }}
                          striped
                          bordered
                          hover
                        >
                          <thead>
                            <tr>
                              <th>
                                Days<span style={{ color: "red" }}>*</span>
                              </th>
                              <th>
                                Title<span style={{ color: "red" }}>*</span>
                              </th>
                              <th>
                                Description
                                <span style={{ color: "red" }}>*</span>
                              </th>
                              <th>Add</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  placeholder="Enter Days"
                                  value={input.Days}
                                  onKeyDown={handleKeyDownNumber}
                                  type="text"
                                  className="form-control"
                                  name="Days"
                                  onChange={handleTableInputChange}
                                />
                                {isAppend && !input.Days && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                              </td>
                              <td>
                                <input
                                  placeholder="Enter Title"
                                  value={input.Title}
                                  type="text"
                                  className="form-control"
                                  name="Title"
                                  onChange={handleTableInputChange}
                                />
                                {isAppend && !input.Title && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                              </td>
                              <td>
                                <textarea
                                  value={input.Description}
                                  onChange={handleTableInputChange}
                                  name="Description"
                                  rows="5"
                                  cols="40"
                                  placeholder="Enter Descreption"
                                  className="form-control"
                                />
                                     {isAppend && !input.Description && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                              </td>
                              {/* <td>
                          <textarea
                            value={input.Description}
                            onChange={handleInputChange}
                            name="Description"
                            style={{ height: "150px" }}
                            rows="2"
                            cols="20"
                            placeholder="Enter Descreption"
                            className="form-control"
                          />
                          {isAppend && !input.Description && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        </td> */}
                              <td>
                                {isEditActiveIndex ? (
                                  <Button
                                    onClick={
                                      () => {handleSetTableData()}
                                    }
                                    variant="primary"
                                    size="sm"
                                  >
                                    UPDATE
                                  </Button>
                                ) : (
                                  <Button
                                  onClick={
                                    () => {handleSetTableData()}
                                  }
                                    variant="primary"
                                    size="sm"
                                  >
                                    ADD
                                  </Button>
                                )}
                              </td>
                              <td>
                                <Button
                                  variant="secondary"
                                  size="sm"
                                  onClick={handleClearInput}
                                >
                                  CANCEL
                                </Button>
                              </td>
                            </tr>
                            {tableData?.map((element, index) => (
                              <tr>
                                <td>
                                  {element.Days}
                                  {/* <input placeholder='Enter Activity Name' type="text" value={element.ActivityName} className='form-control' name='ActivityName' onChange={(e)=>handleActivityChange(e,index)} /> */}
                                  {/* {submit && !element.ActivityName && <span style={{color:"red"}}>*</span>} */}
                                </td>
                                <td>
                                {element.Title}
                                </td>

                                <td>
                                {element.Description}
                                {/* <textarea  value={element.Description=="null" ? "" : element.Description} name='Description' style={{ height: "150px" }} rows="2" cols="20" placeholder="Enter Descreption" className='form-control' /> */}
                                </td>
                                <td>
                                  <Button
                                    onClick={
                                      (e) => {
                                        handleEditTableData(index)
                                      }
                                      // handleActivityChange(index, element)
                                    }
                                    variant="primary"
                                    size="sm"
                                  >
                                    EDIT
                                  </Button>
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                        handleDeleteTableData(index)
                                      // handleActivityDelete(element.ActivityID, index);
                                    }}
                                    variant="secondary"
                                    size="sm"
                                  >
                                    DELETE
                                    
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      {  isSubmit ? !tableData.length ? <span className="text-danger fs-4">Please Add Atleast One Record*</span> :""  : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center gap-2 mt-4">
                <button
                  type="submit"
                  className="btn btn-outline-primary font-weight-bolder ml-2 "
                >
                  Submit
                </button>
                <button
                  onClick={handleCancle}
                  type="button"
                  className="btn btn-outline-danger font-weight-bolder ml-2"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                  
                navigate("/westKameng/viewPackageDetails")
                 
                  }}
                  type="button"
                  className="btn btn-outline-primary font-weight-bolder ml-2"
                >
                  View
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageDetails;

import React, {  useEffect, useRef } from "react";
import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";
import { APIURL } from "../../Process";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";
import "react-clock/dist/Clock.css";

const AddRestaurentDetails = () => {
  const RestaurantMenuRef = useRef(null);
  const params=useParams()
  const RestaurantImageRef = useRef(null);
  const OwnerIDImageRef = useRef(null);
  const OwnerImageRef = useRef(null);
  const ManagerPhotoRef = useRef(null);
  const FireNOCFileRef = useRef(null);
  const POCRef = useRef(null);
  const imagesRef = useRef(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [validPan, setValidPan] = useState(true);
  const [validAdhar, setValidAdhar] = useState(true);
  const [validMail, setValidMail] = useState(true);
  const [isCancle, setIsCancle] = useState(false);
  const [hotelData, setHotelData] = useState([]);
  const [IsUserEditObj,setisUserEditObj]=useState()
  const [menuCount, setMenucount] = useState([]);
  const [destinationOptions, setDestinationOptions] = useState([]);
  const [hotelId,setHotelId]=useState("")
  const [imageCount, setImageCount] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();
  ;
  const handleClearButtonClick = () => {
    RestaurantMenuRef.current.value = "";
    RestaurantImageRef.current.value = "";
    OwnerIDImageRef.current.value = "";
    OwnerImageRef.current.value = "";
    ManagerPhotoRef.current.value = "";
    FireNOCFileRef.current.value = "";
    POCRef.current.value = "";
    imagesRef.current.value = "";
  };
  const [formData, setFormData] = useState({
    DestinationName: {
      textValue:  "",
      required: true,
    },
    RestaurantName: {
      textValue:  "",
      required: true,
    },
    RestaurantType: {
      textValue:  "",
      required: true,
    },
    RestaurantTime: {
      textValue:  "",
      required: true,
    },
    RestaurantMenu: {
      textValue:  "",
      required: true,
      valid: true,
      type: "file",
      count: 0,
    },
    RestaurantAddress: {
      textValue:  "",
      required: true,
    },
    RestaurantLatitude: {
      textValue:  "",
      required: false,
    },
    RestaurantLongitude: {
      textValue:  "",
      required: false,
    },
    RestaurantContactNo1: {
      textValue:  "",
      required: true,
    },
    RestaurantContactNo2: {
      textValue:  "",
      required: false,
    },
    RestaurantContactNo3: {
      textValue:  "",
      required: false,
    },
    RestaurantEmailID: {
      textValue:  "",
      required: true,
    },
    RestaurantImage: {
      textValue:  "",
      required: true,
      valid: true,
      type: "file",
    },
    OwnerName: {
      textValue:  "",
      required: true,
    },
    OwnerIDImage: {
      textValue:  "",
      required: true,
      valid: true,
      type: "file",
    },
    OwnerImage: {
      textValue:  "",
      required: false,
      valid: true,
      type: "file",
    },
    OwnerAadharCardNo: {
      textValue:  "",
      required: false,
    },
    OwnerPanCard: {
      textValue:  "",
      required: false,
    },
    OwnerAddress: {
      textValue:  "",
      required: false,
    },
    ManagerName: {
      textValue:  "",
      required: true,
    },
    ManagerPhoto: {
      textValue:  "",
      required: false,
      valid: true,
      type: "file",
    },
    ManagerAddress: {
      textValue:  "",
      required: false,
    },
    FireNOCFile: {
      textValue:  "",
      required: false,
      valid: true,
      type: "file",
    },
    POC: {
      textValue:  "",
      required: false,
      valid: true,
      type: "file",
    },
    IsWithinHotel: {
      textValue:"No",
      required: false,
    },
    images: {
      textValue:  "",
      required: false,
      valid: true,
      type: "file",
      count: 0,
    },
  });
  const fetchEditUserData=async()=>{
    try{
     const  res=await fetch(`${APIURL}/Restaurant/GetRestaurant`,{
      method:"post",
      headers:{
        "Content-Type":"application/json"
      },
      body:JSON.stringify({
        "RestaurantID": params.id,  
        "PaginationID": "-1",
        "PageLength": ""
      })
     })
     const result=await res.json()
     console.log(result.data[0].RestaurantType,"result")
     if(result.data[0]){
      setisUserEditObj(result.data[0])
     }
     const tempt={
      DestinationName: {
        textValue: result?.data[0]?.DestinationID || "",
        required: true,
      },
      RestaurantName: {
        textValue: result.data[0].RestaurantName || "",
        required: true,
      },
      RestaurantType: {
        textValue: result.data[0].RestaurantType || "",
        required: true,
      },
      RestaurantTime: {
        textValue: result.data[0].RestaurantTime?.split(" - ")[0] || "",
        required: true,
      },
      RestaurantMenu: {
        textValue: result.data[0].ImageDetail || "",
        required: true,
        valid: true,
        type: "file",
        count: 0,
      },
      RestaurantAddress: {
        textValue: result.data[0].RestaurantAddress || "",
        required: true,
      },
      RestaurantLatitude: {
        textValue: result.data[0].RestaurantLatitude || "",
        required: false,
      },
      RestaurantLongitude: {
        textValue: result.data[0].RestaurantLongitude || "",
        required: false,
      },
      RestaurantContactNo1: {
        textValue: result.data[0].RestaurantContactNo1 || "",
        required: true,
      },
      RestaurantContactNo2: {
        textValue: result.data[0].RestaurantContactNo2 || "",
        required: false,
      },
      RestaurantContactNo3: {
        textValue: result.data[0].RestaurantContactNo3 || "",
        required: false,
      },
      RestaurantEmailID: {
        textValue: result.data[0].RestaurantEmailID || "",
        required: true,
      },
      RestaurantImage: {
        textValue: result.data[0].RestaurantImage || "",
        required: true,
        valid: true,
        type: "file",
      },
      OwnerName: {
        textValue: result.data[0].OwnerName || "",
        required: true,
      },
      OwnerIDImage: {
        textValue: result.data[0].OwnerIDImage || "",
        required: true,
        valid: true,
        type: "file",
      },
      OwnerImage: {
        textValue: result.data[0].OwnerImage || "",
        required: false,
        valid: true,
        type: "file",
      },
      OwnerAadharCardNo: {
        textValue: result.data[0].OwnerAadharCardNo || "",
        required: false,
      },
      OwnerPanCard: {
        textValue: result.data[0].OwnerPanCard || "",
        required: false,
      },
      OwnerAddress: {
        textValue: result.data[0].OwnerAddress || "",
        required: false,
      },
      ManagerName: {
        textValue: result.data[0].ManagerName || "",
        required: true,
      },
      ManagerPhoto: {
        textValue: result.data[0].ManagerPhoto || "",
        required: false,
        valid: true,
        type: "file",
      },
      ManagerAddress: {
        textValue: result.data[0].ManagerAddress || "",
        required: false,
      },
      FireNOCFile: {
        textValue: result.data[0].FireNOCFile || "",
        required: false,
        valid: true,
        type: "file",
      },
      POC: {
        textValue: result.data[0].PoliceClearanceCertificateFile || "",
        required: false,
        valid: true,
        type: "file",
      },
      IsWithinHotel: {
        textValue: result.data[0].IsWithinHotel?.toUpperCase() === "YES"
          ? "YES"
          : "NO",
        required: false,
      },
      images: {
        textValue: result.data[0].ImageDetail || "",
        required: false,
        valid: true,
        type: "file",
        count: 0,
      },
    }
     setFormData(tempt)
    if (result.data[0]) {
      let restaurantMenuConunt = 0;
      let restaurantImageCount = 0;
      result.data[0]?.ImageDetail?.forEach((element) => {
        if (element.RestaurantImageType === "RestaurantMenu") {
          restaurantMenuConunt = restaurantMenuConunt + 1;
        } else if (element.RestaurantImageType === "RestaurantImage") {
          restaurantImageCount = restaurantImageCount + 1;
        }
      });
      console.log(restaurantMenuConunt,"restaurantMenuConunt")
      setImageCount(restaurantImageCount);
      setMenucount(restaurantMenuConunt);
      setFormData({
        ...tempt,
        images: {
          ...tempt.images,
          count: tempt.images.count + restaurantImageCount,
        },
        RestaurantMenu: {
          ...tempt.RestaurantMenu,
          count: tempt.RestaurantMenu.count + restaurantMenuConunt,
        },
      });
    }
    setHotelId(  result.data[0] ?  result.data[0]?.HotelID : "")

    }catch(err){
      console.log(err,"err")
    }
  }
  useEffect(() => {
    if(params.id){
      fetchEditUserData()
   
    }
    getHotelData();
    GetDestination()
  }, []);
  const GetDestination = async () => {
    try {
      const res = await fetch(`${APIURL}/Destination/getDestination`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          DestinationID: '',
          DestinationName: '',
          IsActive: 'true',
          IsDelete: 'false',
          IsPagination: '',
          PerPage: '',
          UniqueID: '',
        }),
      });

      const result = await res.json();
      if (result.status) {
        const options = result.data.map(item => ({ value: item.DestinationID, label: item.DestinationName }));
        setDestinationOptions(options);
      } else {
        setDestinationOptions([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getHotelData = async () => {
    try {
      const res = await fetch(`${APIURL}/Hotel/GetHotel`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PaginationID: "-1",
          PageLength: "",
          UsedFor: "Panel",
        }),
      });
      const result = await res.json();
      console.log(result, "result");
      setHotelData(result.data);
    } catch (err) {
      console.log(err);
    }
  };
  const handleHotelId = (id) => {

    setHotelId(id);
  };
  const handleInputChange = (e) => {

    let lastInputString = e.target.value[e.target.value.length - 1];
    console.log(lastInputString);
    if (
      // e.target.name == "RestaurantLatitude" ||
      // e.target.name == "RestaurantLongitude" ||
      e.target.name == "RestaurantContactNo1" ||
      e.target.name == "RestaurantContactNo2" ||
      e.target.name == "RestaurantContactNo3" ||
      e.target.name == "OwnerAadharCardNo"
    ) {
      if (
        e.target.value == "" ||
        (lastInputString.charCodeAt() >= 48 &&
          lastInputString.charCodeAt() <= 57)
      ) {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.value,
          },
        });
        if (e.target.name === "OwnerAadharCardNo") {
          if (e.target.value.length == 12 || !e.target.value.length) {
            setValidAdhar(true);
          } else {
            setValidAdhar(false);
          }
        }
      } else {
        return;
      }
      return;
    }
    if (e.target.name === "RestaurantEmailID") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(e.target.value)) {
        setValidMail(true);
      } else {
        setValidMail(false);
      }
    }
    if (e.target.name === "OwnerPanCard") {
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
      if (panRegex.test(e.target.value) || !e.target.value.length) {
        setValidPan(true);
      } else {
        setValidPan(false);
      }
    }
    setFormData({
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        textValue: e.target.value,
      },
    });
  };
  const handleFileChange = (e) => {
    console.log(e.target.files[0], "e.target.value");
    if (e.target.name == "FireNOCFile" || e.target.name == "POC") {
      if (
        ["pdf", "PDF"].includes(
          e.target.value.split(".")[e.target.value.split(".").length - 1]
        ) ||
        e.target.value == ""
      ) {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.files[0],
            valid: true,
          },
        });
      } else {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: "",
            valid: false,
          },
        });
      }
    } else {
      if (
        ["jpg", "png", "jpeg"].includes(
          e.target.value.split(".")[e.target.value.split(".").length - 1]
        ) ||
        e.target.value === ""
      ) {
        if (e.target.name === "images" || e.target.name === "RestaurantMenu") {
          console.log(
            e.target.name,
            "e.target.name",
            imageCount,
            "imageCount",
            e.target.files.length,
            "e.target.files.length"
          );
          if (
            (e.target.name === "images" &&
              imageCount + e.target.files.length > 5) ||
            (e.target.name === "RestaurantMenu" &&
              menuCount + e.target.files.length > 5)
          ) {
            Swal.fire({
              title: "Cannot Select More Than 5 Images!",
              icon: "error",
              timer: 1500,
showConfirmButton: false,
            });
            return;
          }
          setFormData({
            ...formData,
            [e.target.name]: {
              ...formData[e.target.name],
              textValue: e.target.files,
              valid: true,
              count:
                e.target.name == "images"
                  ? (imageCount || 0) + e.target.files.length
                  : (menuCount || 0) + e.target.files.length,
            },
          });
        } else {
          setFormData({
            ...formData,
            [e.target.name]: {
              ...formData[e.target.name],
              textValue: e.target.files[0],
              valid: true,
            },
          });
        }
      } else {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: "",
            valid: false,
          },
        });
      }
    }
  };
  const handleSubmit = (e) => {
    debugger;
    e.preventDefault();
    console.log(formData, "formData");
    setIsSubmit(true);
    let validForm = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    let validPantempt = true;
    let validAdharTempt = true;
    let validMailTempt = true;
    console.log(!panRegex.test(formData.OwnerPanCard.textValue), "jjjj");
    if (formData.RestaurantEmailID.textValue.length) {
      if (!emailRegex.test(formData.RestaurantEmailID.textValue)) {
        validMailTempt = false;
        setValidMail(() => {
          return false;
        });
      } else {
        validMailTempt = true;
        setValidMail(() => {
          return true;
        });
      }
    }
    if (
      !panRegex.test(formData.OwnerPanCard.textValue) &&
      formData.OwnerPanCard.textValue.length
    ) {
      validPantempt = false;
      setValidPan(() => {
        return false;
      });
    } else {
      validPantempt = true;
      setValidPan(() => {
        return true;
      });
    }
    if (
      formData.RestaurantLatitude.textValue > 90 ||
      formData.RestaurantLatitude.textValue < -90 ||
      formData.RestaurantLongitude.textValue > 180 ||
      formData.RestaurantLongitude.textValue < -180
    ) {
      return;
    }
    if (
      formData.OwnerAadharCardNo.textValue.length !== 12 &&
      formData.OwnerAadharCardNo.textValue.length
    ) {
      validAdharTempt = false;
      setValidAdhar(() => {
        return false;
      });
    } else {
      validAdharTempt = true;
      setValidAdhar(() => {
        return true;
      });
    }

    console.log(validAdhar, "validAdhar", validPan, "validPan");
    if (!validPantempt || !validAdharTempt || !validMailTempt) {
      return;
    }
    if (formData.IsWithinHotel.textValue === "YES" && !hotelId) {
      return;
    }
    for (let key in formData) {
      if (formData[key].required) {
        if (!formData[key].textValue) {
          validForm = false;
        }
        if (!validForm) {
          return;
        }
      }

      if (formData[key].type === "file" && formData[key].valid == false) {
        return;
      }
    }

    let validContact = formData.RestaurantContactNo1.textValue.length === 10;
    let validContact2 =
      formData.RestaurantContactNo2.textValue.length === 10 ||
      formData.RestaurantContactNo2.textValue === "";
    let validContact3 =
      formData.RestaurantContactNo3.textValue.length === 15 ||
      formData.RestaurantContactNo3.textValue === "";

    if (!validContact || !validContact2 || !validContact3) {
      return;
    }
    // Swal.fire({
    //             title: "Top Data Updated successfully!",
    //             icon: "success",
    //             timer: 1500,
// showConfirmButton: false
    //           });
    const form = new FormData();
    form.append("DestinationID", formData.DestinationName.textValue);
    form.append("RestaurantType", formData.RestaurantType.textValue);
    form.append("RestaurantName", formData.RestaurantName.textValue);
    form.append("RestaurantAddress", formData.RestaurantAddress.textValue);
    form.append("RestaurantLatitude", formData.RestaurantLatitude.textValue);
    form.append("RestaurantLongitude", formData.RestaurantLongitude.textValue);
    form.append(
      "RestaurantContactNo1",
      formData.RestaurantContactNo1.textValue
    );
    form.append(
      "RestaurantContactNo2",
      formData.RestaurantContactNo2.textValue
    );
    form.append(
      "RestaurantContactNo3",
      formData.RestaurantContactNo3.textValue
    );
    form.append("RestaurantEmailID", formData.RestaurantEmailID.textValue);
    form.append(
      "RestaurantTime",
      formData.RestaurantTime.textValue
    );
    form.append("RestaurantImage", formData.RestaurantImage.textValue);
    if (IsUserEditObj) {
      form.append("RestaurantID", IsUserEditObj?.RestaurantID);
    }
    if (formData.IsWithinHotel.textValue === "YES") {
      form.append("HotelID", hotelId);
    }
    form.append("OwnerName", formData.OwnerName.textValue);
    form.append("OwnerIDImage", formData.OwnerIDImage.textValue);
    form.append("OwnerImage", formData.OwnerImage.textValue);
    form.append("OwnerAadharCardNo", formData.OwnerAadharCardNo.textValue);
    form.append("OwnerPanCard", formData.OwnerPanCard.textValue);
    form.append("OwnerAddress", formData.OwnerAddress.textValue);
    form.append("ManagerName", formData.ManagerName.textValue);
    form.append("ManagerPhoto", formData.ManagerPhoto.textValue);
    form.append("ManagerAddress", formData.ManagerAddress.textValue);
    form.append("FireNOCFile", formData.FireNOCFile.textValue);
    form.append("PCCertificateFile", formData.POC.textValue);
    form.append("EntryType","App")
    if (IsUserEditObj) {
      // if (Cookies.get("ckUserType") == "Hotel") {
      //   form.append("UpdatedByHotelID", Cookies.get("ckUserID"));
      // } else if (Cookies.get("ckUserType") == "Restaurant") {
        form.append("UpdatedByRestaurantID", IsUserEditObj?.RestaurantID );
      // } else {
      //   form.append("UpdatedByUserID", Cookies.get("ckUserID"));
      //   form.append("UpdatedByUserName", Cookies.get("ckUserName"));
      // }
    } else {
      // if (Cookies.get("ckUserType") == "Hotel") {
      //   form.append("CreatedByHotelID", Cookies.get("ckUserID"));
      // } else if (Cookies.get("ckUserType") == "Restaurant") {
        form.append("CreatedByRestaurantID", "");
      // } else if (Cookies.get("ckUserType") == "Admin") {
      //   form.append("CreatedByUserID", Cookies.get("ckUserID"));
      //   form.append("CreatedByUserName", Cookies.get("ckUserName"));
      // }
    }
    for (let i = 0; i < formData.RestaurantMenu.textValue.length; i++) {
      form.append("RestaurantMenu", formData.RestaurantMenu.textValue[i]);
    }
    for (let i = 0; i < formData.images.textValue.length; i++) {
      form.append("Images", formData.images.textValue[i]);
    }
    form.append("IsWithinHotel", formData.IsWithinHotel.textValue);
    if (!isCancle) {
      if (IsUserEditObj?.RestaurantImage) {
        form.append("OldRestaurantImage", IsUserEditObj?.RestaurantImage);
      }
      if (IsUserEditObj?.OwnerIDImage) {
        form.append("OldOwnerIDImage", IsUserEditObj?.OwnerIDImage);
      }
      if (IsUserEditObj?.OwnerImage) {
        form.append("OldOwnerImage", IsUserEditObj?.OwnerImage);
      }
      if (IsUserEditObj?.ManagerPhoto) {
        form.append("OldManagerPhoto", IsUserEditObj?.ManagerPhoto);
      }
      if (IsUserEditObj?.FireNOCFile) {
        form.append("OldFireNOCFile", IsUserEditObj?.FireNOCFile);
      }
      if (IsUserEditObj?.PoliceClearanceCertificateFile) {
        form.append(
          "OldPCCertificateFile",
          IsUserEditObj?.PoliceClearanceCertificateFile
        );
      }
    }
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    axios
      .post(`${APIURL}/Restaurant/SetRestaurant`, form, config)
      .then((res) => {
        console.log(res, "res");
        if (IsUserEditObj && res.data?.status == 1) {
          Swal.fire({
            title: "Data Updated successfully!",
            icon: "success",
            timer: 1500,
showConfirmButton: false,
          });
        } else if (res.data?.status == 1) {
          Swal.fire({
            title: "Data Inserted successfully!",
            icon: "success",
            timer: 1500,
showConfirmButton: false,
          });
        } else if (res.data?.status == 0) {
          Swal.fire({
            title: res.data.message,
            icon: "error",
            timer: 1500,
showConfirmButton: false,
          });
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(formData, "formData");
  };
  const handleCancle = (e) => {
    setFormData({
      DestinationName: {
        textValue: "",
        required: true,
      },
      RestaurantName: {
        textValue: "",
        required: true,
      },
      // closeTime: {
      //   textValue: "",
      //   required: true,
      // },
      RestaurantType: {
        textValue: "",
        required: true,
      },
      RestaurantTime: {
        textValue: "",
        required: true,
      },
      RestaurantMenu: {
        textValue: "",
        required: true,
        valid: true,
        type: "file",
        count: menuCount,
      },
      RestaurantAddress: {
        textValue: "",
        required: true,
      },
      RestaurantContactNo1: {
        textValue: params.id ? formData.RestaurantContactNo1.textValue : "",
        required: true,
      },
      RestaurantContactNo2: {
        textValue: "",
        required: false,
      },
      RestaurantContactNo3: {
        textValue: "",
        required: false,
      },
      RestaurantEmailID: {
        textValue: "",
        required: true,
      },
      RestaurantImage: {
        textValue: "",
        required: true,
        valid: true,
        type: "file",
      },

      OwnerName: {
        textValue: "",
        required: true,
      },
      OwnerIDImage: {
        textValue: "",
        required: true,
        valid: true,
        type: "file",
      },
      OwnerImage: {
        textValue: "",
        required: false,
        valid: true,
        type: "file",
      },
      OwnerAadharCardNo: {
        textValue: "",
        required: true,
      },
      OwnerPanCard: {
        textValue: "",
        required: true,
      },
      OwnerAddress: {
        textValue: "",
        required: false,
      },
      ManagerName: {
        textValue: "",
        required: true,
      },
      ManagerPhoto: {
        textValue: "",
        required: false,
        valid: true,
        type: "file",
      },
      ManagerAddress: {
        textValue: "",
        required: false,
      },
      FireNOCFile: {
        textValue: "",
        required: false,
        valid: true,
        type: "file",
      },
      POC: {
        textValue: "",
        required: false,
        valid: true,
        type: "file",
      },
      IsWithinHotel: {
        textValue: "NO",
        required: false,
      },
      images: {
        textValue: "",
        required: false,
        valid: true,
        type: "file",
        count: imageCount,
      },
    });
    setIsCancle(true);
    handleClearButtonClick();
  };
  const handleKeyDownNumber = (event) => {
    if (!/\d/.test(event.key) && event.key !== 'Backspace') {
      event.preventDefault();
    }

  };
  return (
    <div className="body-wrapper" style={{backgroundColor:"#d3d3d3",minHeight:"100vh",marginTop:"-5rem"}}>
    <div
      className="container-fluid"
      style={{ backgroundColor: "#d3d3d3", minHeight: "100vh" }}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="card mt-4" style={{ boxShadow: "2px 3px 15px" }}>
            <div className="px-3 pt-4 border-bottom">
              <h5>Restaurant Details</h5>
            </div>
            <div className="card-body">
              <form>
                <div className="row">
                <div className="col-md-3 mb-2">
                      <div className="form-group mb-3">
                        <label htmlFor="DestinationName" className="form-label fw-semibold">
                          Destination Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <select
                          onChange={handleInputChange}
                          name="DestinationName"
                          value={formData.DestinationName.textValue}
                          className="form-control"
                          style={{
                            border: `1px solid ${(isSubmit && !formData.DestinationName.textValue) ? 'red' : isSubmit ? 'green' : 'light-gray'}`,
                          }}
                        >
                          <option value="">--Select Destination--</option>
                          {destinationOptions.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {isSubmit && !formData.DestinationName.textValue && (
                          <span className="text-danger">*</span>
                        )}
                      </div>
                    </div>
                  <div className="col-md-3 mb-2">
                    <div className="form-group mb-3">
                      <label
                        htmlFor="RestaurantType"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        Restaurant Type <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        onChange={handleInputChange}
                        name="RestaurantType"
                        value={formData.RestaurantType.textValue}
                        className="form-control"
                        style={{
                          border: `1px solid ${
                            isSubmit && !formData.RestaurantType.textValue
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                      >
                        <option value={""} selected="selected">
                          --Select Restaurant Type--
                        </option>
                        <option value="Veg">Veg</option>
                        <option value="Non-Veg">Non-Veg</option>
                        <option value="Both">Both</option>
                      </select>
                      {isSubmit && !formData.RestaurantType.textValue && (
                        <span className="text-danger">*</span>
                      )}
                      {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                    </div>
                  </div>
                  <div className="col-md-3 mb-2 ">
                    <div className="form-group">
                      <label
                        htmlFor="RestaurantName"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        Restaurant Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="RestaurantName"
                        value={formData.RestaurantName.textValue}
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        style={{
                          border: `1px solid ${
                            isSubmit && !formData.RestaurantName.textValue
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                      ></input>
                      {isSubmit && !formData.RestaurantName.textValue && (
                        <span className="text-danger">*</span>
                      )}
                      {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                    </div>
                  </div>
                  <div className="col-md-3 mb-4">
                    <div className="form-group">
                      <label className="form-label fw-semibold">
                        Is Within Hotel
                      </label>
                      <div className="form-control">
                        <div className="d-flex align-items-center gap-2">
                          <label className="d-flex align-items-center gap-2">
                            <input
                              onChange={handleInputChange}
                              type="radio"
                              name="IsWithinHotel"
                              value={"YES"}
                              checked={
                                formData.IsWithinHotel.textValue == "YES"
                              }
                            />
                            <span>YES </span>
                          </label>
                          <label className="d-flex align-items-center gap-2">
                            <input
                              onChange={handleInputChange}
                              type="radio"
                              name="IsWithinHotel"
                              value={"NO"}
                              checked={formData.IsWithinHotel.textValue == "NO"}
                            />
                            <span> NO</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {formData.IsWithinHotel.textValue === "YES" && (
                    <div className="col-md-3 mb-2">
                      <div className="form-group mb-3">
                        <label
                          htmlFor="HotelId"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          Hotel Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          onChange={(e) => handleHotelId(e.target.value)}
                          name="HotelId"
                          value={hotelId}
                          className="form-control"
                          style={{
                            border: `1px solid ${
                              isSubmit && !hotelId
                                ? "red"
                                : isSubmit
                                ? "green"
                                : "light-gray"
                            }  `,
                          }}
                        >
                          <option value="" selected="selected">
                            --Select Hotel Type--
                          </option>
                          {hotelData?.map((item, index) => (
                            <option value={item.HotelID}>
                              {item.HotelName}
                            </option>
                          ))}
                        </select>

                        {isSubmit && !hotelId && (
                          <span className="text-danger">*</span>
                        )}
                      </div>
                    </div>
                  )}
                      <div className="col-md-3 mb-2 ">
                       <div className="form-group">
                      <label
                        htmlFor="RestaurantTime"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        Open Time/Close Time <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="RestaurantTime"
                        value={formData.RestaurantTime.textValue}
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        style={{
                          border: `1px solid ${
                            isSubmit && !formData?.RestaurantTime?.textValue
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                      ></input>
                      {isSubmit && !formData.RestaurantTime.textValue && (
                        <span className="text-danger">*</span>
                      )}
                      {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                    </div>
                    </div>
                  <div className="col-md-3 mb-2">
                    <div className="form-group mb-3">
                      <label
                        htmlFor="RestaurantContactNo1"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        Contact Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                         inputmode="numeric"
                      onKeyDown={handleKeyDownNumber}
                        onChange={handleInputChange}
                        disabled={params.id? true :false}
                        name="RestaurantContactNo1"
                        value={formData.RestaurantContactNo1.textValue}
                        maxLength={10}
                        type="text"
                        className="form-control"
                        placeholder="Enter Contact Number"
                        style={{
                          border: `1px solid ${
                            isSubmit &&
                            (!formData.RestaurantContactNo1.textValue ||
                              formData.RestaurantContactNo1.textValue.length !==
                                10)
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                      ></input>
                      {isSubmit && !formData.RestaurantContactNo1.textValue && (
                        <span className="text-danger">*</span>
                      )}
                      {isSubmit &&
                        formData.RestaurantContactNo1.textValue &&
                        formData.RestaurantContactNo1.textValue.length !==
                          10 && (
                          <span className="text-danger">
                            Please Enter Valid Contact No
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-md-3 mb-2">
                    <div className="form-group">
                      <label
                        htmlFor="RestaurantContactNo2"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        Contact Number 2{" "}
                      </label>
                      <input
                         inputmode="numeric"
                      onKeyDown={handleKeyDownNumber}
                        onChange={handleInputChange}
                        name="RestaurantContactNo2"
                        value={formData.RestaurantContactNo2.textValue}
                        maxLength={10}
                        type="text"
                        className="form-control"
                        placeholder="Enter Contact Number"
                        style={{
                          border: `1px solid ${
                            isSubmit &&
                            formData.RestaurantContactNo2.textValue &&
                            formData.RestaurantContactNo2.textValue.length !==
                              10
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                      ></input>
                      {isSubmit &&
                        formData.RestaurantContactNo2.textValue &&
                        formData.RestaurantContactNo2.textValue.length !==
                          10 && (
                          <span className="text-danger">
                            Please Enter Valid Contact No
                          </span>
                        )}

                      {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                    </div>
                  </div>
                  <div className="col-md-3 mb-2">
                    <div className="form-group">
                      <label
                        htmlFor="RestaurantContactNo3"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        Landline Number{" "}
                      </label>
                      <input
                         inputmode="numeric"
                      onKeyDown={handleKeyDownNumber}
                        onChange={handleInputChange}
                        name="RestaurantContactNo3"
                        value={formData.RestaurantContactNo3.textValue}
                        maxLength={15}
                        type="text"
                        className="form-control"
                        placeholder="Enter Contact Number"
                        style={{
                          border: `1px solid ${
                            isSubmit &&
                            formData.RestaurantContactNo3.textValue &&
                            formData.RestaurantContactNo3.textValue.length !==
                              15
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                      ></input>
                      {isSubmit &&
                        formData.RestaurantContactNo3.textValue &&
                        formData.RestaurantContactNo3.textValue.length !==
                          15 && (
                          <span className="text-danger">
                            Please Enter Valid Contact No
                          </span>
                        )}

                      {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                    </div>
                  </div>
                  {/* <div className="col-md-3 mb-2 ">
                    <div className="form-group mb-3">
                      <label className="form-label fw-semibold">
                        Open Time <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        onChange={handleInputChange}
                        type="time"
                        name="RestaurantTime"
                        value={formData.RestaurantTime.textValue}
                        className="form-control"
                        placeholder="Select Time"
                        style={{
                          border: `1px solid ${
                            isSubmit && !formData.RestaurantTime.textValue
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                      />
                      <div></div>
                      {isSubmit && !formData.RestaurantTime.textValue && (
                        <span className="text-danger">*</span>
                      )}
                  
                    </div>
                  </div>
                  <div className="col-md-3 mb-2 ">
                    <div className="form-group mb-3">
                      <label className="form-label fw-semibold">
                        Close Time <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        onChange={handleInputChange}
                        type="time"
                        name="closeTime"
                        value={formData.closeTime.textValue}
                        className="form-control"
                        placeholder="Select Time"
                        style={{
                          border: `1px solid ${
                            isSubmit && !formData.closeTime.textValue
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                      />
                      <div>
                        {isSubmit && !formData?.closeTime?.textValue && (
                          <span className="text-danger">*</span>
                        )}
                      </div>
                     
                    </div>
                  </div> */}
                
                <div className="col-md-3 mb-4  ">
                    <div className="form-group mb-3 ">
                      <label
                        htmlFor="emailID"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        EmailID<span style={{ color: "red" }}>*</span>
                        <span style={{ color: "gray" }}>
                          {" "}
                          (Ex. xyz@gmail.com)
                        </span>
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="RestaurantEmailID"
                        value={formData.RestaurantEmailID.textValue}
                        type="text"
                        className="form-control"
                        placeholder="Enter EmailID"
                        style={{
                          border: `1px solid ${
                            isSubmit &&
                            (!formData.RestaurantEmailID.textValue ||
                              !validMail)
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                      ></input>
                      {isSubmit && !formData.RestaurantEmailID.textValue && (
                        <span className="text-danger">*</span>
                      )}
                      {isSubmit &&
                        !validMail &&
                        formData.RestaurantEmailID.textValue && (
                          <span className="text-danger">
                            Please Enter valid Email ID
                          </span>
                        )}
                    </div>
                    <div className="form-group mb-3">
                    <label className="form-label fw-semibold">
                      Restaurant Front Image{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      name="RestaurantImage"
                      type="file"
                      ref={RestaurantImageRef}
                      onChange={handleFileChange}
                      accept="image/jpeg, image/png, image/jpg"
                      className="form-control"
                      style={{
                        border: `1px solid ${
                          isSubmit &&
                          (!formData.RestaurantImage.textValue ||
                            !formData.RestaurantImage.valid)
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    />
                    {isSubmit && !formData.RestaurantImage.textValue && (
                      <span className="text-danger">*</span>
                    )}
                    {!formData.RestaurantImage.valid && (
                      <span className="text-danger">
                        *please upload valid image{" "}
                      </span>
                    )}
                    {!isCancle && IsUserEditObj?.RestaurantImage && (
                      <span>{IsUserEditObj?.RestaurantImage}</span>
                    )}
                    </div>
                  </div>
          
            <div className="col-md-3 mb-4">
                    <div className="form-group mb-3 ">
                      <label className="form-label fw-semibold">
                        {" "}
                        Latitude
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="RestaurantLatitude"
                        value={formData.RestaurantLatitude.textValue}
                        type="number"
                        className="form-control"
                        placeholder="Enter Latitude"
                        style={{
                          border: `1px solid ${
                            isSubmit &&
                            (formData.RestaurantLatitude.textValue > 90 ||
                              formData.RestaurantLatitude.textValue < -90)
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                      ></input>
                      {isSubmit &&
                        (formData.RestaurantLatitude.textValue > 90 ||
                          formData.RestaurantLatitude.textValue < -90) && (
                          <span className="text-danger">
                            Lattitude must be in range of -90 to 90
                          </span>
                        )}
                    </div>
                    <div className="form-group">
                      <label className="form-label fw-semibold">
                        {" "}
                        Longitude
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="RestaurantLongitude"
                        value={formData.RestaurantLongitude.textValue}
                        type="number"
                        className="form-control"
                        placeholder="Enter Longitude"
                        style={{
                          border: `1px solid ${
                            isSubmit &&
                            (parseFloat(
                              formData.RestaurantLongitude.textValue
                            ) > 180 ||
                              parseFloat(
                                formData.RestaurantLongitude.textValue
                              ) < -180)
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                      ></input>

                      {isSubmit &&
                        (parseFloat(formData.RestaurantLongitude.textValue) >
                          180 ||
                          parseFloat(formData.RestaurantLongitude.textValue) <
                            -180) && (
                          <span className="text-danger">
                            Longitude must be in range of -180 to 180
                          </span>
                        )}
                    </div>
                  </div>
              {/* <div className="col-md-3 mb-4">
              
                  </div>  */}
               
                  <div className="col-md-3 mb-4 ">
                    <div className="form-group">
                      <label className="form-label fw-semibold">
                        Address <span style={{ color: "red" }}>*</span>
                      </label>
                      <textarea
                        onChange={handleInputChange}
                        name="RestaurantAddress"
                        value={formData.RestaurantAddress.textValue}
                        style={{
                          height: "125px",
                          border: `1px solid ${
                            isSubmit && !formData.RestaurantAddress.textValue
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                        rows="2"
                        cols="20"
                        placeholder="Enter Address"
                        className="form-control"
                      />
                      {isSubmit && !formData.RestaurantAddress.textValue && (
                        <span className="text-danger">*</span>
                      )}
                      {/* {hasSubmit && !formData.RestaurantAddress.textValue && <span className='text-danger'>*</span>} */}
                    </div>
                  </div>
                  <div className="col-md-3 mb-2  ">
            
            <div className="form-group">
              <label className="form-label fw-semibold">
                {" "}
                Detail Images
                {IsUserEditObj && (
                  <>
                    {" ("}
                    <span style={{ color: "gray" }}>
                      {formData.images.count} Images Selected{" "}
                    </span>
                    {") "}
                  </>
                )}
              </label>
              <input
                name="images"
                type="file"
                ref={imagesRef}
                onChange={handleFileChange}
                accept="image/jpeg, image/png, image/jpg"
                multiple
                className="form-control"
                style={{
                  border: `1px solid ${
                    isSubmit && !formData.images.valid
                      ? "red"
                      : isSubmit
                      ? "green"
                      : "light-gray"
                  }  `,
                }}
              />

              {!formData.images.valid && (
                <span className="text-danger">
                  *please upload valid image{" "}
                </span>
              )}
              {/* {hasSubmit && (!formData.image.textValue || !formData.image.textValue.length  )  && <span className='text-danger'>*</span>} */}
            </div>
          </div>
                </div>
              </form>
            </div>
          </div>
          <div className="card " style={{ boxShadow: "2px 3px 15px" }}>
            <div className="px-3 pt-4 border-bottom">
              <h5>Owner Details</h5>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-3 mb-4">
                    <div className="form-group">
                      <label
                        htmlFor="OwnerName"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        Owner Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="OwnerName"
                        value={formData.OwnerName.textValue}
                        type="text"
                        className="form-control"
                        placeholder="Enter Owner Name"
                        style={{
                          border: `1px solid ${
                            isSubmit && !formData.OwnerName.textValue
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                      ></input>
                      {isSubmit && !formData.OwnerName.textValue && (
                        <span className="text-danger">*</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3 mb-4  ">
                    <label className="form-label fw-semibold">
                      Owner Image
                    </label>
                    <input
                      name="OwnerImage"
                      type="file"
                      ref={OwnerImageRef}
                      onChange={handleFileChange}
                      accept="image/jpeg, image/png, image/jpg"
                      className="form-control"
                      style={{
                        border: `1px solid ${
                          isSubmit && !formData.OwnerImage.valid
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    />
                    {!formData.OwnerImage.valid && (
                      <span className="text-danger">
                        *please upload valid image{" "}
                      </span>
                    )}
                    {!isCancle && IsUserEditObj?.OwnerImage && (
                      <span>{IsUserEditObj?.OwnerImage}</span>
                    )}
                  </div>
                  <div className="col-md-3 mb-4">
                    <div className="form-group">
                      <label
                        htmlFor="OwnerAadharCardNo"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        Owner Aadhar Card Number{" "}
                      </label>
                      <input
                         inputmode="numeric"

                      onKeyDown={handleKeyDownNumber}
                        onChange={handleInputChange}
                        maxLength={12}
                        name="OwnerAadharCardNo"
                        value={formData.OwnerAadharCardNo.textValue}
                        type="text"
                        className="form-control"
                        placeholder="Enter Owner Aadhar Card No"
                        style={{
                          border: `1px solid ${
                            isSubmit && !validAdhar
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                      ></input>

                      {isSubmit &&
                        !validAdhar &&
                        formData.OwnerAadharCardNo.textValue && (
                          <span className="text-danger">
                            Please Enter Valid Adhar Number{" "}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-md-3 mb-4">
                    <div className="form-group">
                      <label
                        htmlFor="OwnerPanCard"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        Owner Pan Card{" "}
                        <span style={{ color: "gray" }}> (Ex. FJGKP7626B)</span>
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="OwnerPanCard"
                        maxLength={12}
                        value={formData.OwnerPanCard.textValue}
                        type="text"
                        className="form-control"
                        placeholder="Enter OwnerPanCard"
                        style={{
                          border: `1px solid ${
                            isSubmit && !validPan
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                      ></input>

                      {isSubmit &&
                        !validPan &&
                        formData.OwnerPanCard.textValue && (
                          <span className="text-danger">
                            Please Enter Valid PAN Number{" "}
                          </span>
                        )}

                      {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                    </div>
                  </div>
                  <div className="col-md-3 mb-4  ">
                    <label className="form-label fw-semibold">
                      Owner ID Image <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      name="OwnerIDImage"
                      type="file"
                      ref={OwnerIDImageRef}
                      onChange={handleFileChange}
                      accept="image/jpeg, image/png, image/jpg"
                      className="form-control"
                      style={{
                        border: `1px solid ${
                          isSubmit &&
                          (!formData.OwnerIDImage.textValue ||
                            !formData.OwnerIDImage.valid)
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    />
                    {isSubmit && !formData.OwnerIDImage.textValue && (
                      <span className="text-danger">*</span>
                    )}

                    {!formData.OwnerIDImage.valid && (
                      <span className="text-danger">
                        *please upload valid image{" "}
                      </span>
                    )}
                    {!isCancle && IsUserEditObj?.OwnerIDImage && (
                      <span>{IsUserEditObj?.OwnerIDImage}</span>
                    )}
                  </div>
                  <div className="col-md-3 mb-4">
                    <div className="form-group">
                      <label
                        htmlFor="OwnerAddress"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        Owner Address
                      </label>
                      <textarea
                        onChange={handleInputChange}
                        name="OwnerAddress"
                        value={formData.OwnerAddress.textValue}
                        rows="2"
                        cols="20"
                        placeholder="Enter Owner Address"
                        className="form-control"
                        style={{
                          height: "125px",
                          border: `1px solid ${
                            isSubmit ? "green" : "light-gray"
                          }  `,
                        }}
                      />
                      {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="card" style={{ boxShadow: "2px 3px 15px" }}>
            <div className="px-4 pt-4 border-bottom">
              <h5>Manager Details</h5>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-3 mb-4">
                    <div className="form-group">
                      <label
                        htmlFor="ManagerName"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        Manager Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="ManagerName"
                        value={formData.ManagerName.textValue}
                        type="text"
                        className="form-control"
                        placeholder="Enter Manager Name"
                        style={{
                          border: `1px solid ${
                            isSubmit && !formData.ManagerName.textValue
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}
                      ></input>

                      {isSubmit && !formData.ManagerName.textValue && (
                        <span className="text-danger">*</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3 mb-4  ">
                    <label className="form-label fw-semibold">
                      Manager Photo
                    </label>
                    <input
                      name="ManagerPhoto"
                      type="file"
                      ref={ManagerPhotoRef}
                      onChange={handleFileChange}
                      accept="image/jpeg, image/png, image/jpg"
                      className="form-control"
                      style={{
                        border: `1px solid ${
                          isSubmit && !formData.ManagerPhoto.valid
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    />

                    {!formData.ManagerPhoto.valid && (
                      <span className="text-danger">
                        *please upload valid image{" "}
                      </span>
                    )}
                    {!isCancle && IsUserEditObj?.ManagerPhoto && (
                      <span>{IsUserEditObj?.ManagerPhoto}</span>
                    )}
                  </div>
                  <div className="col-md-3 mb-4">
                    <div className="form-group">
                      <label
                        htmlFor="ManagerAddress"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        Manager Address
                      </label>
                      <textarea
                        onChange={handleInputChange}
                        name="ManagerAddress"
                        value={formData.ManagerAddress.textValue}
                        rows="2"
                        cols="20"
                        placeholder="Enter Manager Address"
                        className="form-control"
                        style={{
                          height: "125px",
                          border: `1px solid ${
                            isSubmit ? "green" : "light-gray"
                          }  `,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="card" style={{ boxShadow: "2px 3px 15px" }}>
            <div className="px-3 pt-4 border-bottom">
              <h5>Hotel Certificates And Registration Details</h5>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-3 mb-4  ">
                    <label className="form-label fw-semibold">
                      Restaurant Menu{" "}
                      {IsUserEditObj && (
                        <>
                          {" ("}
                          <span style={{ color: "gray" }}>
                            {formData.RestaurantMenu.count} Images Selected{" "}
                          </span>
                          {") "}
                        </>
                      )}
                    </label>
                    <input
                      name="RestaurantMenu"
                      multiple
                      type="file"
                      ref={RestaurantMenuRef}
                      onChange={handleFileChange}
                      accept="image/jpeg, image/png, image/jpg"
                      className="form-control"
                      style={{
                        border: `1px solid ${
                          isSubmit &&
                          (!formData.RestaurantMenu.textValue ||
                            !formData.RestaurantMenu.valid)
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    />
                    {isSubmit && !formData.RestaurantMenu.textValue && (
                      <span className="text-danger">*</span>
                    )}
                    {!formData.RestaurantMenu.valid && (
                      <span className="text-danger">
                        *please upload valid image{" "}
                      </span>
                    )}
                    {!isCancle && IsUserEditObj?.RestaurantMenu && (
                      <span>{IsUserEditObj?.RestaurantMenu}</span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 mb-4  ">
                    <label className="form-label fw-semibold">
                      Police Clearance Certificate
                    </label>
                    <input
                      name="POC"
                      type="file"
                      ref={POCRef}
                      onChange={handleFileChange}
                      accept=".pdf,.PDF"
                      className="form-control"
                      style={{
                        border: `1px solid ${
                          isSubmit && !formData.POC.valid
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    />
                    {!formData.POC.valid && (
                      <span className="text-danger">
                        *please upload valid PDF File{" "}
                      </span>
                    )}
                    {!isCancle && IsUserEditObj?.PoliceClearanceCertificateFile && (
                      <span>{IsUserEditObj?.PoliceClearanceCertificateFile}</span>
                    )}
                  </div>
                  <div className="col-md-3 mb-4  ">
                    <label className="form-label fw-semibold">
                      Fire Clearance Certificate
                    </label>
                    <input
                      name="FireNOCFile"
                      type="file"
                      ref={FireNOCFileRef}
                      onChange={handleFileChange}
                      accept=".pdf,.PDF"
                      className="form-control"
                      style={{
                        border: `1px solid ${
                          isSubmit && !formData.FireNOCFile.valid
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                    />

                    {!formData.FireNOCFile.valid && (
                      <span className="text-danger">
                        *please upload valid PDF File{" "}
                      </span>
                    )}
                    {!isCancle && IsUserEditObj?.FireNOCFile && (
                      <span>{IsUserEditObj?.FireNOCFile}</span>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2 mt-4">
                  <button
                    type="submit"
                    className="btn btn-outline-primary font-weight-bolder ml-2 "
                  >
                    Submit
                  </button>
                  <button
                    onClick={handleCancle}
                    type="button"
                    className="btn btn-outline-danger font-weight-bolder ml-2"
                  >
                    Cancel
                  </button>
           
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
  );
};

export default AddRestaurentDetails;

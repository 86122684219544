import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import Edit from "../../Assets/Edit.png";
import Delete from "../../Assets/Delete.png";

import { APIURL, IMAGEMODELURL } from "../../Process";
import ImageNotFound from "../../Assets/Noimage.png";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const ViewBanner = () => {
  const navigate = useNavigate();
  ;
  const [bannerImage, setBannerImage] = useState([]);
  const BannerImageDIR = `${IMAGEMODELURL}/Banner`;
  useEffect(() => {
    getTermsAndCondition();
  }, []);
  const getTermsAndCondition = async() => {

   try{ const res = await fetch(`${APIURL}/Banner/GetBanner`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        BannerID: "",
      }),
    })

    const result=await res.json()
    setBannerImage(result.data);
  }catch(err){
    console.log(err)
  }
     
  };
  const editHistoryDetails = (element) => {
    navigate("/westKameng/addbanner", {
      state: element,
    });
  };
  const confirmDelete=async(id)=>{
    
   try{ const res = await fetch(`${APIURL}/Banner/RemoveBanner`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        BannerID: id,
      }),
    });

    const result = await res.json();
    if (result.status == 1) {
      Swal.fire({
        title: "Successfully Deleted!",
        icon: "success",
        timer: 1500,
showConfirmButton: false,
      });
     getTermsAndCondition()
    }
  }catch(err){
    console.log(err)
  }
 
  }
    const handleDeletePlaceDetails = async (id) => {
      Swal.fire({
        title: "Are you sure!",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor:"red"
        
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          confirmDelete(id)
        } 
      });
    };
  return (
    <div className="body-wrapper">
      <div className="container-fluid">
        <div className="card">
          <div className="px-3 py-2 border-bottom">
            <h5>Banner Image</h5>
          </div>
          <Table
            style={{boxShadow: "2px 3px 15px", textAlign: "left", border: "1px solid gray" }}
            striped
            bordered
            hover
          >
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Banner Image</th>
                <th>Delete</th>
                {Cookies.get("ckUserType") == "Admin" && <th>Edit</th>}
              </tr>
            </thead>
            <tbody>
              {bannerImage?.map((element, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td style={{ width: "70%" }}>
                    <img
                      src={
                        bannerImage
                          ? `${BannerImageDIR}/${element.Image}`
                          : ImageNotFound
                      }
                      style={{
                        height: 100,
                        width: 150,
                        borderWidth: 0,
                        border: "1px solid #007AB1",
                      }}
                    />
                  </td>
                  <td>
                    <img
                      onClick={() => {
                        handleDeletePlaceDetails(element.BannerID)
                      }}
                      src={Delete}
                      height={"25px"}
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      onClick={() => {
                        editHistoryDetails(element);
                      }}
                      src={Edit}
                      height={"25px"}
                      alt=""
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ViewBanner;

import React, { useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Edit from "../../../Assets/Edit.png";
import Delete from "../../../Assets/Delete.png";
import { FaToggleOn, FaToggleOff } from "react-icons/fa";
import { RotatingLines } from "react-loader-spinner";
import XLSX from "xlsx-color";
import moment from "moment";
import { APIURL } from "../../../Process";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const ViewNearByToilet = () => {
  const navigate = useNavigate();  
  console.log(Cookies.get("ckUserID"))
  const [totalCount, setTotalCount] = useState();
  const [pageCount, setPageCount] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState({
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    fetchNearByToilet(pageCount);
  }, []);
  const fetchNearByToilet = async (count) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${APIURL}/NearByToilet/getNearByToilet`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          NearByToiletID: "",
          Latitude: "",
          Longitude: "",
          StartDate: filterData.startDate?.split("-").reverse().join("-") || "",
          EndDate: filterData.endDate?.split("-").reverse().join("-") || "",
          IsActive: "",
          IsDelete: "false",
          IsPagination: "true",
          PageNo: "",
          PerPage: count,
          UniqueID: ""
        }),
      });

      const result = await res.json();
      console.log(result, "result")
      setTotalCount(result.TotalCount);
      const tempt = result.data.map((element) => ({ obj: element }));
      setIsLoading(false);
      setTableData(tempt);
      setPageId(1);
      setAllTableData(tempt);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleFilterInputChange = (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  };

  async function fetchExcelData() {

    debugger;
    try {
      const res = await fetch(`${APIURL}/NearByToilet/getNearByToilet`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          NearByToiletID: "",
          Latitude: "",
          Longitude: "",
          StartDate: filterData.startDate?.split("-").reverse().join("-") || "",
          EndDate: filterData.endDate?.split("-").reverse().join("-") || "",
          IsActive: "",
          IsDelete: "false",
          IsPagination: "false",
          PageNo: "",
          PerPage: "",
          UniqueID: ""
        }),
      });
      const result = await res.json();
      handleDownloadExcel(result.data);
    }
    catch (err) {
      console.log(err);
    }
  }
  const handleDownloadExcel = (array) => {
    console.log(array, "array");
    const header = [
      "Address",
      "Latitude",
      "Longitude",
      "Created Date",      
    ];
    const body = [];
    array?.forEach((element, index) => {
      body.push([
        element.Address,
        element.Latitude,
        element.Longitude,
        element.CreatedByDateTime  
      ]);
    });
    if (!body.length) {
      setIsLoading(false);
      return Swal.fire({
        title: "No Data Found!",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    }
    //   setIsLoading(false)
    const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);

    header.forEach((col, index) => {
      ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
        font: { bold: true, color: { rgb: "f7f4ef" } }, // White font color
        fill: { patternType: "solid", fgColor: { rgb: "54153255" } }, // Blue background color
        alignment: { wrapText: true, horizontal: "center" },
      };
    });

    ws1["!cols"] = [
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "NearByToilet Details");

    // Save the workbook
    setIsLoading(false);
    XLSX.writeFile(
      wb,
      `NearByToilet_Details${moment().format("DD-MM-YYYY_HH:mm")}.xlsx`
    );
  };


  const handleDeleteUserDetails = async (id) => {

    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red"

    }).then((result) => {

      if (result.isConfirmed) {
        confirmUsereDelete(id)
      }
    });
  };
  const confirmUsereDelete = async (id) => {
    debugger;
    try {
      console.log()
      const res = await fetch(`${APIURL}/NearByToilet/removeNearByToilet`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          NearByToiletID: id,
          RemovedByUserID: Cookies.get("ckUserID"),
        }),
      });

      const result = await res.json();
      if (result.status == 1) {
        Swal.fire({
          title: "Successfully Deleted!",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        fetchNearByToilet(pageCount);
      }
      console.log(result);
    } catch (err) {
      console.log(err)
    }
  }

  const editDestinationDetails = (element) => {
    debugger;
    navigate("/westKameng/addNearByToilet", {
      state: element.obj,
    });

  };

  const setNextApi = async () => {
    console.log(pageId, "pageID", pageCount);
    if (pageId * pageCount < allTableData.length) {
      const tempt = [...allTableData];
      console.log(tempt, pageId * pageCount, (pageId + 1) * pageCount);
      const sliced = tempt.slice(pageId * pageCount, (pageId + 1) * pageCount);
      setTableData(sliced);
      setPageId(pageId + 1);
      return;
    }
    const paginationIndex = allTableData[pageId * pageCount - 1]?.obj?.NearByToiletID;
    if (!paginationIndex) {
      return;
    }

    console.log(pageId, allTableData);
    try {
      const res = await fetch(`${APIURL}/NearByToilet/getNearByToilet`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          NearByToiletID: "",
          Latitude: "",
          Longitude: "",
          StartDate: filterData.startDate?.split("-").reverse().join("-") || "",
          EndDate: filterData.endDate?.split("-").reverse().join("-") || "",
          IsActive: "",
          IsDelete: "false",
          IsPagination: "true",
          PerPage: `${pageCount}`,
          PageNo: "1",
          UniqueID: `${paginationIndex}`        
        }),
      });
      const result = await res.json();
      console.log(result);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
        });
      });
      console.log(tempt, "tempt");
      setTableData(tempt);
      setPageId(pageId + 1);
      setAllTableData([...allTableData, ...tempt]);
    } catch (err) {
      console.log(err);
    }
  };

  const setPreviousApi = () => {
    if (pageId == 1) {
      return;
    }
    const tempt = [...allTableData];
    console.log(pageId, "pageId");
    console.log(
      pageId,
      tempt,
      (pageId - 1) * pageCount,
      pageId * pageCount + 1
    );
    const sliced = tempt.slice(
      (pageId - 2) * pageCount,
      (pageId - 1) * pageCount
    );
    setTableData(sliced);
    setPageId(pageId - 1);
  };

  const searchHandle = async (count, paginationIndex, filterData) => {
    // setIsLoading(true);
    try {
      console.log(filterData, "filterDataaaaaaaaa", count);
      const res = await fetch(`${APIURL}/NearByToilet/getNearByToilet`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({

          NearByToiletID: "",
          Latitude: "",
          Longitude: "",
          StartDate: filterData.startDate?.split("-").reverse().join("-") || "",
          EndDate: filterData.endDate?.split("-").reverse().join("-") || "",
          IsActive: "",
          IsDelete: "false",
          IsPagination: "true",
          PerPage: count,
          PageNo: "1",
          UniqueID: ""  
        }),
      });
      const result = await res.json();
      console.log(result);
      setTotalCount(result?.TotalCount);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
        });
      });
      console.log(tempt, "?tempt");
      setTableData(tempt);
      setIsLoading(false);
      setAllTableData(tempt);
      setPageId(1);
    } catch (err) {
      console.log(err);
    }
  };
  const handlePageCount = (e) => {
    setPageCount(e.target.value);
    searchHandle(e.target.value, 0, filterData);
  };

  const handleCancle = () => {
    setFilterData({
      StartDate: "",
      EndDate: ""
    });
    fetchNearByToilet(pageCount);
  };

  const NearByToiletActive = async (NearByToiletID, status) => {
    console.log(NearByToiletID, "NearByToiletID");
    try {
      const res = await fetch(`${APIURL}/NearByToilet/setNearByToiletIsActiveStatus`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          NearByToiletID: NearByToiletID,
          IsActive: `${status}`,
          UpdatedByUserID: Cookies.get("ckUserID"),
        }),
      });
      const result = await res.json();
      searchHandle(pageCount, 0, filterData);
      
    } catch (err) {
      console.log(err)
    }
  };


  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: "6",
          left: "600px",
          top: "300px",
        }}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="200"
          height="200"
          visible={true}
        />
      </div>
    );
  }
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return ( 
    <div className="body-wrapper">
    <div className="container-fluid">
      {isLoading && (
        <div
          style={{
            position: "absolute",
            zIndex: "6",
            left: "600px",
            top: "300px",
          }}
        >
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="200"
            height="200"
            visible={true}
          />
        </div>
      )}
      <div
        className={`pt-2 pb-2 NearByToliet-filter  ${isOpen ? "open" : "closed"}`}
      >
        <Row>
          <Col md={2} sm={2} className="col-4">
            <div className="d-flex gap-2 pt-1">
              <span>Total Count</span>
              <span>{totalCount}</span>
            </div>
          </Col>
          <Col md={2} sm={2} className="col-4">
            <Form.Select
              onChange={handlePageCount}
              size="sm"
              className="w-70"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </Form.Select>
          </Col>

          <Col md={{ span: 2, offset: 6 }} className="col-4">
            <Button
              className="float-end"
              md={2}
              variant="outline-primary"
              size="sm"
              onClick={handleToggle}
            >
              {isOpen ? "Close Filter" : "Open Filter"}
            </Button>
          </Col>
        </Row>
        {isOpen && (
          <div
            className="filter-content row"
            style={{
              marginTop: "20px",
              padding: "20px",
              border: "1px solid gray",
            }}
          >
            <Form className="d-flex row pb-4">
              <div className="form-group mb-2 col-md-3">
                <label>
                  Start Date
                  <span style={{ color: "#72bdce", fontSize: 10 }} />
                </label>
                <input
                  onKeyDown={(e) => e.preventDefault()}
                  value={filterData.startDate}
                  onChange={handleFilterInputChange}
                  name="startDate"
                  type="date"
                  class="form-control"
                  placeholder="dd-MM-yyyy"
                ></input>
              </div>
              <div className="form-group mb-2 col-md-3">
                <label>
                  End Date
                  <span style={{ color: "#72bdce", fontSize: 10 }} />
                </label>
                <input
                  onKeyDown={(e) => e.preventDefault()}
                  min={filterData.startDate}
                  value={filterData.endDate}
                  onChange={handleFilterInputChange}
                  name="endDate"
                  type="date"
                  class="form-control"
                  placeholder="dd-MM-yyyy"
                ></input>
                {/* {searched &&
                    filterData.endDate < filterData.startDate && (
                      <span style={{ color: "red" }}>
                        End Date Must be greater than start date
                      </span>
                    )} */}
              </div>

              <div className="col-md-3" style={{ position: "relative", top: "27px" }}>
                <Button
                  size="sm"
                  variant="primary"
                  className="me-2"
                  onClick={() => searchHandle(pageCount, "", filterData)}
                >
                  Apply
                </Button>

                <Button
                  className="me-2"
                  onClick={fetchExcelData}
                  size="sm"
                  variant="success"
                >
                  Export
                </Button>
                <Button onClick={handleCancle} size="sm" variant="danger">
                  Cancel
                </Button>
              </div>
           
            </Form>
          </div>
        )}
      </div>
      <div className="overflow-auto">
      <Table style={{ boxShadow: "2px 3px 15px", textAlign: "left", border: "1px solid gray", }}
            striped
            bordered
            hover
          >
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>Status</th>
                <th>Address</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Created Date</th>
                <th>Edit</th>
                <th>Delete</th>

              </tr>
            </thead>
            <tbody>
              {tableData.map((element, index) => (
                <>
                  <tr>
                    <td>{(pageId - 1) * pageCount + (index + 1)}</td>
                   
                    <td>
                      {" "}
                      {element.obj.IsActive ? (
                        <FaToggleOn
                          onClick={() =>
                            NearByToiletActive(element.obj.NearByToiletID, 0)
                          }
                          style={{ color: "green", fontSize: "1.9em" }}
                        />
                      ) : (
                        <FaToggleOff
                          onClick={() =>
                            NearByToiletActive(element.obj.NearByToiletID, 1)
                          }
                          style={{ color: "red", fontSize: "1.8em" }}
                        />
                      )}
                    </td>                 
                  

                    <td>{element.obj.Address}</td>

                    <td>{element.obj.Latitude}</td>

                    <td>{element.obj.Longitude}</td>    

                    <td>{element.obj.CreatedByDateTime}</td>            

                    <td>
                      <img
                        src={Edit}
                        onClick={() => editDestinationDetails(element)}
                        height={"25px"}
                        alt=""
                      />
                    </td>

                    <td>
                      <img
                        src={Delete}
                        onClick={() => {
                          handleDeleteUserDetails(element.obj.NearByToiletID);
                        }}
                        height={"25px"}
                        alt=""
                      />
                    </td>

                  </tr>
               
                </>
              ))}
            </tbody>
            <tbody>
              <tr>
                {!tableData.length && (
                  <td colSpan={11}>
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#ff0000",
                        backgroundColor: "#f0f0f0",
                        border: "1px solid #e0e0e0",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      NO DATA FOUND
                    </div>
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="pt-2 pb-2">
          <Button onClick={setPreviousApi} variant="primary" size="sm">
            PREVIOUS
          </Button>{" "}
          <Button onClick={setNextApi} variant="secondary" size="sm">
            NEXT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewNearByToilet;

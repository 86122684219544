import React, { useEffect, useState } from "react";
import { APIURL, IMAGEMODELURL } from "../../Process";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";

import { Select } from "@mui/material";
import EyeOpen from "../../Assets/eyeopen.png";
import EyeClose from "../../Assets/eyeclose.png";
import Delete from "../../Assets/Delete.png";
import ImageNotFound from "../../Assets/Noimage.png";
import { FaEyeSlash, FaToggleOff, FaToggleOn } from "react-icons/fa";
import Swal from "sweetalert2";
import XLSX from "xlsx-color";
import moment from "moment";
import ShowPasswardModel from "../ShowPasswardModel";

const ViewUserDetails = () => {
  ;
  const [pageCount, setPageCount] = useState("10");
  const [tableData, setTableData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [allTableData, setAllTableData] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [openModel,setOpenModel]=useState(false)
  const [showPasswordID,setShowPasswordID]=useState("")
  const [filterData, setFilterData] = useState({
    name: "",
    mobileNumber: "",
  });
  const UserImageDIR = `${IMAGEMODELURL}/User`;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchInitialUserDetailsData(pageCount);
  }, []);

  const fetchInitialUserDetailsData = async(count) => {
    setIsLoading(true);
try{
    const res=await fetch(`${APIURL}/User/GetUser`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        PaginationID: "0",
        PageLength: count,
        UsedFor: "Panel",
      }),
    })
    const result=await res.json()
    setTotalCount(result.total[0].TotalCount);
    console.log(result, "initialReasult");
    const tempt = [];
    result.data.forEach((element) => {
      tempt.push({
        obj: element,
        isDetails: false,
      });
    });
    setIsLoading(false);
    setTableData(tempt);
    setPageId(1);
    setAllTableData(tempt);
  }catch(err){
    setIsLoading(false);
    console.log(err)
  }
 
  };
  const handlePageCount = (e) => {
    setPageCount(e.target.value);
    searchHandle(e.target.value, 0);
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleEyeOn = (index) => {
    console.log("eyeon");
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: true,
    });
    setTableData(tempt);
  };
  const handleEyeOff = (index) => {
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: false,
    });
    setTableData(tempt);
  };
  const handleFilterInputChange = (e) => {
    let lastInputString = e.target.value[e.target.value.length - 1];

    if (e.target.name == "mobileNumber") {
      if (
        e.target.value == "" ||
        (lastInputString.charCodeAt() >= 48 &&
          lastInputString.charCodeAt() <= 57)
      ) {
        setFilterData({
          ...filterData,
          [e.target.name]: e.target.value,
        });
      } else {
        return;
      }
      return;
    }
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  };
  const searchHandle = async(count, paginationIndex) => {

try{
    console.log(filterData, "filterDataaaaaaaaa", count);
    const res=await fetch(`${APIURL}/User/GetUser`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Name: filterData.name,
        MobileNo: filterData.mobileNumber,
        PaginationID: `${paginationIndex}`,
        PageLength: count,
      }),
    })
    const result=await res.json()
    console.log(result);
    setTotalCount(result.total[0].TotalCount);
    // setIsLoading(false)
    const tempt = [];
    // setTotalEntries(result.total[0].TotalArji)
    result.data.forEach((element) => {
      tempt.push({
        obj: element,
        isDetails: false,
      });
    });
    console.log(tempt, "?tempt");
    setTableData(tempt);
    setAllTableData(tempt);
    setPageId(1);
  }catch(err){
    console.log(err)
  } 
  };
  const handleCancle = () => {
    setFilterData({
      name: "",
      mobileNumber: "",
    });
    fetchInitialUserDetailsData(pageCount);
  };
  const setNextApi = async() => {
    console.log(pageId, "pageID", pageCount);
    if (pageId * pageCount < allTableData.length) {
      const tempt = [...allTableData];
      console.log(tempt, pageId * pageCount, (pageId + 1) * pageCount);
      const sliced = tempt.slice(pageId * pageCount, (pageId + 1) * pageCount);
      setTableData(sliced);
      setPageId(pageId + 1);
      return;
    }
    const paginationIndex = allTableData[pageId * pageCount - 1]?.obj?.UserID;
    if (!paginationIndex) {
      return;
    }
    console.log(pageId, allTableData);
 try{   const res=await fetch(`${APIURL}/User/GetUser`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Name: filterData.name,
        MobileNo: filterData.mobileNumber,
        PaginationID: `${paginationIndex}`,
        PageLength: `${pageCount}`,
      }),
    })
const result=await res.json()
console.log(result);
const tempt = [];
result.data.forEach((element) => {
  tempt.push({
    obj: element,
    isDetails: false,
  });
});
console.log(tempt, "tempt");
setTableData(tempt);
setPageId(pageId + 1);
setAllTableData([...allTableData, ...tempt]);
 }catch(err){
console.log(err)
}  
  };
  const setPreviousApi = () => {
    if (pageId == 1) {
      return;
    }
    const tempt = [...allTableData];
    console.log(pageId, "pageId");
    console.log(
      pageId,
      tempt,
      (pageId - 1) * pageCount,
      pageId * pageCount + 1
    );
    const sliced = tempt.slice(
      (pageId - 2) * pageCount,
      (pageId - 1) * pageCount
    );
    setTableData(sliced);
    setPageId(pageId - 1);
  };
  const handleUserActive = async (userID, status) => {
    // console.log(placeID,"placeID")
    try{
    const res = await fetch(`${APIURL}/User/UpdateActiveStatus`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserID: userID,
        Status: `${status}`,
      }),
    });
    const result = await res.json();
    searchHandle(pageCount, 0);
    console.log(result, "placeActiveStutus");
  }catch(err){
    console.log(err)
  }
  };
  const confirmUsereDelete=async(id)=>{
    try{
    const res = await fetch(`${APIURL}/User/RemoveUser`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserID: id,
      }),
    });

    const result = await res.json();
    if (result.status == 1) {
      Swal.fire({
        title: "Successfully Deleted!",
        icon: "success",
        timer: 1500,
showConfirmButton: false,
      });
      fetchInitialUserDetailsData(pageCount);
    }
    console.log(result);
  }catch(err){
    console.log(err)
  }
  }
  const handleDeleteUserDetails = async (id) => {

    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor:"red"
      
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmUsereDelete(id)
      } 
    });
  };
  async function fetchExcelData() {
    // setIsLoading(true)
    setIsLoading(true);
    try{
    const res=await fetch(`${APIURL}/User/GetUser`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Name: filterData.name,
        MobileNo: filterData.mobileNumber,
        PaginationID: "-1",
        PageLength: ``,
      }),
    })
    const result=await res.json()
    handleDownloadExcel(result.data);
  }catch(err){
    setIsLoading(false);
    console.log(err)
  }
    
  
  }
  const handleDownloadExcel = (array) => {
    console.log(array, "array");
    const header = [
      "Name",
      "Email ID",
      "Date Of Birth",
      "Gender",
      "Mobile Number",
      "User Type",
      "Register Type",
      "Address",
    ];
    const body = [];
    array?.forEach((element, index) => {
      element.UserType!=="Admin" &&
      body.push([
        element.Name,
        element.EmailID,
        element.DateOfBirth,
        element.Gender,
        element.MobileNo,
        element.UserType,
        element.RegisterType,
        element.Address,
      ]);
    });
    if (!body.length) {
      setIsLoading(false);
      return Swal.fire({
        title: "No Data Found!",
        icon: "error",
        timer: 1500,
showConfirmButton: false,
      });
    }
    //   setIsLoading(false)

    const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);

    header.forEach((col, index) => {
      ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
        font: { bold: true, color: { rgb: "f7f4ef" } }, // White font color
        fill: { patternType: "solid", fgColor: { rgb: "54153255" } }, // Blue background color
        alignment: { wrapText: true, horizontal: "center" },
      };
    });

    ws1["!cols"] = [
      { width: 20 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 40 },
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "User Details");

    // Save the workbook
    setIsLoading(false);
    XLSX.writeFile(
      wb,
      `User_Details${moment().format("DD-MM-YYYY_HH:mm")}.xlsx`
    );
  };
  if(isLoading){
    return   <div
    style={{
      position: "absolute",
      zIndex: "6",
      left: "600px",
      top: "300px",
    }}
  >
    <RotatingLines
      strokeColor="grey"
      strokeWidth="5"
      animationDuration="0.75"
      width="200"
      height="200"
      visible={true}
    />
  </div>
  }
  return (
    <div className="body-wrapper">
      <div className="container-fluid">
       
          {openModel && <ShowPasswardModel openModel={openModel}  setOpenModel={setOpenModel} UserID={showPasswordID} UserType={"Public User"} />}
        <div
          className={` pt-2 pb-2 place-filter  ${isOpen ? "open" : "closed"}`}
        >
          <Row>
            <Col md={2} sm={2} className="col-4">
              <div className="d-flex gap-2 pt-1">
                <span>Total Count</span>
                <span>{totalCount - 1}</span>
              </div>
            </Col>
            <Col md={2} sm={2} className="col-4">
              <Form.Select
                onChange={handlePageCount}
                size="sm"
                className="w-70"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </Form.Select>
            </Col>

            <Col md={{ span: 2, offset: 6 }} className="col-4">
              <Button
                className="float-end"
                md={2}
                variant="outline-primary"
                size="sm"
                onClick={handleToggle}
              >
                {isOpen ? "Close Filter" : "Open Filter"}
              </Button>
            </Col>
          </Row>
          {isOpen && (
            <div
              className="filter-content row"
              style={{
                marginTop: "20px",
                padding: "20px",
                border: "1px solid gray",
              }}
            >
              <Form className="d-flex pb-4 row">
                <div className="mb-2 col-md-3">
                  <label htmlFor="name" className="form-label fw-semibold">
                    {" "}
                    User Name
                  </label>

                  <input
                    type="text"
                    name="name"
                    placeholder="User Name"
                    value={filterData.name}
                    className="form-control"
                    onChange={handleFilterInputChange}
                  />
                </div>
                <div className="mb-2 col-md-3">
                  <label
                    htmlFor="mobileNumber"
                    className="form-label fw-semibold"
                  >
                    {" "}
                    User Mobile Number
                  </label>
                  <input
                    type="text"
                    name="mobileNumber"
                    maxLength={10}
                    placeholder="Mobile Number"
                    value={filterData.mobileNumber}
                    className="form-control"
                    onChange={handleFilterInputChange}
                  />
                </div>

                <div className="col-md-3" style={{ position: "relative", top: "27px" }}>
                  <Button
                    size="sm"
                    variant="primary"
                    className="me-2"
                    onClick={() => {
                      searchHandle(pageCount, 0);
                    }}
                  >
                    Apply
                  </Button>

                  <Button
                    className="me-2"
                    onClick={fetchExcelData}
                    size="sm"
                    variant="success"
                  >
                    Export
                  </Button>
                  <Button onClick={handleCancle} size="sm" variant="danger">
                    Cancel
                  </Button>
                </div>
                {/* Add more InputGroup components for additional filters */}
              </Form>
            </div>
          )}
        </div>
        <Table
          style={{
            boxShadow: "2px 3px 15px",
            textAlign: "left",
            border: "1px solid gray",
          }}
          striped
          bordered
          hover
        >
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Details</th>
              <th>Status</th>
              <th>Name</th>
              <th>Mobile No</th>
              <th>Login OTP</th>
              <th>Email ID</th>
              <th>Address</th>
              {/* <th>Show Password</th> */}
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((element, index) => (
              element?.obj?.UserType !=="Admin" && 
              <>
                <tr>
                  <td>{(pageId - 1) * pageCount + (index + 1)}</td>
                  <td>
                    {!element.isDetails && (
                      <img
                        onClick={() => handleEyeOn(index)}
                        src={EyeOpen}
                        height={"22px"}
                        alt=""
                      />
                    )}{" "}
                    {element.isDetails && (
                      <img
                        onClick={() => handleEyeOff(index)}
                        src={EyeClose}
                        height={"22px"}
                        alt=""
                      />
                    )}
                  </td>
                  <td>
                    {" "}
                    {element.obj.IsActive ? (
                      <FaToggleOn
                        onClick={() => handleUserActive(element.obj.UserID, 0)}
                        style={{ color: "green", fontSize: "1.9em" }}
                      />
                    ) : (
                      <FaToggleOff
                        onClick={() => handleUserActive(element.obj.UserID, 1)}
                        style={{ color: "red", fontSize: "1.8em" }}
                      />
                    )}
                  </td>
                  <td>{element.obj.Name}{element.obj.Type && " (" + element.obj.Type + ")"}</td>
                  <td>{element.obj.MobileNo}</td>
                  <td>{element.obj.LoginOTP}</td>
                  <td>{element.obj.EmailID}</td>
                  <td>{element.obj.Address}</td>
                  {/* <td><FaEyeSlash onClick={()=>{setOpenModel(true);setShowPasswordID(element.obj.UserID)}} style={{fontSize:"1.4em",cursor:"pointer"}} /></td> */}
                  <td>
                    <img
                      src={Delete}
                      onClick={() => {
                        handleDeleteUserDetails(element.obj.UserID);
                      }}
                      height={"25px"}
                      alt=""
                    />
                  </td>
                </tr>

                {element.isDetails && (
                  <tr>
                    <td colSpan={11}>
                      <Table
                        style={{
                          textAlign: "left",
                          border: "1px solid gray",
                        }}
                        striped
                        bordered
                        hover
                      >
                        <thead>
                          <th className="p-2">User Details</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {
                                <Table
                                  style={{
                                    textAlign: "left",
                                    border: "1px solid gray",
                                  }}
                                  striped
                                  bordered
                                  hover
                                >
                                  <thead>
                                    <tr>
                                      <th> User Image</th>
                                      <th> Type</th>
                                      <th> Register Type </th>
                                      <th>Date Of Birth</th>
                                      <th>Gender</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {" "}
                                        <img
                                          key={index}
                                          src={
                                            element?.obj.UserImage
                                              ? `${UserImageDIR}/${element.obj.UserImage}`
                                              : ImageNotFound
                                          }
                                          style={{
                                            marginLeft: "10px",
                                            height: 90,
                                            width: 90,
                                            borderWidth: 0,
                                            border: "1px solid #007AB1",
                                          }}
                                        />
                                      </td>
                                      <td>{element.obj.UserType}</td>
                                      <td>{element.obj.RegisterType}</td>
                                      <td>{element.obj.DateOfBirth}</td>
                                      <td>{element.obj.Gender}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              }
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
          <tbody>
            <tr>
              { !tableData.length  && (
                <td colSpan={11}>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "20px",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#ff0000",
                      backgroundColor: "#f0f0f0",
                      border: "1px solid #e0e0e0",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    NO DATA FOUND
                  </div>
                </td>
              )}
            </tr>
          </tbody>
        </Table>
        <div className="pt-2 pb-2">
          <Button onClick={setPreviousApi} variant="primary" size="sm">
            PREVIOUS
          </Button>{" "}
          <Button onClick={setNextApi} variant="secondary" size="sm">
            NEXT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewUserDetails;

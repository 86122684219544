import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { APIURL } from "../../Process";
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";

import { compressImage } from "../CommonComponents";
import Cookies from "js-cookie";
import { handleKeyDownNumber, handlePaste } from "../../HelperMethod";

const HotelDetails = () => {
  const hotelImageRef = useRef(null);
  const RegistrationImageRef = useRef(null);
  const OwnerIDImageRef = useRef(null);
  const OwnerImageRef = useRef(null);
  const ManagerPhotoRef = useRef(null);
  const FireNOCFileRef = useRef(null);
  const POCRef = useRef(null);
  const imagesRef = useRef(null);
  ;
  const [isSubmit, setIsSubmit] = useState(false);
  const [validPan, setValidPan] = useState(true);
  const [validAdhar, setValidAdhar] = useState(true);
  const [validMail, setValidMail] = useState(true)
  const [isCancle, setIsCancle] = useState(false)
  const [destinationOptions, setDestinationOptions] = useState([]);
  const navigate = useNavigate()
  const { state } = useLocation()
  const handleClearButtonClick = () => {
    hotelImageRef.current.value = "";
    RegistrationImageRef.current.value = "";
    OwnerIDImageRef.current.value = "";
    OwnerImageRef.current.value = "";
    ManagerPhotoRef.current.value = "";
    FireNOCFileRef.current.value = "";
    POCRef.current.value = "";
    imagesRef.current.value = "";
  };
  console.log(state, "state")
 

  useEffect(() => {
    GetDestination(); 
  }, []);

  const GetDestination = async () => {
    try {
      const res = await fetch(`${APIURL}/Destination/getDestination`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          DestinationID: '',
          DestinationName: '',
          IsActive: 'true',
          IsDelete: 'false',
          IsPagination: '',
          PerPage: '',
          UniqueID: '',
        }),
      });

      const result = await res.json();
      if (result.status) {
        const options = result.data.map(item => ({ value: item.DestinationID, label: item.DestinationName }));
        setDestinationOptions(options);
      } else {
        setDestinationOptions([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [formData, setFormData] = useState({
    DestinationName: {
      textValue: state?.DestinationID || "",
      required: true,
    },
    hotelType: {
      textValue: state?.HotelType || "",
      required: true,
    },
    hotelName: {
      textValue: state?.HotelName || "",
      required: true,
    },
    checkInTime: {
      textValue: state?.CheckInTime || "",
      required: true,
    },
    checkOutTime: {
      textValue: state?.CheckOutTime || "",
      required: true,
    },
    address: {
      textValue: state?.HotelAddress || "",
      required: true,
    },
    latitude: {
      textValue: state?.HotelLatitude || "",
      required: false,
    },
    longitude: {
      textValue: state?.HotelLongitude || "",
      required: false,
    },
    contact: {
      textValue: state?.HotelContactNo1 || "",
      required: true,
    },
    contact2: {
      textValue: state?.HotelContactNo2 || "",
      required: false,
    },
    contact3: {
      textValue: state?.HotelContactNo3 || "",
      required: false,
    },
    emailID: {
      textValue: state?.HotelEmailID || "",
      required: true,
    },
    hotelImage: {
      textValue: state?.HotelImage || "",
      required: true,
      valid: true,
      type: "file",
    },
    isRegister: {
      textValue: state?.IsRegisterWithTourismDepartment?.toUpperCase() || "NO",
      required: false,
    },
    RegistrationID: {
      textValue: state?.RegistrationID || "",
      required: false,
    },
    RegistrationImage: {
      textValue: state?.RegistrationImage || "",
      required: false,
      valid: true,
      type: "file",
    },
    OwnerName: {
      textValue: state?.OwnerName || "",
      required: true,
    },
    OwnerIDImage: {
      textValue: state?.OwnerIDImage || "",
      required: false,
      valid: true,
      type: "file",
    },
    OwnerImage: {
      textValue: state?.OwnerImage || "",
      required: false,
      valid: true,
      type: "file",
    },
    OwnerAadharCardNo: {
      textValue: state?.OwnerAadharCardNo || "",
      required: false,
    },
    OwnerPanCard: {
      textValue: state?.OwnerPanCard || "",
      required: false,
    },
    OwnerAddress: {
      textValue: state?.OwnerAddress || "",
      required: false,
    },
    ManagerName: {
      textValue: state?.ManagerName || "",
      required: false,
    },
    ManagerPhoto: {
      textValue: state?.ManagerPhoto || "",
      required: false,
      valid: true,
      type: "file",
    },
    ManagerAddress: {
      textValue: state?.ManagerAddress || "",
      required: false,
    },
    FireNOCFile: {
      textValue: state?.FireNOCFile || "",
      required: false,
      valid: true,
      type: "file",
    },
    POC: {
      textValue: state?.PoliceClearanceCertificateFile || "",
      required: false,
      valid: true,
      type: "file",
    },
    IsRestaurantAvailable: {
      textValue: state?.IsRestaurantAvailable?.toLowerCase() == "yes" ? 'YES' : "NO",
      required: false,
    },
    images: {
      textValue: state?.ImageDetail || "",
      required: false,
      valid: true,
      type: "file",
      count: state?.ImageDetail?.length || 0
    },
  });
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        textValue: e.target.value,
      },
    });
  };
  const handleFileChange = (e) => {
    console.log(e.target.files[0], "e.target.value");
    if (e.target.name == "FireNOCFile" || e.target.name == "POC") {
      if (
        ["pdf", "PDF"].includes(
          e.target.value.split(".")[e.target.value.split(".").length - 1]
        ) ||
        e.target.value == ""
      ) {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.files[0],
            valid: true,
          },
        });
      } else {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: "",
            valid: false,
          },
        });
      }
    } else {

      if (
        ["jpg", "png", "jpeg"].includes(
          e.target.value.split(".")[e.target.value.split(".").length - 1]
        ) ||
        e.target.value === ""
      ) {
        if (e.target.name === "images") {
          if (state?.ImageDetail?.length + e.target.files.length > 5) {
            Swal.fire({
              title: "Cannot Select More Than 5 Images!",
              icon: "error",
              timer: 1500,
showConfirmButton: false
            });
            return
          }
          setFormData({
            ...formData,
            [e.target.name]: {
              ...formData[e.target.name],
              textValue: e.target.files,
              valid: true,
              count: (state?.ImageDetail?.length || 0) + e.target.files.length
            }
          })
        } else {
          setFormData({
            ...formData,
            [e.target.name]: {
              ...formData[e.target.name],
              textValue: e.target.files[0],
              valid: true,
            },
          });
        }
      } else {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: "",
            valid: false,
          },
        });
      }
    }
  };
  const handleSubmit = async(e) => {
    debugger
    e.preventDefault();
    console.log(formData, "formData");
    setIsSubmit(true);
    let validForm = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    let validPantempt = true
    let validAdharTempt = true
    let validMailTempt = true
    console.log(!panRegex.test(formData.OwnerPanCard.textValue), "jjjj");
    if (formData.emailID.textValue.length) {
      if (!emailRegex.test(formData.emailID.textValue)) {
        validMailTempt = false
        setValidMail(() => {
          return false;
        });
      } else {
        validMailTempt = true
        setValidMail(() => {
          return true;
        });
      }
    }
    if (!panRegex.test(formData.OwnerPanCard.textValue) && formData.OwnerPanCard.textValue.length) {
      validPantempt = false
      setValidPan(() => {
        return false;
      });
    } else {
      validPantempt = true
      setValidPan(() => {
        return true;
      });
    }
    if(formData.latitude.textValue>90 ||formData.latitude.textValue<-90 || formData.longitude.textValue>180 || formData.longitude.textValue<-180){
      return
    }
    if (formData.OwnerAadharCardNo.textValue.length !== 12 && formData.OwnerAadharCardNo.textValue.length) {
      validAdharTempt = false
      setValidAdhar(() => {
        return false;
      });
    } else {
      validAdharTempt = true
      setValidAdhar(() => {
        return true;
      });
    }
    console.log(validAdhar, "validAdhar", validPan, "validPan");
    if (!validPantempt || !validAdharTempt || !validMailTempt) {
      return;
    }

    for (let key in formData) {
      if (formData[key].required) {
        if (!formData[key].textValue) {
          validForm = false;
        }

        if (!validForm) {
          return;
        }
      }

      if (formData[key].type === "file" && formData[key].valid == false) {
        return;
      }
    }

    if (formData.isRegister.textValue === "YES") {
      if (
        !formData.RegistrationID.textValue ||
        !formData.RegistrationImage.textValue
      ) {
        return;
      }
    }
    let validContact = formData.contact.textValue.length === 10
    let validContact2 = formData.contact2.textValue.length === 10 || formData.contact2.textValue === ""
    let validContact3 = formData.contact3.textValue.length === 15 || formData.contact3.textValue === ""

    if (!validContact || !validContact2 || !validContact3) {
      return
    }


    const form = new FormData();
    form.append("DestinationID", formData.DestinationName.textValue)    
    form.append("HotelType", formData.hotelType.textValue);
    form.append("HotelName", formData.hotelName.textValue);
    form.append("HotelAddress", formData.address.textValue);
    form.append("HotelLatitude", formData.latitude.textValue);
    form.append("HotelLongitude", formData.longitude.textValue);
    form.append("HotelContactNo1", formData.contact.textValue);
    form.append("HotelContactNo2", formData.contact2.textValue);
    form.append("HotelContactNo3", formData.contact3.textValue);
    form.append("HotelEmailID", formData.emailID.textValue);
    form.append("CheckInTime", formData.checkInTime.textValue);
    form.append("CheckOutTime", formData.checkOutTime.textValue);
    form.append("HotelImage",  formData.hotelImage.textValue ? !(formData.hotelImage.textValue instanceof Blob) ? formData.hotelImage.textValue :  await compressImage(formData.hotelImage.textValue,0.9) : "");
    form.append(
      "IsRegisterWithTourismDepartment",
      formData.isRegister.textValue == "YES" ? "Yes" : "No"
    );
    if (state) {
      form.append("HotelID", state?.HotelID)
    }
    if (formData.isRegister.textValue === "YES") {
      form.append("RegistrationID", formData.RegistrationID.textValue);
      form.append("RegistrationImage",  formData.RegistrationImage.textValue ? !(formData.RegistrationImage.textValue instanceof Blob) ? formData.RegistrationImage.textValue :  await compressImage(formData.RegistrationImage.textValue,0.9) : "");
    }
    form.append("OwnerName", formData.OwnerName.textValue);
    form.append("OwnerIDImage",  formData.OwnerIDImage.textValue ? !(formData.OwnerIDImage.textValue instanceof Blob) ? formData.OwnerIDImage.textValue :  await compressImage(formData.OwnerIDImage.textValue,0.9) : "" );
    form.append("OwnerImage",  formData.OwnerImage.textValue ? !(formData.OwnerImage.textValue instanceof Blob) ? formData.OwnerImage.textValue :  await compressImage(formData.OwnerImage.textValue,0.9) : "");
    form.append("OwnerAadharCardNo", formData.OwnerAadharCardNo.textValue);
    form.append("OwnerPanCard", formData.OwnerPanCard.textValue);
    form.append("OwnerAddress", formData.OwnerAddress.textValue);
    form.append("ManagerName", formData.ManagerName.textValue);
    form.append("ManagerPhoto",  formData.ManagerPhoto.textValue ? !(formData.ManagerPhoto.textValue instanceof Blob) ? formData.ManagerPhoto.textValue :  await compressImage(formData.ManagerPhoto.textValue,0.9) : "");
    form.append("ManagerAddress", formData.ManagerAddress.textValue);
    form.append("FireNOCFile", formData.FireNOCFile.textValue);
    form.append("PCCertificateFile", formData.POC.textValue);
    if (state) {
      if (Cookies.get("ckUserType") == "Hotel") {
        form.append("UpdatedByHotelID", Cookies.get("ckUserID"));
      }else{
        form.append("UpdatedByUserID", Cookies.get("ckUserID"));
        form.append("UpdatedByUserName", Cookies.get("ckUserName"));
      }
     
      // form.append("UpdatedByUserName",Cookies.get("ckUserName"))
    } else {
      if (Cookies.get("ckUserType") == "Hotel") {
        form.append("CreatedByHotelID", Cookies.get("ckUserID"));
      }else{
      form.append("CreatedByUserID", Cookies.get("ckUserID"))
      form.append("CreatedByUserName", Cookies.get("ckUserName"))
      }
    }
    form.append(
      "IsRestaurantAvailable",
      formData.IsRestaurantAvailable.textValue
    );
    if (!isCancle) {
      if (state?.HotelImage) {
        form.append("OldHotelImage", state?.HotelImage)
      }
      if (state?.RegistrationImage) {
        form.append("OldRegistrationImage", state?.RegistrationImage)
      } if (state?.OwnerIDImage) {
        form.append("OldOwnerIDImage", state?.OwnerIDImage)
      } if (state?.OwnerImage) {
        form.append("OldOwnerImage", state?.OwnerImage)
      } if (state?.ManagerPhoto) {
        form.append("OldManagerPhoto", state?.ManagerPhoto)
      } if (state?.FireNOCFile) {
        form.append("OldFireNOCFile", state?.FireNOCFile)
      } if (state?.PoliceClearanceCertificateFile) {
        form.append("OldPCCertificateFile", state?.PoliceClearanceCertificateFile)
      }
    }



    // form.append("Images", formData.images.textValue);
    for (let i = 0; i < formData.images.textValue.length; i++) {
      form.append("Images",  formData.images.textValue[i] ? !(formData.images.textValue[i] instanceof Blob) ? formData.images.textValue[i] :  await compressImage(formData.images.textValue[i],0.9) : "" );
    }
    const config = { headers: { "Content-Type": "multipart/form-data" } }
     axios.post(`${APIURL}/Hotel/SetHotel`, form, config)
      .then((res) => {
        console.log(res, "res")
        if (state && res.data?.status == 1) {
          if (state && Cookies.get("ckUserType") == "Hotel") {
            Cookies.set("IsRestaurantAvailable", formData.IsRestaurantAvailable.textValue)
          }
          Swal.fire({
            title: "Data Updated successfully!",
            icon: "success",
            timer: 1500,
showConfirmButton: false
          });
          navigate("/westKameng/viewHotel")
        }
        else if (res.data?.status == 1) {

          Swal.fire({
            title: "Data Inserted successfully!",
            icon: "success",
            timer: 1500,
showConfirmButton: false
          });
          navigate("/westKameng/viewHotel")
        }
        else if (res.data?.status == 0) {
          Swal.fire({
            title: res.data.message,
            icon: "error",
            timer: 1500,
showConfirmButton: false
          });
        }
      }
      )
      .catch((err)=>{
        console.log(err)
      })
    console.log(formData, "formData");
  };
  const handleCancle = () => {
    setFormData({
      DestinationName: {
        textValue: "",
        required: true,
      },
      hotelType: {
        textValue: "",
        required: true,
      },
      hotelName: {
        textValue: "",
        required: true,
      },
      checkInTime: {
        textValue: "",
        required: true,
      },
      checkOutTime: {
        textValue: "",
        required: true,
      },
      address: {
        textValue: "",
        required: true,
      },
      latitude: {
        textValue: "",
        required: false,
      },
      longitude: {
        textValue: "",
        required: false,
      },
      contact: {
        textValue: "",
        required: true,
      },
      contact2: {
        textValue: "",
        required: false,
      },
      contact3: {
        textValue: "",
        required: false,
      },
      emailID: {
        textValue: "",
        required: false,
      },
      hotelImage: {
        textValue: "",
        required: true,
        valid: true,
        type: "file",
      },
      isRegister: {
        textValue: "NO",
        required: false,
      },
      RegistrationID: {
        textValue: "",
        required: false,
      },
      RegistrationImage: {
        textValue: "",
        required: false,
        valid: true,
        type: "file",
      },
      OwnerName: {
        textValue: "",
        required: true,
      },
      OwnerIDImage: {
        textValue: "",
        required: false,
        valid: true,
        type: "file",
      },
      OwnerImage: {
        textValue: "",
        required: false,
        valid: true,
        type: "file",
      },
      OwnerAadharCardNo: {
        textValue: "",
        required: true,
      },
      OwnerPanCard: {
        textValue: "",
        required: true,
      },
      OwnerAddress: {
        textValue: "",
        required: false,
      },
      ManagerName: {
        textValue: "",
        required: false,
      },
      ManagerPhoto: {
        textValue: "",
        required: false,
        valid: true,
        type: "file",
      },
      ManagerAddress: {
        textValue: "",
        required: false,
      },
      FireNOCFile: {
        textValue: "",
        required: false,
        valid: true,
        type: "file",
      },
      POC: {
        textValue: "",
        required: false,
        valid: true,
        type: "file",
      },
      IsRestaurantAvailable: {
        textValue: "NO",
        required: false,
      },
      images: {
        textValue: "",
        required: false,
        valid: true,
        type: "file",
        count: state?.ImageDetail?.length || 0
      },
    });
    setIsCancle(true)
    handleClearButtonClick()
  }
  return (
    <div className="body-wrapper " style={{ backgroundColor: "#d3d3d3", minHeight: "100vh" }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card mt-3 " style={{ boxShadow: "2px 3px 15px" }}>
              <div className="px-3  pt-4 border-bottom">
                <h5>Stay Details</h5>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">

                    <div className="col-md-3 mb-2">
                      <div className="form-group mb-3">
                        <label htmlFor="DestinationName" className="form-label fw-semibold">
                          Destination Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <select
                          onChange={handleInputChange}
                          name="DestinationName"
                          value={formData.DestinationName.textValue}
                          className="form-control"
                          style={{
                            border: `1px solid ${(isSubmit && !formData.DestinationName.textValue) ? 'red' : isSubmit ? 'green' : 'light-gray'}`,
                          }}
                        >
                          <option value="">--Select Destination--</option>
                          {destinationOptions.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {isSubmit && !formData.DestinationName.textValue && (
                          <span className="text-danger">*</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-3 mb-2">
                      <div className="form-group mb-3">
                        <label
                          htmlFor="hotelType"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          Stay Type <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          onChange={handleInputChange}
                          name="hotelType"
                          value={formData.hotelType.textValue}
                          className="form-control"
                          style={{ border: `1px solid ${(isSubmit && !formData.hotelType.textValue) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}
                        >
                          <option value={""} selected="selected">
                            --Select Stay Type--
                          </option>
                          <option value="Hotel">Stay</option>
                          <option value="Resort">Resort</option>
                          <option value="HomeStay">HomeStay</option>
                          <option value="FarmStay">FarmStay</option>
                        </select>
                        {isSubmit && !formData.hotelType.textValue && (
                          <span className="text-danger">*</span>
                        )}
                        {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                      </div>
                    </div>
                    <div className="col-md-3 mb-2 ">
                      <div className="form-group">
                        <label
                          htmlFor="hotelName"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          onChange={handleInputChange}
                          name="hotelName"
                          value={formData.hotelName.textValue}
                          type="text"
                          className="form-control"
                          placeholder="Enter Name"
                          style={{ border: `1px solid ${(isSubmit && !formData.hotelName.textValue) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                        ></input>
                        {isSubmit && !formData.hotelName.textValue && (
                          <span className="text-danger">*</span>
                        )}
                        {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                      </div>
                    </div>
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        <label
                          htmlFor="contact"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          Contact Number <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          onChange={handleInputChange}
                          name="contact"
                          value={formData.contact.textValue}
                          maxLength={10}
                          onKeyDown={handleKeyDownNumber}
                          onPaste={handlePaste}
                          type="text"
                          className="form-control"
                          placeholder="Enter Contact Number"
                          style={{ border: `1px solid ${(isSubmit && (!formData.contact.textValue || formData.contact.textValue.length !== 10)) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                        ></input>
                        {isSubmit && !formData.contact.textValue && (
                          <span className="text-danger">*</span>
                        )}
                        {isSubmit && formData.contact.textValue && formData.contact.textValue.length !== 10 && <span className='text-danger'>Please Enter Valid Contact No</span>}
                      </div>
                    </div>
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        <label
                          htmlFor="contact2"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          Contact Number 2{" "}
                        </label>
                        <input
                          onChange={handleInputChange}
                          name="contact2"
                          value={formData.contact2.textValue}
                          maxLength={10}
                          onKeyDown={handleKeyDownNumber}
                          onPaste={handlePaste}
                          type="text"
                          className="form-control"
                          placeholder="Enter Contact Number"
                          style={{ border: `1px solid ${(isSubmit && (formData.contact2.textValue && formData.contact2.textValue.length !== 10)) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                        ></input>
                        {isSubmit && formData.contact2.textValue && formData.contact2.textValue.length !== 10 && <span className='text-danger'>Please Enter Valid Contact No</span>}

                        {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                      </div>
                    </div>
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        <label
                          htmlFor="contact3"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          Landline Number{" "}
                        </label>
                        <input
                          onChange={handleInputChange}
                          name="contact3"
                          value={formData.contact3.textValue}
                          maxLength={15}
                          onKeyDown={handleKeyDownNumber}
                          onPaste={handlePaste}
                          type="text"
                          className="form-control"
                          placeholder="Enter Contact Number"
                          style={{ border: `1px solid ${(isSubmit && (formData.contact3.textValue && formData.contact3.textValue.length !== 15)) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                        ></input>
                        {isSubmit && formData.contact3.textValue && formData.contact3.textValue.length !== 15 && <span className='text-danger'>Please Enter Valid Contact No</span>}

                        {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                      </div>
                    </div>
                    <div className="col-md-3 mb-2 ">
                      <div className="form-group mb-3">
                        <label className="form-label fw-semibold">
                          Check In Time <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          onChange={handleInputChange}
                          type="time"
                          name="checkInTime"
                          value={formData.checkInTime.textValue}
                          className="form-control"
                          placeholder="Select Time"
                          style={{ border: `1px solid ${(isSubmit && !formData.checkInTime.textValue) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                        />
                        {isSubmit && !formData.checkInTime.textValue && (
                          <span className="text-danger">*</span>
                        )}
                        {/* {hasSubmit && !formData.startTime.textValue && <span className='text-danger'>*</span>} */}
                      </div>
                    </div>
                    <div className="col-md-3 mb-2 ">
                      <div className="form-group">
                        <label className="form-label fw-semibold">
                          Check Out Time <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          onChange={handleInputChange}
                          type="time"
                          name="checkOutTime"
                          value={formData.checkOutTime.textValue}
                          className="form-control"
                          placeholder="Select Time"
                          style={{ border: `1px solid ${(isSubmit && !formData.checkOutTime.textValue) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                        />
                        {isSubmit && !formData.checkOutTime.textValue && (
                          <span className="text-danger">*</span>
                        )}
                        {/* {hasSubmit && !formData.endTime.textValue && <span className='text-danger'>*</span>} */}
                      </div>
                    </div>
              
                    <div className="col-md-3 mb-4  ">
                      <div className="form-group mb-3">
                        <label className="form-label fw-semibold">
                          {" "}
                          Detail Images {state && <>{" ("}<span style={{ color: "gray" }}>{formData.images.count} Images Selected </span>{") "}</>}
                        </label>
                        <input
                          name="images"
                          type="file"
                          ref={imagesRef}
                          onChange={handleFileChange}
                          accept="image/jpeg, image/png, image/jpg"
                          multiple
                          className="form-control"
                          style={{ border: `1px solid ${(isSubmit && !formData.images.valid) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}
                        />


                        {!formData.images.valid && (
                          <span className="text-danger">
                            *please upload valid image{" "}
                          </span>
                        )}
                        {/* {hasSubmit && (!formData.image.textValue || !formData.image.textValue.length  )  && <span className='text-danger'>*</span>} */}
                      </div>
                      <div className="form-group mb-3">
                        <label
                          htmlFor="emailID"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          EmailID<span style={{ color: "red" }}>*</span><span style={{ color: "gray" }}> (Ex. xyz@gmail.com)</span>
                        </label>
                        <input
                          onChange={handleInputChange}
                          name="emailID"
                          value={formData.emailID.textValue}
                          type="text"
                          className="form-control"
                          placeholder="Enter EmailID"
                          style={{ border: `1px solid ${(isSubmit && (!formData.emailID.textValue || !validMail)) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                        ></input>
                        {isSubmit && !formData.emailID.textValue && (
                          <span className="text-danger">*</span>
                        )}
                        {isSubmit && !validMail && formData.emailID.textValue && <span className='text-danger'>Please Enter valid Email ID</span>}
                      </div>

                    </div>
                    <div className="col-md-3 mb-4">
                    <div className="form-group mb-3 ">
                        <label className="form-label fw-semibold">
                          {" "}
                          Latitude
                        </label>
                        <input
                        onKeyPress={(e)=>{if(e.key=="e"){
                          e.preventDefault()
                        }}}
                          onChange={handleInputChange}
                          name="latitude"
                          value={formData.latitude.textValue}
                          type="number"
                          className="form-control"
                          placeholder="Enter Latitude"
                          style={{border:`1px solid ${(isSubmit && (formData.latitude.textValue>90 || formData.latitude.textValue<-90)) ? "red" : isSubmit ? "green" : "light-gray" }  `}}

                        ></input>
                   
                      {isSubmit && (formData.latitude.textValue>90 || formData.latitude.textValue<-90) && <span className='text-danger'>Lattitude must be in range of -90 to 90</span>}
                      </div> 
                      <div className="form-group ">
                        <label className="form-label fw-semibold">
                          {" "}
                          Longitude
                        </label>
                        <input
                        onKeyPress={(e)=>{if(e.key=="e"){
                          e.preventDefault()
                        }}}
                          onChange={handleInputChange}
                          name="longitude"
                          value={formData.longitude.textValue}
                          type="number"
                          className="form-control"
                          placeholder="Enter longitude"
                          style={{border:`1px solid ${(isSubmit && (formData.longitude.textValue>180 || formData.longitude.textValue<-180)) ? "red" : isSubmit ? "green" : "light-gray" }  `}}

                        ></input>
                   
                      {isSubmit && (formData.longitude.textValue>180 || formData.longitude.textValue<-180) && <span className='text-danger'>Longitude must be in range of -180 to 180</span>}
                      </div> 
                    </div>
             
                    <div className="col-md-3 mb-4 ">
                      <div className="form-group">
                        <label className="form-label fw-semibold">
                          Address <span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                          onChange={handleInputChange}
                          name="address"
                          value={formData.address.textValue}
                          style={{ height: "125px", border: `1px solid ${(isSubmit && !formData.address.textValue) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}
                          rows="2"
                          cols="20"
                          placeholder="Enter Address"
                          className="form-control"


                        />
                        {isSubmit && !formData.address.textValue && (
                          <span className="text-danger">*</span>
                        )}
                        {/* {hasSubmit && !formData.address.textValue && <span className='text-danger'>*</span>} */}
                      </div>
                    </div>
                    <div className="col-md-3 mb-2  ">
                      <label className="form-label fw-semibold">
                        Stay Front Image{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        name="hotelImage"
                        type="file"
                        ref={hotelImageRef}
                        onChange={handleFileChange}
                        accept="image/jpeg, image/png, image/jpg"
                        className="form-control"
                        style={{ border: `1px solid ${(isSubmit && (!formData.hotelImage.textValue || !formData.hotelImage.valid)) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                      />
                      {isSubmit && !formData.hotelImage.textValue && (
                        <span className="text-danger">*</span>
                      )}
                      {!formData.hotelImage.valid && (
                        <span className="text-danger">
                          *please upload valid image{" "}
                        </span>
                      )}
                      {!isCancle && state?.HotelImage && <span >{state?.HotelImage}</span>}

                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card" style={{ boxShadow: "2px 3px 15px" }}>
              <div className="px-3 pt-4 border-bottom">
                <h5>Owner Details</h5>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3 mb-4">
                      <div className="form-group">
                        <label
                          htmlFor="OwnerName"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          Owner Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          onChange={handleInputChange}
                          name="OwnerName"
                          value={formData.OwnerName.textValue}
                          type="text"
                          className="form-control"
                          placeholder="Enter Owner Name"
                          style={{ border: `1px solid ${(isSubmit && !formData.OwnerName.textValue) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                        ></input>
                        {isSubmit && !formData.OwnerName.textValue && (
                          <span className="text-danger">*</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3 mb-4  ">
                      <label className="form-label fw-semibold">
                        Owner Image
                      </label>
                      <input
                        name="OwnerImage"
                        type="file"
                        ref={OwnerImageRef}
                        onChange={handleFileChange}
                        accept="image/jpeg, image/png, image/jpg"
                        className="form-control"
                        style={{ border: `1px solid ${(isSubmit && !formData.OwnerImage.valid) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                      />
                      {!formData.OwnerImage.valid && (
                        <span className="text-danger">
                          *please upload valid image{" "}
                        </span>
                      )}
                      {!isCancle && state?.OwnerImage && <span >{state?.OwnerImage}</span>}

                    </div>
                    <div className="col-md-3 mb-4">
                      <div className="form-group">
                        <label
                          htmlFor="OwnerAadharCardNo"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          Owner Aadhar Card Number{" "}
                          <span style={{ color: "gray" }}> (Ex. 648375633762)</span>
                        </label>
                        <input
                          onChange={handleInputChange}
                          maxLength={12}
                          name="OwnerAadharCardNo"
                          value={formData.OwnerAadharCardNo.textValue}
                          type="text"
                          className="form-control"
                          placeholder="Enter Owner Aadhar Card No"
                          style={{ border: `1px solid ${(isSubmit && (!validAdhar)) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                        ></input>

                        {isSubmit && !validAdhar && formData.OwnerAadharCardNo.textValue && (
                          <span className="text-danger">
                            Please Enter Valid Adhar Number{" "}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3 mb-4">
                      <div className="form-group">
                        <label
                          htmlFor="OwnerPanCard"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          Owner Pan Card <span style={{ color: "gray" }}> (Ex. FJGKP7626B)</span>
                        </label>
                        <input
                          onChange={handleInputChange}
                          name="OwnerPanCard"
                          maxLength={12}
                          value={formData.OwnerPanCard.textValue}
                          type="text"
                          className="form-control"
                          placeholder="Enter OwnerPanCard"
                          style={{ border: `1px solid ${(isSubmit && (!validPan)) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}
                        ></input>

                        {isSubmit && !validPan && formData.OwnerPanCard.textValue && (
                          <span className="text-danger">
                            Please Enter Valid PAN Number{" "}
                          </span>
                        )}

                        {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                      </div>
                    </div>
                    <div className="col-md-3 mb-4  ">
                      <label className="form-label fw-semibold">
                        Owner ID Image 
                      </label>
                      <input
                        name="OwnerIDImage"
                        type="file"
                        ref={OwnerIDImageRef}
                        onChange={handleFileChange}
                        accept="image/jpeg, image/png, image/jpg"
                        className="form-control"
                        style={{ border: `1px solid ${(isSubmit &&  !formData.OwnerIDImage.valid) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                      />

                      {!formData.OwnerIDImage.valid && (
                        <span className="text-danger">
                          *please upload valid image{" "}
                        </span>
                      )}
                      {!isCancle && state?.OwnerIDImage && <span >{state?.OwnerIDImage}</span>}

                    </div>
                    <div className="col-md-3 mb-4">
                      <div className="form-group">
                        <label
                          htmlFor="OwnerAddress"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          Owner Address
                        </label>
                        <textarea
                          onChange={handleInputChange}
                          name="OwnerAddress"
                          value={formData.OwnerAddress.textValue}

                          rows="2"
                          cols="20"
                          placeholder="Enter Owner Address"
                          className="form-control"
                          style={{ height: "125px", border: `1px solid ${isSubmit ? "green" : "light-gray"}  ` }}

                        />
                        {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card" style={{ boxShadow: "2px 3px 15px" }}>
              <div className="px-3 pt-4 border-bottom">
                <h5>Manager Details</h5>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3 mb-4">
                      <div className="form-group">
                        <label
                          htmlFor="ManagerName"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          Manager Name 
                        </label>
                        <input
                          onChange={handleInputChange}
                          name="ManagerName"
                          value={formData.ManagerName.textValue}
                          type="text"
                          className="form-control"
                          placeholder="Enter Manager Name"
                          style={{ border: `1px solid ${ isSubmit ? "green" : "light-gray"}  ` }}
                        ></input>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4  ">
                      <label className="form-label fw-semibold">
                        Manager Photo
                      </label>
                      <input
                        name="ManagerPhoto"
                        type="file"
                        ref={ManagerPhotoRef}
                        onChange={handleFileChange}
                        accept="image/jpeg, image/png, image/jpg"
                        className="form-control"
                        style={{ border: `1px solid ${(isSubmit && !formData.ManagerPhoto.valid) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                      />

                      {!formData.ManagerPhoto.valid && (
                        <span className="text-danger">
                          *please upload valid image{" "}
                        </span>
                      )}
                      {!isCancle && state?.ManagerPhoto && <span >{state?.ManagerPhoto}</span>}

                    </div>
                    <div className="col-md-3 mb-4">
                      <div className="form-group">
                        <label
                          htmlFor="ManagerAddress"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          Manager Address
                        </label>
                        <textarea
                          onChange={handleInputChange}
                          name="ManagerAddress"
                          value={formData.ManagerAddress.textValue}
                          rows="2"
                          cols="20"
                          placeholder="Enter Manager Address"
                          className="form-control"
                          style={{ height: "125px", border: `1px solid ${isSubmit ? "green" : "light-gray"}  ` }}

                        />

                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card" style={{ boxShadow: "2px 3px 15px" }}>
              <div className="px-3 pt-4 border-bottom">
                <h5>Hotel Certificates And Registration Details</h5>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3 mb-4">
                      <div className="form-group">
                        <label className="form-label fw-semibold">
                          Is Register With Tourism Department
                        </label>
                        <div className="form-control">
                          <div className="d-flex align-items-center gap-2">
                            <label className="d-flex align-items-center gap-2">
                              <input
                                onChange={handleInputChange}
                                type="radio"
                                name="isRegister"
                                value={"YES"}
                                checked={formData.isRegister.textValue == "YES"}
                              />
                              <span>YES </span>
                            </label>
                            <label className="d-flex align-items-center gap-2">
                              <input
                                onChange={handleInputChange}
                                value={"NO"}
                                checked={formData.isRegister.textValue == "NO"}
                                type="radio"
                                name="isRegister"
                              />
                              <span> NO</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 mb-4">
                      <div className="form-group">
                        <label
                          htmlFor="RegistrationID"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          Registration ID
                        </label>
                        <input
                          onChange={handleInputChange}
                          name="RegistrationID"
                          value={formData.RegistrationID.textValue}
                          type="text"
                          className="form-control"
                          placeholder="Enter RegistrationID"
                          style={{ border: `1px solid ${(isSubmit && formData.isRegister.textValue == "YES" && !formData.RegistrationID.textValue) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                        ></input>
                        {isSubmit &&
                          formData.isRegister.textValue == "YES" &&
                          !formData.RegistrationID.textValue && (
                            <span className="text-danger">*</span>
                          )}
                        {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                      </div>
                    </div>
                    <div className="col-md-3 mb-4  ">
                      <label className="form-label fw-semibold">
                        Registration Image
                      </label>
                      <input
                        name="RegistrationImage"
                        type="file"
                        ref={RegistrationImageRef}
                        onChange={handleFileChange}
                        accept="image/jpeg, image/png, image/jpg"
                        className="form-control"
                        style={{ border: `1px solid ${(isSubmit && ((formData.isRegister.textValue == "YES" && !formData.RegistrationImage.textValue) || !formData.RegistrationImage.valid)) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                      />
                      {isSubmit &&
                        formData.isRegister.textValue == "YES" &&
                        !formData.RegistrationImage.textValue && (
                          <span className="text-danger">*</span>
                        )}
                      {!formData.RegistrationImage.valid && (
                        <span className="text-danger">
                          *please upload valid image{" "}
                        </span>
                      )}
                      {!isCancle && state?.RegistrationImage && <span >{state?.RegistrationImage}</span>}

                    </div>

                  </div>
                  <div className="row">
                    <div className="col-md-3 mb-4">
                      <div className="form-group">
                        <label className="form-label fw-semibold">
                          Is Restaurant Available
                        </label>
                        <div className="form-control">
                          <div className="d-flex align-items-center gap-2">
                            <label className="d-flex align-items-center gap-2">
                              <input
                                onChange={handleInputChange}
                                type="radio"
                                name="IsRestaurantAvailable"
                                value={"YES"}
                                checked={
                                  formData.IsRestaurantAvailable.textValue ==
                                  "YES"
                                }
                              />
                              <span>YES </span>
                            </label>
                            <label className="d-flex align-items-center gap-2">
                              <input
                                onChange={handleInputChange}
                                type="radio"
                                name="IsRestaurantAvailable"
                                value={"NO"}
                                checked={
                                  formData.IsRestaurantAvailable.textValue ==
                                  "NO"
                                }
                              />
                              <span> NO</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4  ">
                      <label className="form-label fw-semibold">
                        Police Clearance Certificate
                      </label>
                      <input
                        name="POC"
                        type="file"
                        ref={POCRef}
                        onChange={handleFileChange}
                        accept=".pdf,.PDF"
                        className="form-control"
                        style={{ border: `1px solid ${(isSubmit && !formData.POC.valid) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                      />
                      {!formData.POC.valid && (
                        <span className="text-danger">
                          *please upload valid PDF File{" "}
                        </span>
                      )}
                      {!isCancle && state?.PoliceClearanceCertificateFile && <span >{state?.PoliceClearanceCertificateFile}</span>}

                    </div>
                    <div className="col-md-3 mb-4  ">
                      <label className="form-label fw-semibold">
                        Fire Clearance Certificate
                      </label>
                      <input
                        name="FireNOCFile"
                        type="file"
                        ref={FireNOCFileRef}
                        onChange={handleFileChange}
                        accept=".pdf,.PDF"
                        className="form-control"
                        style={{ border: `1px solid ${(isSubmit && !formData.FireNOCFile.valid) ? "red" : isSubmit ? "green" : "light-gray"}  ` }}

                      />

                      {!formData.FireNOCFile.valid && (
                        <span className="text-danger">
                          *please upload valid PDF File{" "}
                        </span>
                      )}
                      {!isCancle && state?.FireNOCFile && <span >{state?.FireNOCFile}</span>}

                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 mt-4">
                    <button
                      type="submit"
                      className="btn btn-outline-primary font-weight-bolder ml-2 "
                    >
                      Submit
                    </button>
                    <button
                      onClick={handleCancle}
                      type="button"
                      className="btn btn-outline-danger font-weight-bolder ml-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => { navigate("/westKameng/viewHotel") }}
                      type="button"
                      className="btn btn-outline-primary font-weight-bolder ml-2"
                    >
                      View
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelDetails;

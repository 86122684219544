// export const APIURL="http://192.168.0.103:2604/api"
// export const IMAGEMODELURL="http://192.168.0.103:2604"

// export const APIURL="http://api.westkameng.com/api/"
// export const IMAGEMODELURL="http://api.westkameng.com"

export const APIURL="https://westkameng.com/WestKamengAPI/api/"
export const IMAGEMODELURL="https://westkameng.com/WestKamengAPI/"
export const SHOWPASSWORDPIN="6713"



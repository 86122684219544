import { Button, Col, Form, Row, Table } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { APIURL } from "../../Process";
import { useEffect, useState } from "react";
import { Rating } from "primereact/rating";
import Delete from "../../Assets/Delete.png";
import { RotatingLines } from "react-loader-spinner";
import Select from "react-select";
import XLSX from "xlsx-color";

import Swal from "sweetalert2";
import moment from "moment";
import Cookies from "js-cookie";

const ViewReview = () => {
  ;
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);

  const [pageCount, setPageCount] = useState("10");
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [totalCount, setTotalCount] = useState()
  const [pageId, setPageId] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [hotelData, setHotelData] = useState([]);
  const [restaurantData, setRestaurantData] = useState([]);
  const [placeData, setPlaceData] = useState([]);
  const [packageData, setPackagData] = useState([]);

  const [cancle, setCancle] = useState(false);

  const [filterData, setFilterData] = useState({
    SelectType: "",
    PropName: "",
    hotelType: Cookies.get("ckUserType") == "Hotel" ? Cookies.get("ckUserID") : "",
    hotelTempt: "",
    restaurantType: Cookies.get("ckUserType") == "Restaurant" ? Cookies.get("ckUserID") : "",
    restaurantTempt: "",
    placeType: "",
    placeTempt: "",
    PackageTitle: "",
    packageTempt: "",
    StartDate: "",
    EndDate: "",
  });

  useEffect(() => {
    getReviewData(pageCount, filterData);
    getHotelDetails();
    getRestaurantDetails();
    getPlaceDetails();
    getPackageDetails();

  }, []);
  const setNextApi = async () => {
    console.log(pageId, "pageID", pageCount);
    if (pageId * pageCount < allTableData.length) {
      const tempt = [...allTableData];
      console.log(tempt, pageId * pageCount, (pageId + 1) * pageCount);
      const sliced = tempt.slice(pageId * pageCount, (pageId + 1) * pageCount);
      setTableData(sliced);
      setPageId(pageId + 1);
      return;
    }
    const paginationIndex = allTableData[pageId * pageCount - 1]?.obj.ReviewID;
    if (!paginationIndex) {
      return;
    }
    console.log(paginationIndex, "paginationIndex");
    try {
      const res = await fetch(`${APIURL}/Review/GetReview`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ReviewType: filterData.SelectType,
          HotelID: filterData.hotelType,
          RestaurantID: filterData.restaurantType,
          PlaceID: filterData.placeType,
          PackageID: filterData.PackageTitle,
          StartDate: filterData.StartDate?.split("-").reverse().join("-") || "",
          EndDate: filterData.EndDate?.split("-").reverse().join("-") || "",
          PaginationID: `${paginationIndex}`,
          PageLength: `${pageCount}`,
        }),
      });
      const result = await res.json();
      const tempt = [];
      console.log(tempt, "search");
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      console.log(tempt, "tempt");
      setTableData(tempt);
      setPageId(pageId + 1);
      setAllTableData([...allTableData, ...tempt]);
    } catch (err) {
      console.log(err)
    }
  };
  const setPreviousApi = () => {
    if (pageId == 1) {
      return;
    }
    const tempt = [...allTableData];
    console.log(pageId, "pageId");
    console.log(
      pageId,
      tempt,
      (pageId - 1) * pageCount,
      pageId * pageCount + 1
    );
    const sliced = tempt.slice(
      (pageId - 2) * pageCount,
      (pageId - 1) * pageCount
    );
    setTableData(sliced);
    setPageId(pageId - 1);
  };
  const getHotelDetails = async () => {
    try {
      const res = await fetch(`${APIURL}/Hotel/GetHotel`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PaginationID: "-1",
          PageLength: "",
          UsedFor: "Panel",
        }),
      });
      const result = await res.json();
      console.log(result, "result");

      setHotelData(
        result.data.map((item) => ({
          value: item.HotelID,
          label: item.HotelName,
        }))
      );
    } catch (err) {
      console.log(err)
    }
  };
  const getRestaurantDetails = async () => {
    try {
      const res = await fetch(`${APIURL}/Restaurant/GetRestaurant`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PaginationID: "-1",
          PageLength: "",
          UsedFor: "Panel",
        }),
      });
      const result = await res.json();
      console.log(result, "result");

      setRestaurantData(
        result.data.map((item) => ({
          value: item.RestaurantID,
          label: item.RestaurantName,
        }))
      );
    } catch (err) {
      console.log(err)
    }
  };
  const getPlaceDetails = async () => {
    try {
      const res = await fetch(`${APIURL}/Place/GetPlace`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PaginationID: "-1",
          PageLength: "",
          UsedFor: "Panel",
        }),
      });
      const result = await res.json();
      console.log(result, "result");

      setPlaceData(
        result.data.map((item) => ({
          value: item.PlaceID,
          label: item.PlaceName,
        }))
      );
    } catch (err) {
      console.log(err)
    }
  };
  const getPackageDetails = async () => {
    try {
      const res = await fetch(`${APIURL}/Package/getPackageDropDown`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PackageID: "",
          PackageTitle: "",
          IsActive: "true",
          IsDelete: "false"
          // PaginationID: "-1",
          // PageLength: "",
          // UsedFor: "Panel",
        }),
      });
      const result = await res.json();
      console.log(result, "result");

      setPackagData(
        result.data.map((item) => ({
          value: item.PackageID,
          label: item.PackageTitle,
        }))
      );
    } catch (err) {
      console.log(err)
    }
  };
  const getReviewData = async (count) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${APIURL}/Review/GetReview`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ReviewType: Cookies.get("ckUserType") == "Admin" ? "" : Cookies.get("ckUserType") == "Hotel" ? "Hotel" : Cookies.get("ckUserType") == "Restaurant" ? "Restaurant" : "",
          HotelID: Cookies.get("ckUserType") == "Hotel" ? Cookies.get("ckUserID") : "",
          RestaurantID: Cookies.get("ckUserType") == "Restaurant" ? Cookies.get("ckUserID") : "",
          PaginationID: "0",
          PageLength: count,
        }),
      });
      const result = await res.json();
      setTotalCount(result.total[0].TotalCount)
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      console.log(tempt, "tempt");
      setIsLoading(false);
      setIsLoading(false);
      setTableData(tempt);
      setPageId(1);
      setAllTableData(tempt);
    } catch (err) {
      setIsLoading(false);
      console.log(err)
    }
  };
  console.log("yesh");
  const handlePageCount = (e) => {
    setPageCount(e.target.value);
    searchHandle(e.target.value, 0);
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const selectDropDownData = (e, action) => {
    console.log(e.value, "e", action.name);
    if (action.name == "hotelType") {
      setFilterData({
        ...filterData,
        [action.name]: e.value,
        hotelTempt: e,
      });
    } else if (action.name == "restaurantType") {
      setFilterData({
        ...filterData,
        [action.name]: e.value,
        restaurantTempt: e,
      });
    } else if (action.name == "placeType") {
      setFilterData({
        ...filterData,
        [action.name]: e.value,
        placeTempt: e,
      });
    } else if (action.name == "PackageTitle") {
      setFilterData({
        ...filterData,
        [action.name]: e.value,
        packageTempt: e,
      });
    }
  };
  const selectFilterData = (e) => {
    if (e.target.name === "SelectType") {
      if (e.target.value === "Hotel") {
        setFilterData({
          ...filterData,
          [e.target.name]: e.target.value,
          restaurantType: "",
          restaurantTempt: "",
          placeType: "",
          placeTempt: "",
        });
      } else if (e.target.value === "Restaurant") {
        setFilterData({
          ...filterData,
          [e.target.name]: e.target.value,
          hotelType: "",
          hotelTempt: "",
          placeType: "",
          placeTempt: "",
        });
      } else if (e.target.value == "Place") {
        setFilterData({
          ...filterData,
          [e.target.name]: e.target.value,
          restaurantType: "",
          restaurantTempt: "",
          hotelType: "",
          hotelTempt: "",
        });
      } else if (e.target.value == "Package") {
        setFilterData({
          ...filterData,
          [e.target.name]: e.target.value,
          restaurantType: "",
          restaurantTempt: "",
          hotelType: "",
          hotelTempt: "",
          placeType: "",
          placeTempt: "",
          PackageTitle: "",
          packageTempt: "",
        });
      }
    } else {
      setFilterData({
        ...filterData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const searchHandle = async (count, paginationIndex) => {

    setIsSubmit(true)
    if (filterData.StartDate > filterData.EndDate || (filterData.EndDate && !filterData.StartDate)) {
      return;
    }
    console.log("searchHandle", count);
    console.log(filterData.hotelName, "filterDataaaaaaaaa", count);
    try {
      const res = await fetch(`${APIURL}/Review/GetReview`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ReviewType: filterData.SelectType,
          HotelID: filterData.hotelType,
          RestaurantID: filterData.restaurantType,
          PlaceID: filterData.placeType,
          PackageID: filterData.PackageTitle,
          StartDate: filterData.StartDate?.split("-").reverse().join("-") || "",
          EndDate: filterData.EndDate?.split("-").reverse().join("-") || "",
          PaginationID: `${paginationIndex}`,
          PageLength: count,
        }),
      });

      const result = await res.json();
      setTotalCount(result.total[0].TotalCount)
      const tempt = [];
      console.log(tempt, "search");
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });

      setTableData(tempt);
      setAllTableData(tempt);
      setPageId(1);
    } catch (err) {
      console.log(err)
    }
  };
  const handleCancle = () => {
    setCancle(true);
    setIsSubmit(false)
    setFilterData({
      SelectType: "",
      PropName: "",
      hotelType: Cookies.get("ckUserType") == "Hotel" ? Cookies.get("ckUserID") : "",
      hotelTempt: "",
      restaurantType: Cookies.get("ckUserType") == "Restaurant" ? Cookies.get("ckUserID") : "",
      restaurantTempt: "",
      placeType: "",
      placeTempt: "",
      PackageTitle: "",
      packageTempt: "",
      StartDate: "",
      EndDate: "",
    });

    getReviewData(pageCount);
  };
  async function fetchExcelData() {
    if (((filterData.EndDate < filterData.StartDate) || (filterData.EndDate && !filterData.StartDate))) {
      return
    }
    setIsLoading(true)
    console.log(filterData.hotelName, "filterData.hotelName")
    try {


      const res = await fetch(`${APIURL}/Review/GetReview`, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ReviewType: filterData.SelectType,
          HotelID: filterData.hotelType,
          RestaurantID: filterData.restaurantType,
          PlaceID: filterData.placeType,
          PackageID: filterData.PackageTitle,
          StartDate: filterData.StartDate?.split("-").reverse().join("-") || "",
          EndDate: filterData.EndDate?.split("-").reverse().join("-") || "",
          PaginationID: `-1`,
          PageLength: "",
        })
      })
      const result = await res.json()
      handleDownloadExcel(result.data)
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }
  const handleDownloadExcel = (array) => {
    console.log(array, "array")
    const header = ["User Name", "Review Type", "Name","Review", "Rating"];
    const body = [];
    array?.forEach((element, index) => {
      body.push([element.Name, element.ReviewType, element.HotelName ||
        element.RestaurantName ||
        element.PlaceName || element.PackageTitle, element.Review, element.Rating,])
    })
    if (!body.length) {
      setIsLoading(false)
      return Swal.fire({
        title: "No Data Found!",
        icon: "error",
        timer: 1500,
showConfirmButton: false
      }
      );
    }

    const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);
    header.forEach((col, index) => {
      ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
        font: { bold: true, size: 14, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
      };
    });



    ws1['!cols'] = [
      { width: 20 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 20 },      
      { width: 40 },


    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, 'Review Details');


    setIsLoading(false)
    XLSX.writeFile(wb, `Review${moment().format('DD-MM-YYYY_HH:mm')}.xlsx`);
  }
  const confirmReviewDelete = async (id) => {
    try {
      const res = await fetch(`${APIURL}/Review/RemoveReview`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ReviewID: id,
        }),
      });

      const result = await res.json();
      if (result.status == 1) {
        Swal.fire({
          title: "Successfully Deleted!",
          icon: "success",
          timer: 1500,
showConfirmButton: false,
        });
        searchHandle(pageCount, 0);
      }
      console.log(result);
    } catch (err) {
      console.log(err)
    }
  }
  const handleDeletePlaceDetails = async (id) => {
    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red"

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmReviewDelete(id)
      }
    });
  };
  if (isLoading) {
    return <div
      style={{
        position: "absolute",
        zIndex: "6",
        left: "600px",
        top: "300px",
      }}
    >
      <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width="200"
        height="200"
        visible={true}
      />
    </div>
  }
  return (
    <div className="body-wrapper">
      <div className="container-fluid">
        {isLoading && (
          <div
            style={{
              position: "absolute",
              zIndex: "6",
              left: "600px",
              top: "300px",
            }}
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="200"
              height="200"
              visible={true}
            />
          </div>
        )}
        <div className={`mt-2 mb-4  place-filter  ${isOpen ? "open" : "closed"}`}>
          <Row>
            <Col md={2} sm={2} className="col-4">
              <div className="d-flex gap-2 pt-1">
                <span>Total Count</span>
                <span>{totalCount}</span>
              </div>
            </Col>
            <Col md={2} sm={2} className="col-4">
              <Form.Select
                onChange={handlePageCount}
                size="sm"
                className="w-70"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </Form.Select>
            </Col>

            <Col md={{ span: 2, offset: 6 }} className="col-4">
              <Button
                className="float-end"
                md={2}
                variant="outline-primary"
                size="sm"
                onClick={handleToggle}
              >
                {isOpen ? "Close Filter" : "Open Filter"}
              </Button>
            </Col>
          </Row>
          {
            <>
              {" "}
              {isOpen && (
                <div
                  className="filter-content row"
                  style={{
                    marginTop: "20px",
                    padding: "20px",
                    border: "1px solid gray",
                  }}
                >
                  <Form className="d-flex pb-2 row">
                    {Cookies.get("ckUserType") === "Admin" && <div className="form-group mb-2 col-md-3">
                      <label
                        htmlFor="SelectType"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        Select Type <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        onChange={selectFilterData}
                        name="SelectType"
                        value={filterData.SelectType}
                        className="form-control"
                      //   style={{border:`1px solid ${(isSubmit && !formData.hotelType.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}
                      >
                        <option value={""} selected="selected">
                          --Select Type--
                        </option>
                        <option value="Hotel">Hotel</option>
                        <option value="Restaurant">Restaurant</option>
                        <option value="Place">Place</option>
                        <option value="Package">Package</option>
                      </select>
                      {/* {isSubmit && !formData.hotelType.textValue && (
                          <span className="text-danger">*</span>
                        )} */}
                    </div>}

                    <>
                      {(filterData.SelectType === "Hotel") && (
                        <div className="form-group mb-2 col-md-3">
                          <label
                            htmlFor="hotelType"
                            className="form-label fw-semibold"
                          >
                            {" "}
                            Hotel Name
                          </label>
                          <Select
                            name="hotelType"
                            placeholder="--Select Hotel--"
                            onChange={selectDropDownData}
                            value={filterData.hotelTempt}
                            options={[
                              { label: "--Select Hotel --", value: "" },
                              ...hotelData,
                            ]}
                          />
                        </div>
                      )}
                      {(filterData.SelectType === "Restaurant") && (
                        <div className="form-group mb-2 col-md-3">
                          <label
                            htmlFor="restaurantType"
                            className="form-label fw-semibold"
                          >
                            {" "}
                            Restaurant Name
                          </label>
                          <Select
                            name="restaurantType"
                            placeholder="--Select Restaurant--"
                            onChange={selectDropDownData}
                            value={filterData.restaurantTempt}
                            options={[
                              { label: "--Select Restaurant --", value: "" },
                              ...restaurantData,
                            ]}
                          />
                        </div>
                      )}
                      {filterData.SelectType === "Place" && (
                        <div className="form-group mb-2 col-md-3">
                          <label
                            htmlFor="placeType"
                            className="form-label fw-semibold"
                          >
                            {" "}
                            Place Name
                          </label>
                          <Select
                            name="placeType"
                            placeholder="--Select Place--"
                            onChange={selectDropDownData}
                            value={filterData.placeTempt}
                            options={[
                              { label: "--Select Place --", value: "" },
                              ...placeData,
                            ]}
                          />
                        </div>
                      )}
                        {filterData.SelectType === "Package" && (
                        <div className="form-group mb-2 col-md-3">
                          <label
                            htmlFor="PackageTitle"
                            className="form-label fw-semibold"
                          >
                            {" "}
                            Package Name
                          </label>
                          <Select
                            name="PackageTitle"
                            placeholder="--Select Package--"
                            onChange={selectDropDownData}
                            value={filterData.packageTempt}
                            options={[
                              { label: "--Select Package --", value: "" },
                              ...packageData,
                            ]}
                          />
                        </div>
                      )}
                    </>
                    <>
                      {" "}
                      <div className="form-group mb-2 col-md-3">
                        <label className="form-label fw-semibold">
                          Start Date
                          <span style={{ color: "#72bdce", fontSize: 10 }} />
                        </label>
                        <input
                          onKeyDown={(e) => e.preventDefault()}
                          value={filterData.StartDate}
                          onChange={selectFilterData}
                          name="StartDate"
                          type="date"
                          class="form-control"
                          placeholder="dd-MM-yyyy"
                        ></input>
                      </div>
                      <div className="form-group mb-2 col-md-3">
                        <label className="form-label fw-semibold">
                          End Date
                          <span style={{ color: "#72bdce", fontSize: 10 }} />
                        </label>
                        <input
                          onKeyDown={(e) => e.preventDefault()}
                          min={filterData.StartDate}
                          value={filterData.EndDate}
                          onChange={selectFilterData}
                          name="EndDate"
                          type="date"
                          class="form-control"
                          placeholder="dd-MM-yyyy"
                        ></input>
                        {isSubmit &&
                          ((filterData.EndDate < filterData.StartDate) || (filterData.EndDate && !filterData.StartDate)) && (
                            <span style={{ color: "red" }}>
                              End Date Must be greater than start date
                            </span>
                          )}
                      </div>
                    </>

                    <div className="col-md-3" style={{ position: "relative", top: "25px" }}>
                      <Button
                        size="sm"
                        variant="primary"
                        className="me-2 "
                        onClick={() => {
                          searchHandle(pageCount, 0);
                        }}
                      >
                        Apply
                      </Button>
                      <Button
                        onClick={fetchExcelData}
                        size="sm"
                        className="me-2"
                        variant="success"
                      >
                        Export
                      </Button>
                      <Button
                        onClick={() => handleCancle()}
                        size="sm"
                        variant="danger"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
            </>
          }
        </div>
        <div className="overflow-auto">
          <Table
            style={{ boxShadow: "2px 3px 15px", textAlign: "left", border: "1px solid gray" }}
            striped
            bordered
            hover
          >
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>User Name</th>
                <th>Review Type </th>
                <th>Name</th>
                <th>Review</th>
                <th>Rating</th>
                <th>Date and Time</th>
                {Cookies.get("ckUserType") == "Admin" && <th>Delete</th>}

              </tr>
            </thead>
            <tbody>
              {tableData.map((element, index) => (
                <>
                  <tr>
                    <td width={"5%"}>{(pageId - 1) * pageCount + (index + 1)}</td>
                    <td width={"13%"}>{element.obj.Name}{" ( " + element.obj.Type + " ) "} </td>
                    <td width={"7%"}>{element.obj.ReviewType}</td>
                    <td width={"20%"}>
                      {element.obj.HotelName ||
                        element.obj.RestaurantName ||
                        element.obj.PlaceName ||
                        element.obj.PackageTitle}
                    </td>
                    <td width={"25%"}>{element.obj.Review}</td>
                    <td width={"8%"}>
                      <Rating
                        value={element.obj.Rating}
                        readOnly
                        cancel={false}
                        style={{
                          color: "goldenrod"
                        }}
                      />
                    </td>
                    <td>{element.obj?.CreatedDate?.split(" ")[0]}{" / "}{element.obj?.CreatedDate?.split(" ")[1]}</td>
                    {Cookies.get("ckUserType") == "Admin" && <td>
                      <img
                        src={Delete}
                        onClick={
                          () => { handleDeletePlaceDetails(element.obj.ReviewID) }
                        }
                        height={"25px"}
                        alt=""
                      />
                    </td>}
                  </tr>
                </>
              ))}
            </tbody>
            <tbody>
              <tr>
                {!tableData.length && (
                  <td colSpan={11}>
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#ff0000",
                        backgroundColor: "#f0f0f0",
                        border: "1px solid #e0e0e0",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      NO DATA FOUND
                    </div>
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="mt-4 mb-2">
          <Button className="me-2" onClick={() => setPreviousApi()} variant="primary" size="sm">
            PREVIOUS
          </Button>{" "}
          <Button onClick={() => setNextApi()} variant="secondary" size="sm">
            NEXT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewReview;

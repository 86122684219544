import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput
}
from 'mdb-react-ui-kit';
import { RotatingLines } from 'react-loader-spinner';
import Swal from 'sweetalert2';
import { APIURL } from '../../Process';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { appTitle, appTitleCapital } from '../../Constants';
const Select = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #3498db; /* Blue border */
  border-radius: 5px;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
  font-weight:600;
  
`;




function App() {
  const navigate=useNavigate()
  
const [userType, setUserType] = useState('');
const [phoneNumber, setPhoneNumber] = useState('');
const [password, setPassword] = useState('');
const [showPassword, setShowPassword] = useState(false);
const [isLoading,setIsLoading]=useState(false)

useEffect(()=>{
  const cookieKeys = Object.keys(Cookies.get());

  // Iterate over each cookie key and remove the cookie
  cookieKeys.forEach(cookieKey => {
      Cookies.remove("ckUserType");
      Cookies.remove("ckUserID");
      Cookies.remove("ckUserName");

  });
},[])

const handleLogin = async(e) => {
  setIsLoading(true)
  e.preventDefault();
  try{
  console.log(userType,phoneNumber,password)
 const res=await fetch(`${APIURL}/Common/Login`,{
  method:"post",
  headers:{
      "Content-Type":"application/json"
  },
  body:JSON.stringify(
      {
          "UserType":userType,
          "MobileNo":phoneNumber,
          "Password":password
      }
  )
 })
 const result=await res.json()
 if(result.status===1){
  Swal.fire({
      title: "Successfully Logged In!",
      icon: "success",
      timer: 1500,
showConfirmButton: false,
    });
    if(userType==="Hotel"){
  Cookies.set('ckUserID', result.data[0].HotelID);
  Cookies.set('ckUserName', result.data[0].HotelName);
  Cookies.set('ckUserType',userType );
  Cookies.set("IsRestaurantAvailable",result.data[0].IsRestaurantAvailable)
  
}
  else if(userType==="Restaurant"){
      Cookies.set('ckUserID', result.data[0].RestaurantID);
      Cookies.set('ckUserName', result.data[0].RestaurantName);
      Cookies.set('ckUserType',userType );
      Cookies.set("IsWithinHotel",result.data[0].IsWithinHotel)

  }else if(userType==="Admin"){
      Cookies.set('ckUserID', result.data[0].UserID);
      Cookies.set('ckUserName', result.data[0].Name);
      Cookies.set('ckUserType',userType );
  }
  navigate("/westKameng/Dashboard")

 }else{
  Swal.fire({
      title: result.message,
      icon: "error",
      timer: 1500,
showConfirmButton: false,
    });
 }
 setIsLoading(false)
 console.log(result,"result") 
}catch(err){
  setIsLoading(false)
  Swal.fire({
    title: "Facing Some Technical Issues! Please Try After Some Time",
    icon: "error",
    timer: 1500,
showConfirmButton: false,
  });
  console.log(err,"err")
}

};
const handlePhoneNumber=(e)=>{
  let lastInputString = e.target.value[e.target.value.length - 1]
  
  if ((e.target.value == "" || lastInputString.charCodeAt() >= 48 && lastInputString.charCodeAt() <= 57 )) {
      setPhoneNumber(e.target.value)
    } else {
      return
    }
}
if(isLoading){
return   <div
style={{
  position: "absolute",
  zIndex: "6",
  left: "600px",
  top: "300px",
}}
>
<RotatingLines
  strokeColor="grey"
  strokeWidth="5"
  animationDuration="0.75"
  width="200"
  height="200"
  visible={true}
/>
</div>
}
  return (
    <div className="body-wrapper">

     
<MDBContainer  style={{  boxShadow: "2px 3px 15px", textAlign: "left", border: "1px solid gray" }} className="my-5 gradient-form">

      <div style={{display:"flex",flexWrap:"wrap"}}>

        <div style={{width:"38rem"}}  className=" me-5 mb-5 mt-4">
          <div className="d-flex flex-column ms-5 ">

            <div className="text-center">
              <img src={require("../../Assets/logo.jpg")}
                style={{width: '145px'}} alt="logo" />
              <h4 className="mt-1 mb-4 pb-1">{appTitleCapital}</h4>
            </div>
            <Label >
          User Type
          </Label>
            <Select
            className='mb-4'
            value={userType}
            onChange={(e) => setUserType(e.target.value)}
            required
          >
            <option value="">Select User Type</option>
            <option value="Admin">Admin</option>
            <option value="Hotel">Stay</option>
            <option value="Restaurant">Restaurant</option>
          </Select>
          <Label >
          Phone Number
          </Label>
            <MDBInput    type="text"
            maxLength={10}
            value={phoneNumber}
            onChange={handlePhoneNumber }
            placeholder="Enter your phone number"
            required wrapperClass='mb-4'  id='form1' />
         <Label >
          Password
          </Label>
            <MDBInput   type={showPassword ? 'text' : 'password'}
            // type="password"
            style={{position:"relative"}}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            required  wrapperClass='mb-4'  id='form2'/>

            <div className="text-center pt-1 mb-5 pb-1">
              <MDBBtn    type={showPassword ? 'text' : 'password'}
       
            style={{position:"relative"}}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            required onClick={handleLogin} className="mb-4 w-100 gradient-custom-2">Log In</MDBBtn>
              
            </div>
          </div>

        </div>

        <div  style={{width:"39.8rem"}}>
          <img   src={require("../../Assets/loginBanner.jpg")} style={{margin:"auto",width:"100%",height:"100%"}} alt="" />
        </div>

      </div>

    </MDBContainer>
</div>
  );
}

export default App;
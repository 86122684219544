import React, { useContext } from 'react'
import { placeContext } from '../Context/PlaceContext'
import { Navigate, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';


const HotelDetailAccess = ({children}) => {
 
    const {state}=useLocation()


    if(Cookies.get("ckUserType")==="Admin" || (Cookies.get("ckUserType")==="Hotel" ) ){
    return children     
    }else{
        return <Navigate to={"/"} />

    }
}

export default HotelDetailAccess
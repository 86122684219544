export const YakatopiaRouterData = [
    '/westkameng/splash',
    '/westkameng/addplacedetails',
    '/westkameng/viewplacedetails',
    '/westkameng/assignactivity',
    '/westkameng/assignitems',
    '/westkameng/addhoteldetails',
    '/westkameng/viewhotel',
    '/westkameng/addrestaurant',
    '/westkameng/viewrestaurant',
    '/westkameng/viewbookingdetails',
    '/westkameng/addwestkamenghistorydetails',
    '/westkameng/viewwestkamenghistorydetails',
    '/westkameng/viewreviews',
    '/westkameng/viewtermsandcondition',
    '/westkameng/addtermsandcondition',
    '/westkameng/addbanner',
    '/westkameng/viewbanner',
    '/westkameng/viewuserdetails',
    '/westkameng/dashboard',
    '/westkameng/viewwestkamengtourismdetails',
    '/westkameng/addaboutus',
    '/westkameng/viewaboutus',
    '/westkameng/addcontactus',
    '/westkameng/viewcontactus',
    '/westkameng/addtouroperator',
    '/westkameng/viewtouroperator',
    '/westkameng/adduseradmin',
    '/westkameng/viewuseradmin',
    '/westkameng/addpackagedetails',
    '/westkameng/viewpackagedetails',
    '/westkameng/viewpackageenquiry',
    '/westkameng/adddestination',
    '/westkameng/viewdestination',
    "/westkameng/addweatheralertdetails",
    "/westkameng/viewweatheralertdetails",
    "/westkameng/addgeneralactivity",
    "/westkameng/viewgeneralactivity",
    "/westkameng/addnearbytoilet",
    "/westkameng/viewnearbytoilet"
]; 
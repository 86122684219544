import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIURL, IMAGEMODELURL } from "../../Process";
import { Button, Col, Row } from "react-bootstrap";
import { Form, FormGroup, InputGroup, Table } from "react-bootstrap";
import Edit from "../../Assets/Edit.png";
import Delete from "../../Assets/Delete.png";
import PDFImg from "../../Assets/pdf2.png";
import { FaToggleOn, FaToggleOff, FaLink } from "react-icons/fa";
import EyeOpen from "../../Assets/eyeopen.png";
import ImageNotFound from "../../Assets/Noimage.png";
import { FiActivity } from "react-icons/fi";
import { MdAssignmentAdd } from "react-icons/md";
import XLSX from "xlsx-color";
import moment from 'moment';
import { RotatingLines } from 'react-loader-spinner'
import EyeClose from "../../Assets/eyeclose.png";
import Swal from "sweetalert2";
import { MdDelete } from "react-icons/md"
import { FaEyeSlash } from "react-icons/fa"


import { placeContext } from "../../Context/PlaceContext";
import Select from 'react-select';
import ShowPasswardModel from "../ShowPasswardModel";
import Cookies from "js-cookie";

const ViewHotelDetails = () => {
  const navigate = useNavigate();
  const { setRestaurantAvailable } = useContext(placeContext)
  const [totalCount, setTotalCount] = useState()
  const [openModel, setOpenModel] = useState(false)
  const [showPasswordID, setShowPasswordID] = useState("")
  const HotelImageDIR = `${IMAGEMODELURL}/Hotel/HotelImage`;
  const OwnerImageDIR = `${IMAGEMODELURL}/Hotel/OwnerImage`;
  const OwnerIDImageDIR = `${IMAGEMODELURL}/Hotel/OwnerIDImage`;
  const ManagerPhotoDIR = `${IMAGEMODELURL}/Hotel/ManagerPhoto`;
  const RegistrationImageDIR = `${IMAGEMODELURL}/Hotel/RegistrationImage`;
  const FireNOCDIR = `${IMAGEMODELURL}/Hotel/FireNOC/`;
  const PCCertificateDIR = `${IMAGEMODELURL}/Hotel/PoliceClearanceCertificate/`;
  const ImagesDIR = `${IMAGEMODELURL}/Hotel/Images`;
  const [pageCount, setPageCount] = useState("10");
  const [tableData, setTableData] = useState([]);
  const [DestinationData, setDestinationData] = useState([])
  const [hotelData, setHotelData] = useState([])
  const [allTableData, setAllTableData] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [filterData, setFilterData] = useState({
    hotelType: "",
    HotelName: "",
    IsRestaurantAvailable: "",
    isRegister: "",
    hotelTempt: "",
    DestinationName: "",
    DestinationTempt: ""
  });
  useEffect(() => {
    if (Cookies.get("ckUserType") === "Admin") {
      getHotelData()
      GetDestination()
      fetchInitialPlaceDetailsData(pageCount, filterData)
    } else if (Cookies.get("ckUserType") == "Hotel") {
      fetchHotelUserData()
    }
  }, []);
  const getHotelData = async () => {
    try {
      const res = await fetch(`${APIURL}/Hotel/GetHotel`, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          PaginationID: "-1",
          PageLength: "",
          UsedFor: "Panel"
        })
      })
      const result = await res.json()
      console.log(result, "result")

      setHotelData(result.data.map(item => ({ value: item.HotelID, label: item.HotelName })))
    } catch (err) {
      console.log(err)
    }

  }

  const GetDestination = async () => {
    try {
      const res = await fetch(`${APIURL}/Destination/getDestination`, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          DestinationID: '',
          DestinationName: '',
          IsActive: 'true',
          IsDelete: 'false',
          IsPagination: '',
          PerPage: '',
          UniqueID: '',
        })
      })
      const result = await res.json()
      console.log(result, "result")

      setDestinationData(result.data.map(item => ({ value: item.DestinationID, label: item.DestinationName })))
    } catch (err) {
      console.log(err)
    }

  }

  const fetchHotelUserData = async () => {
    setIsLoading(true)
    try {
      const res = await fetch(`${APIURL}/Hotel/GetHotel`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          HotelID: Cookies.get("ckUserID"),
          PaginationID: "-1",
          PageLength: "",
        }),
      })
      const result = await res.json()
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      setTableData(tempt);
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }

  }

  const handleCancle = () => {
    setFilterData({
      HotelName: "",
      hotelTempt: "",
      DestinationName: "",
      DestinationTempt: "",
      hotelType: "",
      IsRestaurantAvailable: "",
      isRegister: ""
    });

    let tempt = {
      ...filterData,
      HotelName: "",
      hotelTempt: "",
      DestinationName: "",
      DestinationTempt: "",
      hotelType: "",
      IsRestaurantAvailable: "",
      isRegister: ""
    }
    setFilterData(tempt)

    fetchInitialPlaceDetailsData(pageCount, tempt)
  };
  const fetchInitialPlaceDetailsData = async (count, temp) => {
    setIsLoading(true)
    try {
      const res = await fetch(`${APIURL}/Hotel/GetHotel`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          HotelType: temp?.hotelType,
          IsRegisterWithTourismDepartment: "",
          IsRestaurantAvailable: "",
          UsedFor: "Panel",
          HotelName: "",
          DestinationID: "",
          StartDate: "",
          EndDate: "",
          PaginationID: "0",
          PageLength: count,
        }),
      })
      const result = await res.json()

      setTotalCount(result.total[0].TotalCount)
      console.log(result, "initialReasult");
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      setIsLoading(false)
      setTableData(tempt);
      setPageId(1)
      setAllTableData(tempt);
    } catch (err) {
      console.log(err)
    }
  };
  const handlePageCount = (e) => {
    setPageCount(e.target.value);
    searchHandle(e.target.value, 0, filterData);
  };
  const handleEyeOn = (index) => {
    console.log("eyeon");
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: true,
    });
    setTableData(tempt);
  };
  const handleEyeOff = (index) => {
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: false,
    });
    setTableData(tempt);
  };
  const handleFilterInputChange = (e) => {
    let tempt = { ...filterData }
    tempt = {
      ...filterData,
      [e.target.name]: e.target.value,
    }
    setFilterData(tempt);
    searchHandle(pageCount, 0, tempt)
  };
  const handleHotelBookingStatus = async (hotelId, status) => {
    try {
      console.log(hotelId, "placeID")
      const res = await fetch(`${APIURL}/Hotel/BookingHotelStatus`, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          HotelID: hotelId,
          IsAllowBooking: `${status}`
        })
      })
      const result = await res.json()
      fetchHotelUserData()
      console.log(result, "placeActiveStutus")
    } catch (err) {
      console.log(err)
    }
  }
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const searchHandle = async (count, paginationIndex, filterData) => {
    try {
      const res = await fetch(`${APIURL}/Hotel/GetHotel`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DestinationID: filterData.DestinationName,
          HotelID: filterData.HotelName,
          HotelType: filterData.hotelType,
          IsRegisterWithTourismDepartment: filterData.isRegister,
          IsRestaurantAvailable: filterData.IsRestaurantAvailable,
          UsedFor: "Panel",
          PaginationID: `${paginationIndex}`,
          PageLength: count,
        }),
      })
      const result = await res.json()
      setTotalCount(result.total[0].TotalCount)
      console.log(result);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      setTotalCount(result.total[0].TotalCount)
      console.log(tempt, "?tempt");
      setTableData(tempt);
      setAllTableData(tempt);
      setPageId(1);
    } catch (err) {
      console.log(err)
    }
  };
  const setNextApi = async () => {
    console.log(pageId, "pageID", pageCount);
    if (pageId * pageCount < allTableData.length) {
      const tempt = [...allTableData];
      console.log(tempt, pageId * pageCount, (pageId + 1) * pageCount);
      const sliced = tempt.slice(pageId * pageCount, (pageId + 1) * pageCount);
      setTableData(sliced);
      setPageId(pageId + 1);
      return;
    }
    const paginationIndex = allTableData[pageId * pageCount - 1]?.obj?.HotelID;
    if (!paginationIndex) {
      return;
    }
    try {
      const res = await fetch(`${APIURL}/Hotel/GetHotel`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DestinationID: filterData.DestinationName,
          HotelType: filterData.hotelType,
          IsRegisterWithTourismDepartment: filterData.isRegister,
          IsRestaurantAvailable: filterData.IsRestaurantAvailable,
          UsedFor: "Panel",
          PaginationID: `${paginationIndex}`,
          PageLength: `${pageCount}`,
        }),
      })
      const result = await res.json()
      console.log(result);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      console.log(tempt, "tempt");
      setTableData(tempt);
      setPageId(pageId + 1);
      setAllTableData([...allTableData, ...tempt]);
    } catch (err) {
      console.log(err, "err")
    }
  };
  const setPreviousApi = () => {
    if (pageId == 1) {
      return;
    }
    const tempt = [...allTableData];
    console.log(pageId, "pageId");
    console.log(
      pageId,
      tempt,
      (pageId - 1) * pageCount,
      pageId * pageCount + 1
    );
    const sliced = tempt.slice(
      (pageId - 2) * pageCount,
      (pageId - 1) * pageCount
    );
    setTableData(sliced);
    setPageId(pageId - 1);
  };
  async function fetchExcelData() {
    console.log(filterData, "filterData")
    setIsLoading(true)
    try {

      const res = await fetch(`${APIURL}/Hotel/GetHotel`, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          DestinationID: filterData.DestinationName,
          HotelID: filterData.HotelName,
          HotelType: filterData.hotelType,
          IsRegisterWithTourismDepartment: filterData.isRegister,
          IsRestaurantAvailable: filterData.IsRestaurantAvailable,
          UsedFor: "Panel",
          PaginationID: "-1",
          PageLength: ``
        })
      })

      const result = await res.json()
      handleDownloadExcel(result.data)
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }
  const handleDownloadExcel = (array) => {
    console.log(array, "array")
    const header = ["DestinationName", "Stay Type", "Stay Name", "Check-In", "Check-Out", "Registration with Tourism Department (Yes/No)", "Registration Id num", "Owner Name", "Owner Aadharcard", "Owner Pancard", "Owner Address",
      "Stay Address", "Stay Contact details", "Manager Name", "	Manager Address", "There is a restaurant(Yes/No)"
    ];
    const body = [];
    array?.forEach((element, index) => {
      body.push([element.DestinationName, element.HotelType, element.HotelName, element.CheckInTime, element.CheckOutTime, element.IsRegisterWithTourismDepartment, element.RegistrationID, element.OwnerName, element.OwnerAadharCardNo, element.OwnerPanCard,
      element.OwnerAddress, element.HotelAddress, `${element.HotelContactNo1 ? `Contact1 - ${element.HotelContactNo1}` : ""} ${element.HotelContactNo2 ? `${","} Contact2 - ${element.HotelContactNo2} ` : ""} ${element.HotelContactNo3 ? `${","} Contact3 - ${element.HotelContactNo3} ` : ""}`, element.ManagerName, element.ManagerAddress,
      element.IsRestaurantAvailable])
    })
    if (!body.length) {
      setIsLoading(false)
      return Swal.fire({
        title: "No Data Found!",
        icon: "error",
        timer: 1500,
        showConfirmButton: false
      }
      );
    }
    const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);
    header.forEach((col, index) => {
      ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
        font: { bold: true, size: 14, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
      };
    });
    ws1['!cols'] = [
      { width: 20 },
      { width: 20 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 30 },
      { width: 20 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 40 },
      { width: 40 },
      { width: 60 },
      { width: 40 },
      { width: 40 },
      { width: 50 },
      { width: 10 },

    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, 'Stay Details');

    // Save the workbook
    setIsLoading(false)
    XLSX.writeFile(wb, `Stay${moment().format('DD-MM-YYYY_HH:mm')}.xlsx`);
  }
  const confirmDelete = async (id) => {
    try {
      const res = await fetch(`${APIURL}/Hotel/RemoveHotel`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          HotelID: id,
        }),
      });

      const result = await res.json();
      if (result.status == 1) {
        Swal.fire({
          title: "Successfully Deleted!",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        })
        fetchInitialPlaceDetailsData(pageCount, filterData);
      }
      console.log(result);
    } catch (err) {
      console.log(err, "err")
    }
  }
  const handleDeletePlaceDetails = async (id) => {
    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red"

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmDelete(id)
      }
    });
  };
  const editAppointmentDetails = (element) => {
    navigate("/westKameng/addhoteldetails", {
      state: element.obj,
    });
  };
  const confirmImageDelete = async (element) => {
    try {
      const res = await fetch(`${APIURL}/ImageDetail/DeleteImages`, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ImageDetailID: `${element.ImageDetailID}`,
          "ImageType": element.ImageType,
          "Image": element.Image
        })
      })
      const result = await res.json()
      if (result.status === 1) {
        Swal.fire({
          title: "Successfully Deleted!",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        // fetchInitialPlaceDetailsData(pageCount)
        if (Cookies.get("ckUserType") === "Admin") {
          searchHandle(pageCount, 0, filterData)
        } else if (Cookies.get("ckUserType") == "Hotel") {
          fetchHotelUserData()
        }
        console.log(result, "result")
      }
    } catch (err) {
      console.log(err)
    }
  }
  const deleteImageDetails = async (element) => {
    console.log(element, "element")
    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red"

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmImageDelete(element)
      }
    });
  }
  const handlePlaceActive = async (hotelId, status) => {
    try {
      console.log(hotelId, "placeID")
      const res = await fetch(`${APIURL}/Hotel/UpdateActiveStatus`, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          HotelID: hotelId,
          Status: `${status}`
        })
      })
      const result = await res.json()
      searchHandle(pageCount, 0, filterData)
      console.log(result, "placeActiveStutus")
    } catch (err) {
      console.log(err)
    }
  }

  const selectDropDownData = (e, action) => {
    console.log(e.value, "eeeeee")
    setFilterData({
      ...filterData,
      [action.name]: e.value,
      hotelTempt: e,
    })
  }

  const selectDropDownData1 = (e, action) => {
    console.log(e.value, "epppp")
    setFilterData({
      ...filterData,
      [action.name]: e.value,
      DestinationTempt: e,
    })
  }
  if (isLoading) {
    return <div
      style={{
        position: "absolute",
        zIndex: "6",
        left: "600px",
        top: "300px",
      }}
    >
      <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width="200"
        height="200"
        visible={true}
      />
    </div>
  }

  return (

    <>
      {Cookies.get("ckUserType") === "Admin" && <div className="body-wrapper">
        <div className="container-fluid">
          {isLoading && <div style={{ position: "absolute", zIndex: "6", left: "600px", top: "300px" }}>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="200"
              height="200"
              visible={true}
            />
          </div>}
          {openModel && <ShowPasswardModel openModel={openModel} setOpenModel={setOpenModel} HotelID={showPasswordID} UserType={"Hotel"} />}
          <div className={` pt-2 pb-2 place-filter  ${isOpen ? "open" : "closed"}`}>
            <Row>
              <Col md={2} sm={2} className="col-4">
                <div className="d-flex gap-2 pt-1">
                  <span>Total Count</span>
                  <span>{totalCount}</span>
                </div>
              </Col>
              <Col md={2} sm={2} className="col-4">
                <Form.Select
                  onChange={handlePageCount}
                  size="sm"
                  className="w-70"
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </Form.Select>
              </Col>

              <Col md={{ span: 2, offset: 6 }} className="col-4">
                <Button
                  className="float-end"
                  md={2}
                  variant="outline-primary"
                  size="sm"
                  onClick={handleToggle}
                >
                  {isOpen ? "Close Filter" : "Open Filter"}
                </Button>
              </Col>
            </Row>
            {isOpen && (
              <div className="filter-content row" style={{ marginTop: "20px", padding: "20px", border: "1px solid gray" }}>
                <Form className="d-flex pb-4 row">

                  <div className="form-group col-md-3 mb-2">
                    <label
                      htmlFor="DestinationName"
                      className="form-label fw-semibold"
                    >
                      {" "}
                      Destination Name
                    </label>
                    <Select name='DestinationName' placeholder="--Select Destination--" onChange={selectDropDownData1} value={filterData.DestinationTempt} options={[{ label: '--Select Destination --', value: '' }, ...DestinationData]} />
                  </div>

                  <div className="form-group col-md-3 mb-2">
                    <label
                      htmlFor="hotelType"
                      className="form-label fw-semibold"
                    >
                      {" "}
                      Stay Type
                    </label>
                    <select
                      onChange={handleFilterInputChange}
                      name="hotelType"
                      value={filterData.hotelType}
                      className="form-control"
                    >
                      <option value={""} selected="selected">
                        --Select Stay Type--
                      </option>
                      <option value="Hotel">Stay</option>
                      <option value="Resort">Resort</option>
                      <option value="HomeStay">HomeStay</option>
                      <option value="FarmStay">FarmStay</option>
                    </select>
                    {/* {isSubmit && !formData.hotelType.textValue && (
                          <span className="text-danger">*</span>
                        )} */}
                    {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                  </div>

                  <div className="form-group col-md-3 mb-2">
                    <label
                      htmlFor="HotelName"
                      className="form-label fw-semibold"
                    >
                      {" "}
                      Stay Name
                    </label>
                    <Select name='HotelName' placeholder="--Select Stay--" onChange={selectDropDownData} value={filterData.hotelTempt} options={[{ label: '--Select Stay --', value: '' }, ...hotelData]} />
                  </div>
                  <div className="form-group col-md-3 mb-2">

                    <label className="form-label fw-semibold">
                      Is Restaurant Available
                    </label>
                    <div className="form-control">
                      <div className="d-flex align-items-center gap-2">
                        <label className="d-flex align-items-center gap-2">
                          <input
                            onChange={handleFilterInputChange}
                            type="radio"
                            name="IsRestaurantAvailable"
                            value={"YES"}
                            checked={
                              filterData.IsRestaurantAvailable ==
                              "YES"
                            }
                          />
                          <span>YES </span>
                        </label>
                        <label className="d-flex align-items-center gap-2">
                          <input
                            onChange={handleFilterInputChange}
                            type="radio"
                            name="IsRestaurantAvailable"
                            value={"NO"}
                            checked={
                              filterData.IsRestaurantAvailable ==
                              "NO"
                            }
                          />
                          <span> NO</span>
                        </label>
                      </div>
                    </div>

                  </div>
                  <div className="form-group col-md-3 mb-2">

                    <label className="form-label fw-semibold">
                      Is Register With Tourism Department
                    </label>
                    <div className="form-control">
                      <div className="d-flex align-items-center gap-2">
                        <label className="d-flex align-items-center gap-2">
                          <input
                            onChange={handleFilterInputChange}
                            type="radio"
                            name="isRegister"
                            value={"YES"}
                            checked={filterData.isRegister == "YES"}
                          />
                          <span>YES </span>
                        </label>
                        <label className="d-flex align-items-center gap-2">
                          <input
                            onChange={handleFilterInputChange}
                            value={"NO"}
                            checked={filterData.isRegister == "NO"}
                            type="radio"
                            name="isRegister"
                          />
                          <span> NO</span>
                        </label>
                      </div>
                    </div>

                  </div>
                  <div className="col-md-3" style={{ position: "relative", top: "27px" }} >
                    <Button
                      size="sm"
                      variant="primary"
                      className="me-2 "
                      onClick={() => {
                        searchHandle(pageCount, 0, filterData)
                      }}
                    >
                      Apply
                    </Button>
                    <Button onClick={() => { fetchExcelData() }
                      // fetchExcelData
                    } size="sm" className="me-2" variant="success">
                      Export
                    </Button>
                    <Button onClick={() => handleCancle()} size="sm" variant="danger">
                      Cancel
                    </Button>
                  </div>
                  {/* Add more InputGroup components for additional filters */}
                </Form>
              </div>

            )}
          </div>
          <div className="overflow-auto">
            <Table
              style={{ boxShadow: "2px 3px 15px", textAlign: "left", border: "1px solid gray" }}
              striped
              bordered
              hover
            >
              <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Details</th>
                  <th>Status</th>
                  <th>Destination Name</th>
                  <th>Stay Name / Stay Type</th>
                  <th>Contact Details</th>
                  <th>Check In /Check Out</th>
                  <th>Email ID</th>
                  <th>Address</th>
                  <th>Boking Allowed</th>
                  <th>Show Password</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((element, index) => (
                  <>
                    <tr>
                      <td>{(pageId - 1) * pageCount + (index + 1)}</td>
                      <td>
                        {!element.isDetails && (
                          <img
                            onClick={() => handleEyeOn(index)}
                            src={EyeOpen}
                            height={"22px"}
                            alt=""
                          />
                        )}{" "}
                        {element.isDetails && (
                          <img
                            onClick={() => handleEyeOff(index)}
                            src={EyeClose}
                            height={"22px"}
                            alt=""
                          />
                        )}
                      </td>
                      <td>
                        {" "}
                        {element.obj.IsActive ? (
                          <FaToggleOn onClick={() => handlePlaceActive(element.obj.HotelID, 0)}
                            style={{ color: "green", fontSize: "1.9em" }}
                          />
                        ) : (
                          <FaToggleOff onClick={() => handlePlaceActive(element.obj.HotelID, 1)}
                            style={{ color: "red", fontSize: "1.8em" }}
                          />
                        )}
                      </td>
                      <td> {element.obj.DestinationName}</td>
                      <td>
                        {element.obj.HotelName} ({element.obj.HotelType})
                      </td>
                      <td>
                        <Table
                          size="sm"
                          style={{ textAlign: "left", border: "1px solid gray" }}
                          striped
                          bordered
                          hover
                        >
                          <tbody>
                            <tr>
                              <td>Contact No 1</td>
                              <td>
                                <span>{element.obj.HotelContactNo1}</span>
                                <br />
                              </td>
                            </tr>
                            <tr>
                              <td>Contact No 2</td>
                              <td>
                                <span>{element.obj.HotelContactNo2}</span>
                                <br />
                              </td>
                            </tr>
                            <tr>
                              <td>Landline No</td>
                              <td>
                                <span>{element.obj.HotelContactNo3}</span>
                                <br />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table
                          size="sm"
                          style={{ textAlign: "left", border: "1px solid gray" }}
                          striped
                          bordered
                          hover
                        >
                          <tbody>
                            <tr>
                              <td>Check In Time</td>
                              <td>
                                <span>{element.obj.CheckInTime}</span>
                                <br />
                              </td>
                            </tr>
                            <tr>
                              <td>Check Out Time</td>
                              <td>
                                <span>{element.obj.CheckOutTime}</span>
                                <br />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>{element.obj.HotelEmailID}</td>
                      <td>{element.obj.HotelAddress}</td>
                      <td>{element.obj.IsAllowBooking ? "Yes" : "No"}</td>
                      <td><FaEyeSlash onClick={() => { setOpenModel(true); setShowPasswordID(element.obj.HotelID) }} style={{ fontSize: "1.4em", cursor: "pointer" }} /></td>
                      <td>
                        <img
                          onClick={() => {
                            editAppointmentDetails(element)
                          }}
                          src={Edit}
                          height={"25px"}
                          alt=""
                        />
                      </td>
                      <td>
                        <img
                          src={Delete}
                          onClick={
                            () => { handleDeletePlaceDetails(element.obj.HotelID) }

                          }
                          height={"25px"}
                          alt=""
                        />
                      </td>
                    </tr>

                    {element.isDetails && (
                      <tr>
                        <td colSpan={13}>
                          <Table
                            style={{ textAlign: "left", border: "1px solid gray" }}
                            striped
                            bordered
                            hover
                          >
                            <thead>
                              <th className="p-2">Stay Details</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ display: "flex" }}>
                                  <Table
                                    style={{
                                      textAlign: "left",
                                      border: "1px solid gray",
                                      width: "30%"
                                    }}
                                    striped
                                    bordered
                                    hover
                                  >
                                    <thead>
                                      <th className="p-2">Stay Front Image</th>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td style={{ border: "none" }}>
                                          <img
                                            src={
                                              element?.obj.HotelImage
                                                ? `${HotelImageDIR}/${element.obj.HotelImage}`
                                                : ImageNotFound
                                            }
                                            style={{
                                              height: 80,
                                              width: 80,
                                              borderWidth: 0,
                                              border: "1px solid #007AB1",
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                  <Table
                                    style={{
                                      textAlign: "left",
                                      border: "1px solid gray",
                                    }}
                                    striped
                                    bordered
                                    hover
                                  >
                                    <thead>
                                      <th className="p-2">Stay Detail Images</th>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td style={{ border: "none" }} className="d-flex">
                                          {" "}
                                          {element.obj.ImageDetail?.map(
                                            (element, index) => (
                                              <div className="d-flex" style={{ flexDirection: 'row', width: "100px", marginBottom: "10px", marginRight: "10px" }}>
                                                <img
                                                  key={index}
                                                  src={
                                                    element?.Image
                                                      ? `${ImagesDIR}/${element.Image}`
                                                      : ImageNotFound
                                                  }
                                                  style={{
                                                    marginLeft: "10px",
                                                    height: 90,
                                                    width: 90,
                                                    borderWidth: 0,
                                                    border: "1px solid #007AB1",
                                                  }}
                                                />
                                                <span onClick={() => deleteImageDetails(element)} style={{ color: "black", textAlign: "right", fontSize: "1.4em", position: "relative", right: "20px", bottom: "4px" }}><MdDelete /></span>
                                              </div>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                  <Table
                                    style={{
                                      textAlign: "left",
                                      border: "1px solid gray",
                                      width: "20%"
                                    }}
                                    striped
                                    bordered
                                    hover
                                  >
                                    <thead>
                                      <th className="p-2">Lattitude</th>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td style={{ border: "none" }} className="d-flex">
                                          {element.obj.HotelLatitude}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                  <Table
                                    style={{
                                      textAlign: "left",
                                      border: "1px solid gray",
                                      width: "20%"
                                    }}
                                    striped
                                    bordered
                                    hover
                                  >
                                    <thead>
                                      <th className="p-2">Longitude</th>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td style={{ border: "none" }} className="d-flex">
                                          {element.obj.HotelLongitude}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <Table
                            style={{
                              textAlign: "left",
                              border: "1px solid gray",
                            }}
                            striped
                            bordered
                            hover
                          >
                            <thead>
                              <th className="p-2">Owner Details</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {
                                    <Table
                                      style={{
                                        textAlign: "left",
                                        border: "1px solid gray",
                                      }}
                                      striped
                                      bordered
                                      hover
                                    >
                                      <thead>
                                        <tr>
                                          <th> Owner Name</th>
                                          <th> Adhar Card Number</th>
                                          <th> Pan Card Number </th>
                                          <th>Owner Address</th>
                                          <th>Owner ID Image</th>
                                          <th>Owner Image</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{element.obj.OwnerName}</td>
                                          <td>{element.obj.OwnerAadharCardNo}</td>
                                          <td>{element.obj.OwnerPanCard}</td>
                                          <td>{element.obj.OwnerAddress}</td>
                                          <td>
                                            {" "}
                                            <img
                                              key={index}
                                              src={
                                                element?.obj.OwnerIDImage
                                                  ? `${OwnerIDImageDIR}/${element.obj.OwnerIDImage}`
                                                  : ImageNotFound
                                              }
                                              style={{
                                                marginLeft: "10px",
                                                height: 90,
                                                width: 90,
                                                borderWidth: 0,
                                                border: "1px solid #007AB1",
                                              }}
                                            />
                                          </td>
                                          <td>
                                            <img
                                              key={index}
                                              src={
                                                element?.obj.OwnerImage
                                                  ? `${OwnerImageDIR}/${element.obj.OwnerImage}`
                                                  : ImageNotFound
                                              }
                                              style={{
                                                marginLeft: "10px",
                                                height: 90,
                                                width: 90,
                                                borderWidth: 0,
                                                border: "1px solid #007AB1",
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </Table>

                          <Table
                            style={{
                              textAlign: "left",
                              border: "1px solid gray",
                            }}
                            striped
                            bordered
                            hover
                          >
                            <thead>
                              <th className="p-2">Manager Details</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {
                                    <Table
                                      style={{
                                        textAlign: "left",
                                        border: "1px solid gray",
                                      }}
                                      striped
                                      bordered
                                      hover
                                    >
                                      <thead>
                                        <tr>
                                          <th> Manager Name</th>
                                          <th>Manager Photo</th>
                                          <th>Manager Address </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{element.obj.ManagerName}</td>
                                          <td>
                                            {" "}
                                            <img
                                              key={index}
                                              src={
                                                element?.obj.ManagerPhoto
                                                  ? `${ManagerPhotoDIR}/${element.obj.ManagerPhoto}`
                                                  : ImageNotFound
                                              }
                                              style={{
                                                marginLeft: "10px",
                                                height: 90,
                                                width: 90,
                                                borderWidth: 0,
                                                border: "1px solid #007AB1",
                                              }}
                                            />
                                          </td>
                                          <td>{element.obj.ManagerAddress}</td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </Table>

                          <Table
                            style={{
                              textAlign: "left",
                              border: "1px solid gray",
                            }}
                            striped
                            bordered
                            hover
                          >
                            <thead>
                              <th className="p-2">
                                Stay Certificates And Registration Details
                              </th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {
                                    <Table
                                      style={{
                                        textAlign: "left",
                                        border: "1px solid gray",
                                      }}
                                      striped
                                      bordered
                                      hover
                                    >
                                      <thead>
                                        <tr>
                                          <th>
                                            {" "}
                                            Is Register With Tourism Department
                                          </th>
                                          {element.obj?.IsRegisterWithTourismDepartment?.toLowerCase() === "yes" && (
                                            <th> Registration ID</th>
                                          )}
                                          {element?.obj?.IsRegisterWithTourismDepartment?.toLowerCase() === "yes" && (
                                            <th> Registration Image </th>
                                          )}
                                          <th> Is Restaurant Available</th>
                                          <th> Police Clearance Certificate </th>
                                          <th> Fire Clearance Certificate </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            {
                                              element.obj
                                                .IsRegisterWithTourismDepartment
                                            }
                                          </td>
                                          {element.obj?.IsRegisterWithTourismDepartment?.toLowerCase() === "yes" && (
                                            <td>{element.obj.RegistrationID}</td>
                                          )}
                                          {element.obj?.IsRegisterWithTourismDepartment?.toLowerCase() === "yes" && (
                                            <td>
                                              {" "}
                                              <img
                                                key={index}
                                                src={
                                                  element?.obj.RegistrationImage
                                                    ? `${RegistrationImageDIR}/${element.obj.RegistrationImage}`
                                                    : ImageNotFound
                                                }
                                                style={{
                                                  marginLeft: "10px",
                                                  height: 90,
                                                  width: 90,
                                                  borderWidth: 0,
                                                  border: "1px solid #007AB1",
                                                }}
                                              />
                                            </td>
                                          )}
                                          <td>
                                            {element.obj.IsRestaurantAvailable}
                                          </td>
                                          <td>
                                            <a
                                              target="_blank"
                                              href={`${PCCertificateDIR}${element.obj.PoliceClearanceCertificateFile}`}
                                            >
                                              {element?.obj
                                                .PoliceClearanceCertificateFile && (
                                                  <img
                                                    height={60}
                                                    width={60}
                                                    src={PDFImg}
                                                    alt=""
                                                  />
                                                )}
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              target="_blank"
                                              href={`${FireNOCDIR}${element.obj.FireNOCFile}`}
                                            >
                                              {element?.obj.FireNOCFile && (
                                                <img
                                                  height={60}
                                                  width={60}
                                                  src={PDFImg}
                                                  alt=""
                                                />
                                              )}
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
              <tbody>
                <tr>
                  {!tableData.length && (
                    <td colSpan={11}>
                      <div
                        style={{
                          textAlign: "center",
                          padding: "20px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#ff0000",
                          backgroundColor: "#f0f0f0",
                          border: "1px solid #e0e0e0",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      >
                        NO DATA FOUND
                      </div>
                    </td>
                  )}
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="pt-2 pb-2">
            <Button variant="primary" onClick={setPreviousApi} size="sm">
              PREVIOUS
            </Button>{" "}
            <Button variant="secondary" onClick={setNextApi} size="sm">
              NEXT
            </Button>
          </div>
        </div>
      </div>}
      {Cookies.get("ckUserType") === "Hotel" && <div className="body-wrapper">
        <div className="container-fluid">
          {isLoading && <div style={{ position: "absolute", zIndex: "6", left: "600px", top: "300px" }}>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="200"
              height="200"
              visible={true}
            />
          </div>}
          <Table
            style={{ marginTop: "2rem", boxShadow: "2px 5px 15px", border: "1px solid gray", textAlign: "left", }}
            striped
            bordered
            hover
          >
            <thead >
              <tr>

                <th>Stay Name / Stay Type</th>
                <th>Contact Details</th>
                <th>Check In /Check Out</th>
                <th>Email ID</th>
                <th>Address</th>
                <th>Allow Stay Booking</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody  >
              {tableData.map((element, index) => (
                <>
                  <tr>
                    <td>
                      {element.obj.HotelName} ({element.obj.HotelType})
                    </td>

                    <td>
                      <Table
                        size="sm"
                        style={{ textAlign: "left", border: "1px solid gray" }}
                        striped
                        bordered
                        hover
                      >
                        <tbody>
                          <tr>
                            <td>Contact No 1</td>
                            <td>
                              <span>{element.obj.HotelContactNo1}</span>
                              <br />
                            </td>
                          </tr>
                          <tr>
                            <td>Contact No 2</td>
                            <td>
                              <span>{element.obj.HotelContactNo2}</span>
                              <br />
                            </td>
                          </tr>
                          <tr>
                            <td>Contact No 3</td>
                            <td>
                              <span>{element.obj.HotelContactNo3}</span>
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </td>
                    <td>
                      <Table
                        size="sm"
                        style={{ textAlign: "left", border: "1px solid gray" }}
                        striped
                        bordered
                        hover
                      >
                        <tbody>
                          <tr>
                            <td>Check In Time</td>
                            <td>
                              <span>{element.obj.CheckInTime}</span>
                              <br />
                            </td>
                          </tr>
                          <tr>
                            <td>Check Out Time</td>
                            <td>
                              <span>{element.obj.CheckOutTime}</span>
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </td>
                    <td>{element.obj.HotelEmailID}</td>
                    <td>{element.obj.HotelAddress}</td>
                    <td>
                      {" "}
                      {element.obj.IsAllowBooking ? (
                        <FaToggleOn onClick={() => handleHotelBookingStatus(element.obj.HotelID, false)}
                          style={{ color: "green", fontSize: "1.9em" }}
                        />
                      ) : (
                        <FaToggleOff onClick={() => handleHotelBookingStatus(element.obj.HotelID, true)}
                          style={{ color: "red", fontSize: "1.8em" }}
                        />
                      )}
                    </td>
                    <td>
                      <img
                        onClick={() => {
                          editAppointmentDetails(element)
                        }}
                        src={Edit}
                        height={"25px"}
                        alt=""
                      />
                    </td>

                  </tr>
                  <tr>
                    <td colSpan={11}>
                      <Table
                        style={{ textAlign: "left", border: "1px solid gray" }}
                        striped
                        bordered
                        hover
                      >
                        <thead>
                          <th className="p-2">Stay Details</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ display: "flex" }}>
                              <Table
                                style={{
                                  textAlign: "left",
                                  border: "1px solid gray",
                                  width: "30%"
                                }}
                                striped
                                bordered
                                hover
                              >
                                <thead>
                                  <th className="p-2">Stay Front Image</th>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <img
                                        src={
                                          element?.obj.HotelImage
                                            ? `${HotelImageDIR}/${element.obj.HotelImage}`
                                            : ImageNotFound
                                        }
                                        style={{
                                          height: 80,
                                          width: 80,
                                          borderWidth: 0,
                                          border: "1px solid #007AB1",
                                        }}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                              <Table
                                style={{
                                  textAlign: "left",
                                  border: "1px solid gray",
                                }}
                                striped
                                bordered
                                hover
                              >
                                <thead>
                                  <th className="p-2">Stay Detail Images</th>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td style={{ border: "none" }} className="d-flex">
                                      {" "}
                                      {element?.obj?.ImageDetail?.map(
                                        (element, index) => (
                                          <div className="d-flex" style={{ flexDirection: 'row', width: "100px", marginBottom: "10px", marginRight: "10px" }}>
                                            <img
                                              key={index}
                                              src={
                                                element?.Image
                                                  ? `${ImagesDIR}/${element.Image}`
                                                  : ImageNotFound
                                              }
                                              style={{
                                                marginLeft: "10px",
                                                height: 90,
                                                width: 90,
                                                borderWidth: 0,
                                                border: "1px solid #007AB1",
                                              }}
                                            />
                                            <span onClick={() => deleteImageDetails(element)} style={{ color: "black", textAlign: "right", fontSize: "1.4em", position: "relative", right: "20px", bottom: "4px" }}><MdDelete /></span>
                                          </div>
                                        )
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <Table
                        style={{
                          textAlign: "left",
                          border: "1px solid gray",
                        }}
                        striped
                        bordered
                        hover
                      >
                        <thead>
                          <th className="p-2">Owner Details</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {
                                <Table
                                  style={{
                                    textAlign: "left",
                                    border: "1px solid gray",
                                  }}
                                  striped
                                  bordered
                                  hover
                                >
                                  <thead>
                                    <tr>
                                      <th> Owner Name</th>
                                      <th> Adhar Card Number</th>
                                      <th> Pan Card Number </th>
                                      <th>Address</th>
                                      <th>ID Image</th>
                                      <th>Owner Image</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{element.obj.OwnerName}</td>
                                      <td>{element.obj.OwnerAadharCardNo}</td>
                                      <td>{element.obj.OwnerPanCard}</td>
                                      <td>{element.obj.OwnerAddress}</td>
                                      <td>
                                        {" "}
                                        <img
                                          key={index}
                                          src={
                                            element?.obj.OwnerIDImage
                                              ? `${OwnerIDImageDIR}/${element.obj.OwnerIDImage}`
                                              : ImageNotFound
                                          }
                                          style={{
                                            marginLeft: "10px",
                                            height: 90,
                                            width: 90,
                                            borderWidth: 0,
                                            border: "1px solid #007AB1",
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <img
                                          key={index}
                                          src={
                                            element?.obj.OwnerImage
                                              ? `${OwnerImageDIR}/${element.obj.OwnerImage}`
                                              : ImageNotFound
                                          }
                                          style={{
                                            marginLeft: "10px",
                                            height: 90,
                                            width: 90,
                                            borderWidth: 0,
                                            border: "1px solid #007AB1",
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              }
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <Table
                        style={{
                          textAlign: "left",
                          border: "1px solid gray",
                        }}
                        striped
                        bordered
                        hover
                      >
                        <thead>
                          <th className="p-2">Manager Details</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {
                                <Table
                                  style={{
                                    textAlign: "left",
                                    border: "1px solid gray",
                                  }}
                                  striped
                                  bordered
                                  hover
                                >
                                  <thead>
                                    <tr>
                                      <th> Manager Name</th>
                                      <th> Photo</th>
                                      <th> Address </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{element.obj.ManagerName}</td>
                                      <td>
                                        {" "}
                                        <img
                                          key={index}
                                          src={
                                            element?.obj.ManagerPhoto
                                              ? `${ManagerPhotoDIR}/${element.obj.ManagerPhoto}`
                                              : ImageNotFound
                                          }
                                          style={{
                                            marginLeft: "10px",
                                            height: 90,
                                            width: 90,
                                            borderWidth: 0,
                                            border: "1px solid #007AB1",
                                          }}
                                        />
                                      </td>
                                      <td>{element.obj.ManagerAddress}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              }
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <Table
                        style={{
                          textAlign: "left",
                          border: "1px solid gray",
                        }}
                        striped
                        bordered
                        hover
                      >
                        <thead>
                          <th className="p-2">
                            Stay Certificates And Registration Details
                          </th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {
                                <Table
                                  style={{
                                    textAlign: "left",
                                    border: "1px solid gray",
                                  }}
                                  striped
                                  bordered
                                  hover
                                >
                                  <thead>
                                    <tr>
                                      <th>
                                        {" "}
                                        Is Register With Tourism Department
                                      </th>
                                      {element.obj?.IsRegisterWithTourismDepartment?.toLowerCase() === "yes" && (
                                        <th> Registration ID</th>
                                      )}
                                      {element?.obj?.IsRegisterWithTourismDepartment?.toLowerCase() === "yes" && (
                                        <th> Registration Image </th>
                                      )}
                                      <th> Is Restaurant Available</th>
                                      <th> Police Clearance Certificate </th>
                                      <th> Fire Clearance Certificate </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {
                                          element.obj
                                            .IsRegisterWithTourismDepartment
                                        }
                                      </td>
                                      {element.obj?.IsRegisterWithTourismDepartment?.toLowerCase() === "yes" && (
                                        <td>{element.obj.RegistrationID}</td>
                                      )}
                                      {element?.obj?.IsRegisterWithTourismDepartment?.toLowerCase() === "yes" && (
                                        <td>
                                          {" "}
                                          <img
                                            key={index}
                                            src={
                                              element?.obj.RegistrationImage
                                                ? `${RegistrationImageDIR}/${element.obj.RegistrationImage}`
                                                : ImageNotFound
                                            }
                                            style={{
                                              marginLeft: "10px",
                                              height: 90,
                                              width: 90,
                                              borderWidth: 0,
                                              border: "1px solid #007AB1",
                                            }}
                                          />
                                        </td>
                                      )}
                                      <td>
                                        {element.obj.IsRestaurantAvailable}
                                      </td>
                                      <td>
                                        <a
                                          target="_blank"
                                          href={`${PCCertificateDIR}${element.obj.PoliceClearanceCertificateFile}`}
                                        >
                                          {element?.obj
                                            .PoliceClearanceCertificateFile && (
                                              <img
                                                height={60}
                                                width={60}
                                                src={PDFImg}
                                                alt=""
                                              />
                                            )}
                                        </a>
                                      </td>
                                      <td>
                                        <a
                                          target="_blank"
                                          href={`${FireNOCDIR}${element.obj.FireNOCFile}`}
                                        >
                                          {element?.obj.FireNOCFile && (
                                            <img
                                              height={60}
                                              width={60}
                                              src={PDFImg}
                                              alt=""
                                            />
                                          )}
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              }
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </td>
                  </tr>

                </>
              ))}
            </tbody>

          </Table>

        </div>
      </div>}
    </>
  );
};

export default ViewHotelDetails;



import Cookies from "js-cookie";
import Swal from "sweetalert2";

export const handleLogout=(navigate)=>{
  const element = document.getElementById('main-wrapper');
    element.classList.add('mini-sidebar');
    element.classList.remove('show-sidebar');
  
    
     Swal.fire({
        title: "Successfully Logged Out!",
        icon: "Success",
        timer: 1500,
showConfirmButton: false,
      });
    Cookies.remove("ckUserID")
    Cookies.remove("ckUserName")
    Cookies.remove("ckUserType")
    navigate("/westKameng/splash")
}


export const compressImage = async (file,quality) => {
  return new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      return
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const maxWidth = 1920; // Adjust maximum width as needed
        const maxHeight = 1080; // Adjust maximum height as needed
        let width = img.width;
        let height = img.height;

        // Calculate new dimensions to fit within maxWidth and maxHeight
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        // Set canvas dimensions
        canvas.width = width;
        canvas.height = height;

        // Draw image on canvas
        ctx.drawImage(img, 0, 0, width, height);

        // Convert canvas to blob
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Failed to compress image.'));
            return;
          }
          resolve(new File([blob], file.name, { type: 'image/jpeg', lastModified: Date.now() }));
        }, 'image/jpeg', quality); // Adjust compression quality as needed (0.5 represents 50% quality)
      };
      img.onerror = (error) => {
        reject(error);
      };
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

import React, { useState, useRef, useEffect } from 'react'
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import { allowOnlyNumbers, allowOnlyCharacters } from '../inputvalidation';
import Swal from "sweetalert2";
import axios from "axios";
import { APIURL } from "../Process";

import { useLocation, useNavigate } from "react-router-dom";
import { compressImage } from './CommonComponents';
import Cookies from 'js-cookie';

const Touroperator = () => {

    const [TourOperatorID, setTourOperatorID] = useState('')
    const navigate = useNavigate();
    const { state } = useLocation();
    console.log(state, "outId")
    
    const [Name, setName] = useState('')
    const [NameError, setNameError] = useState('')

    const [MobileNo1, setMobileNo1] = useState('')
    const [Mobileerror, setMobileerror] = useState('')

    const [MobileNo2, setMobileNo2] = useState('')
    const [Mobileerror2, setMobileerror2] = useState('')

    const [LogoImage, setLogoImage] = useState('')
    const [OldLogoImageName, setOldLogoImageName] = useState('')
    const [error, setError] = useState('')
    const [LogoImageerrorMsg, setLogoImageerrorMsg] = useState(true)

    const [AadharCardImage, setAadharCardImage] = useState('')
    const [OldAadharCardImageName, setOldAadharCardImageName] = useState('')
    const [Adharerror, setAdharerror] = useState('')
    const [AadharCardImageerrorMsg, setAadharCardImageerrorMsg] = useState(true)

    const [AadharCardNo, setAadharCardNo] = useState('')
    const [Adharcarderror, setAdharcarderror] = useState('')

    const [Address, setAddress] = useState('')
    const [Addresserror, setAddresserror] = useState('')

    const form = useRef(FormWithConstraints);
    const params = new URLSearchParams(useLocation().search);

    const ref1 = useRef();
    const ref2 = useRef();


    useEffect(() => {
        if (state != null) {
            EditProfile(state);
        }
    }, []);

    const handleNameChange = (e) => {
        setNameError('')
        setName(e.target.value);
    }
    const handleMobileNoChange = (e) => {
        if (e.target.value.length ==10   ) {
            setMobileerror('');
        }else{
            setMobileerror('Please Enter 10 Digit Mobile Number');
        }

        const inputReferMobileNumber = e.target.value;
        const MoNo = inputReferMobileNumber.replace(/\D/g, '');
        setMobileNo1(MoNo);
    }
    const handleMobileNoChange2 = (e) => {
        if (e.target.value.length ==10 || !e.target.value.length  ) {
            setMobileerror2('');
        }else{
            setMobileerror2('Please Enter 10 Digit Mobile Number');
        }
        const inputReferMobileNumber = e.target.value;
        const MoNo = inputReferMobileNumber.replace(/\D/g, '');
        console.log(inputReferMobileNumber,MoNo,"klklklklkl")
        setMobileNo2(MoNo);
    }

    const handleAddressChange = (e) => {
        if(e.target.value.length){
            setAddresserror('')
        }else{
            setAddresserror('*');
        }
        setAddress(e.target.value)
    }
    const handleAadharCardNoChange = (e) => {
        if(e.target.value.length==12 || !e.target.value.length){
            setAdharcarderror('')
        }else{
            setAdharcarderror('Please Enter 12-Digit Aadhar Card Number');
        }
        setAadharCardNo(e.target.value);
    }
    const HandleLogoImage = (e) => {
        if(e.target.value==""){
            setError('')    
            setLogoImageerrorMsg(true)
            setLogoImage("")
            return         
        }
        setError('')
        if (['png', 'jpg', 'jpeg', 'PNG'].includes(e.target.value.split('.')[e.target.value.split('.').length - 1])) {
            setLogoImageerrorMsg(true)
        }
        else {
            setLogoImageerrorMsg(false)
        }
        if (e.target.files.length > 0) {
            setLogoImage(e.target.files);
        }
        // setOldLogoImageName('')
    }
    const HandleAdharImage = (e) => {
        console.log(e.target.value,"klkklklklklklklklklkl")
        if(e.target.value==""){
            setAdharerror('')    
            setAadharCardImageerrorMsg(true)
            setAadharCardImage("")
            return         
        }
        setAdharerror('')
        if (['png', 'jpg', 'jpeg', 'PNG'].includes(e.target.value.split('.')[e.target.value.split('.').length - 1])) {
            setAadharCardImageerrorMsg(true)
        }
        else {
            setAadharCardImageerrorMsg(false)
        }
        if (e.target.files.length > 0) {
            setAadharCardImage(e.target.files);
        }
        // setOldAadharCardImageName('')
    }

    const onSubmitClick = async (e) => {
        e.preventDefault();
        var allvalid = true;

        if (Name.trim() !== '') {
            setNameError('');
        } else {
            setNameError('*');
            allvalid = false;
        }

        if (LogoImage.length === 0 && !OldLogoImageName) {
            setError('*');
            allvalid = false;
        } else {
            setError('');
        }

        if (/^\d{10}$/.test(MobileNo1) && MobileNo1.length === 10) {
            setMobileerror('');
        } else {
            if (MobileNo1.trim() === '') {
                setMobileerror('*');
            } else {
                setMobileerror('Please Enter 10 Digit Mobile Number');
            }
            allvalid = false;
        }

        if (MobileNo2.length !== 10 && MobileNo2.length) {
            setMobileerror2('Please Enter 10 Digit Mobile Number');
            allvalid = false;
        }

        if(!LogoImageerrorMsg || !AadharCardImageerrorMsg){
            allvalid = false;
        }

        // if (AadharCardImage.length === 0 && !OldAadharCardImageName) {
        //     setAdharerror('*');
        //     allvalid = false;
        // } else {
        //     setAdharerror('');
        // }

        if (Address.trim() !== '') {
            setAddresserror('');
        } else {
            setAddresserror('*');
            allvalid = false;
        }

        if (AadharCardNo.trim() == '' || AadharCardNo.length === 12) {
            setAdharcarderror('');
        } else {
            // if (AadharCardNo.trim() === '') {
            //     setAdharcarderror('*');
            // } else {
                setAdharcarderror('Please Enter 12-Digit Aadhar Card Number');
            // }
            allvalid = false;
        }

        if (allvalid) {
            var form = new FormData();
            if (TourOperatorID) {
                form.append('TourOperatorID', TourOperatorID);
                form.append('Name', Name);
                form.append('MobileNo1', MobileNo1);
                form.append('MobileNo2', MobileNo2);
                form.append('OldLogoImageName', OldLogoImageName);

                for (let i = 0; i < LogoImage.length; i++) {
                    form.append('LogoImage', LogoImage[i] ? !(LogoImage[i] instanceof Blob) ? LogoImage[i] : await compressImage(LogoImage[i], 0.9) : "");
                }

                form.append('AadharCardNo', AadharCardNo);
                form.append('OldAadharCardImageName', OldAadharCardImageName);
                for (let i = 0; i < AadharCardImage.length; i++) {
                    form.append('AadharCardImage', AadharCardImage[i] ? (!(AadharCardImage[i] instanceof Blob) ? AadharCardImage[i] : await compressImage(AadharCardImage[i], 0.9)) : "");
                }
                form.append('Address', Address);
                // form.append('EntryByUserType', Cookies.get("ckUserType"));
                // form.append('EntryByUserID', Cookies.get("ckUserID"));
                // form.append('EntryByName', Cookies.get("ckUserName"));
                form.append('UpdatedByUserType', Cookies.get("ckUserType"));
                form.append('UpdatedByUserID', Cookies.get("ckUserID"));
                form.append('UpdatedByName', Cookies.get("ckUserName"));
            } else {
                form.append('TourOperatorID', "");
                form.append('Name', Name);
                form.append('MobileNo1', MobileNo1);
                form.append('MobileNo2', MobileNo2);
                form.append('LogoImage', LogoImage[0]);
                form.append('AadharCardNo', AadharCardNo);
                form.append('AadharCardImage', AadharCardImage[0]);
                form.append('Address', Address);
                form.append('EntryByUserType', Cookies.get("ckUserType"));
                form.append('EntryByUserID', Cookies.get("ckUserID"));
                form.append('EntryByName', Cookies.get("ckUserName"));
                // form.append('UpdatedByUserType', "");
                // form.append('UpdatedByUserID', "");
                // form.append('UpdatedByName', "");
            }

            const config = { headers: { "Content-Type": "multipart/form-data" } };
            axios
                .post(`${APIURL}/TourOperator/setTourOperator`, form, config)
                .then((res) => {
                    console.log(res, "res");
                    if (res.data.status === 1) {
                        if (TourOperatorID) {
                            Swal.fire({
                                title: 'Successfully Updated', icon: "success", timer: 1500,
                                showConfirmButton: false
                            });
                        } else {
                            Swal.fire({
                                title: 'Successfully Inserted', icon: "success", timer: 1500,
                                showConfirmButton: false
                            });
                        }
                    }
                    navigate("/westKameng/viewTourOperator");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }


    const EditProfile = (id) => {
        var data = JSON.stringify({
            "TourOperatorID": id,
            "Name": "",
            "MobileNo": "",
            "AadharCardNo": "",
            "StartDate": "",
            "EndDate": "",
            "IsActive": "",
            "IsDelete": "false",
            "IsPagination": "true",
            "PerPage": "1",
            "UniqueID": ""
        });

        const config = { headers: { "Content-Type": "application/json" } };
        axios
            .post(`${APIURL}/TourOperator/getTourOperator`, data, config)
            .then((response) => {
                setTourOperatorID(response.data.data[0].TourOperatorID);
                setName(response.data.data[0]?.Name || '');
                setMobileNo1(response.data.data[0]?.MobileNo1 || '');
                setMobileNo2(response.data.data[0]?.MobileNo2 || '');
                setOldLogoImageName(response.data.data[0].LogoImage || "");
                setOldAadharCardImageName(response.data.data[0].AadharCardImage || "");
                setAddress(response.data.data[0]?.Address || '');
                setAadharCardNo(response.data.data[0]?.AadharCardNo || '');
                // setAadharCardImage(e.target.files);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const OnCancelClick = () => {
        setName('')
        setNameError('')
        setMobileNo1('')
        setMobileerror('')
        setMobileerror2('')
        setMobileNo2('')
        setMobileerror2('')
        setAddress('')
        setAddresserror('')

        setOldLogoImageName('')
        setLogoImageerrorMsg(true)
        ref1.current.value = ''
        setError("")
        setOldAadharCardImageName('')
        // setAdharerror('')
        setAadharCardImageerrorMsg(true)
        ref2.current.value = ''

        setAadharCardNo('')
        setAdharcarderror('')
    }

    return (
        <div className="body-wrapper" style={{ backgroundColor: "#d3d3d3", minHeight: "100vh" }} >
            <div className="container-fluid">
                <div className="card" style={{ boxShadow: "2px 3px 15px" }}>
                    <div className="px-3 pt-4 border-bottom">
                        <h5>Add Tour Operator</h5>
                    </div>
                    <FormWithConstraints
                        ref={form}
                        onSubmit={onSubmitClick}
                        noValidate>
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className="form-group">
                                        <label htmlFor="Name" className="form-label fw-semibold"> Name<span className="text-danger"> *</span></label>
                                        <input type="Name" onChange={handleNameChange} required value={Name} maxLength={100} onKeyPress={allowOnlyCharacters} type1="textnm" className="form-control" placeholder="Enter Name"
                                            style={{ borderColor: NameError ? 'red' : 'green' }} ></input>
                                        <span className='text-danger'>{NameError}</span>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className="form-group">
                                        <label htmlFor="MobileNo" className="form-label fw-semibold">Mobile No 1<span className="text-danger"> *</span></label>
                                        <input type="MobileNo" onChange={handleMobileNoChange} required value={MobileNo1} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Mobile No 1" style={{ borderColor: Mobileerror ? 'red' : 'green' }} ></input>
                                        <p className='text-danger'>{Mobileerror}</p>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className="form-group">
                                        <label htmlFor="MobileNo2" className="form-label fw-semibold">Mobile No 2</label>
                                        <input type="MobileNo2" onChange={handleMobileNoChange2} required value={MobileNo2} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Mobile No 2" style={{ borderColor: Mobileerror2 ? 'red' : 'green' }} ></input>
                                        <p className='text-danger'>{Mobileerror2}</p>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className="form-group">
                                        <label htmlFor="Address" className="form-label fw-semibold"> Address <span className="text-danger"> *</span></label>
                                        <textarea name="text" onChange={handleAddressChange} required value={Address} rows={2} cols={40} className="form-control" placeholder="Enter Address" style={{ borderColor: Addresserror ? 'red' : 'green' }} />
                                        <span className='text-danger'>{Addresserror}</span>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="fileInput" className="form-label fw-semibold">Profile <span className="text-danger"> *</span></label>
                                    <input type="file" ref={ref1} className="form-control" onChange={HandleLogoImage} accept="image/jpeg, image/png, image/jpg, image/PNG"
                                        style={{ border: `1px solid ${((error || !LogoImageerrorMsg)) ? "red" : onSubmitClick ? "green" : "light-gray"}` }}
                                    />
                                    {OldLogoImageName !== "" && (<label style={{ color: "Red" }}>{OldLogoImageName ? OldLogoImageName : ""} </label>)}
                                    {!LogoImageerrorMsg && <span className="text-danger">Please Enter Only Image File</span>}
                                    {error && <p className='text-danger'>{error}</p>}
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="fileInput" className="form-label fw-semibold">Aadhar Card Image </label>
                                    <input type="file" ref={ref2} className="form-control" onChange={HandleAdharImage} accept="image/jpeg, image/png, image/jpg, image/PNG" style={{ border: `1px solid ${((Adharerror || !AadharCardImageerrorMsg)) ? "red" : onSubmitClick ? "green" : "light-gray"}` }} />
                                    {OldAadharCardImageName !== "" && (<label style={{ color: "Red" }}>{OldAadharCardImageName ? OldAadharCardImageName : ""} </label>)}
                                    {!AadharCardImageerrorMsg && <span className="text-danger">Please Enter Only Image File</span>}
                                    {Adharerror && <p className='text-danger'>{Adharerror}</p>}
                                </div>

                                <div className='col-md-3'>
                                    <div className="form-group">
                                        <label htmlFor="AadharCardNo" className="form-label fw-semibold">Aadhar Card No <span>(ex.265385644663)</span></label>
                                        <input type="AadharCardNo" onChange={handleAadharCardNoChange} required value={AadharCardNo} maxLength={12} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Aadhar Card No" style={{ borderColor: Adharcarderror ? 'red' : 'green' }}></input>
                                        <span className='text-danger'>{Adharcarderror}</span>
                                    </div>
                                </div><br></br>
                            </div><br></br>

                            <div className="d-flex align-items-center gap-2">
                                <button type="button" onClick={onSubmitClick} className="btn btn-outline-primary font-weight-bolder ml-2 ">Submit</button>
                                <button type="button" onClick={OnCancelClick} className="btn btn-outline-danger font-weight-bolder ml-2">Cancel</button>
                                <button onClick={() => { navigate("/westKameng/viewTourOperator") }} type="button" className="btn btn-outline-primary font-weight-bolder ml-2" >View</button>
                            </div>
                        </div>
                    </FormWithConstraints>
                </div>
            </div >
        </div >
    )
}

export default Touroperator

import React, { useContext } from 'react'
import { placeContext } from '../Context/PlaceContext'
import { Navigate, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';

const HotelAccess = ({children}) => {

    const {state}=useLocation()



    if(Cookies.get("ckUserType")==="Admin" || (Cookies.get("ckUserType")==="Hotel" && state)     ){
    return children     
    }else{
        return <Navigate to={"/"} />
    }

}

export default HotelAccess
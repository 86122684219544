import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import { APIURL, SHOWPASSWORDPIN } from '../Process'

const ShowPasswardModel = ({openModel,setOpenModel,HotelID,UserType,RestaurantID,UserID}) => {


    const [pin,setPin]=useState("")
    const [password,setPassword]=useState("")
    const [currentState,setCurrentState]=useState("")
   
    const [isVerify,setIsVerify]=useState(false)
    const [pinError, setPinError] = useState('');
    const handleVerifyPin = () => {
        setIsVerify(true)
        // You can implement your logic to check if the pin is correct
        // For example, compare it with a predefined pin
        const correctPin = SHOWPASSWORDPIN;
    
        if (pin === correctPin) {
            setCurrentState("success")
          // Set the correct password here
        }else if(!pin.length){
    
         setCurrentState("info")
        }else {
       
          setCurrentState("danger")
        }
      };
    const handlePinChange = (e) => {
    let lastInputString = e.target.value[e.target.value.length - 1];
     
        if (
            e.target.value == "" ||
            (lastInputString.charCodeAt() >= 48 && lastInputString.charCodeAt() <= 57)
          ) {
            setPin(e.target.value);
          }
     
      setPinError('');
    };
    useEffect(()=>{
     getShowPassword()
    },[])
    const getShowPassword=async()=>{
      try{
        const res=await fetch(`${APIURL}/Common/ShowPassword`,{
            method:"post",
            headers:{
              "Content-Type":"application/json"
            },
            body:JSON.stringify({
                "UserType": UserType ,
                "HotelID": HotelID || "",
                "RestaurantID": RestaurantID || "",
                "UserID":UserID ||  ""
            })
          })

          const result=await res.json()
          setPassword(result?.data[0]?.Password)
        }catch(err){
          console.log(err)
        }
          
          
    }
     
  return (
    <Modal show={openModel} backdrop="static" keyboard={false} centered>
    <Modal.Header >
      <Modal.Title>Enter Your Pin</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group controlId="pinInput">
          <Form.Label>Pin:</Form.Label>
          <Form.Control
          maxLength="4" 
            type="text"
            placeholder="Enter 4-digit pin"
            value={pin}
            onChange={handlePinChange}
            isInvalid={!!pinError}
          />
          <Form.Control.Feedback type="invalid">{pinError}</Form.Control.Feedback>
        </Form.Group>
      </Form>
      {currentState==="success"  &&  (
        <Alert variant="success">
          <strong>Password:</strong> {password}
        </Alert>
      )}
       {currentState==="danger" && (
        <Alert variant="danger">
          <strong>Invalid Pin</strong>
        </Alert>
      )}
        {currentState==="info" && (
        <Alert variant="info">
          <strong>Please Enter Pin</strong>
        </Alert>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() =>{ setOpenModel(false);setIsVerify(false);setPassword("")}}>
        Close
      </Button>
      <Button variant="primary" 
      onClick={handleVerifyPin}
      >
        Verify
      </Button>
    </Modal.Footer>
  </Modal>
  )
}

export default ShowPasswardModel
import React, { Component } from "react";
import { appTitle, appTitleCapital } from "../../Constants";
import { Button } from "react-bootstrap";
import { Navigate, useParams, useSearchParams } from "react-router-dom";

const Information = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const PramsValue=searchParams.get("plateform")
  console.log("PramsValue",PramsValue)
  return (
    <>
      <div className="ppsbody">
        <style>
          {`
                          .ppsbody {
                            margin: 0;
                            padding: 0;
                        }
                        
                        .navbar {
                            display: block;
                            align-items: center;
                            justify-content: center;
                            position: sticky;
                            top: 0;
                            cursor: pointer;
                            padding: 0px 20px;
                        }
                        
            
                        
                        .nav-list {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            margin-bottom:-3px
                        }
                        
                        .nvalistcss{
                            padding-left: 0px !important;
                        }
                        
                        .logo {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        
                        .logo img {
                            width: 100px !important;
                            /* background: white; */
                            padding: 9px;
                            height: 100px !important;
                        }
                        
                        .nav-list li {
                            list-style: none;
                            padding: 18px 19px;
                            font-weight: 500;
                            color: black !important;
                            font-size: 19px;
                            font-family: 'Times New Roman';
                        }
                        
                        .wrapper {
                            margin-top: 15px;
                        }
                        
                        .container {
                            width: 95%;
                            padding-right: 15px;
                            padding-left: 15px;
                            margin-right: auto;
                            margin-left: auto;
                        }
                        
                        body {
                            /* font-family: 'Times New Roman', Times, serif; */
                            font-family: 'Roboto', sans-serif;
                        }
                        
                        h2 {
                            font-size: 21px;
                            font-weight: bold;
                            color: #505458;
                            margin: 10px 0;
                            line-height: 35px;
                        }
                        
                        .pheading {
                            font-weight: bold;
                        }
                        
                        p {
                            color: #000;
                            text-align: justify !important;
                            line-height: 1.6;
                            margin: 0 0 10px;
                            font-size: 14px;
                        }
                        
                        #kt_footer,
                        #kt_header {
                            display: none !important;
                        }
                        `}
        </style>

        <nav className="navbar background">
          <ul className="nav-list nvalistcss">
            <div className="logo mx-auto ">
              <img
                className="text-center"
                src={require("../../Assets/logo.jpg")}
                style={{ width: "100px" }}
                alt="logo"
              />
            </div>

            {/* <li>{appTitleCapital}</li> */}
          </ul>
          <div className="page-title text-center">
            <h1>{appTitleCapital}</h1>
          </div>
        </nav>

        {/* <div className="wrapper">
                    <div className="container"> */}
        {/* <div style={{height:"10px",width:"90%",margin:"auto" ,backgroundColor:"black"}} /> */}

        <div className="">
          <div className="">
            <div className="row">
              <div className="col-sm-12">
                <div className="btn-group pull-right m-t-25"></div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      {/* <div className="page-title text-center">
                                                    <h1>{appTitleCapital}</h1>
                                                </div> */}
                      <p className="pheading fs-4    ">About Yakatopia</p>
                      <p style={{ color: "rgb(89 84 84)" }}>
                        This app is dedicated to enhancing tourism services,
                        ensuring transparency, and providing seamless access to
                        government offerings. App to significantly impact public
                        engagement, transparency, and overall tourism
                        development in West Kameng District.
                      </p>
                      <hr className="my-3" />

                      <p className="pheading fs-4  ">Other Link</p>
                      <p>
                        <a target="_blank" href="https://arunachalilp.com/">
                        <Button 
                        >🔗 ILP</Button>
                        </a> &nbsp;
                        <a target="_blank" href="https://arunachaltourism.com/protected-area-permit/"><Button> 🔗 PAP (Protocted Area Permit)</Button></a>
                      </p>
                      <hr className="my-3" />
                      <p className="pheading fs-4">Store Link</p>
                      <p>
                    { PramsValue !="ios" && <a target="_blank" href="https://play.google.com/store/apps/details?id=sm.yakatopia"><img
                          
                          className="cursor-pointer"
                          width={"170px"}
                          src={require("../../Assets/play_store.png")}
                          alt="play store"
                        />
                        </a>}
                        &nbsp;
                        <a target="_blank" href="https://apps.apple.com/app/id6479197812">
                        <img
                          className="cursor-pointer"
                          width={"170px"}
                          src={require("../../Assets/apple-store.png")}
                          alt="apple store"
                        />
                        </a>
                      </p>
                      <hr className="my-3" />

                      <p className="pheading fs-4  ">Security</p>
                      <p style={{ color: "rgb(89 84 84)" }}>
                        We are concerned about safeguarding the confidentiality
                        of your information. We provide physical, electronic,
                        and procedural safeguards to protect information we
                        process and maintain. For example, we limit access to
                        this information to authorized employees and contractors
                        who need to know that information in order to operate,
                        develop or improve our Application. Please be aware
                        that, although we endeavor provide reasonable security
                        for information we process.
                      </p>
                      <p style={{ color: "rgb(89 84 84)" }}>
                        If we decide to change our privacy policy, we will post
                        those changes on this page so that you are always aware
                        of what information we collect, how we use it, and under
                        what circumstances we disclose it.
                      </p>
                      <p style={{ color: "rgb(89 84 84)" }}>
                        If you choose to use our Service, then you agree to the
                        collection and use of information in relation to this
                        policy. The Personal Information that we collect is used
                        for providing and improving the Service. We will not use
                        or share your information with anyone except as
                        described in this Privacy Policy.
                      </p>
                      <hr className="my-3" />
                      <p className="pheading fs-4  ">Contact us</p>
                      <p style={{ color: "rgb(89 84 84)" }}>
                        If you have any questions regarding privacy while using
                        the Application, or have questions about our practices,
                        please contact us via mail at{" "}
                        <span className="pheading fs-4  ">
                          info@smtechno.com
                        </span>
                        .
                      </p>
                      <p style={{ color: "rgb(89 84 84)" }}>
                        All rights are reserved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Information;

import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { APIURL } from '../../../Process'

import Cookies from 'js-cookie'

const AddNearByToilet = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const [hasSubmit, setHasSubmit] = useState(false)
  const [isCancle, setIsCancle] = useState(false)

  const [formData, setFormData] = useState({

    address: {
      textValue: state?.Address || "",
      required: true
    },

    latitude: {
      textValue: state?.Latitude || "",
      required: true
    },
    longitude: {
      textValue: state?.Longitude || "",
      required: true
    },


  })
  const handleInputChange = (e) => {
    let lastInputString = e.target.value[e.target.value.length - 1]

    if (e.target.name == "latitude" || e.target.name == "longitude") {
      if ((e.target.value == "" || lastInputString.charCodeAt() >= 48 && lastInputString.charCodeAt() <= 57)) {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.value
          }
        })
      } else {
        return
      }
    }
    setFormData({
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        textValue: e.target.value
      }
    })
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    setHasSubmit(true)


    let validFormDetailsFlag = true
    for (let key in formData) {

      if (formData[key].required) {
        if (formData[key].textValue == "") {
          validFormDetailsFlag = false
        }
        if (!validFormDetailsFlag) {
          return
        }
      }

    }
    if (formData.latitude.textValue > 90 || formData.latitude.textValue < -90 || formData.longitude.textValue > 180 || formData.longitude.textValue < -180) {
      return
    }  

    const data = {
      NearByToiletID: state?.NearByToiletID || "",
      Address: formData.address.textValue || "",
      Latitude: formData.latitude.textValue || "",
      Longitude: formData.longitude.textValue || "",
      CreatedByUserID: Cookies.get("ckUserID"),
      UpdatedByUserID: Cookies.get("ckUserID"),
    };

    const res = await fetch(`${APIURL}/NearByToilet/setNearByToilet`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await res.json();

    if (result.status === 1) {
      Swal.fire({
        title: state ? "Data Updated successfully!" : "Data Inserted successfully!",
        icon: "success",
        timer: 1500,
        showConfirmButton: false
      });
      navigate("/westKameng/viewNearByToilet");
    } else {
      Swal.fire({
        title: result?.message || "Error occurred!",
        icon: "error",
        timer: 1500,
        showConfirmButton: false
      });
    }
    
  }
const handleCancleFrom = (e) => {
  setFormData({
    address: {
      textValue: "",
      required: true
    },
    latitude: {
      textValue: "",
      required: true
    },
    longitude: {
      textValue: "",
      required: true
    },
   
  })
  setIsCancle(true)
}
return (
  <div className='body-wrapper' style={{ backgroundColor: "#d3d3d3", minHeight: "100vh" }}   >
    <div className='container-fluid' >
      <div className='row'>
        <div className='col-md-12'>
          <div className="card mt-4" style={{ boxShadow: "2px 3px 15px" }}>
            <div className="px-3 pt-4 border-bottom">
              <h5>Near By Toilet</h5>
            </div>
            <div className="card-body">
              <form onSubmit={handleFormSubmit} >
                <div className='row'>
             
                 
                  <div className='col-md-3 mb-3  '>
                    <div className="form-group">
                      <label className="form-label fw-semibold"> Latitude <span className='text-danger'> *</span></label>
                      <input style={{ border: `1px solid ${(hasSubmit && (!formData.latitude.textValue  || formData.latitude.textValue > 90 || formData.latitude.textValue < -90)) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }}
                        onKeyPress={(e) => {
                          if (e.key == "e") {
                            e.preventDefault()
                          }
                        }} value={formData.latitude.textValue} onChange={handleInputChange} name='latitude' type="number" className="form-control" placeholder="Enter Latitude"></input>
                      {/* {hasSubmit && !formData.latitude.textValue && <span className='text-danger'>*</span>} */}
                      {hasSubmit && (!formData.latitude.textValue || formData.latitude.textValue > 90 || formData.latitude.textValue < -90) && <span className='text-danger'>Lattitude must be in range of -90 to 90</span>}
                    </div>
                  </div>
                  <div className='col-md-3 mb-3 '>
                    <div className="form-group">
                      <label className="form-label fw-semibold"> Longitude <span className='text-danger'> *</span> </label>
                      <input style={{ border: `1px solid ${(hasSubmit && (!formData.longitude.textValue  || formData.longitude.textValue > 90 || formData.longitude.textValue < -90)) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }}
                        onKeyPress={(e) => {
                          if (e.key == "e") {
                            e.preventDefault()
                          }
                        }} value={formData.longitude.textValue} onChange={handleInputChange} name='longitude' type="number" className="form-control" placeholder="Enter Longitude"></input>
                      {/* {hasSubmit && !formData.longitude.textValue && <span className='text-danger'>*</span>} */}
                      {hasSubmit && (!formData.longitude.textValue  || parseFloat(formData.longitude.textValue) > 180 || parseFloat(formData.longitude.textValue) < -180) && <span className='text-danger'>Longitude must be in range of -180 to 180</span>}
                    </div>
                  </div>
                  <div className='col-md-3 mb-3 '>
                    <div className='form-group'>
                      <label className='form-label fw-semibold'>
                        Address
                        <span className='text-danger'> *</span>
                      </label>
                      <textarea value={formData.address.textValue} onChange={handleInputChange} name='address' style={{ height: "60px", border: `1px solid ${(hasSubmit && !formData.address.textValue) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }} rows="2" cols="20" placeholder="Enter Address" className='form-control' />
                      {hasSubmit && !formData.address.textValue && <span className='text-danger'>*</span>}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2 mt-4">
                  <button type="submit" className="btn btn-outline-primary font-weight-bolder ml-2 ">Submit</button>
                  <button type="button" onClick={handleCancleFrom} className="btn btn-outline-danger font-weight-bolder ml-2" >Cancel</button>
                  <button type="button" onClick={() => navigate("/westKameng/viewNearByToilet")} className="btn btn-outline-primary font-weight-bolder ml-2">View</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


)
}

export default AddNearByToilet
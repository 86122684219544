import React, { useEffect, useState } from "react";
import { Accordion, Table } from "react-bootstrap";

import { APIURL, IMAGEMODELURL } from "../Process";
import EyeOpen from "../Assets/eyeopen.png";
import EyeClose from "../Assets/eyeclose.png";
import Edit from "../Assets/Edit.png";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import ImageNotFound from "../Assets/Noimage.png"
import Cookies from "js-cookie";

const ViewAboutUs = () => {
  const navigate = useNavigate();
  
  const [isLoading, setIsLoading] = useState(true);
  const [historyData, setHistoryData] = useState();
  const AboutUsImageDIR = `${IMAGEMODELURL}/AboutUs`;
  useEffect(() => {
    getAboutUsDetails();
  }, []);
  const getAboutUsDetails = async () => {
    setIsLoading(true)
    try {
      const res = await fetch(
        `${APIURL}/AboutUs/GetAboutUs`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "AboutUsID": ""
          }),
        }
      );
      const result = await res.json();
      if (result?.data?.length) {
        const tempt = [{
          obj: result.data[0],
          isDetails: false,
        }];
        setHistoryData(tempt);
      }
      else {
        setHistoryData([]);

      }
      setIsLoading(false)

    } catch (err) {
      setIsLoading(false)
      console.log(err);
    }
  };
  const handleEyeOn = (index) => {
    console.log("eyeon");
    const tempt = [...historyData];
    tempt.splice(index, 1, {
      ...historyData[index],
      isDetails: true,
    });
    setHistoryData(tempt);
  };
  const handleEyeOff = (index) => {
    const tempt = [...historyData];
    tempt.splice(index, 1, {
      ...historyData[index],
      isDetails: false,
    });
    setHistoryData(tempt);
  };
  const editHistoryDetails = (element) => {
    navigate("/westKameng/addAboutUs", {
      state: element.obj,
    });
  };
  const deleteImageDetails = async (element) => {
    console.log(element, "element");
    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmImageDelete(element);
      }
    });
  };
  const confirmImageDelete = async (element) => {
    console.log(element, "element");
    try {
      const res = await fetch(`${APIURL}/ImageDetail/DeleteImages`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ImageDetailID: `${element.ImageDetailID}`,
          Image: element.Image,
        }),
      });
      const result = await res.json();
      if (result.status === 1) {
        Swal.fire({
          title: "Successfully Deleted!",
          icon: "success",
          timer: 1500,
showConfirmButton: false,
        });
        // getWestKamengHistory();
      }
      console.log(result, "result");
    } catch (err) {
      console.log(err);
    }
  };
  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: "6",
          left: "600px",
          top: "300px",
        }}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="200"
          height="200"
          visible={true}
        />
      </div>
    );
  }
  return (
    <div className="body-wrapper">
      <div className="container-fluid">
        <Table
          style={{
            marginTop: "15px",
            marginBottom: "15px",
            boxShadow: "2px 3px 15px",
            textAlign: "left",
            border: "1px solid gray",
          }}
          striped
          bordered
          hover
        >
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Image</th>
              <th>Description</th>
              {Cookies.get("ckUserType") == "Admin" && <th>Edit</th>}
            </tr>
          </thead>
          <tbody>
            {historyData?.map((element, index) => (
              <>
                <tr>
                  <td>{index + 1}</td>
                  <td>

                    <img
                      src={
                        element.obj?.AboutUsImage
                          ? `${AboutUsImageDIR}/${element?.obj?.AboutUsImage}`
                          : ImageNotFound
                      }
                      style={{
                        height: 100,
                        width: 100,
                        borderWidth: 0,
                        border: "1px solid #007AB1",
                      }}
                    />

                  </td>
                  <td style={{ width: "70%" }}>
                    <Accordion defaultActiveKey={0}>
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                          {element?.obj?.Title || "Descreption"}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                element?.obj?.Description
                              ),
                            }}
                          ></div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </td>
                  {Cookies.get("ckUserType") == "Admin" && (
                    <td>
                      <img
                        onClick={() => {
                          editHistoryDetails(element);
                        }}
                        src={Edit}
                        height={"25px"}
                        alt=""
                      />
                    </td>
                  )}
                </tr>
              </>
            ))}
          </tbody>
        </Table>
        {!historyData?.length && <div
          style={{
            textAlign: "center",
            padding: "20px",
            fontSize: "20px",
            fontWeight: "bold",
            color: "#ff0000",
            backgroundColor: "#f0f0f0",
            border: "1px solid #e0e0e0",
            borderRadius: "5px",
            width: "100%",
          }}
        >
          NO DATA FOUND
        </div>}
      </div>
    </div>
  );
};

export default ViewAboutUs;

import React from 'react'
import  { useContext } from 'react'
import { placeContext } from '../Context/PlaceContext'
import { Navigate, useLocation } from 'react-router-dom'

import Cookies from 'js-cookie'
const RestaurantAccess = ({children}) => {
   const {isRestaurantEmpty}=useContext(placeContext)
   
    const {state}=useLocation()


    if(Cookies.get("ckUserType")==="Admin" || (Cookies.get("ckUserType")==="Restaurant" && state) || (Cookies.get("ckUserType")==="Hotel" && state) || (Cookies.get("ckUserType")==="Hotel" && isRestaurantEmpty )  ){
    return children     
    }else{
        return <Navigate to={"/"} />
    }
}

export default RestaurantAccess



import React from 'react';
import { Link } from 'react-router-dom';
import './index.css'; // You can style your page with CSS

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1 className="not-found-title">404 - Page Not Found</h1>
        <p className="not-found-message">Oops! The page you're looking for doesn't exist.</p>
       
      </div>
    </div>
  );
};

export default NotFoundPage;
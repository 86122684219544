import React, { useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import { APIURL } from '../../../Process'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from 'moment'
import axios from 'axios'
import { compressImage } from '../../CommonComponents'

const AddWeatherAlertDetails = () => {

  const navigate = useNavigate()
  const imageRef = useRef(null)
  const [validMail, setValidMail] = useState(true)
  const { state } = useLocation()

  const [hasSubmit, setHasSubmit] = useState(false)
  const [isCancle, setIsCancle] = useState(false)

  const [formData, setFormData] = useState({
    Title: {
      textValue: state?.Title || '',
      required: true
    },
    Date: {
      textValue: state?.Date ? moment(state.Date, "DD-MM-YYYY").format("YYYY-MM-DD") : '',
      required: true
    },
    Time: {
      textValue: state?.Time || '',
      required: true
    },
    image: {
      textValue: state?.ImageDetailData || "",
      required: false,
      valid: true,
      count: state?.ImageDetailData?.length || 0
    },
    Description: {
      textValue: state?.Description || '',
      required: true
    }
  })
  const handleInputChange = (e) => {
    // console.log(e.target.value,"e.target.value")
    setFormData({
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        textValue: e.target.value
      }
    })
  }
  
  const handleFormSubmit = async (e) => {
    debugger
    e.preventDefault()
    setHasSubmit(true)
   
    let validFormDetailsFlag = true
    for (let key in formData) {
      // if(state ){

      if (formData[key].required) {
        if (formData[key].textValue == "") {
          validFormDetailsFlag = false
        }
        if (!validFormDetailsFlag) {
          return
        }
      }
    }

    if (formData.image.textValue && !formData.image.valid) {
      return;
    }   
   

    const form = new FormData()
    if (state) {
      form.append("WeatherAlertID", state?.WeatherAlertID)
    }
    form.append("Title", formData.Title.textValue)
    form.append("Date", formData?.Date?.textValue ?  moment(formData?.Date?.textValue).format('DD-MM-YYYY') : "")
    form.append("Time", formData.Time.textValue)
    form.append("Description", formData.Description.textValue)

    for (let i = 0; i < formData.image.textValue.length; i++) {
      form.append('Image', formData.image.textValue[i] ? !(formData.image.textValue[i] instanceof Blob) ? formData.image.textValue[i] : await compressImage(formData.image.textValue[i], 0.9) : "");
    }
   
    if (state) {
      form.append("UpdatedByUserID", Cookies.get("ckUserID"))
      form.append("UpdatedByUserName", Cookies.get("ckUserName"))
    } else {
      form.append("CreatedByUserID", Cookies.get("ckUserID"))
      form.append("CreatedByUserName", Cookies.get("ckUserName"))
    }   
   
    const config = { headers: { "Content-Type": "multipart/form-data" } }
    axios.post(`${APIURL}/WeatherAlertController/setWeatherAlert`, form, config)
      .then((res) => {
        console.log(res, "res")
        if (state && res.data?.status == 1) {
          Swal.fire({
            title: "Data Updated successfully!",
            icon: "success",
            timer: 1500,
            showConfirmButton: false
          });
          navigate("/westKameng/viewWeatherAlertDetails")
        }
        else if (res.data?.status == 1) {
          Swal.fire({
            title: "Data Inserted successfully!",
            icon: "success",
            timer: 1500,
            showConfirmButton: false
          });
          navigate("/westKameng/viewWeatherAlertDetails")

        } else {
          Swal.fire({
            title: res.data.message,
            icon: "success",
            timer: 1500,
            showConfirmButton: false
          });
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleFileChange = (e) => {
    console.log(e.target.files, "e.target.files")
    debugger
    if (e.target.name === "image" && ((state?.ImageDetailData?.length || 0) + e.target.files.length) > 3) {
      Swal.fire({
        title: "Cannot Select More Than 3 Images!",
        icon: "error",
        timer: 1500,
        showConfirmButton: false
      });
      setFormData({
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          textValue: state?.ImageDetailData || "",
          valid: true,
          count: (state?.ImageDetailData?.length || 0)
        }
      })
      return
    }
    console.log(e.target.files, "e.target.value")
    if (["jpg", "png", "jpeg"].includes(e.target.value.split(".")[e.target.value.split(".").length - 1]) || e.target.value === "") {
      if (e.target.name === "image") {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.files,
            valid: true,
            count: (state?.ImageDetailData?.length || 0) + e.target.files.length
          }
        })
      } 

    } else {
      setFormData({
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          textValue: "",
          valid: false
        }
      })
    }
  }

  const handleCancleFrom = (e) => {
    setFormData({
      Title: {
        textValue: '',
        required: true
      },
      Date: {
        textValue: '',
        required: true
      },
      Time: {
        textValue: '',
        required: true
      },
      Description: {
        textValue: '',
        required: true
      },
      image: {
        textValue: "",
        required: false,
        valid: true,
        count: state?.ImageDetailData?.length || formData.image.count
      },
    })
    setIsCancle(true)
  }
  return (
    <div className='body-wrapper' style={{ backgroundColor: "#d3d3d3", minHeight: "100vh" }}   >
      <div className='container-fluid' >
        <div className='row'>
          <div className='col-md-12'>
            <div className="card mt-4" style={{ boxShadow: "2px 3px 15px" }}>
              <div className="px-3 pt-4 border-bottom">
                <h5>Weather Alert Details</h5>
              </div>
              <div className="card-body">
                <form onSubmit={handleFormSubmit} >
                  <div className='row'>
                    <div className='col-md-3 mb-3'>
                      <div className="form-group">
                        <label className="form-label fw-semibold"> Title  <span className='text-danger'>*</span></label>
                        <input maxLength={50} style={{ border: `1px solid ${(hasSubmit && !formData.Title.textValue) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }} name='Title' value={formData.Title.textValue} type="text" onChange={handleInputChange} className="form-control" placeholder="Enter Title"></input>
                        {hasSubmit && !formData.Title.textValue && <span className='text-danger'>*</span>}
                      </div>
                    </div>
                    <div className='col-md-3 mb-3'>
                      <div className="form-group">
                        <label className="form-label fw-semibold"> Date  <span className='text-danger'>*</span></label>
                        <input onKeyDown={(e) => e.preventDefault()} style={{ border: `1px solid ${(hasSubmit && !formData.Date.textValue) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }} name='Date' value={formData.Date.textValue} type="date" onChange={handleInputChange} className="form-control" ></input>
                        {hasSubmit && !formData.Date.textValue && <span className='text-danger'>*</span>}
                      </div>
                    </div>
                    <div className='col-md-3 mb-3'>
                      <div className="form-group">
                        <label className="form-label fw-semibold"> Time  <span className='text-danger'>*</span></label>
                        <input maxLength={50} style={{ border: `1px solid ${(hasSubmit && !formData.Time.textValue) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }} name='Time' value={formData.Time.textValue} type="time" onChange={handleInputChange} className="form-control"></input>
                        {hasSubmit && !formData.Time.textValue && <span className='text-danger'>*</span>}
                      </div>
                    </div>

                    <div className='col-md-3 mb-3'>
                      <label className="form-label fw-semibold">
                        Detail Image
                      </label>
                      <input
                        style={{
                          border: `1px solid ${hasSubmit ? "green" : "light-gray"}` 
                        }}
                        name='image'
                        ref={imageRef}
                        onChange={handleFileChange}
                        type="file"
                        accept='image/jpeg, image/png, image/jpg'
                        multiple
                        className='form-control'
                      />
                      <span>{formData?.image.count} Images Selected</span>
                    </div>

                    {/* <div className='col-md-3 mb-3' >
                      <label className="form-label fw-semibold"> Detail Image    <span className='text-danger'>*</span></label>
                      <input style={{ border: `1px solid ${(hasSubmit && (!formData.image.textValue || !formData.image.textValue.length || !formData.image.valid)) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }} name='image' ref={imageRef} onChange={handleFileChange} type="file" accept='image/jpeg, image/png, image/jpg' multiple className='form-control' />
                      {state && <span >{formData.image.count} Images Selected </span>}
                      {!formData.image.valid && <span className='text-danger'>*please upload valid image </span>}
                      {hasSubmit && (!formData.image.textValue || !formData.image.textValue.length) && <span className='text-danger'>*</span>}
                    </div> */}

                    {<div className='col-md-12'>
                      <div className="form-group" style={{ width: '100%' }}>
                        <label className="form-label fw-semibold"> Description  <span className='text-danger'>*</span></label>
                        <div className="img-thumbnail" style={{
                          border: `1px solid ${hasSubmit && !formData.Description.textValue
                            ? "red"
                            : hasSubmit
                              ? "green"
                              : "light-gray"
                            }`,
                        }}>
                          <CKEditor

                            editor={ClassicEditor}
                            data={formData.Description.textValue}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              console.log(data)
                              // setLetterData(data)
                              setFormData((prev) => ({
                                ...prev,
                                Description: {
                                  ...prev.Description,
                                  textValue: data
                                }
                              }))
                            }}
                          />
                          {hasSubmit && !formData.Description.textValue && (
                            <span className="text-danger">*</span>
                          )}
                        </div>
                        {/* <span>uidahfadsads</span> */}
                      </div>
                    </div>}
                  </div>
                  <div className="d-flex align-items-center gap-2 mt-4">
                    <button type="submit" className="btn btn-outline-primary font-weight-bolder ml-2 ">Submit</button>
                    <button type="button" onClick={handleCancleFrom} className="btn btn-outline-danger font-weight-bolder ml-2" >Cancel</button>
                    <button type="button" onClick={() => navigate("/westKameng/viewWeatherAlertDetails")} className="btn btn-outline-primary font-weight-bolder ml-2">View</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}

export default AddWeatherAlertDetails
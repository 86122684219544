import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { APIURL } from '../../../Process'
import axios from 'axios'

import { RotatingLines } from 'react-loader-spinner'
import { compressImage } from '../../CommonComponents'
import Cookies from 'js-cookie'
import { handleKeyDownNumber, handlePaste } from '../../../HelperMethod'

const AddUserAdminDetails = () => {

  const navigate = useNavigate()
  const [validMail, setValidMail] = useState(true)
  const { state } = useLocation()

  const [hasSubmit, setHasSubmit] = useState(false)
  const [isCancle, setIsCancle] = useState(false)

  const [formData, setFormData] = useState({
    name: {
      textValue: state?.Name || '',
      required: true
    },
    password: {
      textValue: state?.Password || '',
      required: true
    },
    MobileNo: {
      textValue: state?.MobileNo || '',
      required: true
    },
    emailID: {
      textValue: state?.EmailID || '',
      required: false
    },
    address: {
      textValue: state?.Address || "",
      required: false
    }
  })
  const handleInputChange = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (e.target.name == "emailID") {
      console.log(emailRegex.test(e.target.value),"klklklkhjhjh")
      if (!emailRegex.test(e.target.value) && e.target.value.length ) {
        setValidMail(false);
      } else {
        setValidMail(true);
      }
    }
    setFormData({
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        textValue: e.target.value
      }
    })
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault()
    setHasSubmit(true)
    let validMailTempt = true
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.emailID.textValue.length) {
      if (!emailRegex.test(formData.emailID.textValue)) {
        validMailTempt = false
        setValidMail(() => {
          return false;
        });
      } else {
        validMailTempt = true
        setValidMail(() => {
          return true;
        });
      }
    }
    if (!validMailTempt) {
      return
    }
 if(formData.MobileNo.textValue.length!=10){
  return
 }
    let validFormDetailsFlag = true
    for (let key in formData) {
      // if(state ){

      if (formData[key].required) {
        if (formData[key].textValue == "") {
          validFormDetailsFlag = false
        }
        if (!validFormDetailsFlag) {
          return
        }
      }
    }

    const res = await fetch(`${APIURL}/User/setUserAdmin`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserID: state?.UserID || "",
        "Name": formData?.name?.textValue || "",
        "Password": formData?.password?.textValue || "",
        "MobileNo": formData?.MobileNo?.textValue || "",
        "EmailID": formData?.emailID?.textValue || "",
        "Address": formData?.address?.textValue || "",
        "CreatedByUserID": Cookies.get("ckUserID"),
        "CreatedByUserName": Cookies.get("ckUserName")
      }),
    });
    const result = await res.json();

    if (result.status == 1) {
      Swal.fire({
        title: state ? "Data Updated successfully!" : "Data Inserted successfully!",
        icon: "success",
        timer: 1500,
        showConfirmButton: false
      });
      navigate("/westKameng/viewUserAdmin")
    }
    else {
      Swal.fire({
        title: result?.message || "",
        icon: "error",
        timer: 1500,
        showConfirmButton: false
      });
    }

  }
  const handleCancleFrom = (e) => {
    setFormData({
      name: {
        textValue: '',
        required: true
      },
      password: {
        textValue: '',
        required: true
      },
      MobileNo: {
        textValue: '',
        required: true
      },
      emailID: {
        textValue: '',
        required: false
      },
      address: {
        textValue: "",
        required: false
      }
    })
    setIsCancle(true)
  }
  return (
    <div className='body-wrapper' style={{ backgroundColor: "#d3d3d3", minHeight: "100vh" }}   >
      <div className='container-fluid' >
        <div className='row'>
          <div className='col-md-12'>
            <div className="card mt-4" style={{ boxShadow: "2px 3px 15px" }}>
              <div className="px-3 pt-4 border-bottom">
                <h5>UserAdmin Details</h5>
              </div>
              <div className="card-body">
                <form onSubmit={handleFormSubmit} >
                  <div className='row'>
                    <div className='col-md-3 mb-3'>
                      <div className="form-group">
                        <label className="form-label fw-semibold"> Name  <span className='text-danger'>*</span></label>
                        <input maxLength={50} style={{ border: `1px solid ${(hasSubmit && !formData.name.textValue) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }} name='name' value={formData.name.textValue} type="text" onChange={handleInputChange} className="form-control" placeholder="Enter Name"></input>
                        {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>}
                      </div>
                    </div>
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        <label
                          htmlFor="contact"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          Mobile No <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDownNumber}
                          onPaste={handlePaste}
                          name="MobileNo"
                          value={formData.MobileNo.textValue}
                          maxLength={10}
                          type="text"
                          className="form-control"
                          placeholder="Enter Mobile No"
                          style={{ border: `1px solid ${(hasSubmit && (!formData.MobileNo.textValue || formData.MobileNo.textValue.length !== 10)) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }}

                        ></input>
                        {hasSubmit && !formData.MobileNo.textValue && (
                          <span className="text-danger">*</span>
                        )}
                        {hasSubmit && formData.MobileNo.textValue && formData.MobileNo.textValue.length !== 10 && <span className='text-danger'>Please Enter Valid Mobile No</span>}
                      </div>
                    </div>
                    <div className='col-md-3 mb-3'>
                      <div className="form-group">
                        <label className="form-label fw-semibold"> Password  <span className='text-danger'>*</span></label>
                        <input maxLength={50} style={{ border: `1px solid ${(hasSubmit && !formData.password.textValue) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }} name='password' value={formData.password.textValue} type="password" onChange={handleInputChange} className="form-control" placeholder="Enter Password"></input>
                        {hasSubmit && !formData.password.textValue && <span className='text-danger'>*</span>}
                      </div>
                    </div>
                 
                    <div className='col-md-3 mb-3'>
                      <div className="form-group mb-3">
                        <label
                          htmlFor="emailID"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          EmailID<span style={{ color: "gray" }}> (Ex. xyz@gmail.com)</span>
                        </label>
                        <input
                          onChange={handleInputChange}
                          name="emailID"
                          value={formData.emailID.textValue}
                          type="text"
                          className="form-control"
                          placeholder="Enter EmailID"
                          style={{ border: `1px solid ${(hasSubmit && !validMail) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }}
                        ></input>
                        {hasSubmit && !validMail && formData.emailID.textValue && <span className='text-danger'>Please Enter valid Email ID</span>}
                      </div>
                    </div>
                    <div className='col-md-3 mb-3 '>
                      <div className='form-group'>
                        <label className='form-label fw-semibold'>
                          Address
                        </label>
                        <textarea value={formData.address.textValue} onChange={handleInputChange} name='address' style={{ height: "150px", border: `1px solid ${hasSubmit ? "green" : "light-gray"}  ` }} rows="2" cols="20" placeholder="Enter Address" className='form-control' />

                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 mt-4">
                    <button type="submit" className="btn btn-outline-primary font-weight-bolder ml-2 ">Submit</button>
                    <button type="button" onClick={handleCancleFrom} className="btn btn-outline-danger font-weight-bolder ml-2" >Cancel</button>
                    <button type="button" onClick={() => navigate("/westKameng/viewUserAdmin")} className="btn btn-outline-primary font-weight-bolder ml-2">View</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}

export default AddUserAdminDetails
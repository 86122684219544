import { createContext, useState } from "react";



export const placeContext=createContext()

const MyProvider=({children})=>{
  
  const [isRestaurantEmpty,setIsRestaurantEmpty]=useState(false)
  const [menuClass,setMenuClass]=useState({
    place:"",
    stay:"",
    restaurant:"",
    history:"",
    master:""
  })
 
    return(
        <placeContext.Provider value={{menuClass,setMenuClass,setIsRestaurantEmpty,isRestaurantEmpty}} >
          {children}
        </placeContext.Provider>
    )
}

export default MyProvider
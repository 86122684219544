import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { APIURL, IMAGEMODELURL } from "../../Process";
import EyeClose from "../../Assets/eyeclose.png";
import EyeOpen from "../../Assets/eyeopen.png";
import Edit from "../../Assets/Edit.png";
import Delete from "../../Assets/Delete.png";
import PDFImg from "../../Assets/pdf2.png";
import ImageNotFound from "../../Assets/Noimage.png";
import { FiActivity } from "react-icons/fi";
import { MdAssignmentAdd } from "react-icons/md";
import XLSX from "xlsx-color";
import moment from "moment";
import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import { MdDelete } from "react-icons/md";
import { Form } from "react-bootstrap";
import { MdAddCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { FaEyeSlash, FaToggleOff, FaToggleOn } from "react-icons/fa";
import { placeContext } from "../../Context/PlaceContext";
import ShowPasswardModel from "../ShowPasswardModel";
import DOMPurify from "dompurify";
import Cookies from "js-cookie";

const ViewPackageDetails = () => {
  ;
  const PackageMenuDIR = `${IMAGEMODELURL}/Package/Images`;
  const PackageImage= `${IMAGEMODELURL}/Package/PackageImage`
  const [pageCount, setPageCount] = useState("10");
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [allTableData, setAllTableData] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState({
    title: "",
    startDate: "",
    endDate: "",
  });
  useEffect(() => {
    fetchInitialPlaceDetailsData(pageCount);
  }, []);
  const navigate = useNavigate();
  const handleEyeOn = (index) => {
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: true,
    });
    setTableData(tempt);
  };
  const handleEyeOff = (index) => {
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: false,
    });
    setTableData(tempt);
  };

  const fetchInitialPlaceDetailsData = async (count) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${APIURL}/Package/getPackage`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PackageID: "",
          PackageTitle: "",
          StartDate: "",
          EndDate: "",
          IsActive: "",
          IsDelete: "false",
          IsPagination: "true",
          PerPage: count,
          UniqueID: "",
        }),
      });
      const result = await res.json();
      console.log(result, "initialReasult");
      setTotalCount(result.total[0].TotalCount);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      setIsLoading(false);
      setTableData(tempt);
      setPageId(1);
      setAllTableData(tempt);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const confirmPackageDelete = async (id) => {
    console.log(id, "confirmDeleteID");
    try {
      const res = await fetch(`${APIURL}/Package/removePackage`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PackageID: `${id}`,
          RemovedByUserType: Cookies.get("ckUserType"),
          RemovedByUserID: Cookies.get("ckUserID"),
          RemovedByName: Cookies.get("ckUserName"),
        }),
      });
      const result = await res.json();
      if (result.status == 1) {
        Swal.fire({
          title: "Successfully Deleted!",
          icon: "success",
          timer: 1500,
showConfirmButton: false,
        });
        fetchInitialPlaceDetailsData(pageCount);
      }
      console.log(result, "result186");
    } catch (err) {
      console.log(err);
    }
  };
  const handleDeletePlaceDetails = async (id) => {
    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmPackageDelete(id);
      }
    });
  };
  const setNextApi = async () => {
    console.log(pageId, "pageID", pageCount);
    if (pageId * pageCount < allTableData.length) {
      const tempt = [...allTableData];
      console.log(tempt, pageId * pageCount, (pageId + 1) * pageCount);
      const sliced = tempt.slice(pageId * pageCount, (pageId + 1) * pageCount);
      setTableData(sliced);
      setPageId(pageId + 1);
      return;
    }
    const paginationIndex =
      allTableData[pageId * pageCount - 1]?.obj?.PackageID;
    if (!paginationIndex) {
      return;
    }
    console.log(paginationIndex, "paginationIndex");
    try {
      const res = await fetch(`${APIURL}/Package/GetPackage`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PackageTitle: filterData.title,
          StartDate: filterData.startDate?.split("-").reverse().join("-") || "",
          EndDate: filterData.endDate?.split("-").reverse().join("-") || "",
          IsActive: "",
          IsDelete: "false",
          IsPagination: "true",
          PerPage: pageCount,
          UniqueID: paginationIndex,
        }),
      });
      const result = await res.json();
      console.log(result);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      console.log(tempt, "tempt");
      setTableData(tempt);
      setPageId(pageId + 1);
      setAllTableData([...allTableData, ...tempt]);
    } catch (err) {
      console.log(err);
    }
  };
  const setPreviousApi = () => {
    if (pageId == 1) {
      return;
    }
    const tempt = [...allTableData];
    console.log(pageId, "pageId");
    console.log(
      pageId,
      tempt,
      (pageId - 1) * pageCount,
      pageId * pageCount + 1
    );
    const sliced = tempt.slice(
      (pageId - 2) * pageCount,
      (pageId - 1) * pageCount
    );
    setTableData(sliced);
    setPageId(pageId - 1);
  };
  const handlePackageEdit = (element) => {
    navigate("/westKameng/addPackageDetails", {
      state: element.obj,
    });
  };
  const handlePageCount = (e) => {
    setPageCount(e.target.value);
    searchHandle(e.target.value, 0, filterData);
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleFilterInputChange = (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  };
  const searchHandle = async (count, paginationIndex, filterData) => {
    console.log(count, "count", filterData, "filterData");
    console.log("searchHandle", count);

    console.log(filterData, "filterDataaaaaaaaa", count);

    try {
      const res = await fetch(`${APIURL}/Package/getPackage`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PackageTitle: filterData.title,
          StartDate: filterData.startDate?.split("-").reverse().join("-") || "",
          EndDate: filterData.endDate?.split("-").reverse().join("-") || "",
          IsActive: "",
          IsDelete: "false",
          IsPagination: "true",
          PerPage: count,
          UniqueID: "",
        }),
      });
      const result = await res.json();
      console.log(result);
      setTotalCount(result.total[0].TotalCount);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      console.log(tempt, "?tempt");
      setTableData(tempt);
      setAllTableData(tempt);
      setPageId(1);
    } catch (err) {
      console.log(err);
    }
  };
  async function fetchExcelData() {
    setIsLoading(true);
    try {
      const res = await fetch(`${APIURL}/Package/GetPackage`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PackageID: "",
          PackageTitle: filterData.title,
          StartDate: filterData.startDate?.split("-").reverse().join("-") || "",
          EndDate: filterData.endDate?.split("-").reverse().join("-") || "",
          IsActive: "",
          IsDelete: "false",
          IsPagination: "",
          PerPage: "",
          UniqueID: "",

        }),
      });
      const result = await res.json();
      handleDownloadExcel(result.data);
    } catch (err) {
      setIsLoading(false);

      console.log(err);
    }
  }
  const handleDownloadExcel = (array) => {
    console.log(array, "array");
    const header = [
      "PackageTitle",
      "PackageDays",
      "Location",
      "Latitude",
      "Longitude",
      "Route",
  
    ];
    const body = [];
    array?.forEach((element, index) => {
      body.push([
        element.PackageTitle,
        element.PackageDays,
        element.Location,
        element.Latitude,
        element.Longitude,
        element.Route,

      ]);
    });
    if (!body.length) {
      setIsLoading(false);
      return Swal.fire({
        title: "No Data Found!",
        icon: "error",
        timer: 1500,
showConfirmButton: false,
      });
    }

    const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);
    header.forEach((col, index) => {
      ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
        font: { bold: true, size: 14, color: { rgb: "f7f4ef" } },
        fill: { patternType: "solid", fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: "center" },
      };
    });
    ws1["!cols"] = [
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 40 }
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "Package Details");

    setIsLoading(false);
    XLSX.writeFile(wb, `Package${moment().format("DD-MM-YYYY_HH:mm")}.xlsx`);
  };
  const handleCancle = () => {
    setFilterData({
      title: "",
      startDate: "",
      endDate: "",
    });
    fetchInitialPlaceDetailsData(pageCount);
  };
  const confirmImageDelete = async (element) => {
    console.log(element, "element");
    try {
      const res = await fetch(`${APIURL}/ImageDetail/DeleteImages`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ImageDetailID: `${element.ImageDetailID}`,
          ImageType: element.ImageType,
          Image: element.Image,
        }),
      });
      const result = await res.json();
      if (result.status === 1) {
        Swal.fire({
          title: "Successfully Deleted!",
          icon: "success",
          timer: 1500,
showConfirmButton: false,
        });
        searchHandle(pageCount, 0, filterData);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const deleteImageDetails = async (element) => {
    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmImageDelete(element);
      }
    });
  };
  const handlePlaceActive = async (PackageID, status) => {
    console.log(PackageID, "placeID");
    try {
      const res = await fetch(`${APIURL}/Package/updatePackageStatus`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PackageID: PackageID,
          IsActive: `${status}`,
          UpdatedByUserType: Cookies.get("ckUserType"),
          UpdatedByUserID: Cookies.get("ckUserID"),
          UpdatedByName: Cookies.get("ckUserName")
        }),
      });
      const result = await res.json();
      if(result.status==1){
        searchHandle(pageCount, 0, filterData);

      }else{
        Swal.fire({
          title: result.message,
          icon: "error",
          timer: 1500,
showConfirmButton: false,
        });
        return
      }
      console.log(result, "placeActiveStutus");
    } catch (err) {
      console.log(err);
    }
  };
  const handleEmptyPackage = () => {
    navigate("/addPackage");
  };
  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: "6",
          left: "600px",
          top: "300px",
        }}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="200"
          height="200"
          visible={true}
        />
      </div>
    );
  }
  return (
    <>
      {Cookies.get("ckUserType") === "Admin" && (
        <div className="body-wrapper">
          <div className="container-fluid">
            {isLoading && (
              <div
                style={{
                  position: "absolute",
                  zIndex: "6",
                  left: "600px",
                  top: "300px",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="200"
                  height="200"
                  visible={true}
                />
              </div>
            )}
            {/* {openModel && (
              <ShowPasswardModel
                openModel={openModel}
                setOpenModel={setOpenModel}
                PackageID={showPasswordID}
                UserType={"Package"}
              />
            )} */}
            <div
              className={`pt-2 pb-2 place-filter  ${
                isOpen ? "open" : "closed"
              }`}
            >
              <Row>
                <Col md={2} sm={2} className="col-4">
                  <div className="d-flex gap-2 pt-1">
                    <span>Total Count</span>
                    <span>{totalCount}</span>
                  </div>
                </Col>
                <Col md={2} sm={2} className="col-4">
                  <Form.Select
                    onChange={handlePageCount}
                    size="sm"
                    className="w-70"
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    {/* <option value="50"></option> */}
                  </Form.Select>
                </Col>
                <Col md={{ span: 2, offset: 6 }} className="col-4">
                  <Button
                    className="float-end"
                    md={2}
                    variant="outline-primary"
                    size="sm"
                    onClick={handleToggle}
                  >
                    {isOpen ? "Close Filter" : "Open Filter"}
                  </Button>
                </Col>
              </Row>
              {isOpen && (
                <div
                  className="filter-content row"
                  style={{
                    marginTop: "20px",
                    padding: "20px",
                    border: "1px solid gray",
                  }}
                >
                  <Form className="d-flex row pb-4">
                    <div className="form-group mb-2 col-md-3">
                      <label>Title</label>
                      <input
                        onChange={handleFilterInputChange}
                        name="title"
                        value={filterData.name}
                        type="text"
                        className="form-control"
                        placeholder="Enter title"
                      ></input>
                    </div>
                    <div className="form-group mb-2 col-md-3">
                      <label>
                        Start Date
                        <span style={{ color: "#72bdce", fontSize: 10 }} />
                      </label>
                      <input
                        onKeyDown={(e) => e.preventDefault()}
                        value={filterData.startDate}
                        onChange={handleFilterInputChange}
                        name="startDate"
                        type="date"
                        class="form-control"
                        placeholder="dd-MM-yyyy"
                      ></input>
                    </div>
                    <div className="form-group mb-2 col-md-3">
                      <label>
                        End Date
                        <span style={{ color: "#72bdce", fontSize: 10 }} />
                      </label>
                      <input
                        onKeyDown={(e) => e.preventDefault()}
                        min={filterData.startDate}
                        value={filterData.endDate}
                        onChange={handleFilterInputChange}
                        name="endDate"
                        type="date"
                        class="form-control"
                        placeholder="dd-MM-yyyy"
                      ></input>
                      {/* {searched &&
                            filterData.endDate < filterData.startDate && (
                              <span style={{ color: "red" }}>
                                End Date Must be greater than start date
                              </span>
                            )} */}
                    </div>

                    <div
                      className="col-md-3"
                      style={{ position: "relative", top: "27px" }}
                    >
                      <Button
                        size="sm"
                        variant="primary"
                        className="me-2 "
                        onClick={() => {
                          searchHandle(pageCount, 0, filterData);
                        }}
                      >
                        Apply
                      </Button>
                      <Button
                        onClick={() => {
                          fetchExcelData();
                        }}
                        size="sm"
                        className="me-2"
                        variant="success"
                      >
                        Export
                      </Button>
                      <Button
                        onClick={() => {
                          handleCancle();
                        }}
                        size="sm"
                        variant="danger"
                      >
                        Cancel
                      </Button>
                    </div>
                    {/* Add more InputGroup components for additional filters */}
                  </Form>
                </div>
              )}
            </div>
            <div className="overflow-auto">
              <Table
                style={{
                  boxShadow: "2px 3px 15px",
                  textAlign: "left",
                  border: "1px solid gray",
                }}
                striped
                bordered
                hover
              >
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Details</th>
                    <th>Status</th>
                    <th>Package Title</th>
                    <th>Package Days</th>
                    <th>Location</th>
                    <th>Latitude</th>
                    <th>Longitude</th>
                    <th>Route</th>
                    <th>Date</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((element, index) => (
                    <>
                      <tr>
                        <td>{(pageId - 1) * pageCount + (index + 1)}</td>
                        <td>
                          {!element.isDetails && (
                            <img
                              onClick={() => handleEyeOn(index)}
                              src={EyeOpen}
                              height={"22px"}
                              alt=""
                            />
                          )}{" "}
                          {element.isDetails && (
                            <img
                              onClick={() => handleEyeOff(index)}
                              src={EyeClose}
                              height={"22px"}
                              alt=""
                            />
                          )}
                        </td>
                        <td>
                          {" "}
                          {element.obj.IsActive ? (
                            <FaToggleOn
                              onClick={() =>
                                handlePlaceActive(element.obj.PackageID, 0)
                              }
                              style={{ color: "green", fontSize: "1.9em" }}
                            />
                          ) : (
                            <FaToggleOff
                              onClick={() =>
                                handlePlaceActive(element.obj.PackageID, 1)
                              }
                              style={{ color: "red", fontSize: "1.8em" }}
                            />
                          )}
                        </td>
                        <td>{element.obj.PackageTitle}</td>
                        <td>{element.obj.PackageDays}</td>
                        <td>{element.obj.Location}</td>
                        <td>{element.obj.Latitude}</td>
                        <td>{element.obj.Longitude}</td>
                        <td>{element.obj.Route}</td>
                        <td>{element.obj.CreatedDate}</td>
                        <td>
                          <img
                            onClick={() => {
                              handlePackageEdit(element);
                            }}
                            src={Edit}
                            height={"25px"}
                            alt=""
                          />
                        </td>
                        <td>
                          <img
                            src={Delete}
                            onClick={() => {
                              handleDeletePlaceDetails(element.obj.PackageID);
                            }}
                            height={"25px"}
                            alt=""
                          />
                        </td>
                      </tr>
                      {element.isDetails && (
                        <tr>
                          <td colSpan={12}>
                            <Table
                              style={{
                                textAlign: "left",
                                border: "1px solid gray",
                              }}
                              striped
                              bordered
                              hover
                            >
                              <thead>
                                <th className="p-2">Image Details</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{ display: "flex" }}>
                                  <Table
                                      style={{
                                        textAlign: "left",
                                        border: "1px solid gray",
                                        width:"30%"
                                      }}
                                      striped
                                      bordered
                                      hover
                                    >
                                      <thead>
                                        <th className="p-2">Package Image</th>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{ border: "none" }}
                                            className="d-flex"
                                          >
                                                <div
                                                  className="d-flex"
                                                  style={{
                                                    flexDirection: "row",
                                                    width: "100px",
                                                    marginBottom: "10px",
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  <img
                                                    key={index}
                                                    src={
                                                      element.obj.PackageImage
                                                        ? `${PackageImage}/${element.obj.PackageImage}`
                                                        : ImageNotFound
                                                    }
                                                    style={{
                                                      marginLeft: "10px",
                                                      height: 90,
                                                      width: 90,
                                                      borderWidth: 0,
                                                      border:
                                                        "1px solid #007AB1",
                                                    }}
                                                  />
                                                </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                    <Table
                                      style={{
                                        textAlign: "left",
                                        border: "1px solid gray",
                                      }}
                                      striped
                                      bordered
                                      hover
                                    >
                                      <thead>
                                        <th className="p-2">Package Images</th>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{ border: "none" }}
                                            className="d-flex"
                                          >
                                            {" "}
                                            {element.obj.ImageDetail?.map(
                                              (element, index) => (
                                                <div
                                                  className="d-flex"
                                                  style={{
                                                    flexDirection: "row",
                                                    width: "100px",
                                                    marginBottom: "10px",
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  <img
                                                    key={index}
                                                    src={
                                                      element?.Image
                                                        ? `${PackageMenuDIR}/${element.Image}`
                                                        : ImageNotFound
                                                    }
                                                    style={{
                                                      marginLeft: "10px",
                                                      height: 90,
                                                      width: 90,
                                                      borderWidth: 0,
                                                      border:
                                                        "1px solid #007AB1",
                                                    }}
                                                  />
                                                  <span
                                                    onClick={() =>
                                                      deleteImageDetails(
                                                        element
                                                      )
                                                    }
                                                    style={{
                                                      color: "black",
                                                      textAlign: "right",
                                                      fontSize: "1.4em",
                                                      position: "relative",
                                                      right: "20px",
                                                      bottom: "4px",
                                                    }}
                                                  >
                                                    <MdDelete />
                                                  </span>
                                                </div>
                                              )
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                            <Table
                              style={{
                                textAlign: "left",
                                border: "1px solid gray",
                              }}
                              striped
                              bordered
                              hover
                            >
                              <thead>
                                <th className="p-2">Package Detail</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {
                                      <Table
                                        style={{
                                          textAlign: "left",
                                          border: "1px solid gray",
                                        }}
                                        striped
                                        bordered
                                        hover
                                      >
                                        <thead>
                                          <tr>
                                            <th> Days</th>
                                            <th> Title</th>
                                            <th> Description </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {element?.obj?.PackageDetail?.map(
                                            (item, index) => (
                                              <tr>
                                                <td>{item.Days}</td>
                                                <td>{item.Title}</td>
                                                <td>{item.Description}</td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </Table>
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                            <Table
                              style={{
                                textAlign: "left",
                                border: "1px solid gray",
                              }}
                              striped
                              bordered
                              hover
                            >
                              <thead>
                                <th className="p-2">Package Description</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{ display: "flex" }}>
                                    <Table
                                      style={{
                                        textAlign: "left",
                                        border: "1px solid gray",
                                      }}
                                      striped
                                      bordered
                                      hover
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{ border: "none" }}
                                            className="d-flex"
                                          >
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                  element.obj.PackageDescription
                                                ),
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                            <Table
                              style={{
                                textAlign: "left",
                                border: "1px solid gray",
                              }}
                              striped
                              bordered
                              hover
                            >
                              <thead>
                                <th className="p-2">Additional Description</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{ display: "flex" }}>
                                    <Table
                                      style={{
                                        textAlign: "left",
                                        border: "1px solid gray",
                                      }}
                                      striped
                                      bordered
                                      hover
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{ border: "none" }}
                                            className="d-flex"
                                          >
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                  element.obj
                                                    .AdditionalDescription
                                                ),
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
                <tbody>
                  <tr>
                    {!tableData.length && (
                      <td colSpan={11}>
                        <div
                          style={{
                            textAlign: "center",
                            padding: "20px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#ff0000",
                            backgroundColor: "#f0f0f0",
                            border: "1px solid #e0e0e0",
                            borderRadius: "5px",
                            width: "100%",
                          }}
                        >
                          NO DATA FOUND
                        </div>
                      </td>
                    )}
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="pt-2 pb-2">
              <Button variant="primary" onClick={setPreviousApi} size="sm">
                PREVIOUS
              </Button>{" "}
              <Button variant="secondary" onClick={setNextApi} size="sm">
                NEXT
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewPackageDetails;

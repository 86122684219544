import React, { useContext } from 'react'
import { placeContext } from '../Context/PlaceContext'
import { Navigate, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';

const ActivityAccess = ({children}) => {

    const { state } = useLocation()
    if(!state){
        return <Navigate to={"/westKameng/viewplacedetails"} /> 
    }
if(Cookies.get("ckUserType")==="Admin"){
    return children
}else{
    return <Navigate to={"/westKameng/Dashboard"} />
}
}

export default ActivityAccess
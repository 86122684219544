import React, { useEffect, useState } from "react";
import { APIURL } from "../../Process";

import { Button, Col, Row, Table, Form } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
import EyeOpen from "../../Assets/eyeopen.png";
import EyeClose from "../../Assets/eyeclose.png";
import Select from "react-select";

import XLSX from "xlsx-color";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import Swal from "sweetalert2";

const ViewPackageEnquiry = () => {
  ;
  const [totalCount, setTotalCount] = useState();
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState("10");
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [searched,setIsSearched]=useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [packageData, setPackageData] = useState([]);
  const [cancle, setCancle] = useState(false);

  const [filterData, setFilterData] = useState({
    packageName: "",
    startDate: "",
    endDate: "",
    packageTempt: "",
    name:"",
    mobileNo:""
  });
  useEffect(() => {
    getEnquiryData(pageCount);
    getPackageData()
  }, []);
  const getEnquiryData = async (count) => {
    setIsLoading(true);
    try{
    const res = await fetch(`${APIURL}/PackageEnquiry/getPackageEnquiry`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        PackageEnquiryID: "",
        PackageID: "",
        Name: "",
        MobileNo: "",
        StartDate: "",
        EndDate: "",
        IsActive: "",
        IsDelete: "false",
        IsPagination: "true",
        PerPage: count,
        UniqueID: ""
        // HotelID: Cookies.get("ckUserType") === "Hotel" ? Cookies.get("ckUserID") : "",
        // PaginationID: "0",
        // PageLength: count,
      }),
    });

    const result = await res.json();
    const tempt = [];
    setTotalCount(result.total[0].TotalCount);
    result.data.forEach((element) => {
      tempt.push({
        obj: element,
        isDetails: false,
      });
    });
    console.log(tempt, "tempt");
    setIsLoading(false);
    setIsLoading(false);
    setTableData(tempt);
    setPageId(1);
    setAllTableData(tempt);
  }
  catch(err){
    console.log(err,"err")
  }
  };
  const handlePageCount = (e) => {
    setPageCount(e.target.value);
    searchHandle(e.target.value, "");
  };
  const handleEyeOn = (index) => {
    console.log("eyeon");
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: true,
    });
    setTableData(tempt);
  };
  const handleEyeOff = (index) => {
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: false,
    });
    setTableData(tempt);
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const getPackageData = async () => {
    try{
    const res = await fetch(`${APIURL}/Package/getPackage`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "IsActive": "",
        "IsDelete": "false",
        "IsPagination": "",
        "PerPage": "",
        "UniqueID": ""
      }),
    });
    const result = await res.json();
    console.log(result, "result");

    setPackageData(
      result.data.map((item) => ({
        value: item.PackageID,
        label: item.PackageTitle,
      }))
    );
  }catch(err){
  console.log(err,"err")
}
  }
  const selectDropDownData = (e, action) => {
    console.log(e.value, "e");
    setFilterData({
      ...filterData,
      [action.name]: e.value,
      packageTempt: e,
    });
  };
  const selectFilterData = (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  };
  const searchHandle = async(count, paginationIndex) => {
    setIsSearched(true)
    // if (filterData.endDate < filterData.startDate) {
    //   return;
    // }

    console.log(filterData, "filterDataaaaaaaaa", count);
    try{
    const res= await fetch(`${APIURL}/PackageEnquiry/getPackageEnquiry`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "PackageEnquiryID": "",
        "PackageID": filterData?.packageTempt?.value || "",
        "Name": filterData?.name || "",
        "MobileNo": filterData?.mobileNo || "",
        "StartDate": filterData?.startDate?.split("-").reverse().join("-") || "",
        "EndDate":  filterData.endDate?.split("-").reverse().join("-") || "",
        "IsActive": "",
        "IsDelete": "false",
        "IsPagination": "true",
        "PerPage": count,
        "UniqueID":  `${paginationIndex}`
        // StartDate: filterData.startDate?.split("-").reverse().join("-") || "",
        // EndDate: filterData.endDate?.split("-").reverse().join("-") || "",
        // HotelID: filterData.hotelName,
        // PaginationID: `${paginationIndex}`,
        // PageLength: count,
      }),
    })
    const result=await res.json()
    // setTotalCount(result?.total[0]?.TotalCount || 0);
    console.log(result);
    const tempt = [];
    result.data.forEach((element) => {
      tempt.push({
        obj: element,
        isDetails: false,
      });
    });
    console.log(tempt, "?tempt");
    setTableData(tempt);
    setAllTableData(tempt);
    setPageId(1);
  }catch(err){
    console.log(err)
  }
     
  };
  const handleCancle = () => {
    setCancle(true);
   setIsSearched(false)
    setFilterData({
      packageName: "",
      startDate: "",
      endDate: "",
      packageTempt: "",
      name:"",
      mobileNo:""
    });
    getEnquiryData(pageCount);
  };
  const setNextApi = async() => {
    console.log(pageId, "pageID", pageCount);
    if (pageId * pageCount < allTableData.length) {
      const tempt = [...allTableData];
      console.log(tempt, pageId * pageCount, (pageId + 1) * pageCount);
      const sliced = tempt.slice(pageId * pageCount, (pageId + 1) * pageCount);
      setTableData(sliced);
      setPageId(pageId + 1);
      return;
    }
    const paginationIndex =
      allTableData[pageId * pageCount - 1]?.obj.HotelBookingID;
    if (!paginationIndex) {
      return;
    }
    console.log(paginationIndex, "paginationIndex");
    try{
    const res=await fetch(`${APIURL}/PackageEnquiry/getPackageEnquiry`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "PackageEnquiryID": "",
        "PackageID": filterData?.packageTempt?.value || "",
        "Name": filterData?.name || "",
        "MobileNo": filterData?.mobileNo || "",
        "StartDate": filterData?.startDate?.split("-").reverse().join("-") || "",
        "EndDate":  filterData.endDate?.split("-").reverse().join("-") || "",
        "IsActive": "",
        "IsDelete": "false",
        "IsPagination": "true",
        "PerPage": pageCount,
        "UniqueID":  `${paginationIndex}`
        // StartDate: filterData.startDate?.split("-").reverse().join("-") || "",
        // EndDate: filterData.endDate?.split("-").reverse().join("-") || "",
        // HotelID: filterData.hotelName,
        // PaginationID: `${paginationIndex}`,
        // PageLength: `${pageCount}`,
      }),
    })
    const result=await res.json()
    console.log(result);
    const tempt = [];
    result.data.forEach((element) => {
      tempt.push({
        obj: element,
        isDetails: false,
      });
    });
    console.log(tempt, "tempt");
    setTableData(tempt);
    setPageId(pageId + 1);
    setAllTableData([...allTableData, ...tempt]);
  }catch(err){
    console.log(err)
  }
    
  };
  const setPreviousApi = () => {
    if (pageId == 1) {
      return;
    }
    const tempt = [...allTableData];
    console.log(pageId, "pageId");
    console.log(
      pageId,
      tempt,
      (pageId - 1) * pageCount,
      pageId * pageCount + 1
    );
    const sliced = tempt.slice(
      (pageId - 2) * pageCount,
      (pageId - 1) * pageCount
    );
    setTableData(sliced);
    setPageId(pageId - 1);
  };
  async function fetchExcelData() {
    setIsLoading(true);
    try{
    console.log(filterData.hotelName, "filterData.hotelName");
  const res=await fetch(`${APIURL}/PackageEnquiry/getPackageEnquiry`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "PackageEnquiryID": "",
        "PackageID": filterData?.packageTempt?.value || "",
        "Name": filterData?.name || "",
        "MobileNo": filterData?.mobileNo || "",
        "StartDate": filterData?.startDate?.split("-").reverse().join("-") || "",
        "EndDate":  filterData.endDate?.split("-").reverse().join("-") || "",
        "IsActive": "",
        "IsDelete": "false",
        "IsPagination": "",
        "PerPage": "",
        "UniqueID":  ""
        // StartDate: filterData?.startDate?.split("-").reverse().join("-") || "",
        // EndDate: filterData.endDate.split("-").reverse().join("-") || "",
        // HotelID: filterData.hotelName,
        // PaginationID: `-1`,
        // PageLength: "",
      }),
    })

    const result=await res.json()
    handleDownloadExcel(result.data);
  }catch(err){
    setIsLoading(false);
    console.log(err)
  }
  }
  const handleDownloadExcel = (array) => {
    console.log(array, "array");
    const header = [
      "Name",
      "PackageTitle",
      "MobileNo",
      "Email",
      "TourStartDate",
      "TourEndDate",
      "NoOfPeople",
      "Message"
    ];
    const body = [];
    array?.forEach((element, index) => {
      body.push([
        element.Name,
      element.PackageTitle,
      element.MobileNo,
      element.Email,
      element.TourStartDate,
      element.TourEndDate,
      element.NoOfPeople,
      element.Message
      ]);
    });
    if (!body.length) {
      setIsLoading(false);
      return Swal.fire({
        title: "No Data Found!",
        icon: "error",
        timer: 1500,
showConfirmButton: false,
      });
    }

    const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);
    header.forEach((col, index) => {
      ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
        font: { bold: true, size: 14, color: { rgb: "f7f4ef" } },
        fill: { patternType: "solid", fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: "center" },
      };
    });

    ws1["!cols"] = [
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 20 },
      { width: 40 },

    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "Package Enquiry Details");

    setIsLoading(false);
    XLSX.writeFile(
      wb,
      `Package${moment().format("DD-MM-YYYY_HH:mm")}.xlsx`
    );
  };
  if(isLoading){
    return   <div
    style={{
      position: "absolute",
      zIndex: "6",
      left: "600px",
      top: "300px",
    }}
  >
    <RotatingLines
      strokeColor="grey"
      strokeWidth="5"
      animationDuration="0.75"
      width="200"
      height="200"
      visible={true}
    />
  </div>
  }
  return (
    <div className="body-wrapper">
      <div className="container-fluid">
        {isLoading && (
          <div
            style={{
              position: "absolute",
              zIndex: "6",
              left: "600px",
              top: "300px",
            }}
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="200"
              height="200"
              visible={true}
            />
          </div>
        )}
        <div
          className={` pt-2 pb-2 place-filter  ${isOpen ? "open" : "closed"}`}
        >
          <Row>
            <Col md={2} sm={2} className="col-4">
              <div className="d-flex gap-2 pt-1">
                <span>Total Count</span>
                <span>{totalCount}</span>
              </div>
            </Col>
            <Col md={2} sm={2} className="col-4">
              <Form.Select
                onChange={handlePageCount}
                size="sm"
                className="w-70"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </Form.Select>
            </Col>

            <Col md={{ span: 2, offset: 6 }} className="col-4">
              <Button
                className="float-end"
                md={2}
                variant="outline-primary"
                size="sm"
                onClick={handleToggle}
              >
                {isOpen ? "Close Filter" : "Open Filter"}
              </Button>
            </Col>
          </Row>
          {
            <>
              {" "}
              {isOpen && (
                <div
                  className="filter-content row"
                  style={{
                    marginTop: "20px",
                    padding: "20px",
                    border: "1px solid gray",
                  }}
                >
                  <Form className="d-flex row pt-2">
                      <div className="form-group mb-2 col-md-3">
                        <label
                        >
                          {" "}
                          Package Name
                        </label>
                        <Select
                          name="packageName"
                          placeholder="--Select Package--"
                          onChange={selectDropDownData}
                          value={filterData.packageTempt}
                          options={[
                            { label: "--Select Package --", value: "" },
                            ...packageData,
                          ]}
                        />
                      </div>
                      <div className="form-group mb-2 col-md-3">
                      <label
    
                      >
                        {" "}
                         Name
                      </label>
                      <input
                    onChange={selectFilterData}
                        name="name"
                        value={filterData.name}
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                      ></input>
                    </div>
                    <div className="mb-2 col-md-3">
                  <label
                  >
                    {" "}
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    name="mobileNo"
                    maxLength={10}
                    placeholder="Mobile Number"
                    value={filterData.mobileNo}
                    className="form-control"
                    onChange={selectFilterData}
                  />
                </div>
                    {
                      <>
                        {" "}
                        <div className="form-group mb-2 col-md-3">
                          <label>
                            Start Date
                            <span style={{ color: "#72bdce", fontSize: 10 }} />
                          </label>
                          <input
                            onKeyDown={(e) => e.preventDefault()}
                            value={filterData.startDate}
                            onChange={selectFilterData}
                            name="startDate"
                            type="date"
                            class="form-control"
                            placeholder="dd-MM-yyyy"
                          ></input>
                        </div>
                        <div className="form-group mb-2 col-md-3">
                          <label>
                            End Date
                            <span style={{ color: "#72bdce", fontSize: 10 }} />
                          </label>
                          <input
                            onKeyDown={(e) => e.preventDefault()}
                            min={filterData.startDate}
                            value={filterData.endDate}
                            onChange={selectFilterData}
                            name="endDate"
                            type="date"
                            class="form-control"
                            placeholder="dd-MM-yyyy"
                          ></input>
                          {/* {searched &&
                            filterData.endDate < filterData.startDate && (
                              <span style={{ color: "red" }}>
                                End Date Must be greater than start date
                              </span>
                            )} */}
                        </div>
                      </>
                    }
                    <div className="mb-2 col-md-3" style={{ position: "relative", top: "20px" }}>
                      <Button
                        size="sm"
                        variant="primary"
                        className="me-2 "
                        onClick={() => {
                          searchHandle(pageCount, "");
                        }}
                      >
                        Apply
                      </Button>
                      <Button
                        onClick={fetchExcelData}
                        size="sm"
                        className="me-2"
                        variant="success"
                      >
                        Export
                      </Button>
                      <Button
                        onClick={() => handleCancle()}
                        size="sm"
                        variant="danger"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
            </>
          }
        </div>
        <div className="overflow-auto">
        <Table
          style={{
            boxShadow: "2px 3px 15px",
            textAlign: "left",
            border: "1px solid gray",
          }}
          striped
          bordered
          hover
        >
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Name</th>
              <th>Package Title</th>
              <th>Mobile Number</th>
              <th>Email</th>
              <th>Tour Start Date</th>
              <th>Tour End Date</th>
              <th>No Of People</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((element, index) => (
              <>
                <tr>
                  <td>{(pageId - 1) * pageCount + (index + 1)}</td>
                  <td>{element.obj.Name}</td>
                  <td>{element.obj.PackageTitle}</td>
                  <td>{element.obj.MobileNo}</td>
                  <td>{element.obj.Email}</td>
                  <td>{element.obj.TourStartDate}</td>
                  <td>{element.obj.TourEndDate}</td>
                  <td>{element.obj.NoOfPeople}</td>
                  <td>{element.obj.Message}</td>
                </tr>
              </>
            ))}
          </tbody>
          <tbody>
            <tr>
              {!tableData.length && (
                <td colSpan={11}>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "20px",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#ff0000",
                      backgroundColor: "#f0f0f0",
                      border: "1px solid #e0e0e0",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    NO DATA FOUND
                  </div>
                </td>
              )}
            </tr>
          </tbody>
        </Table>
        </div>
        <div className="mt-4 pb-2">
          <Button
            className="me-2"
            variant="primary"
            onClick={() => setPreviousApi()}
            size="sm"
          >
            PREVIOUS
          </Button>{" "}
          <Button variant="secondary" onClick={() => setNextApi()} size="sm">
            NEXT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewPackageEnquiry;

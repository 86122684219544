import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { APIURL } from "../../Process";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const AddTermsAndConfition = () => {
  const navigate = useNavigate();
  const [details,setDetails]=useState("")
  ;
  const [isSubmit, setIsSubmit] = useState(false);
  const [isCancle, setIsCancle] = useState(false);
  const { state } = useLocation();
   console.log(state,"state")
  const [formData, setFormData] = useState({
    TermsAndCondition: {
      textValue: state?.TermsAndCondition || "",
      required: true,
    },
  });
  useEffect(() => {   
    getTermsAndCondition();

  }, []);
  const getTermsAndCondition = async () => {
    // setIsLoading(true)
    try{
    const res = await fetch(`${APIURL}/TermsAndCondition/GetTermsAndCondition`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        TermsAndConditionID: "",
      }),
    });
   
    const result = await res.json();
    const tempt = [];
    result.data.forEach((element) => {
      tempt.push({
        obj: element,
        isDetails: false,
      });
    });
    console.log(tempt,"tempt")
    // setIsLoading(false)
  }catch(err){
    console.log(err)
  }

  };
  const handleCancle = () => {
    setFormData(() => {
      const update = {
        TermsAndCondition: {
          textValue: "",
          required: true,
        },
      };
      return update;
    });
    setIsCancle(true);
  };

  const handleSubmit=async(e)=>{
    setIsSubmit(true)
    e.preventDefault()
     if(!formData.TermsAndCondition.textValue){
        return
     }
     let insertBy;
     if(state){
        insertBy={
            CreatedByUserID: Cookies.get("ckUserID"),
            CreatedByUserName: Cookies.get("ckUserName"),
        }
     }else{
        insertBy={
            UpdatedByUserID: Cookies.get("ckUserID"),
            UpdatedByUserName: Cookies.get("ckUserID")
        }
     }
     
    
   try{ const res=await  fetch(`${APIURL}/TermsAndCondition/SetTermsAndCondition`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
            TermsAndConditionID: state?.TermsAndConditionID || "",
            TermsAndCondition:formData.TermsAndCondition.textValue,
            ...insertBy
        }),
      })
      const result=await res.json()
      console.log(result);
      if(state && result.status == 1 ){
        Swal.fire({
          title: "Data Updated successfully!",
          icon: "success",
          timer: 1500,
showConfirmButton: false
        });
        navigate("/westKameng/ViewTermsAndCondition")
      }
      else if(result.status == 1 ) {
        Swal.fire({
          title: "Data Inserted successfully!",
          icon: "success",
          timer: 1500,
showConfirmButton: false
        });
        navigate("/westKameng/ViewTermsAndCondition")
      } 
      else if(result.status==0){
        Swal.fire({
          title: result.data.message,
          icon: "error",
          timer: 1500,
showConfirmButton: false
        });
      }
    }catch(err){
      console.log(err)
    }
}

  return (
    <>
      <div className="body-wrapper" style={{backgroundColor:"#d3d3d3",minHeight:"100vh"}}>
        <div className="container-fluid">
          <div className="card mt-4" style={{boxShadow: "2px 3px 15px"}}>
            <div className="px-3 pt-4 border-bottom">
              <h5>Add Terms And Condition</h5>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row"></div>
                <div>
                  {" "}
                  {
                    <div className="col-md-12">
                      <div className="form-group" style={{ width: "100%" }}>
                        <div
                          className="img-thumbnail"
                          style={{
                            border: `1px solid ${
                              isSubmit && !formData.TermsAndCondition.textValue
                                ? "red"
                                : isSubmit
                                ? "green"
                                : "light-gray"
                            }  `,
                          }}
                        >
                          <CKEditor
                            editor={ClassicEditor}
                            data={formData.TermsAndCondition.textValue}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              console.log(data);
                              setFormData({
                                TermsAndCondition: {
                                  ...formData.Description,
                                  textValue: data,
                                },
                              });
                            }}
                          />
                          {isSubmit &&
                            !formData.TermsAndCondition.textValue && (
                              <span className="text-danger">*</span>
                            )}
                        </div>
                        {/* <span>uidahfadsads</span> */}
                      </div>
                    </div>
                  }
                </div>
                <div className="d-flex align-items-center gap-2 mt-4">
                  <button
                    type="submit"
                    className="btn btn-outline-primary font-weight-bolder ml-2 "
                  >
                    Submit
                  </button>
                  <button
                    onClick={handleCancle}
                    type="button"
                    className="btn btn-outline-danger font-weight-bolder ml-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      navigate("/westKameng/ViewTermsAndCondition");
                    }}
                    type="button"
                    className="btn btn-outline-primary font-weight-bolder ml-2"
                  >
                    View
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTermsAndConfition;
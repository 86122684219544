import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { APIURL } from '../../../Process'
import axios from 'axios'

import { RotatingLines } from 'react-loader-spinner'
import { compressImage } from '../../CommonComponents'
import Cookies from 'js-cookie'

const AddPlaceDetails = () => {
  const fileInputRef = useRef(null);
  const imageRef = useRef(null)
  const navigate = useNavigate()
 
  const [validLink, setValidLink] = useState(true)
  const { state } = useLocation()

  const [hasSubmit, setHasSubmit] = useState(false)
  const [isCancle, setIsCancle] = useState(false)
  const [imageCount, setImageCount] = useState(state ? 5 - state?.ImageDetail?.length : 5)
  const [validImage, setValidImage] = useState(true)
  const handleClearButtonClick = () => {
    fileInputRef.current.value = '';
    imageRef.current.value = ''
  };

  const [destinationOptions, setDestinationOptions] = useState([]);
  
  useEffect(() => {
    GetDestination();
  }, []);

  const GetDestination = async () => {
    try {
      const res = await fetch(`${APIURL}/Destination/getDestination`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          DestinationID: '',
          DestinationName: '',
          IsActive: 'true',
          IsDelete: 'false',
          IsPagination: '',
          PerPage: '',
          UniqueID: '',
        }),
      });

      const result = await res.json();
      if (result.status) {
        const options = result.data.map(item => ({ value: item.DestinationID, label: item.DestinationName }));
        setDestinationOptions(options);
      } else {
        setDestinationOptions([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [formData, setFormData] = useState({
    DestinationName: {
      textValue: state?.DestinationID || "",
      required: true,
    },
    name: {
      textValue: state?.PlaceName || '',
      required: true
    },
    startTime: {
      textValue: state?.StartTime || "",
      required: true
    },
    endTime: {
      textValue: state?.EndTime || "",
      required: true
    },
    latitude: {
      textValue: state?.PlaceLatitude || "",
      required: false
    },
    longitude: {
      textValue: state?.PlaceLongitude || "",
      required: false
    },
    description: {
      textValue: state?.Description || "",
      required: true
    },
    instruction: {
      textValue: state?.Instruction || "",
      required: false
    },
    transportDetails: {
      textValue: state?.TransportDetail || "",
      required: false
    },
    videoLink: {
      textValue: state?.VideoLink || "",
      required: false
    },
    address: {
      textValue: state?.PlaceAddress || "",
      required: true
    },
    FrontImage: {
      textValue: state?.PlaceImage || "",
      valid: true,
      required: true
    },
    image: {
      textValue: state?.ImageDetail || "",
      required: true,
      valid: true,
      count: state?.ImageDetail?.length || 0
    },
  })
  const handleInputChange = (e) => {
    let lastInputString = e.target.value[e.target.value.length - 1]
    console.log(lastInputString)
    if (e.target.name === "videoLink") {
      const videoLinkRegex = /^(ftp|http|https):\/\/[^ "]+$/
      if (e.target.value.length && videoLinkRegex.test(e.target.value)) {
        setValidLink(true)

      } else {
        setValidLink(false)
      }
      setFormData({
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          textValue: e.target.value
        }
      })
      return
    }

    if (e.target.name == "latitude" || e.target.name == "longitude") {
      if ((e.target.value == "" || lastInputString.charCodeAt() >= 48 && lastInputString.charCodeAt() <= 57 )) {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.value
          }
        })
      } else {
        return
      }
    }
    setFormData({
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        textValue: e.target.value
      }
    })
  }
  const handleFileChange = (e) => {
    console.log(e.target.files, "e.target.files")
    if (e.target.name === "image" && state?.ImageDetail?.length + e.target.files.length > 5) {
      Swal.fire({
        title: "Cannot Select More Than 5 Images!",
        icon: "error",
        timer: 1500,
showConfirmButton: false
      });
      return
    }
    console.log(e.target.files, "e.target.value")
    if (["jpg", "png", "jpeg"].includes(e.target.value.split(".")[e.target.value.split(".").length - 1]) || e.target.value === "") {
      if (e.target.name === "image") {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.files,
            valid: true,
            count: (state?.ImageDetail?.length || 0) + e.target.files.length
          }
        })
      } else if (e.target.name === "FrontImage") {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.files[0],
            valid: true,
          }
        })
      }

    } else {
      setFormData({
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          textValue: "",
          valid: false
        }
      })
    }
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault()
    setHasSubmit(true)

    if (formData.videoLink.textValue && !/^(ftp|http|https):\/\/[^ "]+$/.test(formData.videoLink.textValue)) {
      setValidLink(false)
      return
    }
    setValidLink(true)
    console.log(formData, "formData")
    let validFormDetailsFlag = true
    for (let key in formData) {
      // if(state ){

      if (formData[key].required) {
        if (formData[key].textValue == "") {
          validFormDetailsFlag = false
        }
        if (!validFormDetailsFlag) {
          return
        }
      }

      // }else{
      //   if (formData[key].required) {
      //     if (formData[key].textValue == "") {

      //       validFormDetailsFlag = false
      //     }
      //     if (!validFormDetailsFlag) {
      //       return
      //     }
      //   }
      // }
    }
    if(formData.latitude.textValue>90 ||formData.latitude.textValue<-90 || formData.longitude.textValue>180 || formData.longitude.textValue<-180){
      return
    }
    console.log("reached", formData.image.valid, "valid", formData.image.textValue, "text")
    if (state) {
      if (!formData.image.valid) {
        return
      }
    } else {
      if (!formData.image.valid || !formData.image.textValue.length) {
        return
      }
    }

    const form = new FormData()
    if (state) {
      form.append("PlaceID", state?.PlaceID)
    }
    form.append("DestinationID", formData.DestinationName.textValue)    
    form.append("PlaceName", formData.name.textValue)
    // form.append("PlaceImage",formData.image.textValue)
    form.append("StartTime", formData.startTime.textValue)
    form.append("EndTime", formData.endTime.textValue)
    form.append("VideoLink", formData.videoLink.textValue)
    form.append("PlaceAddress", formData.address.textValue)
    form.append("PlaceLatitude",formData.latitude.textValue)
    form.append("PlaceLongitude",formData.longitude.textValue)
    form.append("Description", formData.description.textValue)
    form.append("Instruction", formData.instruction.textValue)
    form.append("TransportDetail", formData.transportDetails.textValue)
    if (state) {
      form.append("UpdatedByUserID", Cookies.get("ckUserID"))
      form.append("UpdatedByUserName", Cookies.get("ckUserName"))
    } else {
      form.append("CreatedByUserID", Cookies.get("ckUserID"))
      form.append("CreatedByUserName", Cookies.get("ckUserName"))
    }

    if (formData.FrontImage.textValue) {
      form.append("PlaceImage",  formData.FrontImage.textValue ? !(formData.FrontImage.textValue instanceof Blob) ? formData.FrontImage.textValue :  await compressImage(formData.FrontImage.textValue,0.9) : "")
    }
    if (!isCancle) {
      if (state?.PlaceImage) {
        form.append("OldPlaceImage",  state?.PlaceImage)
      }
    }
    for (let i = 0; i < formData.image.textValue.length; i++) {
      form.append('Images',   formData.image.textValue[i] ? !(formData.image.textValue[i] instanceof Blob) ? formData.image.textValue[i] :  await compressImage(formData.image.textValue[i],0.9) : "");
    }
    const config = { headers: { "Content-Type": "multipart/form-data" } }
    axios.post(`${APIURL}/Place/SetPlace`, form, config)
      .then((res) => {
        console.log(res, "res")
        if (state && res.data?.status == 1) {
          Swal.fire({
            title: "Data Updated successfully!",
            icon: "success",
            timer: 1500,
showConfirmButton: false
          });
          navigate("/westKameng/viewplacedetails")
        }
        else if (res.data?.status == 1) {
          Swal.fire({
            title: "Data Inserted successfully!",
            icon: "success",
            timer: 1500,
showConfirmButton: false
          });
          navigate("/westKameng/viewplacedetails")

        }else{
          Swal.fire({
            title: res.data.message,
            icon: "success",
            timer: 1500,
showConfirmButton: false
          });
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleCancleFrom = (e) => {
    handleClearButtonClick()
    setFormData({
      DestinationName: {
        textValue: '',
        required: true
      },
      name: {
        textValue: '',
        required: true
      },
      startTime: {
        textValue: "",
        required: true
      },
      endTime: {
        textValue: "",
        required: true
      },
      latitude: {
        textValue: "",
        required: false
      },
      longitude: {
        textValue: "",
        required: false
      },
      description: {
        textValue: "",
        required: true
      },
      instruction: {
        textValue: "",
        required: false
      },
      transportDetails: {
        textValue: "",
        required: false
      },
      videoLink: {
        textValue: "",
        required: false
      },
      address: {
        textValue: "",
        required: true
      },
      FrontImage: {
        textValue: "",
        required: true,
        valid: true
      },
      image: {
        textValue: "",
        required: true,
        valid: true,
        count: state?.ImageDetail?.length || formData.image.count
      },
    })
    setIsCancle(true)
  }
  return (
    <div className='body-wrapper' style={{ backgroundColor: "#d3d3d3", minHeight: "100vh" }}   >
      <div className='container-fluid' >
        <div className='row'>
          <div className='col-md-12'>
            <div className="card mt-4" style={{ boxShadow: "2px 3px 15px" }}>
              <div className="px-3 pt-4 border-bottom">
                <h5>Place Details</h5>
              </div>
              <div className="card-body">
                <form onSubmit={handleFormSubmit} >
                  <div className='row'>
                    <div className="col-md-3 mb-2">
                      <div className="form-group mb-3">
                        <label htmlFor="DestinationName" className="form-label fw-semibold">
                          Destination Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <select
                          onChange={handleInputChange}
                          name="DestinationName"
                          value={formData.DestinationName.textValue}
                          className="form-control"
                          style={{
                            border: `1px solid ${(hasSubmit && !formData.DestinationName.textValue) ? 'red' : hasSubmit ? 'green' : 'light-gray'}`,
                          }}
                        >
                          <option value="">--Select Destination--</option>
                          {destinationOptions.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {hasSubmit && !formData.DestinationName.textValue && (
                          <span className="text-danger">*</span>
                        )}
                      </div>
                    </div>

                    <div className='col-md-3 mb-3'>
                      <div className="form-group">
                        <label htmlFor="ReferenceName" className="form-label fw-semibold"> Name  <span className='text-danger'>*</span></label>
                        <input style={{ border: `1px solid ${(hasSubmit && !formData.name.textValue) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }} name='name' value={formData.name.textValue} type="text" onChange={handleInputChange} className="form-control" placeholder="Enter Name"></input>
                        {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>}
                      </div>
                    </div>

                    <div className='col-md-3 mb-3 '>
                      <div className='form-group'>
                        <label className='form-label fw-semibold'>
                          Start Time
                          <span className='text-danger'>*</span>
                        </label>
                        <input style={{ border: `1px solid ${(hasSubmit && !formData.startTime.textValue) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }} value={formData.startTime.textValue} onChange={handleInputChange} type="time" name='startTime' className='form-control' placeholder='Select Time' />
                        {hasSubmit && !formData.startTime.textValue && <span className='text-danger'>*</span>}
                      </div>
                    </div>

                    <div className='col-md-3 mb-3 '>
                      <div className='form-group'>
                        <label className='form-label fw-semibold'>
                          End Time
                          <span className='text-danger'>*</span>
                        </label>
                        <input style={{ border: `1px solid ${(hasSubmit && !formData.endTime.textValue) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }} value={formData.endTime.textValue} onChange={handleInputChange} type="time" name='endTime' className='form-control' placeholder='Select Time' />
                        {hasSubmit && !formData.endTime.textValue && <span className='text-danger'>*</span>}
                      </div>
                    </div>
                    <div className='col-md-3 mb-3 '>
                      <div className="form-group">
                        <label htmlFor="ReferenceName" className="form-label fw-semibold"> Video Link</label>
                        <input value={formData.videoLink.textValue} onChange={handleInputChange} name='videoLink' type="text" className="form-control" placeholder="Enter Video Link"></input>
                        {hasSubmit && formData.videoLink.textValue && !validLink && <span className='text-danger'>Please Enter Valid Url</span>}
                      </div>
                    </div>
                    <div className='col-md-3 mb-3 '>
                      <div className='form-group'>
                        <label className='form-label fw-semibold'>
                          Description
                          <span className='text-danger'>*</span>
                        </label>
                        <textarea style={{ height: "150px", border: `1px solid ${(hasSubmit && !formData.description.textValue) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }} value={formData.description.textValue} onChange={handleInputChange} name='description' rows="2" cols="20" placeholder="Enter Descreption" className='form-control' />
                        {hasSubmit && !formData.description.textValue && <span className='text-danger'>*</span>}
                      </div>
                    </div>
                    <div className='col-md-3 mb-3 '>
                      <div className='form-group'>
                        <label className='form-label fw-semibold'>
                          Trasnsport Details
                        </label>
                        <textarea value={formData.transportDetails.textValue} onChange={handleInputChange} name='transportDetails' style={{ height: "150px" }} rows="2" cols="20" placeholder="Enter Trasnsport Details" className='form-control' />
                      </div>
                    </div>
                    <div className='col-md-3 mb-3 '>
                      <div className='form-group'>
                        <label className='form-label fw-semibold'>
                          Instruction
                        </label>
                        <textarea value={formData.instruction.textValue} onChange={handleInputChange} name='instruction' rows="2" cols="20" style={{ height: "150px" }} className='form-control' placeholder='Enter Instruction' />
                      </div>
                    </div>
                    <div className='col-md-3 mb-3 '>
                      <div className='form-group'>
                        <label className='form-label fw-semibold'>
                          Address
                          <span className='text-danger'>*</span>
                        </label>
                        <textarea value={formData.address.textValue} onChange={handleInputChange} name='address' style={{ height: "150px", border: `1px solid ${(hasSubmit && !formData.address.textValue) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }} rows="2" cols="20" placeholder="Enter Descreption" className='form-control' />
                        {hasSubmit && !formData.address.textValue && <span className='text-danger'>*</span>}
                      </div>
                    </div>
                    <div className='col-md-3 mb-3  '>
                      <div className="form-group">
                        <label className="form-label fw-semibold"> Latitude </label>
                        <input    style={{border:`1px solid ${(hasSubmit && (formData.latitude.textValue>90 || formData.latitude.textValue<-90 )) ? "red" : hasSubmit ? "green" : "light-gray" }  `}}
                            onKeyPress={(e)=>{if(e.key=="e"){
                            e.preventDefault()
                          }}} value={formData.latitude.textValue} onChange={handleInputChange} name='latitude' type="number" className="form-control" placeholder="Enter Latitude"></input>
                        {/* {hasSubmit && !formData.latitude.textValue && <span className='text-danger'>*</span>} */}
                        {hasSubmit && (formData.latitude.textValue>90 || formData.latitude.textValue<-90  ) && <span className='text-danger'>Lattitude must be in range of -90 to 90</span>}
                      </div>
                    </div>
                    <div className='col-md-3 mb-3 '>
                      <div className="form-group">
                        <label className="form-label fw-semibold"> Longitude </label>
                        <input   style={{border:`1px solid ${(hasSubmit && (formData.longitude.textValue>90 || formData.longitude.textValue<-90)) ? "red" : hasSubmit ? "green" : "light-gray" }  `}}
                             onKeyPress={(e)=>{if(e.key=="e"){
                            e.preventDefault()
                          }}} value={formData.longitude.textValue} onChange={handleInputChange} name='longitude' type="number" className="form-control" placeholder="Enter Longitude"></input>
                        {/* {hasSubmit && !formData.longitude.textValue && <span className='text-danger'>*</span>} */}
                        {hasSubmit && (parseFloat(formData.longitude.textValue)>180 || parseFloat(formData.longitude.textValue)<-180) && <span className='text-danger'>Longitude must be in range of -180 to 180</span>}
                      </div>
                    </div>
                    <div className='col-md-3 mb-3  ' >
                      <label className="form-label fw-semibold">Front Image<span className='text-danger'>*</span></label>
                      <input style={{ border: `1px solid ${(hasSubmit && (!formData.FrontImage.textValue || !formData.FrontImage.valid)) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }} name='FrontImage' onChange={handleFileChange} type="file" ref={fileInputRef} accept='image/jpeg, image/png, image/jpg' className='form-control' />
                      {/* {state  && <span >{formData.image.textValue.length} Images Selected </span>} */}
                      {!formData.FrontImage.valid && <span className='text-danger'>*please upload valid image </span>}
                      {!isCancle && state?.PlaceImage && <span >{state?.PlaceImage}</span>}
                      {hasSubmit && (!formData?.FrontImage?.textValue) && <span className='text-danger'>*</span>}
                    </div>
                    <div className='col-md-3 mb-3' >
                      <label className="form-label fw-semibold"> Detail Image    <span className='text-danger'>*</span></label>
                      <input style={{ border: `1px solid ${(hasSubmit && (!formData.image.textValue || !formData.image.textValue.length || !formData.image.valid)) ? "red" : hasSubmit ? "green" : "light-gray"}  ` }} name='image' ref={imageRef} onChange={handleFileChange} type="file" accept='image/jpeg, image/png, image/jpg' multiple className='form-control' />
                      {state && <span >{formData.image.count} Images Selected </span>}
                      {!formData.image.valid && <span className='text-danger'>*please upload valid image </span>}
                      {/* {!isCancle && state?.obj.Image && !formData.Image.textValue && <span >{state.obj.Image}</span>} */}
                      {hasSubmit && (!formData.image.textValue || !formData.image.textValue.length) && <span className='text-danger'>*</span>}
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 mt-4">
                    <button type="submit" className="btn btn-outline-primary font-weight-bolder ml-2 ">Submit</button>
                    <button type="button" onClick={handleCancleFrom} className="btn btn-outline-danger font-weight-bolder ml-2" >Cancel</button>
                    <button type="button" onClick={() => navigate("/westKameng/viewplacedetails")} className="btn btn-outline-primary font-weight-bolder ml-2">View</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}

export default AddPlaceDetails
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { Accordion, Table } from "react-bootstrap";

import Edit from "../Assets/Edit.png";
import { useNavigate } from "react-router-dom";
import { APIURL } from "../Process";
import { RotatingLines } from "react-loader-spinner";
import Cookies from "js-cookie";

const ViewContactUs = () => {
  const navigate = useNavigate();
  ;
  const [termsAndConditionData, settermsAndConditionData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getContactUs();
  }, []);
  const getContactUs = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`${APIURL}/ContactUs/GetContactUs`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          TermsAndConditionID: "",
        }),
      })
      const result = await res.json()
      const tempt = [];
      if (result?.data?.length) {
        tempt.push(result.data[0])
      }

      // result.data.forEach((element) => {
      //   tempt.push({
      //     obj: element,
      //     isDetails: false,
      //   });
      // });

      settermsAndConditionData(tempt);
      setIsLoading(false);
    } catch (err) {
      console.log(err)
      setIsLoading(false);
    }

  };
  const editHistoryDetails = (element) => {
    navigate("/westKameng/addContactUs", {
      state: element,
    });
  };

  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: "6",
          left: "600px",
          top: "300px",
        }}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="200"
          height="200"
          visible={true}
        />
      </div>
    );
  }

  return (
    <>
      <div className="body-wrapper">
        <div className="container-fluid">
          <div className="card">
            <div className="mt-2 mb-2 px-3 py-2 border-bottom">
              <h5>View Contact Us Details </h5>
            </div>
            <Table
              style={{ boxShadow: "2px 3px 15px", textAlign: "left", border: "1px solid gray" }}
              striped
              bordered
              hover
            >
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Description</th>
                  <th>Contact Details</th>
                  <th>Email ID</th>
                  <th>Address</th>
                  {Cookies.get("ckUserType") == "Admin" && <th>Edit</th>}
                </tr>
              </thead>
              <tbody>
                {termsAndConditionData?.map((element, index) => (
                  <tr>
                    <td  >{index + 1}</td>
                    <td style={{ width: "30rem" }} >
                      <div
                        style={{ width: "30rem" }}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            element.Description
                          ),
                        }}
                      ></div>
                    </td>
                    <td>
                      <Table
                        size="sm"
                        style={{ textAlign: "left", border: "1px solid gray" }}
                        striped
                        bordered
                        hover
                      >
                        <tbody>
                          <tr>
                            <td>Contact 1</td>
                            <td>
                              <span>{element?.ContactNo1}</span>
                              <br />
                            </td>
                          </tr>
                          <tr>
                            <td>Contact 2</td>
                            <td>
                              <span>{element?.ContactNo2}</span>
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </td>
                    <td>
                      {element?.EmailID}
                    </td>
                    <td style={{ width: "16rem" }} >
                      {element?.Address}
                    </td>
                    {Cookies.get("ckUserType") == "Admin" && (
                      <td>
                        <img
                          onClick={() => {
                            editHistoryDetails(element);
                          }}
                          src={Edit}
                          height={"25px"}
                          alt=""
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>

            {!termsAndConditionData?.length && <div
              style={{
                textAlign: "center",
                padding: "20px",
                fontSize: "20px",
                fontWeight: "bold",
                color: "#ff0000",
                backgroundColor: "#f0f0f0",
                border: "1px solid #e0e0e0",
                borderRadius: "5px",
                width: "100%",
              }}
            >
              NO DATA FOUND
            </div>}

          </div>
        </div>
      </div>
    </>
  );
};

export default ViewContactUs;

import React, { useContext, useEffect } from 'react'
import { placeContext } from '../Context/PlaceContext'
import { Navigate, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';


const RestaurantDetailAccess = ({children}) => {
 
    const {state}=useLocation()
    if(Cookies.get("ckUserType")==="Admin" || (Cookies.get("ckUserType")==="Restaurant" ) ||(Cookies.get("ckUserType")==="Hotel" && Cookies.get("IsRestaurantAvailable")?.toLowerCase()=="yes"  )   ){
    return children     
    }else{
        return <Navigate to={"/"} />
    }
}

export default RestaurantDetailAccess
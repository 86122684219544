import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  InputGroup,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Edit from "../../../Assets/Edit.png";
import Delete from "../../../Assets/Delete.png";
import EyeOpen from "../../../Assets/eyeopen.png";
import ImageNotFound from "../../../Assets/Noimage.png";
import EyeClose from "../../../Assets/eyeclose.png";
import { FiActivity } from "react-icons/fi";
import { FaToggleOn, FaToggleOff, FaLink } from "react-icons/fa";
import { MdAssignmentAdd } from "react-icons/md";
import { RotatingLines } from "react-loader-spinner";
import XLSX from "xlsx-color";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import "./index.css";
import Select from 'react-select';
import { APIURL, IMAGEMODELURL } from "../../../Process";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const ViewPlaceDetails = () => {
  const navigate = useNavigate();
  const ActivityImageUrl = `${IMAGEMODELURL}/Activity/ActivityImage`;
  const SpecialItemImageUrl = `${IMAGEMODELURL}/SpecialItem/SpecialItemImage`;
  const PlaceImageUrl = `${IMAGEMODELURL}/Place/Images`;
  const [totalCount, setTotalCount] = useState();
  const [pageCount, setPageCount] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [DestinationData, setDestinationData] = useState([])
  const [filterData, setFilterData] = useState({
    place: "",
    DestinationName: "",
    DestinationTempt: ""
  });

  useEffect(() => {
    GetDestination()
    fetchInitialPlaceDetailsData(pageCount);
  }, []);
  const fetchInitialPlaceDetailsData = async (count) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${APIURL}/Place/GetPlace`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PlaceID: "",
          DestinationID: "",
          PlaceName: "",
          StartDate: "",
          EndDate: "",
          PaginationID: "0",
          PageLength: count,
        }),
      });
      const result = await res.json();
      setTotalCount(result.total[0].TotalCount);
      console.log(result, "initialReasult");
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      setIsLoading(false);
      setTableData(tempt);
      setPageId(1);
      setAllTableData(tempt);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const GetDestination = async () => {
    try {
      const res = await fetch(`${APIURL}/Destination/getDestination`, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          DestinationID: '',
          DestinationName: '',
          IsActive: 'true',
          IsDelete: 'false',
          IsPagination: '',
          PerPage: '',
          UniqueID: '',
        })
      })
      const result = await res.json()
      console.log(result, "result")

      setDestinationData(result.data.map(item => ({ value: item.DestinationID, label: item.DestinationName })))
    } catch (err) {
      console.log(err)
    }

  }

  const confirmDelete = async (id) => {
    try {
      const res = await fetch(`${APIURL}/Place/RemovePlace`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PlaceID: id,
        }),
      });

      const result = await res.json();
      if (result.status == 1) {
        Swal.fire({
          title: "Successfully Deleted!",
          icon: "success",
          timer: 1500,
showConfirmButton: false,
        });
        fetchInitialPlaceDetailsData(pageCount);
      } else {
        Swal.fire({
          title: result?.message || "",
          icon: "error",
          timer: 1500,
showConfirmButton: false,
        });
      }
      console.log(result);
    } catch (err) {
      console.log(err);
    }
  };
  const handleDeletePlaceDetails = async (id) => {
    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmDelete(id);
      }
    });
  };
  const editAppointmentDetails = (element) => {
    navigate("/westKameng/addplacedetails", {
      state: element.obj,
    });
  };
  const handleEyeOn = (index) => {
    console.log("eyeon");
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: true,
    });
    setTableData(tempt);
  };
  const handleEyeOff = (index) => {
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: false,
    });
    setTableData(tempt);
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleFilterInputChange = (e) => {
    setFilterData({
      [e.target.name]: e.target.value,
    });
  };
  const selectDropDownData1 = (e, action) => {
    console.log(e.value, "epppp")
    setFilterData({
      ...filterData,
      [action.name]: e.value,
      DestinationTempt: e,
    })
  }

  const setNextApi = async () => {
    console.log(pageId, "pageID", pageCount);
    if (pageId * pageCount < allTableData.length) {
      const tempt = [...allTableData];
      console.log(tempt, pageId * pageCount, (pageId + 1) * pageCount);
      const sliced = tempt.slice(pageId * pageCount, (pageId + 1) * pageCount);
      setTableData(sliced);
      setPageId(pageId + 1);
      return;
    }
    const paginationIndex = allTableData[pageId * pageCount - 1]?.obj?.PlaceID;
    if (!paginationIndex) {
      return;
    }
    console.log(pageId, allTableData);
    try {
      const res = await fetch(`${APIURL}/Place/GetPlace`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PlaceName: filterData.place,
          PaginationID: `${paginationIndex}`,
          PageLength: `${pageCount}`,
        }),
      });
      const result = await res.json();
      console.log(result);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      console.log(tempt, "tempt");
      setTableData(tempt);
      setPageId(pageId + 1);
      setAllTableData([...allTableData, ...tempt]);
    } catch (err) {
      console.log(err);
    }
  };
  const setPreviousApi = () => {
    if (pageId == 1) {
      return;
    }
    const tempt = [...allTableData];
    console.log(pageId, "pageId");
    console.log(
      pageId,
      tempt,
      (pageId - 1) * pageCount,
      pageId * pageCount + 1
    );
    const sliced = tempt.slice(
      (pageId - 2) * pageCount,
      (pageId - 1) * pageCount
    );
    setTableData(sliced);
    setPageId(pageId - 1);
  };
  const searchHandle = async (count, paginationIndex, filterData) => {
    // setIsLoading(true);
    try {
      console.log(filterData, "filterDataaaaaaaaa", count);
      const res = await fetch(`${APIURL}/Place/GetPlace`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DestinationID: filterData.DestinationName,
          PlaceName: filterData.place,
          PaginationID: `${paginationIndex}`,
          PageLength: count,
        }),
      });
      const result = await res.json();
      console.log(result);
      setTotalCount(result.total[0].TotalCount);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      console.log(tempt, "?tempt");
      setTableData(tempt);
      setIsLoading(false);
      setAllTableData(tempt);
      setPageId(1);
    } catch (err) {
      // setIsLoading(false);
      console.log(err);
    }
  };
  const handlePageCount = (e) => {
    setPageCount(e.target.value);
    searchHandle(e.target.value, 0, filterData);
  };
  const handleCancle = () => {
    setFilterData({
      place: "",
      DestinationName: ""
    });
    fetchInitialPlaceDetailsData(pageCount);
  };
  async function fetchExcelData() {
    // setIsLoading(true)
    setIsLoading(true);
    try {
      const res = await fetch(`${APIURL}/Place/GetPlace`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DestinationID: filterData.DestinationName,
          PlaceName: filterData.place,
          PaginationID: "-1",
          PageLength: ``,
        }),
      });
      const result = await res.json();
      handleDownloadExcel(result.data);
    } catch (err) {
      console.log(err);
    }
  }
  const handleDownloadExcel = (array) => {
    console.log(array, "array");
    const header = [
      "DestinationName",
      "Name",
      "Start Time",
      "End Time",
      "Video Link",
      "Address",
      "Latitude",
      "Longitude",
      "Description",
      "Instruction",
      "Transport Details",
    ];
    const body = [];
    array?.forEach((element, index) => {
      body.push([
        element.DestinationName,
        element.PlaceName,
        element.StartTime,
        element.EndTime,
        element.VideoLink,
        element.PlaceAddress,
        element.PlaceLatitude,
        element.PlaceLongitude,
        element.Description,
        element.Instruction,
        element.TransportDetail,
      ]);
    });
    if (!body.length) {
      setIsLoading(false);
      return Swal.fire({
        title: "No Data Found!",
        icon: "error",
        timer: 1500,
showConfirmButton: false,
      });
    }
    //   setIsLoading(false)

    const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);

    header.forEach((col, index) => {
      ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
        font: { bold: true, color: { rgb: "f7f4ef" } }, // White font color
        fill: { patternType: "solid", fgColor: { rgb: "54153255" } }, // Blue background color
        alignment: { wrapText: true, horizontal: "center" },
      };
    });

    ws1["!cols"] = [
      { width: 30 },
      { width: 20 },
      { width: 10 },
      { width: 10 },
      { width: 40 },
      { width: 40 },
      { width: 10 },
      { width: 10 },
      { width: 40 },
      { width: 40 },
      { width: 40 },
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "Place Details");

    // Save the workbook
    setIsLoading(false);
    XLSX.writeFile(
      wb,
      `Place_Details${moment().format("DD-MM-YYYY_HH:mm")}.xlsx`
    );
  };

  const confirmImageDelete = async (element) => {
    console.log(element, "element");
    const res = await fetch(`${APIURL}/ImageDetail/DeleteImages`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ImageDetailID: element.ImageDetailID,
      }),
    });
    const result = await res.json();
    if (result.status === 1) {
      Swal.fire({
        title: "Successfully Deleted!",
        icon: "success",
        timer: 1500,
showConfirmButton: false,
      });
      // fetchInitialPlaceDetailsData(pageCount)
      searchHandle(pageCount, 0, filterData);
    }
    console.log(result, "result");
  };
  const deleteImageDetails = async (element) => {
    console.log(element, "element");
    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmImageDelete(element);
      }
    });
  };
  const handlePlaceActive = async (placeID, status) => {
    console.log(placeID, "placeID");
    try {
      const res = await fetch(`${APIURL}/Place/UpdateActiveStatus`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PlaceID: placeID,
          Status: `${status}`,
        }),
      });
      const result = await res.json();
      searchHandle(pageCount, 0, filterData);

    } catch (err) {
      console.log(err);
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: "6",
          left: "600px",
          top: "300px",
        }}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="200"
          height="200"
          visible={true}
        />
      </div>
    );
  }
  return (
    <div className="body-wrapper">
      <div className="container-fluid">
        {isLoading && (
          <div
            style={{
              position: "absolute",
              zIndex: "6",
              left: "600px",
              top: "300px",
            }}
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="200"
              height="200"
              visible={true}
            />
          </div>
        )}
        <div
          className={`pt-2 pb-2 place-filter  ${isOpen ? "open" : "closed"}`}
        >
          <Row>
            <Col md={2} sm={2} className="col-4">
              <div className="d-flex gap-2 pt-1">
                <span>Total Count</span>
                <span>{totalCount}</span>
              </div>
            </Col>
            <Col md={2} sm={2} className="col-4">
              <Form.Select
                onChange={handlePageCount}
                size="sm"
                className="w-70"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </Form.Select>
            </Col>

            <Col md={{ span: 2, offset: 6 }} className="col-4">
              <Button
                className="float-end"
                md={2}
                variant="outline-primary"
                size="sm"
                onClick={handleToggle}
              >
                {isOpen ? "Close Filter" : "Open Filter"}
              </Button>
            </Col>
          </Row>
          {isOpen && (
            <div
              className="filter-content row"
              style={{
                marginTop: "20px",
                padding: "20px",
                border: "1px solid gray",
              }}
            >
              <Form className="d-flex row pb-4">

                <div className="form-group col-md-3 mb-2">
                  <label
                    htmlFor="DestinationName"
                    className="form-label fw-semibold"
                  >
                    {" "}
                    Destination Name
                  </label>
                  <Select name='DestinationName' placeholder="--Select Destination Name--" onChange={selectDropDownData1} value={filterData.DestinationTempt} options={[{ label: '--Select Destination Name --', value: '' }, ...DestinationData]} />
                </div>

                <div className="col-md-3 mb-2">
                  <label htmlFor="hotelType" className="form-label fw-semibold">
                    {" "}
                    Place Name
                  </label>

                  <input
                    type="text"
                    name="place"
                    placeholder="Place Name"
                    value={filterData.place}
                    className="form-control"
                    onChange={handleFilterInputChange}
                  />
                </div>

                <div className="col-md-3" style={{ position: "relative", top: "27px" }}>
                  <Button
                    size="sm"
                    variant="primary"
                    className="me-2"
                    onClick={() => searchHandle(pageCount, 0, filterData)}
                  >
                    Apply
                  </Button>

                  <Button
                    className="me-2"
                    onClick={fetchExcelData}
                    size="sm"
                    variant="success"
                  >
                    Export
                  </Button>
                  <Button onClick={handleCancle} size="sm" variant="danger">
                    Cancel
                  </Button>
                </div>
                {/* Add more InputGroup components for additional filters */}
              </Form>
            </div>
          )}
        </div>
        <div className="overflow-auto">
          <Table
            style={{
              boxShadow: "2px 3px 15px",
              textAlign: "left",
              border: "1px solid gray",
            }}
            striped
            bordered
            hover
          >
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>Details</th>
                <th>Status</th>
                <th>Destination Name</th>
                <th>Place Name</th>
                <th>Start Time/End Time</th>
                <th>Link</th>
                <th>Address</th>
                <th>Edit</th>
                <th>Delete</th>
                <th>Add Activities</th>
                <th>Add Special Items</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((element, index) => (
                <>
                  <tr>
                    <td>{(pageId - 1) * pageCount + (index + 1)}</td>
                    <td>
                      {!element.isDetails && (
                        <img
                          onClick={() => handleEyeOn(index)}
                          src={EyeOpen}
                          height={"22px"}
                          alt=""
                        />
                      )}{" "}
                      {element.isDetails && (
                        <img
                          onClick={() => handleEyeOff(index)}
                          src={EyeClose}
                          height={"22px"}
                          alt=""
                        />
                      )}
                    </td>
                    <td>
                      {" "}
                      {element.obj.IsActive ? (
                        <FaToggleOn
                          onClick={() =>
                            handlePlaceActive(element.obj.PlaceID, 0)
                          }
                          style={{ color: "green", fontSize: "1.9em" }}
                        />
                      ) : (
                        <FaToggleOff
                          onClick={() =>
                            handlePlaceActive(element.obj.PlaceID, 1)
                          }
                          style={{ color: "red", fontSize: "1.8em" }}
                        />
                      )}
                    </td>
                    <td>{element.obj.DestinationName}</td>
                    <td>{element.obj.PlaceName}</td>
                    <td>
                      <span>{element.obj.StartTime}</span>
                      {" - "}
                      <span>{element.obj.EndTime}</span>
                    </td>
                    <td>
                      {/^(ftp|http|https):\/\/[^ "]+$/.test(
                        element.obj.VideoLink
                      ) ? (
                        <a target="_blank" href={`${element.obj.VideoLink}`}>
                          {" "}
                          <FaLink style={{ fontSize: "1.5em" }} />
                        </a>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{element.obj.PlaceAddress}</td>
                    <td>
                      <img
                        onClick={() => editAppointmentDetails(element)}
                        src={Edit}
                        height={"25px"}
                        alt=""
                      />
                    </td>
                    <td>
                      <img
                        src={Delete}
                        onClick={() =>
                          handleDeletePlaceDetails(element.obj.PlaceID)
                        }
                        height={"25px"}
                        alt=""
                      />
                    </td>
                    <td>
                      <FiActivity
                        onClick={() =>
                          navigate("/westKameng/assignActivity", {
                            state: element,
                          })
                        }
                        style={{ fontSize: "1.7em" }}
                      />
                    </td>
                    <td>
                      <MdAssignmentAdd
                        onClick={() =>
                          navigate("/westKameng/assignItems", {
                            state: element,
                          })
                        }
                        style={{ fontSize: "1.7em" }}
                      />
                    </td>
                  </tr>

                  {element.isDetails && (
                    <tr>
                      <td colSpan={12}>
                        <Table
                          style={{ textAlign: "left", border: "1px solid gray" }}
                          striped
                          bordered
                          hover
                        >
                          <thead>
                            <th className="p-2">Place Details</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ display: "flex" }}>
                                <Table
                                  style={{
                                    textAlign: "left",
                                    border: "1px solid gray",
                                  }}
                                  striped
                                  bordered
                                  hover
                                >
                                  <thead>
                                    <th className="p-2">Description</th>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{element.obj.Description}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                                <Table
                                  style={{
                                    textAlign: "left",
                                    border: "1px solid gray",
                                  }}
                                  striped
                                  bordered
                                  hover
                                >
                                  <thead>
                                    <th className="p-2">Instruction</th>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{element.obj.Instruction}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                                <Table
                                  style={{
                                    textAlign: "left",
                                    border: "1px solid gray",
                                  }}
                                  striped
                                  bordered
                                  hover
                                >
                                  <thead>
                                    <th className="p-2">Transport Details</th>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{element.obj.TransportDetail}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                                <Table
                                  style={{
                                    textAlign: "left",
                                    border: "1px solid gray",
                                    width:"20%"
                                  }}
                                  striped
                                  bordered
                                  hover
                                >
                                  <thead>
                                    <th className="p-2">Lattitude</th>
                                  </thead>
                                  <tbody >
                                    <tr>
                                      <td>{element.obj.PlaceLatitude}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                                <Table
                                  style={{
                                    textAlign: "left",
                                    border: "1px solid gray",
                                    width:"20%"
                                  }}
                                  striped
                                  bordered
                                  hover
                                >
                                  <thead>
                                    <th className="p-2">Longitude</th>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{element.obj.PlaceLongitude}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        {element.obj.ActivityDetail?.length && (
                          <Table
                            style={{
                              textAlign: "left",
                              border: "1px solid gray",
                            }}
                            striped
                            bordered
                            hover
                          >
                            <thead>
                              <th className="p-2">Activity</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {
                                    <Table
                                      style={{
                                        textAlign: "left",
                                        border: "1px solid gray",
                                      }}
                                      striped
                                      bordered
                                      hover
                                    >
                                      <thead>
                                        <tr>
                                          <th>Activity Name</th>
                                          <th>Image</th>
                                          <th>Description</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {element.obj.ActivityDetail?.map(
                                          (element, index) => (
                                            <tr>
                                              <td>{element.ActivityName}</td>
                                              <td>
                                                <img
                                                  src={
                                                    element?.ActivityImage
                                                      ? `${ActivityImageUrl}/${element.ActivityImage}`
                                                      : ImageNotFound
                                                  }
                                                  style={{
                                                    height: 80,
                                                    width: 80,
                                                    borderWidth: 0,
                                                    border: "1px solid #007AB1",
                                                  }}
                                                />
                                              </td>
                                              <td>{element.Description}</td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        )}
                        {element.obj.SpecialItemDetail?.length && (
                          <Table
                            style={{
                              textAlign: "left",
                              border: "1px solid gray",
                            }}
                            striped
                            bordered
                            hover
                          >
                            <thead>
                              <th className="p-2">Items</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {" "}
                                  <Table
                                    style={{
                                      textAlign: "left",
                                      border: "1px solid gray",
                                    }}
                                    striped
                                    bordered
                                    hover
                                  >
                                    <thead>
                                      <tr>
                                        <th>Items Name</th>
                                        <th>Image</th>
                                        <th>Description</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {element.obj.SpecialItemDetail?.map(
                                        (element, index) => (
                                          <tr>
                                            <td>{element.SpecialItemName}</td>
                                            <td>
                                              <img
                                                src={
                                                  element?.SpecialItemImage
                                                    ? `${SpecialItemImageUrl}/${element.SpecialItemImage}`
                                                    : ImageNotFound
                                                }
                                                style={{
                                                  height: 80,
                                                  width: 80,
                                                  borderWidth: 0,
                                                  border: "1px solid #007AB1",
                                                }}
                                              />
                                            </td>
                                            <td>{element.Description}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        )}
                        {element.obj.ImageDetail?.length && (
                          <Table
                            style={{
                              textAlign: "left",
                              border: "1px solid gray",
                            }}
                            striped
                            bordered
                            hover
                          >
                            <thead>
                              <tr>
                                <th>Place Detail Images</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="d-flex">
                                  {element.obj.ImageDetail?.map(
                                    (element, index) => (
                                      <div
                                        className="d-flex"
                                        style={{
                                          flexDirection: "row",
                                          width: "100px",
                                          marginBottom: "10px",
                                          marginRight: "10px",
                                        }}
                                      >
                                        <img
                                          src={
                                            element?.Image
                                              ? `${PlaceImageUrl}/${element.Image}`
                                              : ImageNotFound
                                          }
                                          style={{
                                            marginLeft: "10px",
                                            height: 90,
                                            width: 90,
                                            borderWidth: 0,
                                            border: "1px solid #007AB1",
                                          }}
                                        />
                                        <span
                                          onClick={() =>
                                            deleteImageDetails(element)
                                          }
                                          style={{
                                            color: "black",
                                            textAlign: "right",
                                            fontSize: "1.4em",
                                            position: "relative",
                                            right: "20px",
                                            bottom: "4px",
                                          }}
                                        >
                                          <MdDelete />
                                        </span>
                                      </div>
                                    )
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        )}
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
            <tbody>
              <tr>
                {!tableData.length && (
                  <td colSpan={12}>
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#ff0000",
                        backgroundColor: "#f0f0f0",
                        border: "1px solid #e0e0e0",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      NO DATA FOUND
                    </div>
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="pt-2 pb-2">
          <Button onClick={setPreviousApi} variant="primary" size="sm">
            PREVIOUS
          </Button>{" "}
          <Button onClick={setNextApi} variant="secondary" size="sm">
            NEXT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewPlaceDetails;

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { Accordion, Table } from "react-bootstrap";

import Edit from "../../Assets/Edit.png";
import { useNavigate } from "react-router-dom";
import { APIURL } from "../../Process";
import Cookies from "js-cookie";

const ViewTermsAndCondition = () => {
  const navigate = useNavigate();
  ;
  const [termsAndConditionData, settermsAndConditionData] = useState();

  useEffect(() => {
    getTermsAndCondition();
  }, []);
  const getTermsAndCondition = async() => {
    
    try{
    const res=await fetch(`${APIURL}/TermsAndCondition/GetTermsAndCondition`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        TermsAndConditionID: "",
      }),
    })
    const result=await res.json()
    console.log(result, "initialReasult");
    const tempt = [];
    result.data.forEach((element) => {
      tempt.push({
        obj: element,
        isDetails: false,
      });
    });

    settermsAndConditionData(tempt);
  }catch(err){
    console.log(err)
  }
 
  };
  const editHistoryDetails = (element) => {
    navigate("/westKameng/AddTermsAndCondition", {
      state: element.obj,
    });
  };

  return (
    <>
      <div className="body-wrapper">
        <div className="container-fluid">
          <div className="card">
            <div className="mt-2 mb-2 px-3 py-2 border-bottom">
              <h5>View Terms And Condition</h5>
            </div>
            <Table
              style={{boxShadow: "2px 3px 15px", textAlign: "left", border: "1px solid gray" }}
              striped
              bordered
              hover
            >
              <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Terms And Condition</th>
                  {Cookies.get("ckUserType") == "Admin" && <th>Edit</th>}
                </tr>
              </thead>
              <tbody>
                {termsAndConditionData?.map((element, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td style={{ width: "85%" }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            element.obj.TermsAndCondition
                          ),
                        }}
                      ></div>
                    </td>
                    {Cookies.get("ckUserType") == "Admin" && (
                      <td>
                        <img
                          onClick={() => {
                            editHistoryDetails(element);
                          }}
                          src={Edit}
                          height={"25px"}
                          alt=""
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTermsAndCondition;

import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { APIURL } from "../Process";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { compressImage } from "./CommonComponents";
import Cookies from "js-cookie";

const AddAboutUs = () => {

  const [isSubmit, setIsSubmit] = useState(false);
  const [isCancle, setIsCancle] = useState(false)
  
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state, "state");
  const [formData, setFormData] = useState({
    Title: {
      textValue:state?.Title || "",
      required:  false ,
    },

    Description: {
      textValue: state?.Description ||"",
      required: true,
    },
    Images:{
        textValue: state?.AboutUsImage || "",
        required:  false  ,
        valid: true,
        type: "file",
    }
  });
  const handleInputChange = (e) => {
    if(e.target.name==="HistoryType"){
      if(e.target.value==="tourist"){
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.value,
          },
          Title: {
           ...formData.Title,
           textValue:"",
           required:false
          }
        });
      }else {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.value,
          },
          Title: {
            ...formData.Title,
            required:true
          }
        });
      }
    }else{
      setFormData({
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          textValue: e.target.value,
        },
      });
    }
 
  };
  const handleFileChange = (e) => {
    if (
      ["jpg", "png", "jpeg"].includes(
        e.target.value.split(".")[e.target.value.split(".").length - 1]
      ) ||
      e.target.value === ""
    ) {

        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.files[0],
            valid: true,
          },
        });
    }
    else {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: "",
            valid: false,
          },
        });
      }
  };
  const handleSubmit=async(e)=>{
    setIsSubmit(true)
    e.preventDefault()
   console.log(formData,"formData")
   let validForm = true;
   
   for (let key in formData) {
    if (formData[key].required) {
      if (!formData[key].textValue) {
        validForm = false;
      }
      if (!validForm) {
        return;
      }
    }

    if (formData[key].type === "file" && formData[key].valid == false) {
      return;
    }
}

    const form = new FormData();
    if(state){
        form.append("AboutUsID",state?.AboutUsID)
      }
    form.append("Title", formData.Title.textValue);
    form.append("Description", formData.Description.textValue);
        form.append("AboutUsImage",  formData.Images.textValue ? !(formData.Images.textValue instanceof Blob) ? formData.Images.textValue :  await compressImage(formData.Images.textValue,0.9) : "");
        if(  !isCancle){
          if(state?.AboutUsImage){
            form.append("OldAboutUsImage",state?.AboutUsImage)
          }
        }
      if(state){
        form.append("UpdatedByUserID",Cookies.get("ckUserID"))
        form.append("UpdatedByUserName",Cookies.get("ckUserName"))
      }else{
        form.append("CreatedByUserID",Cookies.get("ckUserID"))
        form.append("CreatedByUserName",Cookies.get("ckUserName"))
      }
      const config = { headers: { "Content-Type": "multipart/form-data" } }
      axios.post(`${APIURL}/AboutUs/SetAboutUs`, form, config)
        .then((res) => {
          console.log(res, "res")
          if(state && res.data?.status == 1 ){
            Swal.fire({
              title: "Data Updated successfully!",
              icon: "success",
              timer: 1500,
showConfirmButton: false
            });
            navigate("/westKameng/viewAboutUs")
          }
          else if(res.data?.status == 1 ) {
            Swal.fire({
              title: "Data Inserted successfully!",
              icon: "success",
              timer: 1500,
showConfirmButton: false
            });
            navigate("/westKameng/viewAboutUs")
          } 
          else if(res.data?.status==0){
            Swal.fire({
              title: res.data.message,
              icon: "error",
              timer: 1500,
showConfirmButton: false
            });
          }
        }
        )
        .catch((err)=>{
          console.log(err)
        })
  }
     const handleCancle=()=>{
      setFormData((prevFormData) => ({
        ...prevFormData,
        Title: {
          ...prevFormData.Title,
          textValue:  "",
          required: false,
        },
        Description: {
          ...prevFormData.Description,
              textValue: "",
              required: true,
            },
        Images:{
          ...prevFormData.Images,
                textValue:"",
                required:  false  ,
                valid: true,
                type: "file",
            }
      }));

    setIsCancle(true)
  }
  return (
    <div className="body-wrapper" style={{backgroundColor:"#d3d3d3",minHeight:"100vh"}}>
      <div className="container-fluid">
        <div  className="card mt-4" style={{boxShadow: "2px 3px 15px"}}>
          <div className="px-3 pt-4 border-bottom">
            <h5>Add About Us</h5>
          </div>
          <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
    
              <div className="col-md-3 mb-2 ">
                <div className="form-group">
                  <label htmlFor="hotelName" className="form-label fw-semibold">
                    {" "}
                    Title <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    onChange={handleInputChange}
                    name="Title"
                    value={formData.Title.textValue}
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    style={{
                      border: `1px solid ${
                        isSubmit && !formData.Title.textValue
                          ? "red"
                          : isSubmit
                          ? "green"
                          : "light-gray"
                      }  `,
                    }}
                  ></input>
                  {isSubmit && !formData.Title.textValue && (
                    <span className="text-danger">*</span>
                  )}
                  {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                </div>
              </div>    
             <div className="col-md-3 mb-4">
                <div className="form-group mb-3">
                  <label className="form-label fw-semibold">
                  About Us Image

                  </label>
                  <input
                    name="Images"
                    type="file"
                    onChange={handleFileChange}
                    accept="image/jpeg, image/png, image/jpg"
                    className="form-control"
                    style={{
                      border: `1px solid ${
                        isSubmit && !formData.Images.valid
                          ? "red"
                          : isSubmit
                          ? "green"
                          : "light-gray"
                      }  `,
                    }}
                  />

                  {!formData?.Images?.valid && (
                    <span className="text-danger">
                      *please upload valid image{" "}
                    </span>
                  )}
                     {!isCancle && state?.AboutUsImage  && <span >{state?.AboutUsImage}</span>}
                </div>
              </div>
   
            {  <div className='col-md-12'>
            <label htmlFor="hotelName" className="form-label fw-semibold">
                    {" "}
                    Description<span style={{ color: "red" }}>*</span>
                  </label>
                    <div className="form-group" style={{ width: '100%' }}>
                        <div className="img-thumbnail"  style={{
                          border: `1px solid ${
                            isSubmit && !formData.Description.textValue
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}>
                        <CKEditor
                    editor={ ClassicEditor }
                    data={formData.Description.textValue}
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        // setLetterData(data)
                        setFormData(prev=>{return {
                          ...prev,
                          Description:{
                            ...prev.Description,
                            textValue:data
                          }
                        }})
                    }}
                />     
                    {isSubmit && !formData.Description.textValue && (
                    <span className="text-danger">*</span>
                  )}           
                        </div>
                        </div>
                    </div>}
            </div>
            <div className="d-flex align-items-center gap-2 mt-4">
                    <button
                      type="submit"
                      className="btn btn-outline-primary font-weight-bolder ml-2 "
                    >
                      Submit
                    </button>
                    <button
                    onClick={handleCancle}
                      type="button"
                      className="btn btn-outline-danger font-weight-bolder ml-2"
                    >
                      Cancel
                    </button>
                    <button 
                    onClick={()=>{navigate("/westKameng/viewAboutUs") }}
                      type="button"
                      className="btn btn-outline-primary font-weight-bolder ml-2"
                    >
                      View
                    </button>
                  </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAboutUs;

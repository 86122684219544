import React, { useEffect, useState } from "react";
import { Accordion, Table } from "react-bootstrap";

import { APIURL, IMAGEMODELURL } from "../Process";
import EyeOpen from "../Assets/eyeopen.png";
import EyeClose from "../Assets/eyeclose.png";
import Edit from "../Assets/Edit.png";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import Cookies from "js-cookie";
const TourismHistory = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [historyData, setHistoryData] = useState();
  const WestKamengHistoryImageDIR = `${IMAGEMODELURL}/WestKamengHistory`;
  useEffect(() => {
    getWestKamengHistory();
  }, []);
  const getWestKamengHistory = async () => {
    setIsLoading(true)
    try {
      const res = await fetch(`${APIURL}/WestKamengHistory/GetWestKamengHistory`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          WestKamengHistoryID: "",
          HistoryType: "tourist"
        }),
      })
      const result = await res.json()
      if (result?.data?.length) {
        const tempt = [{
          obj: result?.data[0],
          isDetails: false,
        }];
        setHistoryData(tempt);
      } else {
        setHistoryData([]);
      }
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false);
      console.log(err)
    }

  };
  const handleEyeOn = (index) => {
    console.log("eyeon");
    const tempt = [...historyData];
    tempt.splice(index, 1, {
      ...historyData[index],
      isDetails: true,
    });
    setHistoryData(tempt);
  };
  const handleEyeOff = (index) => {
    const tempt = [...historyData];
    tempt.splice(index, 1, {
      ...historyData[index],
      isDetails: false,
    });
    setHistoryData(tempt);
  };
  const editHistoryDetails = (element) => {
    navigate("/westKameng/addWestKamengHistoryDetails", {
      state: element.obj,
    });
  };
  const deleteImageDetails = async (element) => {
    console.log(element, "element");
    try {
      const res = await fetch(`${APIURL}/ImageDetail/DeleteImages`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ImageDetailID: `${element.ImageDetailID}`,
          Image: element.Image,
        }),
      });
      const result = await res.json();
      if (result.status === 1) {
        Swal.fire({
          title: "Successfully Deleted!",
          icon: "success",
          timer: 1500,
showConfirmButton: false,
        });
        getWestKamengHistory();
      }
    } catch (err) {
      console.log(err)
    }

  };
  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: "6",
          left: "600px",
          top: "300px",
        }}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="200"
          height="200"
          visible={true}
        />
      </div>
    );
  }
  return (
    <div className="body-wrapper">
      <div className="container-fluid">
        <Table
          style={{
            marginTop: "15px",
            marginBottom: "15px",
            boxShadow: "2px 3px 15px",
            textAlign: "left",
            border: "1px solid gray",
          }}
          striped
          bordered
          hover
        >
          <thead>
            <tr>
              <th>Sr.No</th>

              <th>HistoryType</th>
              <th>Description</th>
              {Cookies.get("ckUserType") == "Admin" && <th>Edit</th>}
            </tr>
          </thead>
          <tbody>
            {historyData?.map((element, index) => (
              <>
                <tr>
                  <td>{index + 1}</td>
                  <td>{element.obj.HistoryType}</td>
                  <td style={{ width: "70%" }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          element.obj.Description
                        ),
                      }}
                    ></div>
                  </td>
                  {Cookies.get("ckUserType") == "Admin" && (
                    <td>
                      <img
                        onClick={() => {
                          editHistoryDetails(element);
                        }}
                        src={Edit}
                        height={"25px"}
                        alt=""
                      />
                    </td>
                  )}
                </tr>
                {element.isDetails && (
                  <tr>
                    <td colSpan={11}>
                      <Table
                        style={{
                          textAlign: "left",
                          border: "1px solid gray",
                        }}
                        striped
                        bordered
                        hover
                      >
                        <thead>
                          <th className="p-2">Images Details</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ border: "none" }} className="d-flex">
                              {" "}
                              {element.obj.ImageDetail ? (
                                element.obj.ImageDetail.map(
                                  (element, index) => (
                                    <div
                                      className="d-flex"
                                      style={{
                                        flexDirection: "row",
                                        width: "100px",
                                        marginBottom: "10px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <img
                                        key={index}
                                        src={
                                          element?.Image
                                            ? `${WestKamengHistoryImageDIR}/${element.Image}`
                                            : WestKamengHistoryImageDIR
                                        }
                                        style={{
                                          marginLeft: "10px",
                                          height: 90,
                                          width: 90,
                                          borderWidth: 0,
                                          border: "1px solid #007AB1",
                                        }}
                                      />
                                      <span
                                        onClick={() =>
                                          deleteImageDetails(element)
                                        }
                                        style={{
                                          color: "black",
                                          textAlign: "right",
                                          fontSize: "1.4em",
                                          position: "relative",
                                          right: "20px",
                                          bottom: "4px",
                                        }}
                                      >
                                        <MdDelete />
                                      </span>
                                    </div>
                                  )
                                )
                              ) : (
                                <td
                                  style={{
                                    padding: "5px",
                                    fontSize: "1.3em",
                                    color: "gray",
                                  }}
                                >
                                  No Images Found 😟
                                </td>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </Table>
        {!historyData?.length && <div
          style={{
            textAlign: "center",
            padding: "20px",
            fontSize: "20px",
            fontWeight: "bold",
            color: "#ff0000",
            backgroundColor: "#f0f0f0",
            border: "1px solid #e0e0e0",
            borderRadius: "5px",
            width: "100%",
          }}
        >
          NO DATA FOUND
        </div>}
      </div>
    </div>
  )
}

export default TourismHistory
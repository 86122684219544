import React, { useContext } from 'react'
import { placeContext } from '../Context/PlaceContext'
import { Navigate, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';
import { YakatopiaRouterData } from '../data';
import Header from '../component/Header';
import NotFoundPage from "../component/PageNotFound" 
import Information from '../component/Information';

const LoginAuth = ({children}) => {
    
  const dirName=useLocation()

  const shouldHideHeaderFooter = YakatopiaRouterData.includes(dirName.pathname.toLocaleLowerCase());
  console.log(dirName.pathname,"pathName")
    if(!Cookies.get("ckUserID") 
      && dirName.pathname.split("/")[1] !== "hotel" && dirName.pathname.split("/")[1]!=="restaurant"
     && dirName.pathname.toLowerCase() !=="/privacyandpolicy"
      && dirName.pathname.toLowerCase() !=="/westkameng/information"
      ){
        Cookies.remove("ckUserID")
        Cookies.remove("ckUserName")
        Cookies.remove("ckUserType")
        if(shouldHideHeaderFooter  ){
return <Navigate to={"/westKameng/splash"} />
        }
        return <NotFoundPage show={false} />
    }

    if(dirName.pathname.split("/")[1] === "hotel"
    || dirName.pathname.split("/")[1] === "restaurant"
    || dirName.pathname.toLowerCase() =="/privacyandpolicy"
    // || dirName.pathname.toLowerCase() =="/westkameng/information"
    ){
   return <Navigate to={`${dirName.pathname}`} /> 
    }
    if(dirName.pathname.toLowerCase() =="/westkameng/information"){
      return 
    }
       if(shouldHideHeaderFooter){
        if(dirName.pathname=="/westKameng/splash"){
          return children
        }
        return  <>
        <Header />
        {children}
        </>
       }
       else{
        return <NotFoundPage show={true} />
       }
       
     
}

export default LoginAuth
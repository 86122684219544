import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Edit from "../../../Assets/Edit.png";
import Delete from "../../../Assets/Delete.png";
import ImageNotFound from "../../../Assets/Noimage.png";
import { FaToggleOn, FaToggleOff, FaEyeSlash } from "react-icons/fa";
import { RotatingLines } from "react-loader-spinner";
import XLSX from "xlsx-color";
import moment from "moment";
import "./index.css";
import { APIURL, IMAGEMODELURL } from "../../../Process";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ShowPasswardModel from "../../ShowPasswardModel";
import { handleExcellDownload, handleKeyDownNumber, handlePaste } from "../../../HelperMethod";
import Cookies from 'js-cookie'
import DOMPurify from "dompurify";
import EyeOpen from "../../../Assets/eyeopen.png";
import EyeClose from "../../../Assets/eyeclose.png";
import { MdDelete } from "react-icons/md";

const ViewWeatherAlertDetails = () => {
  const navigate = useNavigate();
  const WeatherImageUrl = `${IMAGEMODELURL}/WeatherAlertImage`;

  const [totalCount, setTotalCount] = useState();
  const [openModel, setOpenModel] = useState(false);
  const [showPasswordID, setShowPasswordID] = useState("");
  const [pageCount, setPageCount] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState({
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    fetchInitialWeatherAlertDetailsData(pageCount);
  }, []);

  const fetchInitialWeatherAlertDetailsData = async (count) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${APIURL}/WeatherAlertController/getWeatherAlert`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "WeatherAlertID": "",
          "Title": "",
          "Date": "",
          "Time": "",
          "IsActive": "",
          "IsDelete": "false",
          IsPagination: "true",
          UniqueID: "",
          PerPage: count,
        }),
      });
      const result = await res.json();
      if (result.status == true) {

        const tempt = [];
        result.data.forEach((element) => {
          tempt.push({
            obj: element,
            isDetails: false,
          });
        });
        console.log(tempt, "tempttempt")
        setIsLoading(false);
        setTableData(tempt);
        setPageId(1);
        setAllTableData(tempt);
        setTotalCount(result.TotalCount);

      } else {
        Swal.fire({
          title: result?.message || "",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const confirmDelete = async (id) => {
    try {
      const res = await fetch(`${APIURL}/WeatherAlertController/removeWeatherAlert`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          WeatherAlertID: id,
          "RemovedByUserID": Cookies.get("ckUserID"),
        }),
      });

      const result = await res.json();
      if (result.status == 1) {
        Swal.fire({
          title: "Successfully Deleted!",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        fetchInitialWeatherAlertDetailsData(pageCount);
      } else {
        Swal.fire({
          title: result?.message || "",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
      console.log(result);
    } catch (err) {
      console.log(err);
    }
  };
  const handleDeleteWeatherAlertDetails = async (id) => {
    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDelete(id);
      }
    });
  };
  const editAppointmentDetails = (element) => {
    navigate("/westKameng/addWeatherAlertDetails", {
      state: element.obj,
    });
  };
  const handleEyeOn = (index) => {
    console.log("eyeon");
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: true,
    });
    setTableData(tempt);
  };
  const handleEyeOff = (index) => {
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: false,
    });
    setTableData(tempt);
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleFilterInputChange = (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  };


  const setNextApi = async () => {
    console.log(pageId, "pageID", pageCount);
    if (pageId * pageCount < allTableData.length) {
      const tempt = [...allTableData];
      console.log(tempt, pageId * pageCount, (pageId + 1) * pageCount);
      const sliced = tempt.slice(pageId * pageCount, (pageId + 1) * pageCount);
      setTableData(sliced);
      setPageId(pageId + 1);
      return;
    }
    const paginationIndex = allTableData[pageId * pageCount - 1]?.obj?.WeatherAlertID;
    if (!paginationIndex) {
      return;
    }
    console.log(pageId, allTableData);
    try {
      const res = await fetch(`${APIURL}/WeatherAlertController/getWeatherAlert`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          StartDate: filterData.startDate?.split("-").reverse().join("-") || "",
          EndDate: filterData.endDate?.split("-").reverse().join("-") || "",
          IsPagination: "true",
          IsActive: "",
          IsDelete: "false",
          UniqueID: `${paginationIndex}`,
          PerPage: `${pageCount}`,
        }),
      });
      const result = await res.json();
      console.log(result);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      console.log(tempt, "tempt");
      setTableData(tempt);
      setPageId(pageId + 1);
      setAllTableData([...allTableData, ...tempt]);
    } catch (err) {
      console.log(err);
    }
  };
  const setPreviousApi = () => {
    if (pageId == 1) {
      return;
    }
    const tempt = [...allTableData];
    console.log(pageId, "pageId");
    console.log(
      pageId,
      tempt,
      (pageId - 1) * pageCount,
      pageId * pageCount + 1
    );
    const sliced = tempt.slice(
      (pageId - 2) * pageCount,
      (pageId - 1) * pageCount
    );
    setTableData(sliced);
    setPageId(pageId - 1);
  };
  const searchHandle = async (count, paginationIndex, filterData) => {
    // setIsLoading(true);
    try {

      const res = await fetch(`${APIURL}/WeatherAlertController/getWeatherAlert`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          StartDate: filterData.startDate?.split("-").reverse().join("-") || "",
          EndDate: filterData.endDate?.split("-").reverse().join("-") || "",
          IsActive: "",
          IsDelete: "false",
          IsPagination: "true",
          PerPage: count,
          PageNo: "",
          UniqueID: ""
        }),
      });
      const result = await res.json();
      // console.log(result);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      console.log(tempt, "?tempt");
      setTableData(tempt);
      setIsLoading(false);
      setAllTableData(tempt);
      setPageId(1);
      setTotalCount(result.TotalCount);

    } catch (err) {
      console.log(err);
    }
  };
  const handlePageCount = (e) => {
    setPageCount(e.target.value);
    searchHandle(e.target.value, "", filterData);
  };
  const handleCancle = () => {
    setFilterData({
      StartDate: "",
      EndDate: "",
    });
    fetchInitialWeatherAlertDetailsData(pageCount);
  };
  async function fetchExcelData() {
    // setIsLoading(true)
    setIsLoading(true);
    try {
      const res = await fetch(`${APIURL}/WeatherAlertController/getWeatherAlert`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          StartDate: filterData?.startDate?.split("-").reverse().join("-") || "",
          EndDate: filterData?.endDate?.split("-").reverse().join("-") || "",
          "IsActive": "true",
          "IsDelete": "false",
          IsPagination: "false",
          UniqueID: "",
          PerPage: "",
        }),
      });
      const result = await res.json();
      handleDownloadExcel(result.data);
    } catch (err) {
      console.log(err);
    }
  }
  const convertHtmlToPlainText = (html) => {
    // Sanitize the HTML to remove any dangerous scripts
    let sanitizedHtml = DOMPurify.sanitize(html);

    // Create a temporary DOM element to extract plain text
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sanitizedHtml;

    // Replace &nbsp; with regular spaces
    const text = tempDiv.innerText.replace(/&nbsp;/g, ' ').replace(/\s+/g, ' ');

    // Replace line breaks with new lines
    return text.replace(/<br\s*\/?>/g, '\n'); // Handle <br> tags as new lines
  };
  const handleDownloadExcel = (array) => {
    const header = [
      "Title",
      "Date",
      "Time",
      "Description",
    ];
    const body = [];
    array?.forEach((element, index) => {
      const descriptionWithoutTags = element.Description.replace(/<[^>]*>?/gm, '');
      body.push([
        element.Title || "",
        element.Date || "",
        element.Time || "",
        descriptionWithoutTags
      ]);
    });
    if (!body.length) {
      setIsLoading(false);
      return Swal.fire({
        title: "No Data Found!",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    }
    //   setIsLoading(false)

    handleExcellDownload(header, body, "View Weather Report");
    // Save the workbook
    setIsLoading(false);
    // XLSX.writeFile(
    //   wb,
    //   `WeatherAlert_Details${moment().format("DD-MM-YYYY_HH:mm")}.xlsx`
    // );
  };
 
  const deleteImageDetails = async (element) => {
    debugger
    console.log(element, "element");
    Swal.fire({
      title: "Are you sure!",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        confirmImageDelete(element);
      }
    });
  };

  const confirmImageDelete = async (element) => {
    debugger
    console.log(element, "element");
    
    const imageType = "WeatherAlertImage"; 
    const image = element.Image; 
  
    const res = await fetch(`${APIURL}/ImageDetail/DeleteImages`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ImageDetailID: element.ImageDetailID,
        ImageType: imageType,
        Image: image,
      }),
    });
  
    const result = await res.json();
    if (result.status === 1) {
      Swal.fire({
        title: "Successfully Deleted!",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      // Refresh data or update the UI
      fetchInitialWeatherAlertDetailsData(pageCount);
    }
    console.log(result, "result");
  };

  const handleWeatherAlertActive = async (WeatherAlertID, status) => {
    debugger
    console.log(WeatherAlertID, "WeatherAlertID");
    try {
      const res = await fetch(`${APIURL}/WeatherAlertController/setWeatherAlertIsActiveStatus`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          WeatherAlertID: WeatherAlertID,
          IsActive: `${status}`,
          UpdatedByUserID: Cookies.get("ckUserID"),         
        }),
      });
      const result = await res.json(); 
      
    
      searchHandle(pageCount, 0, filterData);
      // fetchInitialWeatherAlertDetailsData(pageCount);
    } catch (err) {
      console.log(err)
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: "6",
          left: "600px",
          top: "300px",
        }}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="200"
          height="200"
          visible={true}
        />
      </div>
    );
  }
  return (
    <div className="body-wrapper">
      <div className="container-fluid">
        {isLoading && (
          <div
            style={{
              position: "absolute",
              zIndex: "6",
              left: "600px",
              top: "300px",
            }}
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="200"
              height="200"
              visible={true}
            />
          </div>
        )}
       {openModel && (
          <ShowPasswardModel
            openModel={openModel}
            setOpenModel={setOpenModel}
            UserID={showPasswordID}
            User Type={"Admin"}
          />
        )}
        <div
          className={`pt-2 pb-2 WeatherAlert-filter  ${isOpen ? "open" : "closed"}`}
        >
          <Row>
            <Col md={2} sm={2} className="col-4">
              <div className="d-flex gap-2 pt-1">
                <span>Total Count</span>
                <span>{totalCount}</span>
              </div>
            </Col>
            <Col md={2} sm={2} className="col-4">
              <Form.Select
                onChange={handlePageCount}
                size="sm"
                className="w-70"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </Form.Select>
            </Col>

            <Col md={{ span: 2, offset: 6 }} className="col-4">
              <Button
                className="float-end"
                md={2}
                variant="outline-primary"
                size="sm"
                onClick={handleToggle}
              >
                {isOpen ? "Close Filter" : "Open Filter"}
              </Button>
            </Col>
          </Row>
          {isOpen && (
            <div
              className="filter-content row"
              style={{
                marginTop: "20px",
                padding: "20px",
                border: "1px solid gray",
              }}
            >
              <Form className="d-flex row pb-4">
                <div className="form-group mb-2 col-md-3">
                  <label>
                    Start Date
                    <span style={{ color: "#72bdce", fontSize: 10 }} />
                  </label>
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    value={filterData.startDate}
                    onChange={handleFilterInputChange}
                    name="startDate"
                    type="date"
                    class="form-control"
                    placeholder="dd-MM-yyyy"
                  ></input>
                </div>
                <div className="form-group mb-2 col-md-3">
                  <label>
                    End Date
                    <span style={{ color: "#72bdce", fontSize: 10 }} />
                  </label>
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    min={filterData.startDate}
                    value={filterData.endDate}
                    onChange={handleFilterInputChange}
                    name="endDate"
                    type="date"
                    class="form-control"
                    placeholder="dd-MM-yyyy"
                  ></input>
                  {/* {searched &&
                            filterData.endDate < filterData.startDate && (
                              <span style={{ color: "red" }}>
                                End Date Must be greater than start date
                              </span>
                            )} */}
                </div>

                <div className="col-md-3" style={{ position: "relative", top: "27px" }}>
                  <Button
                    size="sm"
                    variant="primary"
                    className="me-2"
                    onClick={() => searchHandle(pageCount, "", filterData)}
                  >
                    Apply
                  </Button>

                  <Button
                    className="me-2"
                    onClick={fetchExcelData}
                    size="sm"
                    variant="success"
                  >
                    Export
                  </Button>
                  <Button onClick={handleCancle} size="sm" variant="danger">
                    Cancel
                  </Button>
                </div>
                {/* Add more InputGroup components for additional filters */}
              </Form>
            </div>
          )}
        </div>
        <div className="overflow-auto">
          <Table
            style={{
              boxShadow: "2px 3px 15px",
              textAlign: "left",
              border: "1px solid gray",
            }}
            striped
            bordered
            hover
          >
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>Details</th>
                <th>Status</th>
                <th>Title</th>
                <th>Date</th>
                <th>Time</th>
                <th>Description</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((element, index) => (
                <>
                  <tr>
                    <td style={{ width: "2%" }}>{(pageId - 1) * pageCount + (index + 1)}</td>

                    <td>
                      {!element.isDetails && (
                        <img
                          onClick={() => handleEyeOn(index)}
                          src={EyeOpen}
                          height={"22px"}
                          alt=""
                        />
                      )}{" "}
                      {element.isDetails && (
                        <img
                          onClick={() => handleEyeOff(index)}
                          src={EyeClose}
                          height={"22px"}
                          alt=""
                        />
                      )}
                    </td>

                    
                    <td>
                      {" "}
                      {element.obj.IsActive ? (
                        <FaToggleOn
                          onClick={() =>
                            handleWeatherAlertActive(element.obj.WeatherAlertID, 0)
                          }
                          style={{ color: "green", fontSize: "1.9em" }}
                        />
                      ) : (
                        <FaToggleOff
                          onClick={() =>
                            handleWeatherAlertActive(element.obj.WeatherAlertID, 1)
                          }
                          style={{ color: "red", fontSize: "1.8em" }}
                        />
                      )}
                    </td>               

                  
                    <td >{element.obj.Title}</td>
                    <td>{element.obj.Date}</td>
                    <td>{element.obj.Time}</td>
                    <td style={{ width: "50%" }}>  <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          element.obj.Description
                        ),
                      }}
                    ></div></td>
                    <td>
                      <img
                        onClick={() => editAppointmentDetails(element)}
                        src={Edit}
                        height={"25px"}
                        alt=""
                      />
                    </td>
                    <td>
                      <img
                        src={Delete}
                        onClick={() =>
                          handleDeleteWeatherAlertDetails(element.obj.WeatherAlertID)
                        }
                        height={"25px"}
                        alt=""
                      />
                    </td>
                  </tr>
                  {element.isDetails &&
                    <tr>
                      <td colSpan={7}>
                        {element.obj.ImageDetailData?.length && (
                          <Table
                            style={{
                              textAlign: "left",
                              border: "1px solid gray",
                            }}
                            striped
                            bordered
                            hover
                          >
                            <thead>
                              <tr>
                                <th>Weather Alert Images</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="d-flex">
                                  {element.obj.ImageDetailData?.map(
                                    (element, index) => (
                                      <div
                                        className="d-flex"
                                        style={{
                                          flexDirection: "row",
                                          width: "100px",
                                          marginBottom: "10px",
                                          marginRight: "10px",
                                        }}
                                      >
                                        <img
                                          src={
                                            element?.Image
                                              ? `${WeatherImageUrl}/${element.Image}`
                                              : ImageNotFound
                                          }
                                          style={{
                                            marginLeft: "10px",
                                            height: 90,
                                            width: 90,
                                            borderWidth: 0,
                                            border: "1px solid #007AB1",
                                          }}
                                        />
                                        <span
                                          onClick={() =>
                                            deleteImageDetails(element)
                                          }
                                          style={{
                                            color: "black",
                                            textAlign: "right",
                                            fontSize: "1.4em",
                                            position: "relative",
                                            right: "20px",
                                            bottom: "4px",
                                          }}
                                        >
                                          <MdDelete />
                                        </span>
                                      </div>
                                    )
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        )}
                      </td>
                    </tr>
                  }
                </>
              ))}
            </tbody>
            <tbody>
              <tr>
                {!tableData.length && (
                  <td colSpan={12}>
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#ff0000",
                        backgroundColor: "#f0f0f0",
                        border: "1px solid #e0e0e0",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      NO DATA FOUND
                    </div>
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="pt-2 pb-2">
          <Button onClick={setPreviousApi} variant="primary" size="sm">
            PREVIOUS
          </Button>{" "}
          <Button onClick={setNextApi} variant="secondary" size="sm">
            NEXT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewWeatherAlertDetails;

import './App.css';
// import Routes from './Routing';
import Header from './component/Header';
// import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route, useParams, } from "react-router-dom"
import AddPlaceDetails from './component/PlaceDetails/AddPlaceDetails';

import AssignActivity from './component/PlaceDetails/AssignActivity';
import MyProvider from './Context/PlaceContext';
import AssignItems from './component/PlaceDetails/AssignItems';
import LoginPage from './component/Login';
import HotelDetails from './component/HotelDetails/HotelDetails';
import ViewHotelDetails from './component/HotelDetails/ViewHotelDetails';
import UserAccessRoute from './Route/UserAccessRoute';
import HotelAccess from './Route/HotelAccess';
import LoginAuth from './Route/LoginAuth';
import ActivityAccess from './Route/ActivityAccess';
import HotelDetailAccess from './Route/HotelDetailAccess';
import AddRestaurentDetails from './component/RestaurantDetails/AddRestaurentDetails';
import ViewRestaurentDetails from './component/RestaurantDetails/ViewRestaurentDetails';
import RestaurantAccess from './Route/RestaurantAccess';
import RestaurantDetailAccess from './Route/RestaurantDetailAccess';
import HotelBooking from './component/HotelBooking/HotelBooking';
import ViewWestKamengHistoryDetails from './component/WestKamengHistory/ViewWestKamengHistoryDetails';
import WestKamengHistoryDetails from './component/WestKamengHistory/WestKamengHistoryDetails';
import ViewReview from './component/Review/GetReview';
import AddTermsAndConfition from './component/TermsAndCondition/AddTermsAndConfition';
import ViewTermsAndCondition from './component/TermsAndCondition/ViewTermsAndCondition';
import HotelBookingDetailsEccess from './Route/HotelBookingDetailsEccess';
import AddBanner from './component/Banner/AddBanner';
import ViewBanner from './component/Banner/ViewBanner';
import Dashboard from './component/Dashboard';
import ReviewAccess from './Route/ReviewAccess';
import ViewUserDetails from './component/UserDetails/ViewUserDetails';
import LoginAccess from './Route/LoginAccess';
import DashBoardAccess from './Route/DashBoardAccess';
import TourismHistory from './component/TourismHistory';
import AddContactDetails from './component/AddAboutUs';
import AddAboutUs from './component/AddAboutUs';
import ViewAboutUs from './component/ViewAboutUs';
import AddContactUs from './component/AddContactUs';
import ViewContactUs from './component/ViewContactUs';
import AddAppHotel from './component/AddAppHotel';
import AddAppRestaurant from "./component/AddAppRestaurant"
import Touroperator from "./component/Touroperator"
import ViewTouroperator from "./component/viewTouroperator"
import PackageDetails from './component/PackageDetails/AddPackagedetails';
import ViewPackageDetails from './component/PackageDetails/ViewPackageDetails';
import ViewPackageEnquiry from './component/PackageEnquiry/ViewPackageEnquiry';
import Destination from './component/Destination';
import ViewDestination from './component/viewDestination';

import PrivacyAndPolicy from './component/privacyandpolicy';
import ViewPlaceDetails from './component/PlaceDetails/ViewPlaceDetails';
import Information from './component/Information';
import AddUserAdminDetails from './component/UserAdminDetails/AddUserAdminDetails';
import ViewUserAdminDetails from './component/UserAdminDetails/ViewUserAdminDetails';
import AddWeatherAlertDetails from './component/WeatherAlertDetails/AddWeatherAlertDetails';
import ViewWeatherAlertDetails from './component/WeatherAlertDetails/ViewWeatherAlertDetails';
import ViewGeneralActivity from './component/GeneralActivity/ViewGeneralActivity';
import AddGeneralActivity from './component/GeneralActivity/AddGeneralActivity';
import AddNearByToilet from './component/NearByToilet/AddNearByToilet';
import ViewNearByToilet from './component/NearByToilet/ViewNearByToilet';

function App() {


  return (
    <MyProvider>
      <BrowserRouter>
        <Routes>
          <Route path={"/*"} element={<LoginAuth />} />
          <Route exact path={"/hotel/:id"} element={<AddAppHotel />} />
          <Route exact path={"/hotel"} element={<AddAppHotel />} />
          <Route exact path={"/restaurant/:id"} element={<AddAppRestaurant />} />
          <Route exact path={"/restaurant"} element={<AddAppRestaurant />} />
        </Routes>
        <Routes>
          <Route
            path='/westKameng/*'
            element={
              <LoginAuth>
                <>
                  <Header />
                  <Routes>
                    {/* <Route path={"/"} element={<DashBoardAccess><Dashboard  /></DashBoardAccess>}  /> */}
                    <Route path='/addplacedetails' element={<UserAccessRoute ><AddPlaceDetails /></UserAccessRoute>} />
                    <Route path='/viewplacedetails' element={<UserAccessRoute ><ViewPlaceDetails /> </UserAccessRoute>} />
                    <Route path='/assignActivity' element={<ActivityAccess><AssignActivity /></ActivityAccess>} />
                    <Route path='/assignItems' element={<ActivityAccess><AssignItems /></ActivityAccess>} />
                    <Route path='/addhoteldetails' element={<HotelAccess><HotelDetails /></HotelAccess>} />
                    <Route path='/viewHotel' element={<HotelDetailAccess><ViewHotelDetails /></HotelDetailAccess>} />
                    <Route path='/addRestaurant' element={<RestaurantAccess><AddRestaurentDetails /></RestaurantAccess>} />
                    <Route path='/viewRestaurant' element={<RestaurantDetailAccess><ViewRestaurentDetails /></RestaurantDetailAccess>} />
                    <Route path='/viewBookingDetails' element={<HotelBookingDetailsEccess><HotelBooking /></HotelBookingDetailsEccess>} />
                    <Route path='/addWestKamengHistoryDetails' element={<UserAccessRoute ><WestKamengHistoryDetails /></UserAccessRoute>} />
                    <Route path='/viewWestKamengHistoryDetails' element={<UserAccessRoute><ViewWestKamengHistoryDetails /></UserAccessRoute>} />
                    <Route path='/viewReviews' element={<ReviewAccess ><ViewReview /></ReviewAccess>} />
                    <Route path='/ViewTermsAndCondition' element={<UserAccessRoute ><ViewTermsAndCondition /></UserAccessRoute>} />
                    <Route path='/AddTermsAndCondition' element={<UserAccessRoute ><AddTermsAndConfition /></UserAccessRoute>} />
                    <Route path='/addbanner' element={<UserAccessRoute ><AddBanner /></UserAccessRoute>} />
                    <Route path='/viewBanner' element={<UserAccessRoute ><ViewBanner /></UserAccessRoute>} />
                    <Route path='/ViewUserDetails' element={<UserAccessRoute><ViewUserDetails /></UserAccessRoute>} />
                    <Route path='/Dashboard' element={<DashBoardAccess><Dashboard /></DashBoardAccess>} />
                    <Route path='/viewWestKamengTourismDetails' element={<UserAccessRoute><TourismHistory /></UserAccessRoute>} />
                    <Route path='/addAboutUs' element={<UserAccessRoute><AddAboutUs /></UserAccessRoute>} />
                    <Route path='/viewAboutUs' element={<UserAccessRoute><ViewAboutUs /></UserAccessRoute>} />
                    <Route path='/addContactUs' element={<UserAccessRoute><AddContactUs /></UserAccessRoute>} />
                    <Route path='/viewContactUs' element={<UserAccessRoute><ViewContactUs /></UserAccessRoute>} />
                    <Route path='/addTourOperator' element={<UserAccessRoute><Touroperator /></UserAccessRoute>} />
                    <Route path='/viewTourOperator' element={<UserAccessRoute><ViewTouroperator /></UserAccessRoute>} />
                    <Route path='/addUserAdmin' element={<UserAccessRoute><AddUserAdminDetails /></UserAccessRoute>} />
                    <Route path='/viewUserAdmin' element={<UserAccessRoute><ViewUserAdminDetails /></UserAccessRoute>} />
                    <Route path='/addPackageDetails' element={<UserAccessRoute><PackageDetails /></UserAccessRoute>} />
                    <Route path='/viewPackageDetails' element={<UserAccessRoute><ViewPackageDetails /></UserAccessRoute>} />
                    <Route path='/viewPackageEnquiry' element={<UserAccessRoute><ViewPackageEnquiry /></UserAccessRoute>} />
                    <Route path='/addDestination' element={<UserAccessRoute><Destination /></UserAccessRoute>} />
                    <Route path='/viewDestination' element={<UserAccessRoute><ViewDestination /></UserAccessRoute>} />
                    <Route path='/addWeatherAlertDetails' element={<UserAccessRoute><AddWeatherAlertDetails /></UserAccessRoute>} />
                    <Route path='/viewWeatherAlertDetails' element={<UserAccessRoute><ViewWeatherAlertDetails /></UserAccessRoute>} />
                    <Route path='/addGeneralActivity' element={<UserAccessRoute><AddGeneralActivity /></UserAccessRoute>} />
                    <Route path='/viewGeneralActivity' element={<UserAccessRoute><ViewGeneralActivity /></UserAccessRoute>} />
                    <Route path='/addNearByToilet' element={<UserAccessRoute><AddNearByToilet /></UserAccessRoute>} />
                    <Route path='/viewNearByToilet' element={<UserAccessRoute><ViewNearByToilet /></UserAccessRoute>} />
                  </Routes>
                </>
              </LoginAuth>
            }
          />
          <Route exact path='/westKameng/splash' element={<LoginPage />} />
          <Route exact path='/privacyandpolicy' element={<PrivacyAndPolicy />} />
          <Route exact path='/westKameng/information' element={<Information />} />

        </Routes>
      </BrowserRouter>
    </MyProvider>
  );
}

export default App;

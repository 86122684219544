import React, { useState, useRef, useEffect } from 'react'
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import { allowOnlyNumbers, allowOnlyCharacters } from '../inputvalidation';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import axios from "axios";
import { APIURL } from "../Process";

import { useLocation, useNavigate } from "react-router-dom";
import { compressImage } from './CommonComponents';
import Cookies from 'js-cookie';

const Destination = () => {

  const [DestinationID, setDestinationID] = useState('')
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state, "outId")

  const [DestinationName, setDestinationName] = useState('')
  const [DestinationError, setDestinationError] = useState('')

  const [DestinationImage, setDestinationImage] = useState('')
  const [OldDestinationImage, setOldDestinationImage] = useState('')
  const [error, setError] = useState('')
  const [DestinationImageerrorMsg, setDestinationImageerrorMsg] = useState(true)

  const [Images, setImages] = useState([])
  const [ImgerrorMsg, setImgErrorMsg] = useState('')
  const [ImageserrorMsg, setImageserrorMsg] = useState(true)

  const [Description, setDescription] = useState('');
  const [validationError, setValidationError] = useState('');

  const form = useRef(FormWithConstraints);
  const params = new URLSearchParams(useLocation().search);

  const ref1 = useRef();
  const ref2 = useRef();


  useEffect(() => {
    if (state != null) {
      EditProfile(state);
    }
  }, []);

  const handleNameChange = (e) => {
    setDestinationError('')
    setDestinationName(e.target.value);
  }

  const handleEditorChange = (event, editor) => {
    setValidationError('')
    const data = editor.getData();
    setDescription(data);
  };

const HandleDestinationImage = (e) => {
  setError('');
  if (['png', 'jpg', 'jpeg', 'PNG'].includes(e.target.value.split('.')[e.target.value.split('.').length - 1])) {
    setDestinationImageerrorMsg(true);
  } else {
    setDestinationImageerrorMsg(false);
  }
  if (e.target.files.length > 0) {
    setDestinationImage(e.target.files);
  }
  setOldDestinationImage('');
};

const HandleImages = (e) => {
  setError('');
  if (['png', 'jpg', 'jpeg', 'PNG'].includes(e.target.value.split('.')[e.target.value.split('.').length - 1])) {
    setImageserrorMsg(true);
  } else {
    setImageserrorMsg(false);
  }
  const files = e.target.files;
  setImages(files);
  setImgErrorMsg('');
};

  const onSubmitClick = async(e) => {
    debugger;
    e.preventDefault();
    var allvalid = true;

    if (DestinationName.trim() !== '') {
      setDestinationError('');
    } else {
      setDestinationError('*');
      allvalid = false;
    }

    if (DestinationImage.length === 0 && !OldDestinationImage) {
      setError('*');
      allvalid = false;
    } else if (!DestinationImageerrorMsg ) {
      setError('');
      allvalid = false;
    } else {
      setError('');
    }

    if (!DestinationID && Images.length === 0) {
      setImgErrorMsg('*');
      allvalid = false;
    } else if (!ImageserrorMsg && Images.length > 0) {
      setImgErrorMsg('');
      allvalid = false;
    } else {
      setImgErrorMsg('');
    }  
   
    if (!Description.trim()) {
      setValidationError('*');
    } else {
      setValidationError('');
    }
   
    if (allvalid) {
      var form = new FormData();
      if (DestinationID) {
        form.append('DestinationID', DestinationID);
        form.append('DestinationName', DestinationName);
        form.append('Description', Description);
        form.append('OldDestinationImage', OldDestinationImage);
        // -- Single image//
        for (let i = 0; i < DestinationImage.length; i++) {
          form.append('DestinationImage',  DestinationImage[i] ? !(DestinationImage[i] instanceof Blob) ? DestinationImage[i] :  await compressImage(DestinationImage[i],0.9) : "");
        }

        //--multi Image//
        for (let i = 0; i < Images.length; i++) {
          form.append('Images', Images[i]);
        }

        form.append('UpdatedByUserType', Cookies.get("ckUserType"));
        form.append('UpdatedByUserID', Cookies.get("ckUserID"));
        form.append('UpdatedByName', Cookies.get("ckUserName"));
      } else {

        form.append('DestinationID', "");
        form.append('DestinationName', DestinationName);
        form.append('Description', Description);
        form.append('DestinationImage', DestinationImage[0]);

        //--multi Image//
        for (let i = 0; i < Images.length; i++) {
          form.append('Images', Images[i]);
        }

        form.append('EntryByUserType', Cookies.get("ckUserType"));
        form.append('EntryByUserID', Cookies.get("ckUserID"));
        form.append('EntryByName', Cookies.get("ckUserName"));
      }

      const config = { headers: { "Content-Type": "multipart/form-data" } };
      axios
        .post(`${APIURL}/Destination/setDestination`, form, config)
        .then((res) => {
          console.log(res, "res");
          if (res.data.status === 1) {
            if (DestinationID) {
              Swal.fire({
                title: 'Successfully Updated', icon: "success", timer: 1500,
showConfirmButton: false
              });
            } else {
              Swal.fire({
                title: 'Successfully Inserted', icon: "success", timer: 1500,
showConfirmButton: false
              });
            }
          }
          navigate("/westKameng/viewDestination");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const EditProfile = (id) => {
    var data = JSON.stringify({

      "DestinationID": id,
      "DestinationName": "",
      "IsActive": "",
      "IsDelete": "false",
      "IsPagination": "",
      "PerPage": "",
      "UniqueID": ""
    });

    const config = { headers: { "Content-Type": "application/json" } };
    axios
      .post(`${APIURL}/Destination/getDestination`, data, config)
      .then((response) => {
        setDestinationID(response.data.data[0].DestinationID);
        setDestinationName(response.data.data[0]?.DestinationName || '');
        setOldDestinationImage(response.data.data[0].DestinationImage);
        setImages(response.data.data[0]?.Images || '')

        setDescription(response.data.data[0]?.Description || '');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const OnCancelClick = () => {
    setDestinationName('')
    setDescription('')

    setOldDestinationImage('')
    setDestinationImageerrorMsg(true)

    setImageserrorMsg(true)
    ref1.current.value = ''
    ref2.current.value = ''


  }

  return (
    <div className="body-wrapper" style={{ backgroundColor: "#d3d3d3", minHeight: "100vh" }} >
      <div className="container-fluid">
        <div className="card" style={{ boxShadow: "2px 3px 15px" }}>
          <div className="px-3 pt-4 border-bottom">
            <h5>Add Destination</h5>
          </div>
          <FormWithConstraints
            ref={form}
            onSubmit={onSubmitClick}
            noValidate>
            <div className="card-body">
              <div className='row'>
                <div className='col-md-3'>
                  <div className="form-group">
                    <label htmlFor="DestinationName" className="form-label fw-semibold"> Destination Name<span className="text-danger"> *</span></label>
                    <input type="DestinationName" onChange={handleNameChange} required value={DestinationName} maxLength={100} onKeyPress={allowOnlyCharacters} type1="textnm" className="form-control" placeholder="Enter Destination Name"
                      style={{ borderColor: DestinationError ? 'red' : 'green' }} ></input>
                    <span style={{ color: 'red' }}>{DestinationError}</span>
                  </div>
                </div>

                <div className="col-md-3">
                  <label htmlFor="fileInput" className="form-label fw-semibold">Destination Image <span className="text-danger"> *</span></label>
                  <input type="file" ref={ref1} className="form-control" onChange={HandleDestinationImage} accept="image/jpeg, image/png, image/jpg, image/PNG"
                    style={{ border: `1px solid ${((error || !DestinationImageerrorMsg)) ? "red" : onSubmitClick ? "green" : "light-gray"}` }}
                  />
                  {OldDestinationImage !== "" && (<label style={{ color: "Red" }}>{OldDestinationImage ? OldDestinationImage : ""} </label>)}
                  {!DestinationImageerrorMsg && <span className="text-danger">Please Enter Only Image File</span>}
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>

                <div className="col-md-3">
                  <label htmlFor="fileInput" className="form-label fw-semibold">Images
                    <span style={{ color: 'red' }}> (Single/ Multiple)</span>
                  </label>
                  <input type="file" style={{ height: "37px", border: `1px solid ${ImgerrorMsg || !ImageserrorMsg ? "red" : onSubmitClick ? "green" : "light-gray"}` }} ref={ref2} className="form-control" multiple onChange={HandleImages} accept="image/jpeg, image/png, image/jpg, image/PNG" />
                  {!ImageserrorMsg && <span className="text-danger">Please Enter Only Image File</span>}
                  {ImgerrorMsg && <p style={{ color: 'red' }}>{ImgerrorMsg}</p>}<br></br>
                </div>



                <label htmlFor="fileInput" className="form-label fw-semibold">Description <span className="text-danger"> *</span></label>
                <div className="form-group" style={{ width: '100%' }} >
                  <div className="img-thumbnail" style={{ borderColor: validationError ? 'red' : 'green' }}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={Description}
                      onInit={(editor) => {
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={handleEditorChange}
                    />
                  </div>
                  {validationError && <div style={{ color: 'red' }}>{validationError}</div>}
                  <br></br>
                </div>

              </div><br></br>

              <div className="d-flex align-items-center gap-2">
                <button type="button" onClick={onSubmitClick} className="btn btn-outline-primary font-weight-bolder ml-2 ">Submit</button>
                <button type="button" onClick={OnCancelClick} className="btn btn-outline-danger font-weight-bolder ml-2">Cancel</button>
                <button onClick={() => { navigate("/westKameng/viewDestination") }} type="button" className="btn btn-outline-primary font-weight-bolder ml-2" >View</button>
              </div>
            </div>
          </FormWithConstraints>
        </div>
      </div >
    </div >
  )
}

export default Destination









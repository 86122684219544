import React, { useContext } from 'react'
import { placeContext } from '../Context/PlaceContext'
import { Navigate } from 'react-router-dom'
import Cookies from 'js-cookie';

const ReviewAccess = ({children}) => {

  
  return (
    Cookies.get("ckUserType")==="Admin" ||Cookies.get("ckUserType")==="Hotel" ||Cookies.get("ckUserType")==="Restaurant"   ?  children : <Navigate to={"/westKameng/Dashboard"} />  
  )
}

export default ReviewAccess
import React, { useEffect, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { APIURL } from "../../Process";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { appTitle } from "../../Constants";
import { compressImage } from "../CommonComponents";
import Cookies from "js-cookie";

const WestKamengHistoryDetails = () => {
  const fileInputRef = useRef(null);
  ;
  const [isSubmit, setIsSubmit] = useState(false);
  const [isCancle, setIsCancle] = useState(false)
  
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state, "state");
  const [formData, setFormData] = useState({
    Title: {
      textValue:state?.Title || "",
      required: state?.HistoryType?.toLowerCase()==="history" ? true : false ,
    },
    HistoryType: {
      textValue:state?.HistoryType?.toLowerCase() || "",
      required: true,
    },
    Description: {
      textValue: state?.Description ||"",
      required: true,
    },
    Images:{
        textValue: state?.ImageDetail || "",
        required:  false  ,
        valid: true,
        type: "file",
        count:state?.ImageDetail?.length || 0
    }
  });
  const handleInputChange = (e) => {
    if(e.target.name==="HistoryType"){
      if(e.target.value==="tourist"){
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.value,
          },
          Title: {
           ...formData.Title,
           textValue:"",
           required:false
          },
          Images:{
            ...formData.Images,
            textValue: state?.ImageDetail || "",
            required:  false  ,
            valid: true,
            type: "file",
            count:state?.ImageDetail?.length || 0
        }
          
          
        });
      }else {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.value,
          },
          Title: {
            ...formData.Title,
            required:true
          }
        });
      }
    }else{
      setFormData({
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          textValue: e.target.value,
        },
      });
    }
 
  };
  const handleClearButtonClick = () => {
    fileInputRef.current.value = '';


};
  
  const handleFileChange = (e) => {
    if (
      ["jpg", "png", "jpeg"].includes(
        e.target.value.split(".")[e.target.value.split(".").length - 1]
      ) ||
      e.target.value === ""
    ) {
        if (state?.ImageDetail?.length + e.target.files.length > 5) {
          Swal.fire({
            title: "Cannot Select More Than 5 Images!",
            icon: "error",
            timer: 1500,
showConfirmButton: false,
          });
          return;
        }
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.files,
            valid: true,
            count: (state?.ImageDetail?.length || 0) + e.target.files.length,
          },
        });
    }
    else {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: "",
            valid: false,
          },
        });
      }
  };
  const handleSubmit=async(e)=>{
    setIsSubmit(true)
    e.preventDefault()
   console.log(formData,"formData")
   let validForm = true;
   
   for (let key in formData) {
    if (formData[key].required) {
      if (!formData[key].textValue) {
        validForm = false;
      }
      if (!validForm) {
        return;
      }
    }
    if (formData[key].type === "file" && formData[key].valid == false) {
      return;
    }
}

    const form = new FormData();
    if(state){
        form.append("WestKamengHistoryID",state?.WestKamengHistoryID)
      }
    form.append("Title", formData.Title.textValue);
    form.append("Description", formData.Description.textValue);
    form.append("HistoryType", formData.HistoryType.textValue);
    for (let i = 0; i < formData.Images.textValue.length; i++) {
   
        form.append("Images",  formData.Images.textValue[i] ? !(formData.Images.textValue[i] instanceof Blob) ? formData.Images.textValue[i] :  await compressImage(formData.Images.textValue[i],0.9) : "");
      }
      if(state){
        form.append("UpdatedByUserID",Cookies.get("ckUserID"))
        form.append("UpdatedByUserName",Cookies.get("ckUserName"))
      }else{
        form.append("CreatedByUserID",Cookies.get("ckUserID"))
        form.append("CreatedByUserName",Cookies.get("ckUserName"))
      }
      const config = { headers: { "Content-Type": "multipart/form-data" } }
      axios.post(`${APIURL}/WestKamengHistory/SetWestKamengHistory`, form, config)
        .then((res) => {
          console.log(res, "res")
          if(state && res.data?.status == 1 ){
            Swal.fire({
              title: "Data Updated successfully!",
              icon: "success",
              timer: 1500,
showConfirmButton: false
            });
            formData.HistoryType.textValue.toLowerCase() =="tourist" ? navigate("/westKameng/viewWestKamengTourismDetails") : navigate("/westKameng/viewWestKamengHistoryDetails")
          }
          else if(res.data?.status == 1 ) {
            Swal.fire({
              title: "Data Inserted successfully!",
              icon: "success",
              timer: 1500,
showConfirmButton: false
            });
            formData.HistoryType.textValue.toLowerCase() =="tourist" ? navigate("/westKameng/viewWestKamengTourismDetails") : navigate("/westKameng/viewWestKamengHistoryDetails")
          } 
          else if(res.data?.status==0){
            Swal.fire({
              title: res.data.message,
              icon: "error",
              timer: 1500,
showConfirmButton: false
            });
          }
        }
        )
        .catch((err)=>{
          console.log(err)
        })

  }
     const handleCancle=()=>{
      if(!state){
  setFormData({
    ...formData,
    Title: {
      ...formData.Title,
      textValue: "",
    },
    Description: {
      textValue: "",
      required: true,
    },
    Images:{
        textValue:"",
        required: false,
        valid: true,
        type: "file",
      
    }
  })
}else{
  setFormData({
    ...formData,
    Title: {
      ...formData.Title,
      textValue: "",
    },
    Description: {
      textValue: "",
      required: true,
    },
    Images:{
        textValue:"",
        required: false,
        valid: true,
        type: "file",
      
    }
  })

}
    setIsCancle(true)
    handleClearButtonClick()
  }
  return (
    <div className="body-wrapper" style={{backgroundColor:"#d3d3d3",minHeight:"100vh"}}>
      <div className="container-fluid">
        <div  className="card mt-4" style={{boxShadow: "2px 3px 15px"}}>
          <div className="px-3 pt-4 border-bottom">
            <h5>{appTitle} History Details</h5>
          </div>
          <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
            <div className="col-md-3 mb-2 ">
              <div className="form-group">
                        <label
                          htmlFor="hotelType"
                          className="form-label fw-semibold"
                        >
                          {" "}
                          History Type 
                        </label>
                        <select
                        disabled={state}
                          onChange={handleInputChange}
                          name="HistoryType"
                          value={formData.HistoryType.textValue}
                          className="form-control" 
                          style={{
                            border: `1px solid ${
                              isSubmit && !formData.HistoryType.textValue
                                ? "red"
                                : isSubmit
                                ? "green"
                                : "light-gray"
                            }  `,
                          }}
                        >
                          <option value={""} selected="selected">
                            --Select History Type--
                          </option>
                          <option value="tourist">Tourist</option>
                          <option value="history">History</option>
                        </select>
                        {isSubmit && !formData.HistoryType.textValue && (
                    <span className="text-danger">*</span>
                  )}
                      
                      </div>
                      </div>
              {formData?.HistoryType?.textValue?.toLowerCase() !=="tourist" && <div className="col-md-3 mb-2 ">
                <div className="form-group">
                  <label htmlFor="hotelName" className="form-label fw-semibold">
                    {" "}
                    Title <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    onChange={handleInputChange}
                    name="Title"
                    value={formData.Title.textValue}
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    style={{
                      border: `1px solid ${
                        isSubmit && !formData.Title.textValue
                          ? "red"
                          : isSubmit
                          ? "green"
                          : "light-gray"
                      }  `,
                    }}
                  ></input>
                  {isSubmit && !formData.Title.textValue && (
                    <span className="text-danger">*</span>
                  )}
                  {/* {hasSubmit && !formData.name.textValue && <span className='text-danger'>*</span>} */}
                </div>
              </div>}
              {/* <div className="col-md-3 mb-2 ">
                <div className="form-group">
                  <label htmlFor="HistoryType" className="form-label fw-semibold">
                    {" "}
                    History Type <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    onChange={handleInputChange}
                    name="HistoryType"
                    value={formData.HistoryType.textValue}
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    style={{
                      border: `1px solid ${
                        isSubmit && !formData.HistoryType.textValue
                          ? "red"
                          : isSubmit
                          ? "green"
                          : "light-gray"
                      }  `,
                    }}
                  ></input>
                  {isSubmit && !formData.HistoryType.textValue && (
                    <span className="text-danger">*</span>
                  )}
                </div>
              </div> */}
             
             {formData?.HistoryType?.textValue?.toLowerCase()!=="tourist" &&  <div className="col-md-3 mb-4">
                <div className="form-group mb-3">
                  <label className="form-label fw-semibold">
                    Detail Images
                    {state && (
                     <>{" ("} <span style={{ color: "gray" }}>
                        {formData.Images.count} Images Selected{" "}
                      </span> {") "}</>
                    )}
                   
                  </label>
                  <input
                    name="Images"
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept="image/jpeg, image/png, image/jpg"
                    multiple
                    className="form-control"
                    style={{
                      border: `1px solid ${
                        isSubmit && !formData.Images.valid
                          ? "red"
                          : isSubmit
                          ? "green"
                          : "light-gray"
                      }  `,
                    }}
                  />

                  {!formData?.Images?.valid && (
                    <span className="text-danger">
                      *please upload valid image{" "}
                    </span>
                  )}
                  {/* {hasSubmit && (!formData.image.textValue || !formData.image.textValue.length  )  && <span className='text-danger'>*</span>} */}
                </div>
              </div>}
              {/* <div className="col-md-3 mb-4">
                <div className="form-group">
                  <label
                    htmlFor="Description"
                    className="form-label fw-semibold"
                  >
                    {" "}
                    Description
                  </label>
                  <textarea
                    onChange={handleInputChange}
                    name="Description"
                    value={formData.Description.textValue}
                    rows="2"
                    cols="20"
                    placeholder="Enter Owner Address"
                    className="form-control"
                    style={{
                        border: `1px solid ${
                          isSubmit && !formData.Description.textValue
                            ? "red"
                            : isSubmit
                            ? "green"
                            : "light-gray"
                        }  `,
                      }}
                  />
                   {isSubmit && !formData.Description.textValue && (
                    <span className="text-danger">*</span>
                  )}
                
                </div>
              </div> */}
            {  <div className='col-md-12'>
                    <div className="form-group" style={{ width: '100%' }}>
                        <div className="img-thumbnail"  style={{
                          border: `1px solid ${
                            isSubmit && !formData.Description.textValue
                              ? "red"
                              : isSubmit
                              ? "green"
                              : "light-gray"
                          }  `,
                        }}>
                        <CKEditor
                       
                    editor={ ClassicEditor }
                    data={formData.Description.textValue}
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log(data)
                        // setLetterData(data)
                        setFormData((prev)=>({
                          ...prev,
                          Description:{
                            ...prev.Description,
                            textValue:data
                          }
                        }))
                    }}
                />     
                    {isSubmit && !formData.Description.textValue && (
                    <span className="text-danger">*</span>
                  )}           
                        </div>
                      {/* <span>uidahfadsads</span> */}
                        </div>
                    </div>}
            </div>
            <div className="d-flex align-items-center gap-2 mt-4">
                    <button
                      type="submit"
                      className="btn btn-outline-primary font-weight-bolder ml-2 "
                    >
                      Submit
                    </button>
                    <button
                    onClick={handleCancle}
                      type="button"
                      className="btn btn-outline-danger font-weight-bolder ml-2"
                    >
                      Cancel
                    </button>
                    <button 
                    onClick={()=>{formData.HistoryType.textValue.toLowerCase() =="tourist" ? navigate("/westKameng/viewWestKamengTourismDetails") : navigate("/westKameng/viewWestKamengHistoryDetails")}}
                      type="button"
                      className="btn btn-outline-primary font-weight-bolder ml-2"
                    >
                      View
                    </button>
                  </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WestKamengHistoryDetails;

import React, { useContext } from 'react'
import { placeContext } from '../Context/PlaceContext'
import { Navigate } from 'react-router-dom'
import Cookies from 'js-cookie';

const DashBoardAccess = ({children}) => {
   
  return (
    Cookies.get("ckUserType")==="Admin"  ? children : Cookies.get("ckUserType")==="Hotel" ? <Navigate to={"/westKameng/viewHotel"} /> :Cookies.get("ckUserType")==="Restaurant" ? <Navigate to={"/westKameng/viewRestaurant"} />  :""
  )
}

export default DashBoardAccess
import React, { useContext } from "react";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import ChangePasswordModal from "./ChangePasswordModal";
import { handleLogout } from "./CommonComponents";
import { placeContext } from "../Context/PlaceContext";
import { appTitle, appTitleCapital } from "../Constants";
import Cookies from "js-cookie";

const Header = ({ children }) => {
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState("");
  const headerPath = useLocation()
  console.log(headerPath, "headerPath")
  const [openModel, setOpenModel] = useState(false);
  const [showPasswordID, setShowPasswordID] = useState("");

  const location = useLocation();

  const [currentActiveNav, setCurrentActive] = useState(location.pathname);
  const { menuClass, setMenuClass } = useContext(placeContext);
  const changeMenuBar = () => {

    // Access the element with ID "main-wrapper" from index.html
    const element = document.getElementById('main-wrapper');
    //  debugger
    // Check if the element exists and has the class "xyz"
    if (element && element.classList.contains('show-sidebar')) {
      // If it has the class "xyz", remove it and add the class "differentClass"
      element.classList.remove('show-sidebar');
      // element.classList.add('show-sidebar');
    } else {
      // If it doesn't have the class "xyz", add it and remove the class "differentClass"
      element.classList.add('show-sidebar')
      // element.classList.remove('show-sidebar');
    }
  };
  const handleNavCollapse = (name) => {
    setMenuClass({
      ...menuClass,
      [name]: menuClass[name] == "in" ? "" : "in"
    })
  }

  const handleNavLink = (name) => {
    // const element = document.getElementsByClassName("")
    // if( element.classList.contains('selected')){
    //   element.classList.remove('selected');
    // }

    setCurrentActive(name)
  };
  return (
    <>

      {openModel && (
        <ChangePasswordModal
          openModel={openModel}
          setOpenModel={setOpenModel}
          UserType={Cookies.get("ckUserType")}
          id={Cookies.get("ckUserID")}
        />
      )}
      <header
        className={`bg-white app-header mb-3 `}
        style={{ boxShadow: "-1px -1px 10px" }}
      >
        <nav className="navbar navbar-expand-lg navbar-light">
          <ul className="navbar-nav">
            <li className="nav-item d-block d-lg-none">
              <div onClick={changeMenuBar}
                className="nav-link sidebartoggler ms-n3"
                id="sidebarCollapse"
              >
                <i className="ti ti-menu-2" />
              </div>
            </li>
            <li className="nav-item d-none d-lg-block">
              <NavLink onClick={() => handleNavLink("/westKameng/Dashboard")} to="/westKameng/Dashboard" className="text-nowrap nav-link">
                <img
                  src={require("../Assets/logo.jpg")}
                  className="rounded-circle me-2"
                  width={35}
                  height={35}
                  alt="User Avatar"
                />{" "}
                <h2 className="mt-1">{appTitleCapital}</h2>
              </NavLink>
            </li>
          </ul>
          <div className="d-block d-lg-none">
            <a className="text-nowrap nav-link">
              <h5>{appTitle}</h5>
            </a>
          </div>
          <button
            className="navbar-toggler p-0 border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="p-2">
              <i className="ti ti-dots fs-7" />
            </span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <div className="d-flex align-items-center justify-content-between px-0 px-lg-8">
              <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                <li className="nav-item dropdown">
                  <div
                    className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                    aria-labelledby="drop2"
                  >
                    <div className="d-flex align-items-center justify-content-between py-3 px-7">
                      <h5 className="mb-0 fs-5 fw-semibold">User Profile</h5>
                    </div>

                    <div className="d-flex align-items-center py-9 mx-7 border-bottom">
                      <img
                        src={require("../Assets/avatar.jpg")}
                        className="rounded-circle"
                        width={100}
                        height={100}
                        alt="true"
                      />
                    </div>

                    <div className="d-grid py-4 px-7 pt-8">
                      <a
                        onClick={() => handleLogout(navigate)}
                        className="btn btn-outline-primary"
                      >
                        Log Out
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <div className="d-flex align-items-center justify-content-between px-0 px-lg-8">
              <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link pe-0"
                    href=""
                    id="drop2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center">
                      <div className="user-profile-img">
                        <img
                          src={require("../Assets/logo.jpg")}
                          className="rounded-circle"
                          width={35}
                          height={35}
                          alt="User Avatar"
                        />
                      </div>
                    </div>
                  </a>
                  <div
                    className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                    aria-labelledby="drop2"
                  >
                    <div className="d-flex align-items-center justify-content-between py-3 px-7">
                      <h5 className="mb-0 fs-5 fw-semibold">User Profile</h5>
                    </div>

                    <div className="d-flex align-items-center py-4 px-7 border-bottom">
                      <img
                        src={require("../Assets/logo.jpg")}
                        className="rounded-circle"
                        width={100}
                        height={100}
                        alt="User Avatar"
                      />
                    </div>

                    <div className="d-grid py-4 px-7 pt-8">
                      <button
                        onClick={() => handleLogout(navigate)}
                        className="btn btn-outline-danger btn-lg"
                      >
                        Log Out
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <aside className={` left-sidebar`}>
        <nav className="pt-2 sidebar-nav scroll-sidebar">
          <ul id="sidebarnav" className="in addFont">
            <li className="nav-small-cap">
              <i className="ti ti-dots nav-small-cap-icon fs-4" />
              <span className="hide-menu">Home</span>
            </li>
            {Cookies.get("ckUserType") == "Admin" && (
              <div className="sidebar-item">
                <NavLink onClick={() => handleNavLink("/westKameng/Dashboard")} to="/westKameng/Dashboard" className={`sidebar-link  ${currentActiveNav === "/westKameng/Dashboard"
                  ? "active"
                  : ""
                  }`}>
                  <span>
                    <i className="ti ti-home-2" />
                  </span>
                  <span className="hide-menu">Dashboard</span>
                </NavLink>
              </div>
            )}
            <li className="nav-small-cap">
              <i className="ti ti-dots nav-small-cap-icon fs-4" />
              <span className="hide-menu">Place Details</span>
            </li>
            {Cookies.get("ckUserType") == "Admin" && (
              <div className="sidebar-item">
                <NavLink onClick={() => handleNavLink("/westKameng/ViewUserDetails")} to="/westKameng/ViewUserDetails" className={`sidebar-link  ${currentActiveNav === "/westKameng/ViewUserDetails"
                  ? "active"
                  : ""
                  }`}>
                  <span className="hide-menu"> User Details</span>
                </NavLink>
              </div>
            )}
            {Cookies.get("ckUserType") == "Admin" && (
              <div className="sidebar-item">
                <a
                  // onClick={()=>handleNavCollapse("place")}
                  className={`sidebar-link has-arrow  ${["/westKameng/addplacedetails", "/westKameng/viewplacedetails"].includes(currentActiveNav)
                    ? "active"
                    : ""
                    }`}
                >
                  <span className="rounded-3">
                    <i className="ti ti-" />
                  </span>
                  <span className="">Place Details</span>
                </a>
                <ul className={`collapse first-level ${menuClass["place"]}`}>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/addplacedetails")} to="/westKameng/addplacedetails" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">Add Place Details</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/viewplacedetails")} to="/westKameng/viewplacedetails" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">View Place Details</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}
            {Cookies.get("ckUserType") === "Admin" && (
              <div className="sidebar-item">
                <a
                  className={`sidebar-link has-arrow  ${["/westKameng/addhoteldetails", "/westKameng/viewHotel", "/westKameng/viewBookingDetails"].includes(currentActiveNav)
                    ? "active"
                    : ""
                    }`}
                  href="#"
                  aria-expanded="false"
                  onClick={() => handleNavCollapse("stay")}
                >
                  <span className="rounded-3">
                    <i className="ti ti-" />
                  </span>
                  <span className="hide-menu">Stay Details</span>
                </a>
                <ul aria-expanded="false" className={`collapse first-level ${menuClass["stay"]}`}>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/addhoteldetails")} to="/westKameng/addhoteldetails" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">Add Stay Details</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/viewHotel")} to="/westKameng/viewHotel" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">View Stay Details</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/viewBookingDetails")} to="/westKameng/viewBookingDetails" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">Booking Details</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}
            {Cookies.get("ckUserType") === "Hotel" && (
              <div className="sidebar-item">
                <NavLink onClick={() => handleNavLink("/westKameng/viewHotel")} to="/westKameng/viewHotel" className="sidebar-link">
                  <span className="hide-menu">View Stay Details</span>
                </NavLink>
              </div>
            )}

            {Cookies.get("ckUserType") === "Admin" && (
              <div className="sidebar-item">
                <a
                  className={`sidebar-link has-arrow  ${["/westKameng/addRestaurant", "/westKameng/viewRestaurant"].includes(currentActiveNav)
                    ? "active"
                    : ""
                    }`}
                  href="#"
                  aria-expanded="false"
                  onClick={() => handleNavCollapse("restaurant")}
                >
                  <span className="rounded-3">
                    <i className="ti ti-" />
                  </span>
                  <span className="hide-menu">Restaurant Details</span>
                </a>
                <ul aria-expanded="false" className={`collapse first-level ${menuClass["restaurant"]}`}>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/addRestaurant")} to="/westKameng/addRestaurant" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">
                        Add Restaurant Details
                      </span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/viewRestaurant")} to="/westKameng/viewRestaurant" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">
                        View Restaurant Details
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}

            {((Cookies.get("ckUserType") === "Hotel" &&
              Cookies.get("IsRestaurantAvailable")?.toLowerCase() == "yes") ||
              Cookies.get("ckUserType") === "Restaurant") && (
                <div className="sidebar-item">
                  <NavLink onClick={() => handleNavLink("/westKameng/viewRestaurant")} to="/westKameng/viewRestaurant" className="sidebar-link">
                    <span className="hide-menu">View Restaurant Details</span>
                  </NavLink>
                </div>
              )}

            {(
              Cookies.get("ckUserType") == "Hotel") && (
                <div className="sidebar-item">
                  <NavLink onClick={() => handleNavLink("/westKameng/viewBookingDetails")} to="/westKameng/viewBookingDetails" className="sidebar-link">
                    <span className="hide-menu">Booking Details</span>
                  </NavLink>
                </div>
              )}
            {Cookies.get("ckUserType") === "Admin" && (
              <div className="sidebar-item">
                <a
                  className={`sidebar-link has-arrow  ${["/westKameng/addPackageDetails", "/westKameng/viewPackageDetails", "/westKameng/viewPackageEnquiry"].includes(currentActiveNav)
                    ? "active"
                    : ""
                    }`}
                  href="#"
                  aria-expanded="false"
                  onClick={() => handleNavCollapse("restaurant")}
                >
                  <span className="rounded-3">
                    <i className="ti ti-" />
                  </span>
                  <span className="hide-menu">Package Details</span>
                </a>
                <ul aria-expanded="false" className={`collapse first-level ${menuClass["package"]}`}>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/addPackageDetails")} to="/westKameng/addPackageDetails" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">
                        Add Package Details
                      </span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/viewPackageDetails")} to="/westKameng/viewPackageDetails" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">
                        View package Details
                      </span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/viewPackageEnquiry")} to="/westKameng/viewPackageEnquiry" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">
                        View Package Enquiry
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}


            {Cookies.get("ckUserType") == "Admin" && (
              <div className="sidebar-item">
                <a
                  className={`sidebar-link has-arrow  ${["/westKameng/addWestKamengHistoryDetails", "/westKameng/viewWestKamengHistoryDetails", "/westKameng/viewWestKamengTourismDetails"].includes(currentActiveNav)
                    ? "active"
                    : ""
                    }`}
                  href="#"
                  aria-expanded="false"
                  onClick={() => handleNavCollapse("history")}
                >
                  <span className="rounded-3">
                    <i className="ti ti-" />
                  </span>
                  <span style={{ margin: "0 1em" }} className="hide-menu">
                    {appTitle} History
                  </span>
                </a>
                <ul aria-expanded="false" className={`collapse first-level ${menuClass["history"]}`}>
                  {Cookies.get("ckUserType") == "Admin" && (
                    <li className="sidebar-item">
                      <NavLink
                        onClick={() => handleNavLink("/westKameng/addWestKamengHistoryDetails")} to="/westKameng/addWestKamengHistoryDetails"
                        className="sidebar-link"
                      >
                        <i className="ti ti-circle" />
                        <span className="hide-menu">Add Details</span>
                      </NavLink>
                    </li>
                  )}
                  <li className="sidebar-item">
                    <NavLink
                      onClick={() => handleNavLink("/westKameng/viewWestKamengHistoryDetails")} to="/westKameng/viewWestKamengHistoryDetails"
                      className="sidebar-link"
                    >
                      <i className="ti ti-circle" />
                      <span className="hide-menu">View Details History</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink
                      onClick={() => handleNavLink("/westKameng/viewWestKamengTourismDetails")} to="/westKameng/viewWestKamengTourismDetails"
                      className="sidebar-link"
                    >
                      <i className="ti ti-circle" />
                      <span className="hide-menu">View Tourism History</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}
            {(
              Cookies.get("ckUserType") == "Hotel" ||
              Cookies.get("ckUserType") == "Restaurant") && (
                <div className="sidebar-item">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    onClick={() => handleNavLink("/westKameng/viewReviews")} to="/westKameng/viewReviews"
                    className="sidebar-link"
                  >
                    <span className="hide-menu">Review</span>
                  </NavLink>
                </div>
              )}

            {Cookies.get("ckUserType") == "Admin" && (
              <div className="sidebar-item">
                <a
                  className={`sidebar-link has-arrow  ${["/westKameng/AddTermsAndCondition", "/westKameng/ViewTermsAndCondition", "/westKameng/viewReviews",
                    "/westKameng/addbanner", "/westKameng/viewBanner",
                    "/westKameng/addAboutUs",
                    "/westKameng/viewAboutUs",
                    "/westKameng/westKameng/addContactUs",
                    "/westKameng/westKameng/viewContactUs",
                    "/westKameng/westKameng/addTourOperator",
                    "/westKameng/westKameng/viewTourOperator",
                    "/westKameng/westKameng/addDestination",
                    "/westKameng/westKameng/viewDestination"].includes(currentActiveNav)
                    ? "active"
                    : ""
                    }`}
                  href="#"
                  aria-expanded="false"
                  onClick={() => handleNavCollapse("master")}
                >
                  <span className="rounded-3">
                    <i className="ti ti-" />
                  </span>
                  <span className="hide-menu">Other Details</span>
                </a>
                <ul style={{
                  overflowY: "auto",
                  height: "500px"
                }} aria-expanded="false" className={`collapse first-level ${menuClass["master"]}`}>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/addWeatherAlertDetails")} to="/westKameng/addWeatherAlertDetails" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">Add Weather Alert</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/viewWeatherAlertDetails")} to="/westKameng/viewWeatherAlertDetails" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">View Weather Alert</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/addGeneralActivity")} to="/westKameng/addGeneralActivity" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">Add General Activity</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/viewGeneralActivity")} to="/westKameng/viewGeneralActivity" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">View General Activity</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/addNearByToilet")} to="/westKameng/addNearByToilet" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">Add Near By Toilet</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/viewNearByToilet")} to="/westKameng/viewNearByToilet" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">View  Near By Toilet</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/addUserAdmin")} to="/westKameng/addUserAdmin" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">Add Admin User</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/viewUserAdmin")} to="/westKameng/viewUserAdmin" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">View Admin User</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink
                      onClick={() => handleNavLink("/westKameng/AddTermsAndCondition")} to="/westKameng/AddTermsAndCondition"
                      className="sidebar-link"
                    >
                      <i className="ti ti-circle" />
                      <span className="hide-menu">
                        Add Terms And Condition
                      </span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink
                      onClick={() => handleNavLink("/westKameng/ViewTermsAndCondition")} to="/westKameng/ViewTermsAndCondition"
                      className="sidebar-link"
                    >
                      <i className="ti ti-circle" />
                      <span className="hide-menu">
                        View Terms And Condition
                      </span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      onClick={() => handleNavLink("/westKameng/viewReviews")} to="/westKameng/viewReviews"
                      className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">Review</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/addbanner")} to="/westKameng/addbanner" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">Add Banner</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/viewBanner")} to="/westKameng/viewBanner" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">View Banner</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/addAboutUs")} to="/westKameng/addAboutUs" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">Add AboutUs</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/viewAboutUs")} to="/westKameng/viewAboutUs" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">View AboutUs</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/addContactUs")} to="/westKameng/addContactUs" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">Add ContactUs</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/viewContactUs")} to="/westKameng/viewContactUs" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">View ContactUs</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/addTourOperator")} to="/westKameng/addTourOperator" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">Add Tour Operator</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/viewTourOperator")} to="/westKameng/viewTourOperator" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">View Tour Operator</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/addDestination")} to="/westKameng/addDestination" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">Add Destination</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink onClick={() => handleNavLink("/westKameng/viewDestination")} to="/westKameng/viewDestination" className="sidebar-link">
                      <i className="ti ti-circle" />
                      <span className="hide-menu">View Destination</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}
            {(Cookies.get("ckUserType") == "Hotel" ||
              Cookies.get("ckUserType") == "Restaurant" ||
              Cookies.get("ckUserType") == "Admin") && (
                <div className="sidebar-item">
                  <a style={{ cursor: "pointer" }} className="sidebar-link">
                    <span
                      onClick={() => setOpenModel(true)}
                      className="hide-menu"
                    >
                      Change Password
                    </span>
                  </a>
                </div>
              )}
          </ul>
          <div className="unlimited-access hide-menu bg-light-primary position-relative my-7 rounded d-block d-lg-none">
            <div className="d-flex">
              <div className="unlimited-access-title">
                <h6 className="fw-semibold fs-4 mb-6 text-dark w-85">
                  Unlimited Access
                </h6>
                <button className="btn btn-primary fs-2 fw-semibold lh-sm">
                  Signup
                </button>
              </div>
              <div className="unlimited-access-img">
                <img
                  src="../../dist/images/backgrounds/rocket.png"
                  alt="true"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </nav>
      </aside>

    </>
  );
};

export default Header;
